import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getLeagueTypeForDL,
  getPath,
  getTranslations,
  isAndroid,
  isIOS,
} from "../../../Common/utils";
import { league } from "../../../redux/actions";
import {
  CreateALeague,
  CtaButton,
  Overlays,
} from "../../../ReusableComponents";
import useDataLayer from "../../../Common/hooks/useDataLayer";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../../Common/constants";
import useTournamentScanario from "../../../Common/hooks/useTournamentScanario";

const CreateLeague = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const { toggleCreateOverlay, createLeagueData } = useSelector(
    (state) => state.league
  );
  const translationState = useSelector((state) => state.translation);
  const userState = useSelector((state) => state.user);

  const pushToDataLayer = useDataLayer();

  const webview = userState?.webview;

  const toggleCreateLeagueOverlay = () => {
    dispatch(league.toggleCreateLeagueOverlay());
  };

  const pushCreateLeagueDataLayer = () => {
    const dataLayerObject = {
      actionType: "Create League",
      clickText: getTranslations(
        "league_landing_create_league_cta",
        "Create League"
      ),
      path: "/leagues",
      pageName: "Leagues",
      leagueType: getLeagueTypeForDL(),
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("navigation_click", dataLayerObject);
  };

  const handleClick = () => {
    pushCreateLeagueDataLayer();
    toggleCreateLeagueOverlay();
  };

  const handleConfirm = () => {
    navigate(getPath(translationState, "leagueSelectTeam"), {
      state: {
        type: "create-league",
      },
    });
  };

  const canCreateLeague = () => {
    let isValid = true;
    for (const key in createLeagueData) {
      if (Object.hasOwnProperty.call(createLeagueData, key)) {
        const element = createLeagueData[key];
        if (!element) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  useEffect(() => {
    if (toggleCreateOverlay) {
      toggleCreateLeagueOverlay();
    }
  }, []);

  return (
    <>
      <CtaButton
        btnCls="si-btn si-btn__secondary"
        iconCls="f1i-add"
        btnText={getTranslations(
          "league_landing_create_league_cta",
          "Create League"
        )}
        ariaLabel={getTranslations(
          "league_landing_create_league_cta",
          "Create League"
        )}
        btnState={disableCTAs}
        onClickProp={() => handleClick()}
      />
    </>
  );
};

export { CreateLeague };
