import { FETCH_DRIVERS, FETCH_RACE_WEEK_DRIVERS } from "../../constants/driver";
import { feeds } from "../../apis/services";

export const fetchDrivers = (params) => {
  return {
    type: FETCH_DRIVERS,
    payload: feeds.getDriversList(params),
  };
};

export const fetchRaceWeekDrivers = (params) => {
  return {
    type: FETCH_RACE_WEEK_DRIVERS,
    payload: feeds.getDriversList(params),
  };
};
