export const adsConfig = {
  home: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_homepage_2023",
    slotId: "div-gpt-ad-1676892619292-0",
    sizes: [
      [300, 600],
      [320, 50],
      [970, 250],
      [160, 600],
      [728, 90],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  createTeamAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_create_a_team_2023_atf",
    slotId: "div-gpt-ad-1676894694859-0",
    sizes: [
      [320, 50],
      [320, 100],
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 50],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [728, 90],
      ],
    ],
  },
  createTeamBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_create_a_team_2023_btf",
    slotId: "div-gpt-ad-1677590207859-0",
    sizes: [
      [320, 100],
      [320, 50],
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [300, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  createTeamDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_create_a_team_2023_desktop",
    slotId: "div-gpt-ad-1676904296467-0",
    sizes: [
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  createTeamTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_create_a_team_2023_tablet",
    slotId: "div-gpt-ad-1676904528507-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  createTeamMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_create_a_team_2023_mobile",
    slotId: "div-gpt-ad-1676904713170-0",
    sizes: [
      [320, 50],
      [300, 250],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  manageTeamAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_manage_team_2023_atf",
    slotId: "div-gpt-ad-1676905896719-0",
    sizes: [
      [970, 250],
      [300, 250],
      [728, 90],
      [320, 100],
      [320, 50],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  manageTeamBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_manage_team_2023_Btf",
    slotId: "div-gpt-ad-1677590246291-0",
    sizes: [
      [320, 50],
      [320, 100],
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [300, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  manageTeamDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_manage_team_2023_desktop",
    slotId: "div-gpt-ad-1676906957177-0",
    sizes: [
      [970, 250],
      [728, 90],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  manageTeamTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_manage_team_2023_tablet",
    slotId: "div-gpt-ad-1676907047935-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  manageTeamMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_manage_team_2023_mobile",
    slotId: "div-gpt-ad-1676907189712-0",
    sizes: [
      [320, 100],
      [300, 250],
      [320, 50],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  leaguesAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_leagues_2023_atf",
    slotId: "div-gpt-ad-1677063544226-0",
    sizes: [
      [320, 50],
      [970, 250],
      [300, 250],
      [728, 90],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 100],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  leaguesBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_leagues_2023_btf",
    slotId: "div-gpt-ad-1677590284034-0",
    sizes: [
      [300, 250],
      [320, 100],
      [320, 50],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [300, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  leaguesDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_leagues_2023_desktop",
    slotId: "div-gpt-ad-1677063732211-0",
    sizes: [
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  leaguesTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_leagues_2023_tablet",
    slotId: "div-gpt-ad-1677063841262-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  leaguesMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_leagues_2023_mobile",
    slotId: "div-gpt-ad-1677063911044-0",
    sizes: [
      [320, 50],
      [300, 250],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  viewAllPrivateLeaguesAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_private_2023_atf",
    slotId: "div-gpt-ad-1677065826121-0",
    sizes: [
      [728, 90],
      [300, 250],
      [970, 250],
      [320, 50],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  viewAllPrivateLeaguesBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_private_2023_btf",
    slotId: "div-gpt-ad-1677590363224-0",
    sizes: [
      [320, 50],
      [300, 250],
      [320, 100],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  viewAllPrivateLeaguesDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_private_2023_desktop",
    slotId: "div-gpt-ad-1677077791427-0",
    sizes: [
      [970, 250],
      [728, 90],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  viewAllLeaguesPrivateTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_private_2023_tablet",
    slotId: "div-gpt-ad-1677077906613-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  viewAllLeaguesPrivateMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_private_2023_mobile",
    slotId: "div-gpt-ad-1677077988735-0",
    sizes: [
      [320, 50],
      [320, 100],
      [300, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  viewAllPublicLeaguesAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_public_2023_atf",
    slotId: "div-gpt-ad-1677064132373-0",
    sizes: [
      [320, 100],
      [320, 50],
      [728, 90],
      [300, 250],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  viewAllPublicLeaguesBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_public_2023_btf",
    slotId: "div-gpt-ad-1677590330458-0",
    sizes: [
      [320, 50],
      [320, 100],
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 50],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  viewAllPublicLeaguesDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_public_2023_desktop",
    slotId: "div-gpt-ad-1677064989585-0",
    sizes: [
      [970, 250],
      [728, 90],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  viewAllPublicLeaguesTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_public_2023_tablet",
    slotId: "div-gpt-ad-1677065135458-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  viewAllPublicLeaguesMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_view_all_leagues_public_2023_mobile",
    slotId: "div-gpt-ad-1677065234673-0",
    sizes: [
      [320, 50],
      [300, 250],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  leagueTeamSelectAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_league_team_select_2023_atf",
    slotId: "div-gpt-ad-1677078099449-0",
    sizes: [
      [320, 50],
      [320, 100],
      [728, 90],
      [300, 250],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  leagueTeamSelectBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_league_team_select_2023_btf",
    slotId: "div-gpt-ad-1677590408131-0",
    sizes: [
      [320, 100],
      [300, 250],
      [320, 50],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  statisticsAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_statistics_2023_atf",
    slotId: "div-gpt-ad-1677078677777-0",
    sizes: [
      [728, 90],
      [300, 250],
      [970, 250],
      [320, 50],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  statisticsBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_statistics_2023_btf",
    slotId: "div-gpt-ad-1677590443627-0",
    sizes: [
      [300, 250],
      [320, 50],
      [320, 100],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  statisticsDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_statistics_2023_desktop",
    slotId: "div-gpt-ad-1677078860340-0",
    sizes: [
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  statisticsTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_statistics_2023_tablet",
    slotId: "div-gpt-ad-1677078920606-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  statisticsMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_statistics_2023_mobile",
    slotId: "div-gpt-ad-1677079007629-0",
    sizes: [
      [320, 100],
      [300, 250],
      [320, 50],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  achievementsBadgesAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_achievements_and_badges_2023_atf",
    slotId: "div-gpt-ad-1677079267238-0",
    sizes: [
      [320, 50],
      [320, 100],
      [728, 90],
      [300, 250],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  achievementsBadgesBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_achievements_and_badges_2023_btf",
    slotId: "div-gpt-ad-1677590135239-0",
    sizes: [
      [320, 50],
      [300, 250],
      [320, 100],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  prizesAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_prizes_2023_atf",
    slotId: "div-gpt-ad-1677079664087-0",
    sizes: [
      [970, 250],
      [320, 100],
      [300, 250],
      [728, 90],
      [320, 50],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  prizesBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_prizes_2023_btf",
    slotId: "div-gpt-ad-1677590484371-0",
    sizes: [
      [300, 250],
      [320, 50],
      [320, 100],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  prizesDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_prizes_2023_desktop",
    slotId: "div-gpt-ad-1677080015909-0",
    sizes: [
      [970, 250],
      [728, 90],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  prizesTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_prizes_2023_tablet",
    slotId: "div-gpt-ad-1677080127888-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  prizesMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_prizes_2023_mobile",
    slotId: "div-gpt-ad-1677080193100-0",
    sizes: [
      [300, 250],
      [320, 50],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  howToPlayAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_how_to_play_2023_atf",
    slotId: "div-gpt-ad-1677080326239-0",
    sizes: [
      [970, 250],
      [320, 100],
      [300, 250],
      [728, 90],
      [320, 50],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  howToPlayBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_how_to_play_2023_btf",
    slotId: "div-gpt-ad-1677590513959-0",
    sizes: [
      [300, 250],
      [320, 50],
      [320, 100],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  howToPlayDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_how_to_play_2023_desktop",
    slotId: "div-gpt-ad-1677080456567-0",
    sizes: [
      [970, 250],
      [728, 90],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  howToPlayTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_how_to_play_2023_tablet",
    slotId: "div-gpt-ad-1677080511022-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  howToPlayMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_how_to_play_2023_mobile",
    slotId: "div-gpt-ad-1677080743037-0",
    sizes: [
      [300, 250],
      [320, 100],
      [320, 50],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  gameRulesAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_game_rules_2023_atf",
    slotId: "div-gpt-ad-1677080849985-0",
    sizes: [
      [320, 100],
      [300, 250],
      [728, 90],
      [320, 50],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  gameRulesBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_game_rules_2023_btf",
    slotId: "div-gpt-ad-1677590556781-0",
    sizes: [
      [320, 50],
      [300, 250],
      [320, 100],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  gameRulesDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_game_rules_2023_desktop",
    slotId: "div-gpt-ad-1677080975121-0",
    sizes: [
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  gameRulesTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_game_rules_2023_tablet",
    slotId: "div-gpt-ad-1677081023920-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  gameRulesMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_game_rules_2023_mobile",
    slotId: "div-gpt-ad-1677081082943-0",
    sizes: [
      [320, 50],
      [300, 250],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
  faqsAboveFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_faqs_2023_atf",
    slotId: "div-gpt-ad-1677081154192-0",
    sizes: [
      [320, 50],
      [320, 100],
      [728, 90],
      [970, 250],
      [300, 250],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  faqsBelowFold: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_faqs_2023_btf",
    slotId: "div-gpt-ad-1677590591998-0",
    sizes: [
      [300, 250],
      [320, 100],
      [320, 50],
      [728, 90],
      [970, 250],
    ],
    sizeMapping: [
      [
        [320, 50],
        [320, 100],
      ],
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  faqsDesktop: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_faqs_2023_desktop",
    slotId: "div-gpt-ad-1677081276668-0",
    sizes: [
      [970, 250],
      [728, 90],
    ],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
      [
        [996, 0],
        [970, 250],
      ],
    ],
  },
  faqsTablet: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_faqs_2023_tablet",
    slotId: "div-gpt-ad-1677081333998-0",
    sizes: [728, 90],
    sizeMapping: [
      [
        [720, 0],
        [728, 90],
      ],
    ],
  },
  faqsMobile: {
    adUnitId:
      "/21703060983/F1_SportzInteractive_Fantasy_2023/fantasy_faqs_2023_mobile",
    slotId: "div-gpt-ad-1677081394222-0",
    sizes: [
      [300, 250],
      [320, 50],
      [320, 100],
    ],
    sizeMapping: [
      [
        [320, 0],
        [320, 100],
      ],
    ],
  },
};
