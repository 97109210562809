import React from "react";
import { H2hNoData } from "../../H2hNoData";
import { getTranslations } from "../../../Common/utils";

const H2HTeamLock = () => {
  return (
    <>
      <H2hNoData
        h2hIcon={"f1i-lock"}
        h2hText={getTranslations(
          "league_hth_view_after_team_lock",
          "View after team lock"
        )}
      />
    </>
  );
};

export default H2HTeamLock;
