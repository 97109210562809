import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPath, scrollToTop } from "../Common/utils";
import useTournamentScanario from "../Common/hooks/useTournamentScanario";

const EosRedirectRoute = ({ children }) => {
  const navigate = useNavigate();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;
  const translationState = useSelector((state) => state.translation);

  useEffect(() => {
    //scroll Top
    scrollToTop(100);
    if (disableCTAs) {
      navigate(getPath(translationState, "home"));
    }
  }, [disableCTAs, navigate, translationState]);
  return children;
};

export default EosRedirectRoute;
