import { useMemo } from "react";
import { currentFixture } from "../utils/fixture";
import { useSelector } from "react-redux";

const useSelectedRaceFixture = (
  selectedRaceOption,
  isPreviousFixtureOnEosForAllRaces
) => {
  const fixtureState = useSelector((state) => state.fixture);

  const selectedRaceFixture = useMemo(() => {
    const selectedRaceGamedayId = selectedRaceOption?.id;
    if (selectedRaceGamedayId === 0) {
      if (isPreviousFixtureOnEosForAllRaces) {
        return fixtureState?.list?.[fixtureState?.list?.length - 1];
      }
      return currentFixture(fixtureState);
    } else {
      return fixtureState?.list?.find(
        (fixture) => fixture?.GamedayId === selectedRaceGamedayId
      );
    }
  }, [selectedRaceOption, fixtureState, isPreviousFixtureOnEosForAllRaces]);

  return selectedRaceFixture;
};

export default useSelectedRaceFixture;
