import { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { ViewPromotionContext } from "./ViewPromotionContext";

const ViewPromotionContextProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [viewPromotion, setViewPromotion] = useState(false);

  // * TODO: uncomment this useEffect when the pageView event is enabled on a per page basis and not on menu click
  // useEffect(() => {
  //   setViewPromotion(false);
  // }, [pathname]);

  const updateViewPromotion = (value) => {
    setViewPromotion(value);
  };

  return (
    <ViewPromotionContext.Provider
      value={{ viewPromotion, updateViewPromotion }}
    >
      {children}
    </ViewPromotionContext.Provider>
  );
};

export const useViewPromotionContext = () => {
  const viewPromotionContext = useContext(ViewPromotionContext);

  if (!viewPromotionContext) {
    throw new Error(
      "useViewPromotionContext is defined outside of the ViewPromotionContextProvider bounds"
    );
  }

  return viewPromotionContext;
};

export default ViewPromotionContextProvider;
