const miniLeaguesData = [
  {
    name: "Late But Not Last",
    prizes: 3,
    isActive: true,
  },
  {
    name: "Street Racers",
    prizes: 3,
    isActive: false,
  },
];

function formatLeagueName(name) {
  return name?.toLowerCase()?.replace(/ /g, "_");
}

function getPrizeMedal(prizeMedal) {
  switch (prizeMedal) {
    case 0:
      return "gold";
    case 1:
      return "silver";
    case 2:
      return "bronze";
    default:
      return "";
  }
}

miniLeaguesData.forEach((mini, index) => {
  const emptyArr = [];
  const mainDescription = `${formatLeagueName(
    mini.name
  )}_prize_main_description`;

  for (let i = 0; i < mini.prizes; i++) {
    emptyArr.push({
      type: getPrizeMedal(i),
      title: `${formatLeagueName(mini.name)}_${i + 1}_prize_heading`,
      caption: `${formatLeagueName(mini.name)}_${i + 1}_prize_description`,
      position: `prizes_${i + 1}_prize`,
      thumb: `${formatLeagueName(mini.name)}_prize_${i + 1}.png`,
    });
  }
  miniLeaguesData[index].data = emptyArr;
  miniLeaguesData[index].mainDescription = mainDescription;
});

export const getMiniLeaguesData = (miniLeaguesData) => {
  const updatedMiniLeaguesData = miniLeaguesData?.map((mini, index) => {
    const data = [];
    const mainDescription = `${formatLeagueName(
      mini?.name
    )}_prize_main_description`;

    for (let i = 0; i < mini?.prizes; i++) {
      data.push({
        type: getPrizeMedal(i),
        title: `${formatLeagueName(mini?.name)}_${i + 1}_prize_heading`,
        caption: `${formatLeagueName(mini?.name)}_${i + 1}_prize_description`,
        position: `prizes_${i + 1}_prize`,
        thumb: `${formatLeagueName(mini?.name)}_prize_${i + 1}.png`,
      });
    }

    return {
      ...mini,
      data,
      mainDescription,
      isActive: index === 0 ? true : false,
    };
  });
  return updatedMiniLeaguesData;
};

export { miniLeaguesData };
