import React from "react";

const Alert = ({ content, component, showAlertIcon = true }) => {
  return (
    <>
      <div className="si-alert__wrap">
        {showAlertIcon && (
          <div className="si-alert__icon">
            <i className="f1i-error"></i>
          </div>
        )}
        <>{content}</>
        <>{component}</>
      </div>
    </>
  );
};

export { Alert };
