import React, { useEffect, useMemo, useRef, useState } from "react";
import { SectionHeadingWithFiller } from "../HeadingWithFiller";
import PreviousRaceCard from "./PreviousRaceCard";
import {
  getTranslations,
  isAndroid,
  isIOS,
  isMobile,
} from "../../Common/utils";
import { MatchHeader } from "../MatchHeader";
import { getFixtureFromMatchDayId } from "../../Common/utils/fixture";
import { useSelector } from "react-redux";
import useIntersectionObserver from "../../Common/hooks/useIntersectionObserver";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useDataLayer from "../../Common/hooks/useDataLayer";

const PreviousRaceSection = ({
  previousRaceResultsData,
  previousMatchdayId,
}) => {
  const previousRaceResults = useMemo(() => {
    const results = [...previousRaceResultsData];
    results.sort((item1, item2) => item1.teamNo - item2.teamNo);

    return results;
  }, [previousRaceResultsData]);
  const fixtureState = useSelector((state) => state.fixture);
  const [previousFixture, setPreviousFixture] = useState(() => {
    return getFixtureFromMatchDayId(fixtureState, previousMatchdayId);
  });
  const [teamOneAccordion, setTeamOneAccordion] = useState(true);
  const [teamTwoAccordion, setTeamTwoAccordion] = useState(false);
  const [teamThreeAccordion, setTeamThreeAccordion] = useState(false);
  const userState = useSelector((state) => state.user);
  const webview = userState?.webview;
  const pushToDataLayer = useDataLayer();
  const previousRaceResultsRef = useRef(null);
  const [isDataPushed, setIsDataPushed] = useState(false);
  const updateActiveAccordion = (teamNo) => {
    if (teamNo === 3) {
      setTeamOneAccordion(false);
      setTeamTwoAccordion(false);
      setTeamThreeAccordion((value) => !value);
      return;
    }
    if (teamNo === 2) {
      setTeamOneAccordion(false);
      setTeamTwoAccordion((value) => !value);
      setTeamThreeAccordion(false);
      return;
    }
    if (teamNo === 1) {
      setTeamOneAccordion((value) => !value);
      setTeamTwoAccordion(false);
      setTeamThreeAccordion(false);
      return;
    }
  };
  const isPreviousRaceResultIntersectingMap = useIntersectionObserver(
    previousRaceResultsRef,
    {
      // threshold: isMobile() ? 0.5 : 1,
      threshold: 0.75,
    }
  );

  useEffect(() => {
    const dataLayerObject = {
      actionType: "view",
      locationInPage: "Previous Race results",
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    if (previousRaceResultsRef.current) {
      isPreviousRaceResultIntersectingMap.forEach((value, key) => {
        if (value && !isDataPushed) {
          pushToDataLayer("widget_impressions", dataLayerObject);
          setIsDataPushed(true);
        }
      });
    }
  }, [isPreviousRaceResultIntersectingMap]);

  useEffect(() => {
    if (fixtureState?.list) {
      setPreviousFixture(
        getFixtureFromMatchDayId(fixtureState, previousMatchdayId)
      );
    }
  }, [fixtureState, previousMatchdayId]);

  return (
    <>
      <section className="si-prevRace__wrap" ref={previousRaceResultsRef}>
        <div className="si-main__container">
          <SectionHeadingWithFiller
            customCls="si-fjcc"
            heading={getTranslations(
              "previous_race_results_heading",
              "Previous Race Results"
            )}
          />
        </div>
        <div className="si-main__container">
          <div className="si-prevRace__fixture">
            <MatchHeader
              fixture={previousFixture}
              tagName="h2"
              MatchHeaderConfig={{
                MatchHeaderLHS: true,
                MatchHeaderRHS: false,
                MatchHeaderExtra: true,
                FixtureDateNewLine: true,
                ShowFixtureDate: false,
                MatchFixtureInfo: false,
              }}
            />
          </div>
        </div>
        <div className="si-main__container">
          <PreviousRaceCard
            previousFixture={previousFixture}
            previousRaceResults={previousRaceResults}
            activeAccordions={{
              teamOneAccordion,
              teamTwoAccordion,
              teamThreeAccordion,
            }}
            updateActiveAccordion={updateActiveAccordion}
          />
        </div>
      </section>
    </>
  );
};

export { PreviousRaceSection };
