import { TEAM_BUSTER } from "../../../../Common/busters";
import axios from "../../axios";
import { getURLString } from "../../index";
const URLString = (url) => getURLString(BASE_ROUTE, url);

const BASE_ROUTE = `services/user/booster`;
const FINAL_FIX = `applyfinalfix`;
const AUTO_PILOT = `applydrsenabled`;
const LIMITLESS = `applylimitless`;
const EXTRA_DRS = `applymegadriver`;
const NO_NEGATIVE = `applynonegative`;
const EXTRA_DRS_CHANGE = "megadriverchange";

export function applyFinalFix(params) {
  return axios.post(URLString(FINAL_FIX), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function applyAutoPilot(params) {
  return axios.post(URLString(AUTO_PILOT), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function applyLimitless(params) {
  return axios.post(URLString(LIMITLESS), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function applyExtraDRS(params) {
  return axios.post(URLString(EXTRA_DRS), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function changeExtraDRS(params) {
  return axios.post(URLString(EXTRA_DRS_CHANGE), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}

export function applyNoNegative(params) {
  return axios.post(URLString(NO_NEGATIVE), params, {
    bustCookie: true,
    busterCookieName: TEAM_BUSTER,
  });
}
