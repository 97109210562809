import React, { useEffect, useState } from "react";
import config from "../../Common/config";
import { useDispatch } from "react-redux";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  IMAGE_VERSION,
  PLATFORM_CATEGORY,
  PLATFORM_ID,
  PLATFORM_VERSION,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import { useSelector } from "react-redux";
import { currentFixture } from "../../Common/utils/fixture";
import { getTranslations, isAndroid, isIOS } from "../../Common/utils";
import useDataLayer from "../../Common/hooks/useDataLayer";
import { CtaButton } from "../Buttons";
import { getPinUnpinLeagueLeague } from "../../redux/actions/league";
import { league } from "../../redux/actions";
import { ToastNotification } from "../ToastNotification";

const SponsorBanner = ({
  baseURL,
  mobileBaseURL,
  VipSponsorBannerImage,
  isVipSponsorMobileBannerImage,
  bannerUrl = null,
  bannerInternalUrl = null,
  bannerUrlFlag = null,
  leagueName = null,
  showLeagueTypeBanner = false,
  leagueTypeBannerName = null,
  showPin = true,
  data,
  type = () => {},
}) => {
  const dispatch = useDispatch();
  const fixtureState = useSelector((state) => state.fixture);
  const webConfigState = useSelector((state) => state.webconfig);
  const leagueState = useSelector((state) => state.league);
  const { webConfigData = null } = webConfigState;
  const pinUnpinLeagueEndpoints =
    webConfigData.leagues.endPoints.pinUnpinLeague;
  const pinUnpinLeagueFulfilled = leagueState?.pinUnpinLeagueFulfilled;
  const pinUnpinLeagueData = leagueState?.pinUnpinLeagueData;
  const userState = useSelector((state) => state.user);
  const userGUID = userState?.data?.GUID;
  const leaguesConfig = webConfigData?.leagues;
  const leaguesEndpoints = leaguesConfig?.endPoints;
  const leagueTypesWithCountApi = leaguesEndpoints?.leagueTypesWithCount;
  const pushToDataLayer = useDataLayer();
  const leagueTypeWithCountData = useSelector(
    (state) => state.league.leagueTypesWithCountData
  );
  const [showPinLeagueError, setShowPinLeagueError] = useState(false);

  const pinUnpinErrorRetval = pinUnpinLeagueData?.RetVal;

  useEffect(() => {
    if (pinUnpinErrorRetval) {
      setShowPinLeagueError(true);
    } else {
      setShowPinLeagueError(false);
    }
  }, [pinUnpinErrorRetval]);

  const getPinUnpinErrorMessage = () => {
    switch (pinUnpinErrorRetval) {
      case 4051:
        return getTranslations("retval_4051", "Maximum pin limit exceeded");
      case 4052:
        return getTranslations("retval_4052", "League already pinned");
      case 4053:
        return getTranslations("retval_4053", "League already unpinned");
      default:
        return null;
    }
  };

  const pinnedLeagueData = leagueTypeWithCountData?.find(
    (obj) => obj.name === "pinned"
  );

  const getLeagueType = () => {
    switch (data?.leagueType) {
      case "Classic Public":
      case "Public":
      case "PUBLIC":
      case "public":
      case "PUBLIC CLASSIC":
        return "classicpublic";
      case "Private H-T-H":
      case "HTH Private":
        return "hthprivate";
      case "Classic Private":
      case "C.Private":
      case "Private":
      case "classic":
        return "classicprivate";
      case "VIP Private":
      case "Vip Private":
        return "vipprivate";
      case "Mini":
        return "mini";
      default:
        return null;
    }
  };

  const [srcSet, setSourceSrcSet] = useState(
    `${config.IMG_BASE}${baseURL}/${VipSponsorBannerImage}?v=${IMAGE_VERSION}`
  );

  const webview = userState?.webview;

  const getUrlType = () => {
    if (bannerInternalUrl) {
      return "Internal";
    } else if (bannerUrl) {
      return "External";
    } else return null;
  };

  const getBannerUrl = () => {
    if (bannerInternalUrl) {
      return bannerInternalUrl;
    } else if (bannerUrl) {
      return bannerUrl;
    } else return null;
  };

  const fantasyBannerClicksDataLayer = () => {
    const urlType = getUrlType();
    const dataLayerObject = {
      pathType: urlType,
      raceName: currentFixture(fixtureState)?.MeetingOfficialName,
      locationInPage: "Header",
      path: getBannerUrl(),
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
      pageName: "Leagues",
      navigationElement: leagueName,
    };
    pushToDataLayer("fantasyBannerClicks", dataLayerObject);
  };

  const bannerImage = (
    <picture>
      <source
        media="(min-width:768px)"
        srcSet={srcSet}
        alt={VipSponsorBannerImage}
        loading="lazy"
        onError={(e) => {
          console.log(e);
          e.target.srcSet = `${config.IMG_BASE}${baseURL}/0.png?v=${IMAGE_VERSION}`;
        }}
        // web img come here
      />
      <img
        src={`${config.IMG_BASE}${mobileBaseURL}/${VipSponsorBannerImage}?v=${IMAGE_VERSION}`}
        alt={VipSponsorBannerImage}
        loading="lazy"
        onError={(e) => {
          e.target.src = `${config.IMG_BASE}${mobileBaseURL}/0.png?v=${IMAGE_VERSION}`;
          setSourceSrcSet(
            `${config.IMG_BASE}${baseURL}/0.png?v=${IMAGE_VERSION}`
          );
        }}
        // mob img come here
      />
    </picture>
  );

  const pinUnpinLeague = () => {
    dispatch(
      getPinUnpinLeagueLeague({
        url: pinUnpinLeagueEndpoints,
        body: {
          optType: data?.isPinned ? 2 : 1,
          leagueid: data.leagueId,
          platformId: PLATFORM_ID,
          platformVersion: PLATFORM_VERSION,
          platformCategory: PLATFORM_CATEGORY,
          leagueType: getLeagueType(),
        },
      })
    );
  };

  return (
    <>
      <div className="si-vip__wrap">
        <div className="si-vip__banner">
          {showLeagueTypeBanner ? (
            <h2 className="si-vip__banner-heading">{leagueTypeBannerName}</h2>
          ) : !!getBannerUrl() ? (
            <a
              rel="noopener noreferrer"
              href={getBannerUrl()}
              target={"_blank"}
              style={{ display: "block", width: "100%" }}
              onClick={fantasyBannerClicksDataLayer}
            >
              {bannerImage}
            </a>
          ) : (
            bannerImage
          )}
        </div>
        {showPin && (
          <CtaButton
            ariaLabel={data?.isPinned ? "Unpin" : "Pin"}
            btnCls={`si-btn si-btn__pinned ${
              data?.isPinned ? "si-active" : ""
            }`}
            iconCls={data?.isPinned ? " f1i-pin" : "f1i-unpin"}
            onClickProp={() => pinUnpinLeague()}
          />
        )}
      </div>
      {showPinLeagueError && (
        <>
          <ToastNotification
            type={"alert"}
            alertCaption={getPinUnpinErrorMessage()}
            handleClose={() => dispatch(league.resetPinUnpinLeague())}
          />
        </>
      )}
      {/* <div className="si-main__row">
      </div> */}
    </>
  );
};

export { SponsorBanner };
