import { HOME_BUSTER } from "../../../../Common/busters";
import axios from "../../axios";
import { getURLString } from "../../index";

const URLString = (url, base = BASE_ROUTE) => getURLString(base, url);

const BASE_ROUTE = `services/user/cards`;
const SEASON_FEATURED_MINI_LEAGUE_BASE_ROUTE = `services/cards`;

export function getUserHomepageStats(params) {
  const { url, GUID } = params;
  const path = url?.replace("{GUID}", GUID);

  return axios.get(URLString(path), {
    addBusterIfAvailable: true,
    busterCookieName: HOME_BUSTER,
  });
}

export function getSeasonFeaturedMiniLeagueData(params) {
  const { url, GUID, loggedInFlag } = params;
  const path = url
    ?.replace("{GUID}", GUID)
    ?.replace("{loggedInFlag}", loggedInFlag);

  return axios.get(URLString(path), {
    feedBuster: true,
  });
}
