import React from "react";

function PlayerName({ playerName }) {
  const splitName = (name) => {
    try {
      name = name.toLowerCase();
      const nameArr = name?.split(" ");
      return [
        nameArr.slice(0, nameArr.length - 1).join(" "),
        nameArr[nameArr.length - 1],
      ];
    } catch (error) {
      return ["", ""];
    }
  };
  return (
    <>
      <div className="si-player__name">
        {splitName(playerName)[0] && <span>{splitName(playerName)[0]}</span>}
        <span>{splitName(playerName)[1]}</span>
      </div>
    </>
  );
}

export { PlayerName };
