import { createStore, applyMiddleware, compose } from "redux";
import promiseMiddleware from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
// import logger from 'redux-logger'
import rootReducer from "./../reducers";
//for redux dev Tool
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  keyPrefix: "si-persist:",
  storage,
  whitelist: ["user"], // add reducers to be persisted
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore(initialState) {
  return createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(promiseMiddleware /* logger, */))
    // applyMiddleware(promiseMiddleware /* logger, */)
  );
}

export default () => {
  let store = configureStore();
  let persistor = persistStore(store);
  return { store, persistor };
};
