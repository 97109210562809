import React from "react";
import { BoosterChips } from "../BoosterChips";

const BoosterAlert = (props) => {
  const { selectedBooster, selectedTeam, alertText1, alertText2, warning } =
    props;
  return (
    <>
      <div className="si-booster__picked-thumbnail">
        <BoosterChips
          boosterCls={selectedBooster[selectedTeam].boosterCls}
          boosterIcon={selectedBooster[selectedTeam].boosterIcon}
          boosterText={selectedBooster[selectedTeam].boosterText}
        />
      </div>
      <h3 className="si-alert__info">
        <span>{alertText1}</span>
      </h3>
      <div className="si-alert__info">
        <span>{alertText2}</span>
      </div>
      <div className="si-errMsg">
        <span>{warning}</span>
      </div>
    </>
  );
};

export { BoosterAlert };
