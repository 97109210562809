import { useState, useEffect } from "react";

const useIntersectionObserver = (ref, options) => {
  const [isIntersectingMap, setIsIntersectingMap] = useState(new Map());

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const newIsIntersectingMap = new Map(isIntersectingMap);

      entries.forEach((entry) => {
        newIsIntersectingMap.set(entry.target, entry.isIntersecting);
      });

      setIsIntersectingMap(newIsIntersectingMap);
    }, options);

    let elements = [];
    if (Array.isArray(ref.current)) {
      elements = ref.current;
    } else if (ref.current) {
      elements = [ref.current];
    }

    elements.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });

    return () => {
      elements.forEach((element) => {
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [ref.current]);

  return isIntersectingMap;
};

export default useIntersectionObserver;
