import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion } from "../../../Accordion";
import AccordionChild from "../../../Accordion/AccordionChild";
import { PvtPubCardInfo } from "../../../PvtPubCardInfo";
import StatisticsTable from "../../../StatisticsTable";
import { Tabs } from "../../../Tabs";
import TotalPoints from "../TotalPoints";
import { NoDataFound } from "../../../NoDataFound";
import { RequiredData as DUMMY_PERFORMANCE_DATA } from "../driverConstructorDataConfig";
import {
  WEEKEND,
  QUALIFYING,
  RACE,
  SPRINT,
  TOTAL,
  LIVE_POINTS_CLASS,
} from "../../../../Common/constants";
import {
  capitalizeFirstLetter,
  getFormattedDate,
  getTranslations,
} from "../../../../Common/utils";
import { statisticsTableEventData } from "../../../../Common/utils/statistics";
import PageLoader from "../../../Loaders/PageLoader";
import SectionLoader from "../../../Loaders/SectionLoader";
import { MatchData } from "../../../MatchHeader";
import { ProvisionalPtsTooltip } from "../../../ProvisionalPtsTooltip";
import { getPlayerStatsNotFoundMessage } from "../../../../Common/utils/team";
import useLivePoints from "../../../../Common/hooks/useLivePoints";

const PerformanceList = ({
  statistics,
  isOppositeTeam = false,
  selectedGameday = null,
}) => {
  const weekendLabel = getTranslations("driver_stats_modal_weekend", WEEKEND);
  const qualifyingLabel = getTranslations(
    "driver_stats_modal_qualifying",
    QUALIFYING
  );
  const raceLabel = getTranslations("driver_stats_modal_race", RACE);
  const sprintLabel = getTranslations("driver_stats_modal_sprint", SPRINT);
  const [performanceData, setPerformanceData] = useState(statistics);
  const [activeIndex, setActiveIndex] = useState(
    performanceData?.[0]?.isActive
  );
  const [isUpdateAccordion, setIsUpdateAccordion] = useState(false);
  const [currentTableItem, setCurrentTableItem] = useState(weekendLabel);
  const [isScrollDone, setIsScrollDone] = useState(false);

  const { teamLivePointsCondition } = useLivePoints();

  const statisticsState = useSelector((state) => state.statistics);
  const fixtureState = useSelector((state) => state.fixture);
  const { loading } = statisticsState;
  const userGamedaysRef = useRef([]);

  useEffect(() => {
    if (!loading && performanceData?.length > 0) {
      if (!isOppositeTeam) {
        const totalElements = userGamedaysRef?.current?.length;
        const currentGamedayElement =
          userGamedaysRef?.current?.[totalElements - 1];
        if (!isScrollDone) {
          currentGamedayElement?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
        setIsScrollDone(true);
      } else {
        const currentGamedayElement =
          userGamedaysRef?.current?.[selectedGameday?.id];
        if (!isScrollDone) {
          currentGamedayElement?.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
        setIsScrollDone(true);
      }
    }

    return () => setIsScrollDone(false);
  }, [performanceData, loading, isOppositeTeam, selectedGameday, isScrollDone]);

  useEffect(() => {
    if (statistics) {
      if (isOppositeTeam) {
        const updatedPerformanceData = statistics?.map((item, index) => {
          return {
            ...item,
            isActive: item?.content?.GamedayId === selectedGameday?.id,
          };
        });
        setPerformanceData(updatedPerformanceData);
      } else {
        let activeAccordionItem = 0;
        const N = statistics?.length - 1;
        for (let i = N; i >= 0; i--) {
          const currentAccordion = statistics?.[i];
          if (currentAccordion?.content?.WeekendData?.StatsWise) {
            activeAccordionItem = i;
            break;
          }
        }
        const updatedPerformanceData = statistics?.map((item, index) => {
          if (index === activeAccordionItem) {
            return {
              ...item,
              isActive: true,
            };
          } else {
            return { ...item };
          }
        });
        setPerformanceData(updatedPerformanceData);
      }
    }
  }, [statistics, selectedGameday, isOppositeTeam]);

  const updateCurrentTableItem = (value) => setCurrentTableItem(value);

  const updateActiveIndex = (index, value) => {
    if (!value) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
    setCurrentTableItem(weekendLabel);
    setIsUpdateAccordion(true);
  };
  const updatePerformanceData = (data) => setPerformanceData(data);

  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  return (
    <>
      <div className="si-performance__wrap">
        <div className="si-performance__title">
          <span>
            {getTranslations("driver_stats_modal_performance", "Performance")}
          </span>
        </div>
        <div
          className={`si-performance__list ${
            loading ? "si-loaderMaster" : ""
          } `}
        >
          {loading && <SectionLoader />}
          {!performanceData || performanceData.length === 0 ? (
            <NoDataFound
              noDataCaption={getTranslations(
                "driver_constructor_card_modal_points_will_be_displayed_after_first_race",
                "Points will be displayed after the first race."
              )}
            />
          ) : (
            <Accordion
              data={performanceData}
              activeIndex={activeIndex}
              updateData={updatePerformanceData}
              isUpdateAccordion={isUpdateAccordion}
              accordionChild={performanceData?.map(
                ({ id, isActive, content }, index) => {
                  const {
                    // TourId,
                    // PlayerValue,
                    // isPlayed,
                    isSeasonData,
                    StatsWise,
                    // CountryId,
                    CountryName,
                    isFixtureData,
                    isWeekendData,
                    // isQualifyingMatchData,
                    // isRaceMatchData,
                    // isSprintMatchData,
                    QualifyingMatchData,
                    RaceMatchData,
                    SprintMatchData,
                    WeekendData,
                    isProvisionalPoints,
                    isRaceLockedPoints,
                    SortedMatchSchedule,
                  } = content;

                  const raceLockedPointsAndLivePoints =
                    isRaceLockedPoints && teamLivePointsCondition;

                  const tabData = [];

                  if (isWeekendData) {
                    tabData.push(weekendLabel);
                  }

                  SortedMatchSchedule?.forEach((item) => {
                    if (item?.SessionType === "Qualifying") {
                      tabData.push(qualifyingLabel);
                    }
                    if (item?.SessionType === "Sprint Qualifying") {
                      tabData.push(sprintLabel);
                    }
                    if (item?.SessionType === "Race") {
                      tabData.push(raceLabel);
                    }
                  });

                  // if (isWeekendData) {
                  //   tabData.push(weekendLabel);
                  // }

                  // if (isQualifyingMatchData) {
                  //   tabData.push(qualifyingLabel);
                  // }

                  // if (isSprintMatchData) {
                  //   tabData.push(sprintLabel);
                  // }

                  // if (isRaceMatchData) {
                  //   tabData.push(raceLabel);
                  // }

                  const getCurrentTableData = (value) => {
                    if (value === weekendLabel) {
                      return WeekendData;
                    }
                    if (value === qualifyingLabel) {
                      return QualifyingMatchData;
                    }
                    if (value === raceLabel) {
                      return RaceMatchData;
                    }
                    if (value === sprintLabel) {
                      return SprintMatchData;
                    }
                  };

                  const getTotalPoints = (statWiseData) => {
                    const TotalPointsObj = statWiseData?.find(
                      (item) => item.Event === TOTAL
                    );
                    return TotalPointsObj?.Value;
                  };

                  /**
                   * * Displaying season data points in the first accordion tab
                   * * In the remaining tabs, Displaying the total points for the current race type
                   * * If the current tab is inActive, then displaying the weekend data for the total points
                   */
                  const totalPoints = isSeasonData
                    ? getTotalPoints(StatsWise)
                    : !isActive
                    ? getTotalPoints(
                        getCurrentTableData(weekendLabel)?.StatsWise
                      )
                    : getTotalPoints(
                        getCurrentTableData(currentTableItem)?.StatsWise
                      );

                  const tableData = isSeasonData
                    ? StatsWise
                    : getCurrentTableData(currentTableItem)?.StatsWise;

                  const matchDate =
                    getCurrentTableData(currentTableItem)?.SessionStartDate;

                  if (!isSeasonData && !isFixtureData) {
                    return null;
                  }

                  return (
                    <AccordionChild
                      key={id}
                      accordionConfig={{}}
                      accordionBoxExtraClass={
                        raceLockedPointsAndLivePoints ? LIVE_POINTS_CLASS : ""
                      }
                      headComponentLHS={
                        <>
                          <PvtPubCardInfo
                            PvtPubCardConfig={{
                              showThumb: true,
                              showBottomInfo: false,
                              showLeagueCount: false,
                              imageSrc: isSeasonData
                                ? ""
                                : webConfigData?.imagePaths?.flags,
                              imageName: isSeasonData
                                ? "chequered-flag.svg"
                                : `${CountryName}.svg`,
                              leagueName: isSeasonData
                                ? getTranslations(
                                    "driver_stats_modal_season",
                                    "Season"
                                  )
                                : CountryName,
                            }}
                          />{" "}
                          {!isActive && isProvisionalPoints && (
                            <ProvisionalPtsTooltip isTooltipTrue={false} />
                          )}
                        </>
                      }
                      headComponentRHS={
                        <>
                          {isActive &&
                            (isProvisionalPoints ||
                              raceLockedPointsAndLivePoints) && (
                              <MatchData
                                showFixtureData={false}
                                MatchStatusWrap={true}
                                fixtureState={fixtureState}
                              />
                            )}
                          {!isActive && raceLockedPointsAndLivePoints && (
                            <i className="f1i-live" />
                          )}
                          {/* {!isActive && isProvisionalPoints && (
                            <div className="pp_icon">
                              <i className="f1i-provipts"></i>
                            </div>
                          )} */}
                          {!isActive && (
                            <TotalPoints
                              totalPts={totalPoints ? totalPoints : 0}
                              raceLockedPointsAndLivePoints={
                                raceLockedPointsAndLivePoints
                              }
                              onlyPointsLiveClass={false}
                            />
                          )}
                        </>
                      }
                      bodyComponent={
                        <>
                          {!isSeasonData &&
                            (!!WeekendData?.IsActive ||
                              (!WeekendData.isActive &&
                                WeekendData?.StatsWise?.length > 0)) && (
                              <>
                                {tabData.length > 0 && (
                                  <Tabs
                                    data={tabData}
                                    active={currentTableItem}
                                    handleClick={updateCurrentTableItem}
                                  />
                                )}
                                <div className="si-performance__dates">
                                  {matchDate && (
                                    <span>
                                      {getFormattedDate(new Date(matchDate))}
                                    </span>
                                  )}
                                </div>
                              </>
                            )}

                          <div className="si-performance__tbl">
                            {tableData?.length > 0 ? (
                              <StatisticsTable tableData={tableData} />
                            ) : (
                              <NoDataFound
                                noDataCaption={getPlayerStatsNotFoundMessage({
                                  seasonDataPlayerInActive:
                                    isSeasonData && !content?.IsActive,
                                  tabLengthIsEmpty: tabData.length === 0,
                                  weekendDataPlayerInActive:
                                    !WeekendData?.IsActive,
                                  showEliminatedBeforeQ3:
                                    getCurrentTableData(currentTableItem)
                                      ?.IsPlayed &&
                                    (currentTableItem === weekendLabel ||
                                      currentTableItem === qualifyingLabel),
                                  showNotInGrid:
                                    getCurrentTableData(currentTableItem)
                                      ?.IsPlayed &&
                                    (currentTableItem === sprintLabel ||
                                      currentTableItem === raceLabel),
                                })}
                              />
                            )}
                          </div>
                          {tableData?.length > 0 && (
                            <TotalPoints
                              totalPts={totalPoints}
                              raceLockedPointsAndLivePoints={
                                raceLockedPointsAndLivePoints
                              }
                              onlyPointsLiveClass={true}
                            />
                          )}
                        </>
                      }
                      index={index}
                      isActive={isActive}
                      innerRef={(el) => (userGamedaysRef.current[index] = el)}
                      updateActiveIndex={updateActiveIndex}
                    />
                  );
                }
              )}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PerformanceList;
