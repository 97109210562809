import React from "react";
import { CtaButton } from "../Buttons";

const LoadMoreCta = ({ handleLoadMore = () => {} }) => {
  return (
    <>
      <CtaButton
        btnCls="si-btn si-btn__loadmore"
        iconCls="f1i-chevron-down"
        btnText="Load more"
        ariaLabel="Load more"
        onClickProp={() => handleLoadMore()}
      />
    </>
  );
};

export { LoadMoreCta };
