import React from "react";

const SectionLoader = () => {
  return (
    <>
      <div className="si-sectionloader__wrap">
        <div className="si-sectionloader__box">
          <span></span>
          <span></span>
        </div>
      </div>
    </>
  );
};

export default SectionLoader;
