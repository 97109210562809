import React, { useEffect, useState } from "react";
import { league } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { disableEmojiInput, getTranslations } from "../../../Common/utils";
import {
  MAX_TEAM_NAME_CHARACTERS,
  MIN_TEAM_NAME_CHARACTERS,
  MAX_LEAGUE_NAME_CHARACTERS,
  cf_user_team_create,
} from "../../../Common/constants";
import { ErrorMessage } from "../../Error/message";

const LeagueNameOverlay = ({ leagueName, callback, isLeagueNameValid }) => {
  const dispatch = useDispatch();
  const leagueState = useSelector((state) => state.league);
  const { updateLeagueNameErrorData = null } = leagueState;
  const [name, setName] = useState("");
  const handleInput = (e) => {
    let val = disableEmojiInput(e.target.value);
    setName(val);
    callback(val);
  };
  useEffect(() => {
    setName(leagueName);
  }, []);

  const maxCharacterErrorMessage =
    "League name cannot be greater than {{MAX_TEAM_NAME_CHARACTERS}} characters".replace(
      "{{MAX_TEAM_NAME_CHARACTERS}}",
      MAX_LEAGUE_NAME_CHARACTERS
    );
  const minCharacterErrorMessage =
    "League name cannot be less than {{MIN_TEAM_NAME_CHARACTERS}} characters".replace(
      "{{MIN_TEAM_NAME_CHARACTERS}}",
      MIN_TEAM_NAME_CHARACTERS
    );

  // const getErrorMessage = () => {
  //   switch (saveErrorData?.RetVal) {
  //     case cf_user_team_create["EXP_TEAM_NAME_EXISTS"]:
  //       return <ErrorMessage text="Team name already taken" />;

  //     case cf_user_team_create["PROFANITY_CHECK"]:
  //       return <ErrorMessage text="Team name not allowed" />;

  //     case cf_user_team_create["EXP_TEAM_NAME_LENGTH_NOT_MATCH"]:
  //       return <ErrorMessage text="Invalid Team Length" />;

  //     default:
  //       return <ErrorMessage />;
  //   }
  // };

  return (
    <>
      <form
        className="si-search-form"
        action=""
        method=""
        aria-label="Type to create your team name"
      >
        <div className="si-input si-input--search ">
          <label className="si-search__label" htmlFor="createTeamName">
            Type your League Name
          </label>
          <input
            type="text"
            id="createTeamName"
            className=""
            placeholder="Type your League Name"
            value={name}
            spellCheck="false"
            onChange={(e) => handleInput(e)}
          />
          {/* {saveErrorData && getErrorMessage()} */}
          {!isLeagueNameValid &&
            name?.trim()?.length !== 0 &&
            name?.trim()?.length > MAX_LEAGUE_NAME_CHARACTERS && (
              <ErrorMessage text={maxCharacterErrorMessage} />
            )}
        </div>
      </form>
    </>
  );
};

export default LeagueNameOverlay;
