import React, { useRef, useEffect } from "react";

const RaceProgress = ({
  racePgrTitle,
  racePgrIconName,
  racePgrCurrentCount,
  racePgrTotalCount,
}) => {
  return (
    <>
      <div className="si-racePgr__wrap" aria-label="">
        <div className="si-racePgr__box">
          <div className="si-racePgr__box-top ">
            <h2 className="si-racePgr__title">
              {racePgrIconName ? <i className={racePgrIconName}></i> : ""}
              <span>{racePgrTitle}:</span>
            </h2>
            <div className="si-racePgr__stats">
              <span>
                <em>{racePgrCurrentCount}</em>/{racePgrTotalCount}
              </span>
            </div>
          </div>
          <div className="si-racePgr__box-bot ">
            <div className="si-budgetBar__progress">
              <progress
                id={`raceWeek-progress`}
                value={racePgrCurrentCount}
                max={racePgrTotalCount}
                // aria-valuemin="0"
                // aria-valuemax="100"
              ></progress>
              <span
                style={{
                  width: `${(racePgrCurrentCount / racePgrTotalCount) * 100}%`,
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { RaceProgress };
