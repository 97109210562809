import { F1_FANTASY_COOKIE } from "../constants";
import { getCookie } from "./index";

export const isUserLoggedIn = (userState) => {
  // if (process.env.NODE_ENV === "development") {
  //   return true;
  // }
  return getCookie(F1_FANTASY_COOKIE) && userState?.data?.GUID;
};

export const isF1LoggedIn = () => {
  // if (process.env.NODE_ENV === "development") {
  //   return true;
  // }
  return getCookie("login-session");
};
