const MiniLeagueHeadLHS = ({ miniLeague }) => {
  return (
    <>
      <div className="si-miniLeague__header">
        <span>{miniLeague?.name}</span>
      </div>
    </>
  );
};

export default MiniLeagueHeadLHS;
