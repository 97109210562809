import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPath, getTranslations, isMobile } from "../../../Common/utils";
import { CtaButton } from "../../../ReusableComponents";

const LeagueSetting = ({ type, leagueId, pushLeagueSettingsDataLayer }) => {
  const navigate = useNavigate();
  const translationState = useSelector((state) => state.translation);

  return (
    <>
      <div className="si-league__setting">
        <CtaButton
          btnCls={`si-btn ${
            !isMobile() ? "si-btn__secondary" : "si-btn__icon"
          }`}
          iconCls="f1i-customise"
          btnText=""
          ariaLabel={getTranslations(
            "league_members_share_aria",
            "Click to share your league"
          )}
          onClickProp={() => {
            navigate(
              getPath(translationState, "leaguesManage", {
                type: type,
                leagueId: leagueId,
              })
            );
            pushLeagueSettingsDataLayer();
          }}
        />
      </div>
    </>
  );
};

export default LeagueSetting;
