import React, { useState } from "react";
import { ProfanityAlertIcon } from "./ProfanityAlertIcon";
import { Overlays, ProfanityOverlay } from "../../ReusableComponents";
import { getTranslations } from "../../Common/utils";
import { createPortal } from "react-dom";

const Profanity = ({ webPurifyStatus, type }) => {
  const [showAlertPopup, setShowAlertPopup] = useState(false);

  return (
    <>
      <ProfanityAlertIcon
        webPurifyStatus={webPurifyStatus}
        setShowAlertPopup={setShowAlertPopup}
      />
      {showAlertPopup
        ? createPortal(
            <div onClick={(e) => e.stopPropagation()}>
              <Overlays
                overlayName={"si-popup__wrap--warning"}
                overlayTitle={getTranslations(
                  "profanity_popup_title",
                  "Warning"
                )}
                overlayConfig={{
                  noOverlayFoot: false,
                  submitBtn: false,
                  closeBtn: true,
                }}
                onCancelClick={() => setShowAlertPopup(false)}
                component={
                  <ProfanityOverlay
                    type={type}
                    webPurifyStatus={webPurifyStatus}
                  />
                }
              />
            </div>,
            document.getElementById("si-profanity")
          )
        : null}
    </>
  );
};

export default Profanity;
