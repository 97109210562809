import { useState, useEffect } from "react";

import { DESKTOP, TABLET, MOBILE } from "../constants";

function useDeviceType() {
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 996) {
        setDeviceType(DESKTOP);
      } else if (screenWidth >= 720 && screenWidth <= 995) {
        setDeviceType(TABLET);
      } else {
        setDeviceType(MOBILE);
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return deviceType;
}

export default useDeviceType;
