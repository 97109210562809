import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { DESKTOP, MOBILE, TABLET } from "../../Common/constants";
import useDeviceType from "../../Common/hooks/useDeviceType";
import { adsConfig } from "./googleAdsConfig";

const GoogleAds = ({ adKey }) => {
  const location = useLocation();
  const deviceType = useDeviceType();

  const currentAdUnit = adsConfig?.[adKey];
  const adUnitId = currentAdUnit?.adUnitId;
  const sizeMapping = currentAdUnit?.sizeMapping;
  const sizes = currentAdUnit?.sizes;
  const slotId = currentAdUnit?.slotId;

  const adContainerClass =
    deviceType === DESKTOP
      ? "si-970by250"
      : deviceType === TABLET
      ? "si-728by90"
      : "si-320by100";

  useEffect(() => {
    if (!currentAdUnit || !window?.googletag?.pubads) {
      return;
    }

    const existingSlot = window?.googletag
      ?.pubads()
      ?.getSlots()
      ?.find((slot) => slot?.getSlotElementId() === slotId);

    if (!existingSlot) {
      let size;
      for (let index = 0; index < sizeMapping?.length; index++) {
        const currentSizeMap = sizeMapping[index];
        if (index === 0) {
          size = window?.googletag?.sizeMapping();
        }
        size = size?.addSize(currentSizeMap[0], currentSizeMap[1]);
      }

      const builtMapping = size.build();

      window.googletag = window?.googletag || { cmd: [] };
      window?.googletag?.cmd?.push(function () {
        const adUnit = window?.googletag?.defineSlot(adUnitId, sizes, slotId);
        adUnit?.defineSizeMapping(builtMapping);
        adUnit?.addService(window?.googletag?.pubads());
        window?.googletag?.pubads()?.enableSingleRequest();
        window?.googletag?.pubads()?.collapseEmptyDivs();
        window?.googletag?.enableServices();
      });
    }

    window?.googletag?.cmd?.push(function () {
      window?.googletag?.display(slotId);
    });

    const destroySlots = () => {
      if (window?.googletag && window.googletag?.pubadsReady) {
        window?.googletag?.destroySlots();
      }
    };

    return () => {
      destroySlots();
    };
  }, [location, deviceType]);
  // si-970by250
  return (
    <>
      <div className="si-main__container">
        <div className="si-googleAd__wrap">
          <div className={`si-googleAd__box ${adContainerClass}`}>
            {currentAdUnit ? (
              <div className="si-google-ad-component" id={slotId}></div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export { GoogleAds };
