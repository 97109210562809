import { FETCH_TRANSLATIONS, SET_LANGUAGE } from "../../constants/translation";
import { translation } from "../../apis/services";

export const fetchTranslations = (params) => {
  return {
    type: FETCH_TRANSLATIONS,
    payload: translation.getTranslations(params),
  };
};

export const setLanguage = (params) => {
  return {
    type: SET_LANGUAGE,
    payload: params,
  };
};
