import React from "react";
import { getTranslations } from "../../Common/utils";
import { EXTRA_TRANSFER_COST } from "../../Common/constants";

const TransfersCounts = ({
  transfersDetails,
  isInfinite,
  hasMadeExtraTransfers,
  transfersMade,
}) => {
  return (
    <>
      <div className="si-myTeamHeader__transfers">
        <span>{transfersDetails.transfersTitle}</span>

        <span>
          {isInfinite ? (
            <i className="f1i-infinite"></i>
          ) : hasMadeExtraTransfers ? (
            `${Math.abs(transfersMade)} (${
              transfersMade * EXTRA_TRANSFER_COST
            } pts)`
          ) : (
            getTranslations(
              "manage_team_transfers_count",
              "{{USED_TRANSFERS}} of {{AVAILABLE_FREE_TRANSFERS}}"
            )
              .replace(
                "{{USED_TRANSFERS}}",
                transfersDetails.availableTransfers
              )
              .replace(
                "{{AVAILABLE_FREE_TRANSFERS}}",
                transfersDetails.maxTransfers
              )
          )}
        </span>
      </div>
    </>
  );
};

export { TransfersCounts };
