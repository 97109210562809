import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { useSelector } from "react-redux";

const H2hNoData = ({ h2hIcon, h2hText }) => {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  return (
    <>
      <div className="si-h2h__noData">
        <div className="si-h2h__noData-thumb">
          <img
            src={`${
              config.IMG_BASE + webConfigData?.imagePaths?.leaguesAsset
            }h2h-nodata-thumb.png?v=${IMAGE_VERSION}`}
            alt={""}
            aria-label=""
            loading="lazy"
          />
        </div>
        <div className="si-h2h__noData-box">
          <div className="si-h2h__noData-icon">
            <i className={h2hIcon}></i>
          </div>
          <div className="si-h2h__noData-text">{h2hText}</div>
        </div>
      </div>
    </>
  );
};

export { H2hNoData };
