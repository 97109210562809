import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  h2hCurrentUser,
  isH2HLeaderboardGenerated,
  isH2HTeamLock,
  isH2HYetToJoin,
} from "../../../Common/utils/league";
import { Tabs } from "../../Tabs";
import H2HYetToJoin from "./yet-to-join";
import H2HTeamLock from "./team-lock";
import H2HBattleMode from "./battle-mode";
import H2HBattleModeCurrentFixture from "./current-fixture";
import { gameplay } from "../../../redux/actions";
import H2HSeasonSoFar from "./season-so-far";
import { getTranslations } from "../../../Common/utils";

const HeadToHeadLeaderboard = ({
  activeTab,
  handleTabClick,
  optType,
  gameDayId,
  phaseId,
  selectedRaceOption,
  handleSeasonSoFarRaceweekClick,
}) => {
  const dispatch = useDispatch();
  const fixtureState = useSelector((state) => state.fixture);
  const leagueState = useSelector((state) => state.league);
  const { selectedLeague } = leagueState;
  const leaderboardTabs = [
    { id: 1, text: getTranslations("league_hth_tab_1", "Battle Mode") },
    {
      id: 2,
      text: getTranslations("league_hth_tab_2", "Season So Far"),
      isDisabled:
        isH2HYetToJoin(selectedLeague?.users) ||
        !isH2HLeaderboardGenerated(
          fixtureState,
          selectedLeague?.battleStartMatchday
        ),
    },
  ];

  const isTeamLock = isH2HTeamLock(
    fixtureState,
    selectedLeague?.battleStartMatchday,
    selectedRaceOption
  );

  useEffect(() => {
    return () => {
      dispatch(gameplay.resetH2HOpponent());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isTeamLock) {
      dispatch(gameplay.resetH2HOpponent());
    }
  }, [isTeamLock, dispatch]);

  return (
    <div className="si-battle__wrap">
      <div className="si-battle__container">
        <div className="si-battle__tabs">
          <Tabs
            data={leaderboardTabs}
            handleClick={(name) => handleTabClick(name)}
            active={activeTab}
            type="object"
          />
        </div>
        {activeTab === 1 && (
          <div className="si-battle__grid">
            <H2HBattleModeCurrentFixture
              selectedLeague={selectedLeague}
              selectedRaceOption={selectedRaceOption}
              isYetToJoin={isH2HYetToJoin(selectedLeague?.users)}
            />
            <div className="si-battle__body">
              {isH2HYetToJoin(selectedLeague?.users) ? (
                <H2HYetToJoin />
              ) : isTeamLock ? (
                <H2HTeamLock />
              ) : (
                <H2HBattleMode
                  selectedLeague={selectedLeague}
                  selectedRaceOption={selectedRaceOption}
                />
              )}
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <H2HSeasonSoFar
            optType={optType}
            gameDayId={gameDayId}
            phaseId={phaseId}
            handleSeasonSoFarRaceweekClick={handleSeasonSoFarRaceweekClick}
            handleTabClick={handleTabClick}
          />
        )}
      </div>
    </div>
  );
};

export default HeadToHeadLeaderboard;
