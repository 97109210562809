import { useNavigate } from "react-router-dom";
import {
  camelCaseMiniLeagueDL,
  getTranslations,
  handleF1LoginCustomPath,
  isAndroid,
  isIOS,
} from "../../Common/utils";
import { CtaButton } from "../Buttons";
import { PrizesBox } from "../PrizesBox";
import { useSelector } from "react-redux";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useDataLayer from "../../Common/hooks/useDataLayer";
import { isUserLoggedIn } from "../../Common/utils/user";

const MiniLeagueBody = ({
  miniLeague,
  showViewMiniLeagues = false,
  showMainDescription = true,
}) => {
  const translationState = useSelector((state) => state.translation);
  const userState = useSelector((state) => state.user);
  const navigate = useNavigate();
  const pushToDataLayer = useDataLayer();

  const language = translationState?.language;
  const webview = userState?.webview;

  const handleViewMiniLeagues = () => {
    if (isUserLoggedIn(userState)) {
      navigate(`/${language}/leagues`);
    } else {
      handleF1LoginCustomPath(
        {},
        "login",
        translationState,
        userState,
        "mini-league-redirect",
        "prizes"
      );
    }
  };

  const pushViewMiniLeaguesDataLayer = () => {
    const dataLayerObject = {
      pageName: "Prizes",
      actionType: "View Mini Leagues",
      locationInPage: camelCaseMiniLeagueDL(miniLeague?.name),
      clickText: getTranslations(
        "prizes_accordion_view_mini_leagues",
        "View Mini Leagues"
      ),
      path: "/leagues",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("navigation_click", dataLayerObject);
  };

  return (
    <div className="si-prize__row si-prize__row--miniPrizes">
      {showMainDescription && (
        <div className="si-prize__caption">
          <p
            dangerouslySetInnerHTML={{
              __html: getTranslations(miniLeague?.mainDescription),
            }}
          ></p>
        </div>
      )}
      <div className="si-prize__list">
        {miniLeague?.data?.map((data, index) => (
          <PrizesBox
            key={index}
            prizeType={data?.type}
            prizeTitle={data?.title}
            prizeCaption={data?.caption}
            prizePosition={data?.position}
            prizeThumb={data?.thumb}
            miniLeague={miniLeague}
          />
        ))}
      </div>
      {showViewMiniLeagues && (
        <div className="si-prize__cta">
          <CtaButton
            btnCls="si-btn si-btn__secondary si-btn__secondary--outline"
            iconCls="f1i-chevron-right"
            btnText={getTranslations(
              "prizes_accordion_view_mini_leagues",
              "View Mini Leagues"
            )}
            ariaLabel={getTranslations(
              "prizes_accordion_view_mini_leagues",
              "View Mini Leagues"
            )}
            btnState=""
            onClickProp={() => {
              handleViewMiniLeagues();
              pushViewMiniLeaguesDataLayer();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MiniLeagueBody;
