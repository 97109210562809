const TeamStatsSkeleton = ({
  title,
  component,
  customCls,
  titleContd = null,
}) => {
  return (
    <div className={`si-prevRace__contentBox ${customCls ? customCls : ""}`}>
      <div className="si-prevRace__contentBox-title">
        <span>{titleContd ? `${title} ${titleContd}` : title}</span>
      </div>
      <div className="si-prevRace__contentBox-body">
        {component ? component : null}
      </div>
    </div>
  );
};

export { TeamStatsSkeleton };
