import React, { useEffect, useMemo, useRef, useState } from "react";
import { SectionHeadingWithFiller } from "../HeadingWithFiller";
import {
  getPath,
  getTranslations,
  isAndroid,
  isIOS,
  isMobile,
} from "../../Common/utils";
import { CtaButton } from "../Buttons";
import { NoDataFound } from "../NoDataFound";
import YourSeasonStatsCard from "./YourSeasonStatsCard";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";
import { currentFixture } from "../../Common/utils/fixture";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useDataLayer from "../../Common/hooks/useDataLayer";
import useIntersectionObserver from "../../Common/hooks/useIntersectionObserver";

const YourSeasonStatsSection = ({ seasonStatsData = [] }) => {
  const userState = useSelector((state) => state.user);
  const webview = userState?.webview;
  const pushToDataLayer = useDataLayer();
  const fixtureState = useSelector((state) => state.fixture);
  const yourSeasonStatsSectionRef = useRef(null);
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;
  const seasonStats = useMemo(() => {
    const results = [...seasonStatsData];
    results.sort((item1, item2) => item1.teamNo - item2.teamNo);

    return results;
  }, [seasonStatsData]);
  const navigate = useNavigate();
  const translationState = useSelector((state) => state.translation);
  const [isDataPushed, setIsDataPushed] = useState(false);
  const handleCreateTeam = () => {
    navigate(getPath(translationState, "createTeam"));
  };
  const myTeamClickDataLayer = () => {
    const dataLayerObject = {
      actionType: "My Team",
      clickText: getTranslations("your_season_stats_my_team_cta", "My Team"),
      path: getPath(translationState, "myTeam"),
      locationInPage: "Your Season Stats",
      componentType: webview ? WEBVIEW : WEB,
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
    };
    pushToDataLayer("navigation_click", dataLayerObject);
  };
  const handleMyTeam = () => {
    myTeamClickDataLayer();
    navigate(getPath(translationState, "myTeam"));
  };
  const isYourSeasonStatsSectionIntersectingMap = useIntersectionObserver(
    yourSeasonStatsSectionRef,
    {
      // threshold: isMobile() ? 0.5 : 1,
      threshold: 0.75,
    }
  );

  useEffect(() => {
    const dataLayerObject = {
      actionType: "view",
      locationInPage: "Season Stats",
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    if (yourSeasonStatsSectionRef.current) {
      isYourSeasonStatsSectionIntersectingMap.forEach((value, key) => {
        if (value && !isDataPushed) {
          pushToDataLayer("widget_impressions", dataLayerObject);
          setIsDataPushed(true);
        }
      });
    }
  }, [isYourSeasonStatsSectionIntersectingMap]);
  return (
    <>
      <section
        className="si-yourSeasonStats__wrap"
        ref={yourSeasonStatsSectionRef}
      >
        <div className="si-main__container">
          <div className="si-yourSeasonStats__grid">
            <SectionHeadingWithFiller
              dark={true}
              heading={getTranslations(
                "your_season_stats_heading",
                "Your Season Stats"
              )}
            />
            {!isMobile() ? (
              <div className="si-yourSeasonStats__cta">
                <CtaButton
                  btnCls={"si-btn si-btn__secondary si-btn__secondary--outline"}
                  iconCls={"f1i-chevron-right"}
                  btnText={getTranslations(
                    "your_season_stats_my_team_cta",
                    "My Team"
                  )}
                  onClickProp={handleMyTeam}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="si-main__container">
          <div className="si-yourSeasonStats__list">
            {seasonStats?.length ? (
              <>
                {seasonStats?.map((teamStats) => {
                  return (
                    <YourSeasonStatsCard
                      key={teamStats?.teamNo}
                      teamStats={teamStats}
                    />
                  );
                })}
                {seasonStats?.length < 3 && (
                  <div className="si-yourSeasonStats__addCta">
                    <NoDataFound
                      noDataCaption={getTranslations(
                        "your_season_stats_add_teams",
                        "Add teams to view their performances"
                      )}
                      noDataCta={true}
                      noDataConfig={{
                        btnCls:
                          "si-btn si-btn__icon si-btn__icon--primary si-btn__radius",
                        iconCls: "f1i-add",
                        onClickProp: handleCreateTeam,
                        btnState:
                          !currentFixture(fixtureState)?.IsActiveForNewUser ||
                          disableCTAs,
                      }}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="si-yourSeasonStats__addCta">
                  <NoDataFound
                    noDataCaption={getTranslations(
                      "your_season_stats_add_teams",
                      " Add teams to view their performances"
                    )}
                    noDataCta={true}
                    noDataConfig={{
                      btnCls:
                        "si-btn si-btn__icon si-btn__icon--primary si-btn__radius",
                      iconCls: "f1i-add",
                      onClickProp: handleCreateTeam,
                      btnState:
                        !currentFixture(fixtureState)?.IsActiveForNewUser ||
                        disableCTAs,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {isMobile() ? (
          <div className="si-yourSeasonStats__footer">
            <div className="si-main__container">
              <div className="si-yourSeasonStats__cta">
                <CtaButton
                  btnCls={"si-btn si-btn__secondary si-btn__secondary--outline"}
                  iconCls={"f1i-chevron-right"}
                  btnText={getTranslations(
                    "your_season_stats_my_team_cta",
                    "My Team"
                  )}
                  btnState={
                    !currentFixture(fixtureState)?.IsActiveForNewUser ||
                    disableCTAs
                  }
                  onClickProp={handleMyTeam}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export { YourSeasonStatsSection };
