import React from "react";

const MatchStatus = ({ matchStatusText, matchStatusIcon, matchStatusCls }) => {
  return (
    <>
      <div
        className={`si-matchHeader__status ${
          matchStatusCls ? matchStatusCls : ""
        } `}
        // si-live | si-provipts
      >
        {matchStatusIcon ? <i className={`${matchStatusIcon}`}></i> : ""}
        {matchStatusText ? <span>{matchStatusText}</span> : ""}
      </div>
    </>
  );
};

export default MatchStatus;
