import React from "react";

const Notes = ({ customCls, text, iconName }) => {
  return (
    <>
      <div className={`si-notes ${customCls ? customCls : ""}`}>
        {iconName ? <i className={iconName}></i> : ""}
        {text ? <span>{text}</span> : ""}
      </div>
    </>
  );
};

export default Notes;
