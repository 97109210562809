import { getTranslations } from "../../../Common/utils";

export const teamNameProfaneAndProvisionalViolationTranslation =
  getTranslations(
    "profanity_team_common_desp_provisional",
    "F1 is reviewing your team name to ensure it follows our content guidelines."
  );

export const teamNameUpdatedBySystemViolationTranslation = getTranslations(
  "profanity_team_common_desp_updated",
  "F1 has identified that your Team name violated our content guidelines. "
);

export const teamProfaneAndProvisionalTranslation = getTranslations(
  "profanity_team_profane_provisional_desp",
  "Your team name will be changed automatically to a default team name if its found to be violating these guidelines to maintain a respectful and inclusive environment."
);

export const teamUpdatedBySystemTranslation = getTranslations(
  "profanity_team_updated_by_system_desp",
  "To maintain a respectful and inclusive environment, your Team name has been automatically changed to a default name."
);

export const leagueNameProfaneAndProvisionalViolationTranslation =
  getTranslations(
    "profanity_league_common_desp_provisional",
    "F1 is reviewing your league name to ensure it follows our content guidelines."
  );

export const leagueNameUpdatedBySystemViolationTranslation = getTranslations(
  "profanity_league_common_desp_updated",
  "F1 has identified that your League name violated our content guidelines."
);

export const leagueProfaneAndProvisionalTranslation = getTranslations(
  "profanity_league_profane_provisional_desp",
  "Your league name will be changed automatically to a default league name if its found to be violating these guidelines to maintain a respectful and inclusive environment."
);

export const leagueUpdatedBySystemTranslation = getTranslations(
  "profanity_league_updated_by_system_desp",
  "To maintain a respectful and inclusive environment, your League name has been automatically changed to a default name."
);

export const profanityCoolNewNameTeamTranslation = getTranslations(
  "profanity_cool_new_name_team",
  "We encourage you to update your team’s name."
);

export const profanityCoolNewNameLeagueTranslation = getTranslations(
  "profanity_cool_new_name_league",
  "We encourage you to update your league’s name."
);
