import { useSelector } from "react-redux";
import { WEB, WEBVIEW } from "../constants";
import { getTranslations } from "../utils";
import useDataLayer from "./useDataLayer";

const useDataLayerLeagueMenuClick = () => {
  const userState = useSelector((state) => state.user);
  const pushToDataLayer = useDataLayer();
  const webview = userState?.webview;

  const dataLayerLeagueMenuClicked = (name, type) => {
    const leagueTypeObj = {
      private_classic: "Private | Classic Private",
      private_hth: "Private | H2H Battle Mode",
      private_vip: "Private | Classic VIP",
      public_starter: "Public | Starter Leagues",
      public_classic: "Public | Classic Public",
    };

    const isPrivateOrPublicLeague = name === "private" || name === "public";

    const dataLayerObject = {
      actionType: "league dropdown",
      clickText: getTranslations(`league_sidebar_${name}`, name),
      leagueType: isPrivateOrPublicLeague
        ? leagueTypeObj?.[`${name}_${type}`]
        : `${type?.toUpperCase()}`,
      path: `/leagues?type=${
        isPrivateOrPublicLeague
          ? `${name?.toLowerCase()}-${type?.toLowerCase()}`
          : type?.toLowerCase()
      }`,
      pageName: "Leagues",

      componentType: webview ? WEBVIEW : WEB,
    };
    pushToDataLayer("navigation_click", dataLayerObject);
  };

  return dataLayerLeagueMenuClicked;
};

export default useDataLayerLeagueMenuClick;
