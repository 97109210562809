import { getTranslations } from "../../../Common/utils";

export const leagueSortValues = {
  recentlyCreated: getTranslations(
    "league_sort_sort_recently_created_",
    "Recently Created / Joined"
  ),
  lastChanceToJoin: getTranslations(
    "league_sort_sort_last_chance_to_join_",
    "Last Chance to Join"
  ),
  popular: getTranslations("league_sort_sort_popular_", "Popular"),
  new: getTranslations("league_sort_sort_new_", "New"),
  ascending: getTranslations("league_sort__asscending_", "Ascending"),
  descending: getTranslations("league_sort__descending_", "Descending"),
};

export const radioValues1 = [
  {
    value: leagueSortValues.recentlyCreated,
    nameKey: "league_sort_sort_recently_created_",
  },
  {
    value: leagueSortValues.lastChanceToJoin,
    nameKey: "league_sort_sort_last_chance_to_join_",
  },
  {
    value: leagueSortValues.popular,
    nameKey: "league_sort_sort_popular_",
  },
  {
    value: leagueSortValues.new,
    nameKey: "league_sort_sort_new_",
  },
];

export const radioValues2 = [
  {
    value: leagueSortValues.ascending,
    nameKey: "league_sort__asscending_",
  },
  {
    value: leagueSortValues.descending,
    nameKey: "league_sort__descending_",
  },
];

export const defaultSortValue1 = radioValues1?.[0];
export const defaultSortValue2 = radioValues2?.[0];
