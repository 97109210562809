import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useDataLayer from "../../Common/hooks/useDataLayer";
import { isAndroid, isIOS } from "../../Common/utils";

export const useHowToPlayLearnMoreDL = () => {
  const userState = useSelector((state) => state.user);
  const pushToDataLayer = useDataLayer();
  const webview = userState?.webview;

  const handleLearnMoreDLClick = useCallback(
    ({ path, clickText, actionType, pageName }) => {
      const learnMoreDataLayerObject = {
        actionType,
        clickText,
        path,
        pageName,
        platform: isAndroid()
          ? FANTASY_ANDROID
          : isIOS()
          ? FANTASY_IOS
          : FANTASY_WEBSITE,
        componentType: webview ? WEBVIEW : WEB,
      };
      pushToDataLayer("navigation_click", learnMoreDataLayerObject);
    },
    [webview, pushToDataLayer]
  );

  return { handleLearnMoreDLClick };
};
