import React from "react";

const MostUsedBooster = ({
  boosterCls,
  boosterTitle,
  boosterCaption,
  isTeamName,
  boosterTeamName,
  boosterIcon,
  boosterStats,
  boosterStatsSlug,
}) => {
  return (
    <>
      <section className={`si-muBoost__wrap ${boosterCls}`}>
        <div className="si-main__container">
          <div className="si-muBoost__grid">
            <div className="si-muBoost__grid-lft">
              <h2 className="si-muBoost__title">{boosterTitle}</h2>
              <p className="si-muBoost__caption">{boosterCaption}</p>
              {isTeamName && boosterTeamName && (
                <h3 className="si-muBoost__teamName">
                  <span>{boosterTeamName}</span>
                </h3>
              )}
            </div>
            <div className="si-muBoost__grid-rgt">
              <div className="si-muBoost__chips">
                <div className="si-muBoost__chips-stats">
                  <span>{boosterStats}</span>
                  <em>{boosterStatsSlug}</em>
                </div>
                <div className="si-muBoost__chips-icon">
                  <i className={`${boosterIcon}`}></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { MostUsedBooster };
