import React from "react";
import { useSelector } from "react-redux";

const LeagueSelectBox = ({
  handleChange = () => {},
  selectedOption = "",
  options = [],
  extraClass = "",
}) => {
  return (
    <>
      <div className={`si-league__selectbox ${extraClass}`}>
        <form className="" action="" method="" aria-label="">
          <div className="si-form__control">
            <div className="si-input ">
              <label className="si-lbl" htmlFor="leaguesMatchList"></label>
              <select
                id="leaguesMatchList"
                name="leaguesMatchList"
                aria-disabled="true"
                aria-expanded="true"
                aria-label=""
                value={selectedOption?.value}
                onChange={(e) => handleChange(e)}
              >
                {options?.map((item, index) => (
                  <option key={index} value={item?.value}>
                    {item?.value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LeagueSelectBox;
