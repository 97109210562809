import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";

const IncludeRaceMini = ({ includeRaceMiniConfig }) => {
  const { imageName, imageSrc, matchName, matchRound, matchDate, matchMonth } =
    includeRaceMiniConfig;
  return (
    <>
      <div className="si-includeRace__grid">
        <div className="si-includeRace__grid-lhs">
          <div className="si-includeRace__logo">
            <img
              src={`${config.IMG_BASE}${imageSrc}${imageName}?v=${IMAGE_VERSION}`}
              alt={imageName}
              loading="lazy"
            />
          </div>
          <div className="si-includeRace__fixture">
            <div className="si-includeRace__name">
              <span>{matchName}</span>
            </div>
            <div className="si-includeRace__round">
              <span>{matchRound}</span>
            </div>
          </div>
        </div>
        <div className="si-includeRace__grid-rhs">
          <div className="si-includeRace__date">
            <span>{matchDate}</span>
          </div>
          <div className="si-includeRace__month">
            <span>{matchMonth}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export { IncludeRaceMini };
