export const FETCH_FEEDBACK_QUESTIONS = "FETCH_FEEDBACK_QUESTIONS";
export const FETCH_FEEDBACK_QUESTIONS_PENDING =
  "FETCH_FEEDBACK_QUESTIONS_PENDING";
export const FETCH_FEEDBACK_QUESTIONS_REJECTED =
  "FETCH_FEEDBACK_QUESTIONS_REJECTED";
export const FETCH_FEEDBACK_QUESTIONS_FULFILLED =
  "FETCH_FEEDBACK_QUESTIONS_FULFILLED";

export const SUBMIT_FEEDBACK_QUESTIONS = "SUBMIT_FEEDBACK_QUESTIONS";
export const SUBMIT_FEEDBACK_QUESTIONS_PENDING =
  "SUBMIT_FEEDBACK_QUESTIONS_PENDING";
export const SUBMIT_FEEDBACK_QUESTIONS_REJECTED =
  "SUBMIT_FEEDBACK_QUESTIONS_REJECTED";
export const SUBMIT_FEEDBACK_QUESTIONS_FULFILLED =
  "SUBMIT_FEEDBACK_QUESTIONS_FULFILLED";

export const SUBMIT_CONSTRUCTOR_CONSENT = "SUBMIT_CONSTRUCTOR_CONSENT";
export const SUBMIT_CONSTRUCTOR_CONSENT_PENDING =
  "SUBMIT_CONSTRUCTOR_CONSENT_PENDING";
export const SUBMIT_CONSTRUCTOR_CONSENT_REJECTED =
  "SUBMIT_CONSTRUCTOR_CONSENT_REJECTED";
export const SUBMIT_CONSTRUCTOR_CONSENT_FULFILLED =
  "SUBMIT_CONSTRUCTOR_CONSENT_FULFILLED";
