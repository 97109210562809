import React from "react";
import { H2hNoData } from "../../H2hNoData";
import { getTranslations } from "../../../Common/utils";

const H2HYetToJoin = () => {
  return (
    <>
      <H2hNoData
        h2hIcon={"f1i-timer"}
        h2hText={getTranslations(
          "league_hth_view_after_opponent_joins",
          "View after opponent joins"
        )}
      />
    </>
  );
};

export default H2HYetToJoin;
