import React from "react";
import useDataLayerLeagueMenuClick from "../../Common/hooks/useDataLayerLeagueMenuClick";
import { getTranslations } from "../../Common/utils";
import { LeagueTypeAccordion } from "../LeagueTypeAccordion";
import { SectionHeadingWithCount } from "../SectionHeadingWithCount";

const LeagueAsideNav = ({ asideNavConfig, list, currentActiveItem }) => {
  const { activeItem = null, updateActiveLeagueType = () => {} } =
    asideNavConfig;
  const dataLayerLeagueMenuClicked = useDataLayerLeagueMenuClick();

  return (
    <>
      <div className="si-asideBar__list">
        <ul>
          {list?.map((item, index) => {
            const name = item?.name;
            const isActive = activeItem?.name === name;
            const count = item?.count;
            const isdisabled = !count || count === "0";

            if (!!item?.nestedLeague) {
              return (
                <li key={index}>
                  <LeagueTypeAccordion
                    leagueTypeItem={item}
                    activeItem={activeItem}
                    updateActiveLeagueType={updateActiveLeagueType}
                    currentActiveItem={currentActiveItem}
                    accordionType={name}
                  />
                </li>
              );
            }

            return (
              <li
                key={index}
                className={`${isdisabled ? "si-disabled" : ""} ${
                  isActive ? "si-active" : ""
                }`}
                onClick={() => {
                  updateActiveLeagueType({ name, type: item?.type });
                  dataLayerLeagueMenuClicked(name, item?.type);
                }}
              >
                <SectionHeadingWithCount
                  heading={getTranslations(`league_sidebar_${name}`, name)}
                  count={count}
                />
                {isActive ? <i className="f1i-ar-right"></i> : ""}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export { LeagueAsideNav };
