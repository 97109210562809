import React from "react";
import { PlayerMiniCard } from "../../PlayerMiniCard";
import useBattleMode from "../../HeadToHead/useBattleMode";
import { handleTotalPoints } from "../../../Common/utils/gameplay";
import { h2hCurrentUser, h2hOpponentUser } from "../../../Common/utils/league";
import { useDispatch, useSelector } from "react-redux";
import { currentFixture } from "../../../Common/utils/fixture";
import { getBooster } from "../../../Common/utils/booster";
import {
  getTranslations,
  getYearForImagePath,
  playerPoints,
} from "../../../Common/utils";
import { gameplay } from "../../../redux/actions";
import { PlayerInfo } from "../../PlayerInfo";

const H2HBattleMode = ({ selectedLeague, selectedRaceOption }) => {
  const gameplayState = useSelector((state) => state.gameplay);

  const {
    captains = {},
    drivers = [],
    constructors = [],
    finalFixNewPlayer = {},
    finalFixOldPlayer = {},
    userTeam = {},
    opponentTeam = {},
  } = useBattleMode({
    opponent: h2hOpponentUser(selectedLeague?.users),
    user: h2hCurrentUser(selectedLeague?.users),
    selectedRaceOption,
  });

  if (
    !gameplayState?.fetchH2HOppTeamSuccess ||
    !gameplayState?.h2hOppUserGameDaysSuccess
  ) {
    return <></>;
  }
  return (
    <>
      <div className="si-battle__list">
        <div className="si-battle__list-box">
          <h3 className="si-battle__list-title">
            {getTranslations(
              "league_hth_battle_mode_drs_drivers",
              "DRS Drivers (2x)"
            )}{" "}
          </h3>
          <div className="si-battle__list-items">
            <ul>
              <li>
                <div className="si-battle__listGrid">
                  <H2HBattleModeItem
                    team={userTeam}
                    player={captains.user}
                    finalFixOldPlayer={finalFixOldPlayer?.user}
                  />
                  <H2HBattleModeItem
                    team={opponentTeam}
                    player={captains.opponent}
                    finalFixOldPlayer={finalFixOldPlayer?.opponent}
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="si-battle__list-box">
          <h3 className="si-battle__list-title">
            {getTranslations("league_hth_battle_mode_drivers", "Drivers")}
          </h3>
          <div className="si-battle__list-items">
            <ul>
              {drivers?.map((driver) => (
                <li key={driver.PlayerId}>
                  <div className="si-battle__listGrid">
                    <H2HBattleModeItem
                      team={userTeam}
                      player={driver?.user}
                      finalFixNewPlayer={finalFixNewPlayer?.user}
                    />
                    <H2HBattleModeItem
                      team={opponentTeam}
                      player={driver?.opponent}
                      finalFixNewPlayer={finalFixNewPlayer?.opponent}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="si-battle__list-box">
          <h3 className="si-battle__list-title">
            {getTranslations(
              "league_hth_battle_mode_constructors",
              "Constructors"
            )}
          </h3>
          <div className="si-battle__list-items">
            <ul>
              {constructors?.map((constructor) => (
                <li>
                  <div className="si-battle__listGrid">
                    <H2HBattleModeItem
                      team={userTeam}
                      player={constructor?.user}
                    />
                    <H2HBattleModeItem
                      team={opponentTeam}
                      player={constructor?.opponent}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <PlayerInfo hasCTAs={false} />
    </>
  );
};

const H2HBattleModeItem = ({
  team,
  player,
  finalFixNewPlayer = {},
  finalFixOldPlayer = {},
}) => {
  const dispatch = useDispatch();

  const fixtureState = useSelector((state) => state.fixture);
  const gameplayState = useSelector((state) => state.gameplay);
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const togglePlayerPopup = (player) => {
    dispatch(gameplay.togglePlayerPopup(player));
  };

  return (
    <div className="si-battle__listGrid-item">
      {player?.isFinalFixNewPlayer &&
        player?.isCaptain &&
        finalFixOldPlayer?.PlayerId && (
          <div
            className="si-battle__list-card si-cursor"
            onClick={() => togglePlayerPopup(finalFixOldPlayer)}
          >
            <PlayerMiniCard
              imageSrc={webConfigData?.imagePaths?.driversFront?.replace(
                "{seasonYear}",
                getYearForImagePath(webConfigData)
              )}
              teamID={finalFixOldPlayer?.TeamId}
              imageName={`${finalFixOldPlayer?.PlayerId}.png`}
              PlayerMiniCardName={finalFixOldPlayer?.FUllName}
              extraClass="si-disabled"
              isNameSplit
            />
            <div className="si-battle__list-card-pts">
              <span>
                {playerPoints({
                  team,
                  player: finalFixOldPlayer,
                })}
              </span>
            </div>
          </div>
        )}
      <div
        className="si-battle__list-card si-cursor"
        onClick={() => togglePlayerPopup(player)}
      >
        <PlayerMiniCard
          imageSrc={
            player?.Skill === 1
              ? webConfigData?.imagePaths?.driversFront?.replace(
                  "{seasonYear}",
                  getYearForImagePath(webConfigData)
                )
              : webConfigData?.imagePaths?.teamLogos?.replace(
                  "{seasonYear}",
                  getYearForImagePath(webConfigData)
                )
          }
          teamID={player?.TeamId}
          imageName={`${player?.PlayerId}.png`}
          PlayerMiniCardName={
            player?.Skill === 1 ? player?.FUllName : player?.DisplayName
          }
          booster={{
            ...(player?.PlayerId === team?.selectedExtraDRS?.PlayerId ||
            player?.isFinalFixNewPlayer
              ? getBooster(gameplayState?.boosters?.Value, team?.boosterid)
              : {}),
            hideBoosterText: true,
          }}
          extraClass={
            team?.boosterid === 3 && player?.isFinalFixOldPlayer
              ? "si-disabled"
              : ""
          }
          isNameSplit
        />
        <div className="si-battle__list-card-pts">
          <span>
            {playerPoints({
              team,
              player,
            })}
          </span>
        </div>
      </div>
      {player?.isFinalFixOldPlayer && finalFixNewPlayer?.PlayerId && (
        <div
          className="si-battle__list-card si-cursor"
          onClick={() => togglePlayerPopup(finalFixNewPlayer)}
        >
          <PlayerMiniCard
            imageSrc={webConfigData?.imagePaths?.driversFront?.replace(
              "{seasonYear}",
              getYearForImagePath(webConfigData)
            )}
            teamID={finalFixNewPlayer?.TeamId}
            imageName={`${finalFixNewPlayer?.PlayerId}.png`}
            PlayerMiniCardName={finalFixNewPlayer?.FUllName}
            booster={{
              ...getBooster(gameplayState?.boosters?.Value, 3),
              hideBoosterText: true,
            }}
            isNameSplit
          />
          <div className="si-battle__list-card-pts">
            <span>
              {playerPoints({
                team,
                player: finalFixNewPlayer,
              })}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default H2HBattleMode;
