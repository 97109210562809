import React, { useState } from "react";
import { LeagueContext } from "./LeagueContext";

const LeagueContextProvider = ({ children }) => {
  const [leagueCode, setLeagueCode] = useState("");
  const [sponsorChecked, setSponsorChecked] = useState(false);
  const [
    triggerClassicPublicLeagueRefetch,
    setTriggerClassicPublicLeagueRefetch,
  ] = useState(false);

  const value = {
    leagueCode,
    sponsorChecked,
    triggerClassicPublicLeagueRefetch,
    updateLeagueCode: (value) => setLeagueCode(value),
    updateSponsorChecked: (value) => setSponsorChecked(value),
    updateTriggerClassicPublicLeagueRefetch: (value) =>
      setTriggerClassicPublicLeagueRefetch(value),
  };

  return (
    <LeagueContext.Provider value={value}>{children}</LeagueContext.Provider>
  );
};

export default LeagueContextProvider;
