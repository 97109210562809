import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPath } from "../Common/utils";

const EosOfflineModeRoute = ({ children }) => {
  const navigate = useNavigate();
  const mixAPIState = useSelector((state) => state.mixapi);
  const translationState = useSelector((state) => state.translation);
  const eosOfflineMode = mixAPIState?.data?.conf?.eosoff;

  useEffect(() => {
    if (eosOfflineMode) {
      navigate(getPath(translationState, "home"));
    }
  }, [eosOfflineMode, navigate, translationState]);

  return <>{children}</>;
};

export default EosOfflineModeRoute;
