export const GET_H2H_LEAGUE_LEADER_BOARD = "GET_H2H_LEAGUE_LEADER_BOARD";
export const GET_H2H_LEAGUE_LEADER_BOARD_PENDING =
  "GET_H2H_LEAGUE_LEADER_BOARD_PENDING";
export const GET_H2H_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_H2H_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_H2H_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_H2H_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_USER_PRIVATE_LEAGUE_LEADER_BOARD =
  "GET_USER_PRIVATE_LEAGUE_LEADER_BOARD";
export const GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_PENDING =
  "GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_PENDING";
export const GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_PUBLIC_LEAGUE_LEADER_BOARD = "GET_PUBLIC_LEAGUE_LEADER_BOARD";
export const GET_PUBLIC_LEAGUE_LEADER_BOARD_PENDING =
  "GET_PUBLIC_LEAGUE_LEADER_BOARD_PENDING";
export const GET_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_USER_PUBLIC_LEAGUE_LEADER_BOARD =
  "GET_USER_PUBLIC_LEAGUE_LEADER_BOARD";
export const GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_PENDING =
  "GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_PENDING";
export const GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED";

export const RESET_LEADER_BOARD = "RESET_LEADER_BOARD";

export const GET_GLOBAL_LEAGUE_LEADER_BOARD = "GET_GLOBAL_LEAGUE_LEADER_BOARD";
export const GET_GLOBAL_LEAGUE_LEADER_BOARD_PENDING =
  "GET_GLOBAL_LEAGUE_LEADER_BOARD_PENDING";
export const GET_GLOBAL_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_GLOBAL_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_GLOBAL_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_GLOBAL_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD =
  "GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD";
export const GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_PENDING =
  "GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_PENDING";
export const GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_USER_GLOBAL_LEAGUE_LEADER_BOARD =
  "GET_USER_GLOBAL_LEAGUE_LEADER_BOARD";
export const GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_PENDING =
  "GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_PENDING";
export const GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD =
  "GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD";
export const GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_PENDING =
  "GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_PENDING";
export const GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD =
  "GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD";
export const GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_PENDING =
  "GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_PENDING";
export const GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_COUNTRY_LEAGUE_LEADER_BOARD =
  "GET_COUNTRY_LEAGUE_LEADER_BOARD";
export const GET_COUNTRY_LEAGUE_LEADER_BOARD_PENDING =
  "GET_COUNTRY_LEAGUE_LEADER_BOARD_PENDING";
export const GET_COUNTRY_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_COUNTRY_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_COUNTRY_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_COUNTRY_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_USER_COUNTRY_LEAGUE_LEADER_BOARD =
  "GET_USER_COUNTRY_LEAGUE_LEADER_BOARD";
export const GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_PENDING =
  "GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_PENDING";
export const GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_DRIVER_LEAGUE_LEADER_BOARD = "GET_DRIVER_LEAGUE_LEADER_BOARD";
export const GET_DRIVER_LEAGUE_LEADER_BOARD_PENDING =
  "GET_DRIVER_LEAGUE_LEADER_BOARD_PENDING";
export const GET_DRIVER_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_DRIVER_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_DRIVER_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_DRIVER_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_USER_DRIVER_LEAGUE_LEADER_BOARD =
  "GET_USER_DRIVER_LEAGUE_LEADER_BOARD";
export const GET_USER_DRIVER_LEAGUE_LEADER_BOARD_PENDING =
  "GET_USER_DRIVER_LEAGUE_LEADER_BOARD_PENDING";
export const GET_USER_DRIVER_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_USER_DRIVER_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_USER_DRIVER_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_USER_DRIVER_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_USER_MINI_LEAGUE_LEADER_BOARD =
  "GET_USER_MINI_LEAGUE_LEADER_BOARD";
export const GET_USER_MINI_LEAGUE_LEADER_BOARD_PENDING =
  "GET_USER_MINI_LEAGUE_LEADER_BOARD_PENDING";
export const GET_USER_MINI_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_USER_MINI_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_USER_MINI_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_USER_MINI_LEAGUE_LEADER_BOARD_FULFILLED";

export const GET_MINI_LEAGUE_LEADER_BOARD = "GET_MINI_LEAGUE_LEADER_BOARD";
export const GET_MINI_LEAGUE_LEADER_BOARD_PENDING =
  "GET_MINI_LEAGUE_LEADER_BOARD_PENDING";
export const GET_MINI_LEAGUE_LEADER_BOARD_REJECTED =
  "GET_MINI_LEAGUE_LEADER_BOARD_REJECTED";
export const GET_MINI_LEAGUE_LEADER_BOARD_FULFILLED =
  "GET_MINI_LEAGUE_LEADER_BOARD_FULFILLED";
