import React from "react";
import { getTranslations } from "../../Common/utils";
import { BoosterChips } from "../BoosterChips";

const SelectedChipsWrap = (SelectedChipsConfig) => {
  const {
    boosterCls,
    boosterIcon,
    boosterText,
    boosterThumbnail,
    boosterCaption,
    showWarningText = false,
    lockText = false,
  } = SelectedChipsConfig;
  return (
    <>
      {boosterThumbnail && (
        <div className="si-booster__picked-thumbnail">
          <BoosterChips
            boosterCls={boosterCls}
            boosterIcon={boosterIcon}
            boosterText={boosterText}
          />
        </div>
      )}
      <div className="si-booster__picked">
        {boosterText && (
          <h3 className="si-booster__picked-title">
            <span>{boosterText}</span>
          </h3>
        )}
        {boosterCaption && (
          <div className="si-booster__picked-caption">
            <p>{boosterCaption}</p>
          </div>
        )}
        {lockText && (
          <div className="si-booster__picked-caption si-negative">
            <p>{lockText}</p>
          </div>
        )}
      </div>
      {showWarningText && (
        <div className="si-errMsg">
          <span>
            {getTranslations(
              "confirm_chip_warning",
              "*After applying you will not be able to change or remove the chip."
            )}
          </span>
        </div>
      )}
    </>
  );
};

export { SelectedChipsWrap };
