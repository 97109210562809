import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameplay } from "../../redux/actions";
import { CtaButton } from "../Buttons";
import {
  isPlayerAdded,
  canAddPlayer,
  isReplacedPlayer,
  isFinalFix,
  togglePlayer,
} from "../../Common/utils/team";
import { getTranslations } from "../../Common/utils";

const PlayerButton = ({
  player,
  ctaAppendClass,
  showButtonText = true,
  index = undefined,
  type = "list",
  isTurboDriver = false,
  disableRemoveCTA = false,
  pushTurboDriverDataLayer = () => {},
}) => {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);
  const {
    selectedPlayers,
    budget,
    replacedPlayer,
    selectedTurboDriver,
    showPlayerPopup,
  } = gameplayState;

  const isDisabled = () => {
    const finalFixChipCondition =
      disableRemoveCTA && isPlayerAdded(player, selectedPlayers);
    if (finalFixChipCondition) {
      return true;
    }
    return (
      isReplacedPlayer(player, replacedPlayer) ||
      (!isPlayerAdded(player, selectedPlayers) &&
        !canAddPlayer(player, selectedPlayers, budget, gameplayState))
    );
  };

  const isDriver = () => {
    return player?.Skill === 1;
  };

  const isConstructor = () => {
    return player?.Skill === 2;
  };

  const handleFinalFix = () => {
    return (
      isFinalFix(gameplayState) &&
      selectedPlayers?.filter((x) => x.PlayerId)?.length === 7
    );
  };

  const setTurboDriver = (player) => {
    dispatch(gameplay.setTempTurboDriver(player));
    setTimeout(() => {
      dispatch(gameplay.setTurboDriver());
    }, 100);
  };

  const handleTogglePlayer = (
    player,
    index,
    dispatch,
    gameplay,
    selectedPlayers,
    budget
  ) => {
    togglePlayer(
      player,
      index,
      dispatch,
      gameplay,
      selectedPlayers,
      budget,
      gameplayState
    );
    if (
      isFinalFix(gameplayState) &&
      replacedPlayer?.PlayerId &&
      !selectedTurboDriver
    ) {
      setTurboDriver(player);
    }

    if (showPlayerPopup) {
      togglePlayerPopup();
    }
  };

  const togglePlayerPopup = () => {
    dispatch(gameplay.togglePlayerPopup(null));
  };

  const getButtonText = () => {
    if (type === "info") {
      if (isPlayerAdded(player, selectedPlayers) && isDriver()) {
        return getTranslations("create_team_remove_driver", "Remove Driver");
      }
      if (!isPlayerAdded(player, selectedPlayers) && isDriver()) {
        return getTranslations("create_team_add_driver", "Add Driver");
      }
      if (isPlayerAdded(player, selectedPlayers) && isConstructor()) {
        return getTranslations(
          "create_team_remove_constructor",
          "Remove Constructor"
        );
      }
      if (!isPlayerAdded(player, selectedPlayers) && isConstructor()) {
        return getTranslations(
          "create_team_add_constructor",
          "Add Constructor"
        );
      }
    }
    if (showButtonText && ctaAppendClass !== "si-btn__link") {
      return isPlayerAdded(player, selectedPlayers)
        ? getTranslations("create_team_remove", "Remove")
        : getTranslations("create_team_add", "Add");
    }
  };

  const getAriaText = () => {
    if (isPlayerAdded(player, selectedPlayers) && isDriver()) {
      return getTranslations("create_team_remove_driver", "Remove Driver");
    }
    if (!isPlayerAdded(player, selectedPlayers) && isDriver()) {
      return getTranslations("create_team_add_driver", "Add Driver");
    }
    if (isPlayerAdded(player, selectedPlayers) && isConstructor()) {
      return getTranslations(
        "create_team_remove_constructor",
        "Remove Constructor"
      );
    }
    if (!isPlayerAdded(player, selectedPlayers) && isConstructor()) {
      return getTranslations("create_team_add_constructor", "Add Constructor");
    }
  };

  const toggleDRSBoost = () => {
    if (isTurboDriver) {
      setTurboDriver(null);
    } else {
      setTurboDriver(player);
    }
    if (showPlayerPopup) {
      togglePlayerPopup();
    }
  };

  const getButtonClass = () => {
    if (type === "info") {
      return isPlayerAdded(player, selectedPlayers)
        ? ctaAppendClass
        : "si-btn__primary";
    }
    return ctaAppendClass;
  };

  return (
    <>
      <CtaButton
        btnCls={`si-btn ${getButtonClass()} ${
          isPlayerAdded(player, selectedPlayers) &&
          ctaAppendClass !== "si-btn__link"
            ? "si-btn__secondary--outline"
            : ""
        }`}
        iconCls={
          type !== "info"
            ? isPlayerAdded(player, selectedPlayers)
              ? "f1i-close"
              : "f1i-add"
            : undefined
        }
        btnText={getButtonText()}
        btnState={isDisabled()}
        ariaLabel={getAriaText()}
        onClickProp={(e) => {
          e.stopPropagation();
          if (handleFinalFix()) {
            dispatch(gameplay.setReplacedPlayer(player));
          }
          handleTogglePlayer(
            player,
            index,
            dispatch,
            gameplay,
            selectedPlayers,
            budget
          );
        }}
      />
      {type === "info" &&
        isPlayerAdded(player, selectedPlayers) &&
        isDriver() &&
        !isTurboDriver && (
          <CtaButton
            btnCls="si-btn si-btn__primary"
            btnText={getTranslations(
              "driver_constructor_card_add_drs_boost",
              "Add DRS Boost"
            )}
            ariaLabel="Add DRS Boost"
            onClickProp={() => {
              pushTurboDriverDataLayer(
                getTranslations(
                  "driver_constructor_card_add_drs_boost",
                  "Add DRS Boost"
                ),
                "Confirm"
              );
              toggleDRSBoost();
            }}
          />
        )}
      {type === "info" &&
        isPlayerAdded(player, selectedPlayers) &&
        isDriver() &&
        isTurboDriver && (
          <CtaButton
            btnCls="si-btn si-btn__secondary"
            btnText={getTranslations(
              "driver_constructor_card_remove_drs_boost",
              "Remove DRS Boost"
            )}
            ariaLabel="Remove DRS Boost"
            onClickProp={() => toggleDRSBoost()}
          />
        )}
    </>
  );
};

export default PlayerButton;
