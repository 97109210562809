export const DUMMY_DATA = {
  Data: {
    Value: {
      PlayerId: 6066,
      PlayerSkill: 1,
      GamedayWiseStats: [
        // Weekend data for the GamedayId
        {
          GamedayId: 1,
          PlayerValue: 23.7,
          IsPlayed: 1,
          StatsWise: [
            {
              Frequency: 10.1,
              Event: "Total",
              Value: 10,
            },
            {
              Frequency: 10,
              Event: "Race Position",
              Value: 8,
            },
            {
              Frequency: 11,
              Event: "Qualifying Position",
              Value: 9,
            },
            {
              Frequency: 12,
              Event: "Sprint Position",
              Value: 7,
            },
            {
              Frequency: 13,
              Event: "Race not classified ",
              Value: 10,
            },
            {
              Frequency: 14,
              Event: "QF not classified ",
              Value: 7,
            },
            {
              Frequency: 15,
              Event: "DQ Race",
              Value: 8,
            },
            {
              Frequency: 16,
              Event: "DQ QF",
              Value: 10,
            },
            {
              Frequency: 17,
              Event: "DQ Sprint",
              Value: 6,
            },
            {
              Frequency: 18,
              Event: "Both Driver Q3",
              Value: 10,
            },
            {
              Frequency: 19,
              Event: "One Driver Q3",
              Value: 8,
            },
            {
              Frequency: 2,
              Event: "Both Driver Q2",
              Value: 6,
            },
            {
              Frequency: 20,
              Event: "One Driver Q2",
              Value: 7,
            },
            {
              Frequency: 21,
              Event: "No Driver Q2",
              Value: 6,
            },
            {
              Frequency: 22,
              Event: "Sprint position gained",
              Value: 7,
            },
            {
              Frequency: 23,
              Event: "Sprint Position lost",
              Value: 5,
            },
            {
              Frequency: 24,
              Event: "race Fastest lap",
              Value: 8,
            },
            {
              Frequency: 9,
              Event: "Driver Of Day",
              Value: 7,
            },
            {
              Frequency: 8,
              Event: "Sprint overtake bonus",
              Value: 10,
            },
            {
              Frequency: 5,
              Event: "race position gained",
              Value: 10,
            },
            {
              Frequency: 4,
              Event: "race Position lost",
              Value: 10,
            },
            {
              Frequency: 3,
              Event: "race Fastest lap",
              Value: 10,
            },
            {
              Frequency: 25,
              Event: "Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 26,
              Event: "2nd Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 27,
              Event: "3rd Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 28,
              Event: "race overtake bonus",
              Value: 10,
            },
          ],
        },
        {
          GamedayId: 2,
          PlayerValue: 23.7,
          IsPlayed: 1,
          StatsWise: [
            {
              Frequency: 11.1,
              Event: "Total",
              Value: 11,
            },
            {
              Frequency: 10,
              Event: "Race Position",
              Value: 8,
            },
            {
              Frequency: 11,
              Event: "Qualifying Position",
              Value: 9,
            },
            {
              Frequency: 12,
              Event: "Sprint Position",
              Value: 7,
            },
            {
              Frequency: 13,
              Event: "Race not classified ",
              Value: 10,
            },
            {
              Frequency: 14,
              Event: "QF not classified ",
              Value: 7,
            },
            {
              Frequency: 15,
              Event: "DQ Race",
              Value: 8,
            },
            {
              Frequency: 16,
              Event: "DQ QF",
              Value: 10,
            },
            {
              Frequency: 17,
              Event: "DQ Sprint",
              Value: 6,
            },
            {
              Frequency: 18,
              Event: "Both Driver Q3",
              Value: 10,
            },
            {
              Frequency: 19,
              Event: "One Driver Q3",
              Value: 8,
            },
            {
              Frequency: 2,
              Event: "Both Driver Q2",
              Value: 6,
            },
            {
              Frequency: 20,
              Event: "One Driver Q2",
              Value: 7,
            },
            {
              Frequency: 21,
              Event: "No Driver Q2",
              Value: 6,
            },
            {
              Frequency: 22,
              Event: "Sprint position gained",
              Value: 7,
            },
            {
              Frequency: 23,
              Event: "Sprint Position lost",
              Value: 5,
            },
            {
              Frequency: 24,
              Event: "race Fastest lap",
              Value: 8,
            },
            {
              Frequency: 9,
              Event: "Driver Of Day",
              Value: 7,
            },
            {
              Frequency: 8,
              Event: "Sprint overtake bonus",
              Value: 10,
            },
            {
              Frequency: 5,
              Event: "race position gained",
              Value: 10,
            },
            {
              Frequency: 4,
              Event: "race Position lost",
              Value: 10,
            },
            {
              Frequency: 3,
              Event: "race Fastest lap",
              Value: 10,
            },
            {
              Frequency: 25,
              Event: "Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 26,
              Event: "2nd Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 27,
              Event: "3rd Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 28,
              Event: "race overtake bonus",
              Value: 10,
            },
          ],
        },
      ],
      TourWiseStats: [
        //Commplete season data
        {
          TourId: 1,
          PlayerValue: 23.7,
          IsPlayed: 1,
          StatsWise: [
            {
              Frequency: 10.1,
              Event: "Total",
              Value: 10,
            },
            {
              Frequency: 10,
              Event: "Race Position",
              Value: 8,
            },
            {
              Frequency: 11,
              Event: "Qualifying Position",
              Value: 9,
            },
            {
              Frequency: 12,
              Event: "Sprint Position",
              Value: 7,
            },
            {
              Frequency: 13,
              Event: "Race not classified ",
              Value: 10,
            },
            {
              Frequency: 14,
              Event: "QF not classified ",
              Value: 7,
            },
            {
              Frequency: 15,
              Event: "DQ Race",
              Value: 8,
            },
            {
              Frequency: 16,
              Event: "DQ QF",
              Value: 10,
            },
            {
              Frequency: 17,
              Event: "DQ Sprint",
              Value: 6,
            },
            {
              Frequency: 18,
              Event: "Both Driver Q3",
              Value: 10,
            },
            {
              Frequency: 19,
              Event: "One Driver Q3",
              Value: 8,
            },
            {
              Frequency: 2,
              Event: "Both Driver Q2",
              Value: 6,
            },
            {
              Frequency: 20,
              Event: "One Driver Q2",
              Value: 7,
            },
            {
              Frequency: 21,
              Event: "No Driver Q2",
              Value: 6,
            },
            {
              Frequency: 22,
              Event: "Sprint position gained",
              Value: 7,
            },
            {
              Frequency: 23,
              Event: "Sprint Position lost",
              Value: 5,
            },
            {
              Frequency: 24,
              Event: "race Fastest lap",
              Value: 8,
            },
            {
              Frequency: 9,
              Event: "Driver Of Day",
              Value: 7,
            },
            {
              Frequency: 8,
              Event: "Sprint overtake bonus",
              Value: 10,
            },
            {
              Frequency: 5,
              Event: "race position gained",
              Value: 10,
            },
            {
              Frequency: 4,
              Event: "race Position lost",
              Value: 10,
            },
            {
              Frequency: 3,
              Event: "race Fastest lap",
              Value: 10,
            },
            {
              Frequency: 25,
              Event: "Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 26,
              Event: "2nd Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 27,
              Event: "3rd Fastest Pitstop",
              Value: 10,
            },
            {
              Frequency: 28,
              Event: "race overtake bonus",
              Value: 10,
            },
          ],
        },
      ],
      FixtureWiseStats: [
        {
          GamedayId: 1,
          TourId: 1,
          RaceDayWise: [
            {
              PlayerValue: 23.7,
              RaceDayId: 4784,
              TeamId: 8,
              DateTime: "2023-03-30 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 1,
              CountryName: "India",
              CircuitOfficialName: "",
              SessionName: "Race",
              SessionType: "Race",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              SelectedPercentage: "57",
              CapSelectedPercentage: "75",
            },
            {
              PlayerValue: 23.7,
              RaceDayId: 4783,
              TeamId: 8,
              DateTime: "2023-03-18 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 1,
              CountryName: "India",
              CircuitOfficialName: "",
              SessionName: "Qualifying",
              SessionType: "Qualifying",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              SelectedPercentage: "57",
              CapSelectedPercentage: "75",
            },
          ],
        },
        {
          GamedayId: 2,
          TourId: 2,
          RaceDayWise: [
            {
              PlayerValue: 23.7,
              RaceDayId: 4786,
              TeamId: 8,
              DateTime: "2023-03-20 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 2,
              CountryName: "Australia",
              CircuitOfficialName: "",
              SessionName: "Race",
              SessionType: "Race",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              SelectedPercentage: "57",
              CapSelectedPercentage: "75",
            },
            {
              PlayerValue: 23.7,
              RaceDayId: 4787,
              TeamId: 8,
              DateTime: "2023-03-08 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 2,
              CountryName: "Australia",
              CircuitOfficialName: "",
              SessionName: "Sprint",
              SessionType: "Sprint",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              SelectedPercentage: "57",
              CapSelectedPercentage: "75",
            },
            {
              PlayerValue: 23.7,
              RaceDayId: 4785,
              TeamId: 8,
              DateTime: "2023-03-05 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 2,
              CountryName: "Australia",
              CircuitOfficialName: "",
              SessionName: "Qualifying",
              SessionType: "Qualifying",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              SelectedPercentage: "57",
              CapSelectedPercentage: "75",
            },
          ],
        },
      ],
      MatchWiseStats: [
        {
          GamedayId: 1,
          TourId: 1,
          RaceDayWise: [
            // Qualifying / Race / Sprint Data
            {
              PlayerValue: 23.7,
              RaceDayId: 4784,
              TeamId: 8,
              DateTime: "2023-03-30 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 1,
              CountryName: "India",
              CircuitOfficialName: "",
              SessionName: "Race",
              SessionType: "Race",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              IsPlayed: 1,
              StatsWise: [
                {
                  Frequency: 10.1,
                  Event: "Total",
                  Value: 10,
                },
                {
                  Frequency: 10,
                  Event: "Race Position",
                  Value: 8,
                },
                {
                  Frequency: 11,
                  Event: "Qualifying Position",
                  Value: 9,
                },
                {
                  Frequency: 12,
                  Event: "Sprint Position",
                  Value: 7,
                },
                {
                  Frequency: 13,
                  Event: "Race not classified ",
                  Value: 10,
                },
                {
                  Frequency: 14,
                  Event: "QF not classified ",
                  Value: 7,
                },
                {
                  Frequency: 15,
                  Event: "DQ Race",
                  Value: 8,
                },
                {
                  Frequency: 16,
                  Event: "DQ QF",
                  Value: 10,
                },
                {
                  Frequency: 17,
                  Event: "DQ Sprint",
                  Value: 6,
                },
                {
                  Frequency: 18,
                  Event: "Both Driver Q3",
                  Value: 10,
                },
                {
                  Frequency: 19,
                  Event: "One Driver Q3",
                  Value: 8,
                },
                {
                  Frequency: 2,
                  Event: "Both Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 20,
                  Event: "One Driver Q2",
                  Value: 7,
                },
                {
                  Frequency: 21,
                  Event: "No Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 22,
                  Event: "Sprint position gained",
                  Value: 7,
                },
                {
                  Frequency: 23,
                  Event: "Sprint Position lost",
                  Value: 5,
                },
                {
                  Frequency: 24,
                  Event: "race Fastest lap",
                  Value: 8,
                },
                {
                  Frequency: 9,
                  Event: "Driver Of Day",
                  Value: 7,
                },
                {
                  Frequency: 8,
                  Event: "Sprint overtake bonus",
                  Value: 10,
                },
                {
                  Frequency: 5,
                  Event: "race position gained",
                  Value: 10,
                },
                {
                  Frequency: 4,
                  Event: "race Position lost",
                  Value: 10,
                },
                {
                  Frequency: 3,
                  Event: "race Fastest lap",
                  Value: 10,
                },
                {
                  Frequency: 25,
                  Event: "Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 26,
                  Event: "2nd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 27,
                  Event: "3rd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 28,
                  Event: "race overtake bonus",
                  Value: 10,
                },
              ],
            },
            {
              PlayerValue: 23.7,
              RaceDayId: 4783,
              TeamId: 8,
              DateTime: "2023-03-18 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 1,
              CountryName: "India",
              CircuitOfficialName: "",
              SessionName: "Qualifying",
              SessionType: "Qualifying",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              IsPlayed: 1,
              StatsWise: [
                {
                  Frequency: 10.1,
                  Event: "Total",
                  Value: 10,
                },
                {
                  Frequency: 10,
                  Event: "Race Position",
                  Value: 8,
                },
                {
                  Frequency: 11,
                  Event: "Qualifying Position",
                  Value: 9,
                },
                {
                  Frequency: 12,
                  Event: "Sprint Position",
                  Value: 7,
                },
                {
                  Frequency: 13,
                  Event: "Race not classified ",
                  Value: 10,
                },
                {
                  Frequency: 14,
                  Event: "QF not classified ",
                  Value: 7,
                },
                {
                  Frequency: 15,
                  Event: "DQ Race",
                  Value: 8,
                },
                {
                  Frequency: 16,
                  Event: "DQ QF",
                  Value: 10,
                },
                {
                  Frequency: 17,
                  Event: "DQ Sprint",
                  Value: 6,
                },
                {
                  Frequency: 18,
                  Event: "Both Driver Q3",
                  Value: 10,
                },
                {
                  Frequency: 19,
                  Event: "One Driver Q3",
                  Value: 8,
                },
                {
                  Frequency: 2,
                  Event: "Both Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 20,
                  Event: "One Driver Q2",
                  Value: 7,
                },
                {
                  Frequency: 21,
                  Event: "No Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 22,
                  Event: "Sprint position gained",
                  Value: 7,
                },
                {
                  Frequency: 23,
                  Event: "Sprint Position lost",
                  Value: 5,
                },
                {
                  Frequency: 24,
                  Event: "race Fastest lap",
                  Value: 8,
                },
                {
                  Frequency: 9,
                  Event: "Driver Of Day",
                  Value: 7,
                },
                {
                  Frequency: 8,
                  Event: "Sprint overtake bonus",
                  Value: 10,
                },
                {
                  Frequency: 5,
                  Event: "race position gained",
                  Value: 10,
                },
                {
                  Frequency: 4,
                  Event: "race Position lost",
                  Value: 10,
                },
                {
                  Frequency: 3,
                  Event: "race Fastest lap",
                  Value: 10,
                },
                {
                  Frequency: 25,
                  Event: "Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 26,
                  Event: "2nd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 27,
                  Event: "3rd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 28,
                  Event: "race overtake bonus",
                  Value: 10,
                },
              ],
            },
          ],
        },
        {
          GamedayId: 2,
          TourId: 2,
          RaceDayWise: [
            // Qualifying / Race / Sprint Data
            {
              PlayerValue: 23.7,
              RaceDayId: 4786,
              TeamId: 8,
              DateTime: "2023-03-20 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 2,
              CountryName: "Australia",
              CircuitOfficialName: "",
              SessionName: "Race",
              SessionType: "Race",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              IsPlayed: 1,
              StatsWise: [
                {
                  Frequency: 10.1,
                  Event: "Total",
                  Value: 10,
                },
                {
                  Frequency: 10,
                  Event: "Race Position",
                  Value: 8,
                },
                {
                  Frequency: 11,
                  Event: "Qualifying Position",
                  Value: 9,
                },
                {
                  Frequency: 12,
                  Event: "Sprint Position",
                  Value: 7,
                },
                {
                  Frequency: 13,
                  Event: "Race not classified ",
                  Value: 10,
                },
                {
                  Frequency: 14,
                  Event: "QF not classified ",
                  Value: 7,
                },
                {
                  Frequency: 15,
                  Event: "DQ Race",
                  Value: 8,
                },
                {
                  Frequency: 16,
                  Event: "DQ QF",
                  Value: 10,
                },
                {
                  Frequency: 17,
                  Event: "DQ Sprint",
                  Value: 6,
                },
                {
                  Frequency: 18,
                  Event: "Both Driver Q3",
                  Value: 10,
                },
                {
                  Frequency: 19,
                  Event: "One Driver Q3",
                  Value: 8,
                },
                {
                  Frequency: 2,
                  Event: "Both Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 20,
                  Event: "One Driver Q2",
                  Value: 7,
                },
                {
                  Frequency: 21,
                  Event: "No Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 22,
                  Event: "Sprint position gained",
                  Value: 7,
                },
                {
                  Frequency: 23,
                  Event: "Sprint Position lost",
                  Value: 5,
                },
                {
                  Frequency: 24,
                  Event: "race Fastest lap",
                  Value: 8,
                },
                {
                  Frequency: 9,
                  Event: "Driver Of Day",
                  Value: 7,
                },
                {
                  Frequency: 8,
                  Event: "Sprint overtake bonus",
                  Value: 10,
                },
                {
                  Frequency: 5,
                  Event: "race position gained",
                  Value: 10,
                },
                {
                  Frequency: 4,
                  Event: "race Position lost",
                  Value: 10,
                },
                {
                  Frequency: 3,
                  Event: "race Fastest lap",
                  Value: 10,
                },
                {
                  Frequency: 25,
                  Event: "Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 26,
                  Event: "2nd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 27,
                  Event: "3rd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 28,
                  Event: "race overtake bonus",
                  Value: 10,
                },
              ],
            },
            {
              PlayerValue: 23.7,
              RaceDayId: 4787,
              TeamId: 8,
              DateTime: "2023-03-08 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 2,
              CountryName: "Australia",
              CircuitOfficialName: "",
              SessionName: "Sprint",
              SessionType: "Sprint",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              IsPlayed: 1,
              StatsWise: [
                {
                  Frequency: 10.1,
                  Event: "Total",
                  Value: 10,
                },
                {
                  Frequency: 10,
                  Event: "Race Position",
                  Value: 8,
                },
                {
                  Frequency: 11,
                  Event: "Qualifying Position",
                  Value: 9,
                },
                {
                  Frequency: 12,
                  Event: "Sprint Position",
                  Value: 7,
                },
                {
                  Frequency: 13,
                  Event: "Race not classified ",
                  Value: 10,
                },
                {
                  Frequency: 14,
                  Event: "QF not classified ",
                  Value: 7,
                },
                {
                  Frequency: 15,
                  Event: "DQ Race",
                  Value: 8,
                },
                {
                  Frequency: 16,
                  Event: "DQ QF",
                  Value: 10,
                },
                {
                  Frequency: 17,
                  Event: "DQ Sprint",
                  Value: 6,
                },
                {
                  Frequency: 18,
                  Event: "Both Driver Q3",
                  Value: 10,
                },
                {
                  Frequency: 19,
                  Event: "One Driver Q3",
                  Value: 8,
                },
                {
                  Frequency: 2,
                  Event: "Both Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 20,
                  Event: "One Driver Q2",
                  Value: 7,
                },
                {
                  Frequency: 21,
                  Event: "No Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 22,
                  Event: "Sprint position gained",
                  Value: 7,
                },
                {
                  Frequency: 23,
                  Event: "Sprint Position lost",
                  Value: 5,
                },
                {
                  Frequency: 24,
                  Event: "race Fastest lap",
                  Value: 8,
                },
                {
                  Frequency: 9,
                  Event: "Driver Of Day",
                  Value: 7,
                },
                {
                  Frequency: 8,
                  Event: "Sprint overtake bonus",
                  Value: 10,
                },
                {
                  Frequency: 5,
                  Event: "race position gained",
                  Value: 10,
                },
                {
                  Frequency: 4,
                  Event: "race Position lost",
                  Value: 10,
                },
                {
                  Frequency: 3,
                  Event: "race Fastest lap",
                  Value: 10,
                },
                {
                  Frequency: 25,
                  Event: "Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 26,
                  Event: "2nd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 27,
                  Event: "3rd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 28,
                  Event: "race overtake bonus",
                  Value: 10,
                },
              ],
            },
            {
              PlayerValue: 23.7,
              RaceDayId: 4785,
              TeamId: 8,
              DateTime: "2023-03-05 01:30:00",
              MatchStatus: "",
              MeetingLocation: "Sakhir",
              MeetingNumber: 1,
              MeetingName: "Bahrain Grand Prix",
              CountryId: 2,
              CountryName: "Australia",
              CircuitOfficialName: "",
              SessionName: "Qualifying",
              SessionType: "Qualifying",
              SessionNumber: "0",
              SessionStartDate: "2023-03-18",
              Season: "2022",
              IsPlayed: 1,
              StatsWise: [
                {
                  Frequency: 10.1,
                  Event: "Total",
                  Value: 10,
                },
                {
                  Frequency: 10,
                  Event: "Race Position",
                  Value: 8,
                },
                {
                  Frequency: 11,
                  Event: "Qualifying Position",
                  Value: 9,
                },
                {
                  Frequency: 12,
                  Event: "Sprint Position",
                  Value: 7,
                },
                {
                  Frequency: 13,
                  Event: "Race not classified ",
                  Value: 10,
                },
                {
                  Frequency: 14,
                  Event: "QF not classified ",
                  Value: 7,
                },
                {
                  Frequency: 15,
                  Event: "DQ Race",
                  Value: 8,
                },
                {
                  Frequency: 16,
                  Event: "DQ QF",
                  Value: 10,
                },
                {
                  Frequency: 17,
                  Event: "DQ Sprint",
                  Value: 6,
                },
                {
                  Frequency: 18,
                  Event: "Both Driver Q3",
                  Value: 10,
                },
                {
                  Frequency: 19,
                  Event: "One Driver Q3",
                  Value: 8,
                },
                {
                  Frequency: 2,
                  Event: "Both Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 20,
                  Event: "One Driver Q2",
                  Value: 7,
                },
                {
                  Frequency: 21,
                  Event: "No Driver Q2",
                  Value: 6,
                },
                {
                  Frequency: 22,
                  Event: "Sprint position gained",
                  Value: 7,
                },
                {
                  Frequency: 23,
                  Event: "Sprint Position lost",
                  Value: 5,
                },
                {
                  Frequency: 24,
                  Event: "race Fastest lap",
                  Value: 8,
                },
                {
                  Frequency: 9,
                  Event: "Driver Of Day",
                  Value: 7,
                },
                {
                  Frequency: 8,
                  Event: "Sprint overtake bonus",
                  Value: 10,
                },
                {
                  Frequency: 5,
                  Event: "race position gained",
                  Value: 10,
                },
                {
                  Frequency: 4,
                  Event: "race Position lost",
                  Value: 10,
                },
                {
                  Frequency: 3,
                  Event: "race Fastest lap",
                  Value: 10,
                },
                {
                  Frequency: 25,
                  Event: "Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 26,
                  Event: "2nd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 27,
                  Event: "3rd Fastest Pitstop",
                  Value: 10,
                },
                {
                  Frequency: 28,
                  Event: "race overtake bonus",
                  Value: 10,
                },
              ],
            },
          ],
        },
      ],
    },
    FeedTime: {
      UTCTime: "2/2/2023 11:07:16 AM",
      ISTTime: "2/2/2023 4:35:16 AM",
      CESTTime: "2/2/2023 12:07:16 AM",
    },
  },
  Meta: {
    Message: "Success",
    RefVal: 1,
    Success: true,
    Timestamp: {
      UTCTime: "2/2/2023 11:07:16 AM",
      ISTTime: "2/2/2023 4:35:16 AM",
      CESTTime: "2/2/2023 12:07:16 AM",
    },
  },
};

const DataValue = DUMMY_DATA.Data.Value;
const {
  PlayerId,
  PlayerSkill,
  GamedayWiseStats, // Weekend data for the GamedayId
  TourWiseStats, // Complete Season Data
  FixtureWiseStats, // Data for rest of the Accordion
  MatchWiseStats, // Qualifying / Race / Sprint Data
} = DataValue;

const RaceCompleteData = FixtureWiseStats.map(
  ({ GamedayId, TourId, RaceDayWise }) => {
    const { CountryId, CountryName } = RaceDayWise[0];

    const WeekendTypeData = GamedayWiseStats?.find(
      (item) => item.GamedayId === GamedayId
    );
    const isWeekendData = WeekendTypeData ? true : false;

    const MatchWiseData = MatchWiseStats?.find(
      (item) => item.GamedayId === GamedayId
    );

    const QualifyingMatchDetails = RaceDayWise?.find(
      (item) => item.SessionType === "Qualifying"
    );
    const { RaceDayId: QualifyingMatchId } = QualifyingMatchDetails;
    const QualifyingMatchData = MatchWiseData?.RaceDayWise?.find(
      (item) => item.RaceDayId === QualifyingMatchId
    );
    const isQualifyingMatchData = QualifyingMatchData ? true : false;

    const RaceMatchDetails = RaceDayWise?.find(
      (item) => item.SessionType === "Race"
    );
    const { RaceDayId: RaceMatchId } = RaceMatchDetails;
    const RaceMatchData = MatchWiseData?.RaceDayWise?.find(
      (item) => item.RaceDayId === RaceMatchId
    );
    const isRaceMatchData = RaceMatchData ? true : false;

    const SprintMatchDetails = RaceDayWise?.find(
      (item) => item.SessionType === "Sprint"
    );
    const SprintMatchId = SprintMatchDetails?.RaceDayId;
    let SprintMatchData;
    if (SprintMatchId) {
      SprintMatchData = MatchWiseData.RaceDayWise?.find(
        (item) => item.RaceDayId === SprintMatchId
      );
    }
    const isSprintMatchData = SprintMatchData ? true : false;

    return {
      GamedayId,
      TourId,
      CountryId,
      CountryName,
      isWeekendData,
      isQualifyingMatchData,
      isRaceMatchData,
      isSprintMatchData,
      WeekendData: WeekendTypeData,
      QualifyingMatchData,
      RaceMatchData,
      SprintMatchData,
    };
  }
);

const getRaceData = (raceData) => {
  let id = 2;

  return raceData.map((item) => ({
    id: id++,
    isActive: false,
    isTabRequired: true,
    content: item,
  }));
};

export const RequiredData = [
  {
    id: 1,
    isActive: true,
    isTabRequired: false,
    content: {
      isSeasonData: true,
      ...TourWiseStats[0],
    },
  },
  ...getRaceData(RaceCompleteData),
];
