import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CtaButton,
  FixDates,
  FixRound,
  LoadMoreCta,
  NoDataFound,
  Overlays,
  PvtPubCardInfo,
  ToastNotification,
} from "..";
import {
  cf_user_league_create,
  cf_user_league_create_error_messages,
  cf_user_league_create_error_headers,
  leagueMembersConstants,
  PLATFORM_CATEGORY,
  PLATFORM_ID,
  PLATFORM_VERSION,
  MAX_LEAGUE_NAME_CHARACTERS,
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEBVIEW,
  WEB,
  MIN_LEAGUE_NAME_CHARACTERS,
} from "../../Common/constants";
import {
  decodeName,
  getLeagueTypeForDL,
  getPath,
  getTranslations,
  getWebPurifyStatus,
  isAndroid,
  isIOS,
  isMobile,
} from "../../Common/utils";
import { getFixtureDateForLeagues } from "../../Common/utils/fixture";
import {
  getLeagueTypeId,
  manageAllowedOptions,
} from "../../Common/utils/league";
import LeagueDelete from "../../Components/League/LeagueDelete";
import LeagueSelectBox from "../../Components/League/LeagueSelectBox";
import LeagueShare from "../../Components/League/LeagueShare";
import { LeaveLeague } from "../../Components/League/LeaveLeague";
import { league } from "../../redux/actions";
import { InputSearch } from "../InputSearch";
import { LeagueHeader } from "../LeagueHeader";
import PageLoader from "../Loaders/PageLoader";
import SectionLoader from "../Loaders/SectionLoader";
import LeagueNameOverlay from "../Overlays/LeagueNameOverlay";
import ReportLeague from "../Overlays/ReportLeague";
import List from "./List";
import useDataLayer from "../../Common/hooks/useDataLayer";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";
import { EditALeague } from "../Overlays/EditALeague.js";

const LeagueManages = () => {
  const { leagueId, type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pushToDataLayer = useDataLayer();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const { pathname } = location;

  const [showLeagueNamePopup, setShowLeagueNamePopup] = useState(false);
  const [showReportLeaguePopup, setShowReportLeaguePopup] = useState(false);
  const [showReportLeagueSuccessToast, setShowReportLeagueSuccessToast] =
    useState(false);
  const [showEditLeaguePopup, setShowEditLeaguePopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearched, setIsSearched] = useState(false);
  const [isSearchResetted, setIsSearchResetted] = useState(false);
  const [isAnAdmin, setIsAnAdmin] = useState(false);
  const [isMemberRemoved, setIsMemberRemoved] = useState(false);
  const [isUnlimitedMembers, setIsUnlimitedMembers] = useState(false);
  const [isEditSubmit, setIsEditSubmit] = useState(false);
  const [name, setName] = useState("");

  const leagueState = useSelector((state) => state.league);
  const userState = useSelector((state) => state.user);
  const translationState = useSelector((state) => state.translation);
  const mixApiState = useSelector((state) => state.mixapi);
  const [currentLeagueAdmin, setCurrentLeagueAdmin] = useState(null);
  const {
    showRemoveMember,
    success,
    privateLeagues,
    leagueMembers,
    leagueAdmin,
    leagueMembersLoading,
    leagueMembersSuccess,
    leagueMembersError,
    updateLeagueSuccess,
    deleteLeagueSuccess,
    reportLeagueSuccess,
    removeMemberSuccess,
    leagueUserInfo,
    selectedMember,
    selectedLeague,
    updateLeagueError,
    updateLeagueErrorData,
    hasMoreMembers,
    editLeagueFulfilled,
  } = leagueState;
  const webview = userState?.webview;

  const isProfanityEnabled = mixApiState?.data?.conf?.ipe;
  const isWebPurifyCalled = selectedLeague?.isWebPurifyCalled;
  const isSystemNameUpd = selectedLeague?.isSystemNameUpd;
  const webPurifyResponse = selectedLeague?.webPurifyresponse;
  const [editLeagueConfirmState, setEditLeagueConfirmState] = useState(false);

  const [isNameChanged, setIsNameChanged] = useState(false);
  const [isMaxTeamCountChanged, setIsMaxTeamCountChanged] = useState(false);

  const webPurifyStatus = getWebPurifyStatus(
    isWebPurifyCalled,
    isSystemNameUpd,
    webPurifyResponse
  );

  useEffect(() => {
    if (selectedLeague?.maxMembers === 99999999) {
      setIsUnlimitedMembers(true);
    }
    if (selectedLeague?.leagueName) {
      setName(selectedLeague?.leagueName);
    }
  }, [selectedLeague]);

  useEffect(() => {
    if (leagueAdmin && !!Object.keys(leagueAdmin?.[0]).length) {
      setCurrentLeagueAdmin(leagueAdmin);
    }
  }, [leagueAdmin]);

  useEffect(() => {
    const handleSearch = () => {
      dispatch(league.resetLeagueMembers());
      if (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin) {
        getLeagueMembersForManager(searchTerm);
      } else {
        getLeagueMembers(searchTerm);
      }
    };

    if (isSearched) {
      handleSearch();
    }
  }, [isSearched]);

  useEffect(() => {
    if (isSearchResetted) {
      if (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin) {
        getLeagueMembersForManager();
      } else {
        getLeagueMembers();
      }
    }
  }, [isSearchResetted]);

  useEffect(() => {
    if (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin) {
      setIsAnAdmin(true);
    }
  }, [selectedLeague]);

  useEffect(() => {
    if (isMemberRemoved) {
      dispatch(league.resetLeagueMembers());
      if (
        selectedLeague &&
        (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin)
      ) {
        getLeagueMembersForManager();
      } else if (
        selectedLeague &&
        !(selectedLeague?.leagueAdmin || selectedLeague?.isAdmin)
      ) {
        getLeagueMembers();
      }
      setIsMemberRemoved(false);
      setSearchTerm("");
    }
  }, [isMemberRemoved]);

  const isAdmin = () => !!leagueUserInfo?.isAdmin;
  const maxTeam = leagueUserInfo?.maxTeam;
  const PvtPubCardConfig = {
    showPvtPubCardChips: true,
    showThumb: false,
    conditionClsName: getLeagueTypeId(selectedLeague?.leagueType),
    leagueName: selectedLeague?.leagueName,
    leagueCount: selectedLeague?.memberCount,
    maxLeagueCount:
      selectedLeague?.maxMembers === 99999999
        ? getTranslations("league_card_total_team_unlimited", "Unlimited")
        : selectedLeague?.maxMembers,
    showLeagueCount: true,
    type: selectedLeague?.legaueVipFlag
      ? 3
      : getLeagueTypeId(selectedLeague?.leagueType),
    showRound: true,
    roundNo: leagueUserInfo?.ptgamedayid,
    fixDates: getFixtureDateForLeagues(leagueUserInfo?.meetingdate),
    showDate: true,
    fixRoundLbl: true,
    editName: false,
    handleEditClick: () => toggleLeagueNamePopup(),
    showMaxTeams: true,
    maxTeams: maxTeam,
    userJoinedTeams: selectedLeague?.teamNo?.length,
    showProfanityIcon: isAnAdmin && isProfanityEnabled && webPurifyStatus,
    webPurifyStatus,
    errorIcon:
      !!selectedLeague?.leagueReportFlg || !!selectedLeague?.isReportFlag,
    adminIcon: selectedLeague?.isSponsor
      ? false
      : selectedLeague?.leagueAdmin || selectedLeague?.isAdmin,
    leagueBadgeId: selectedLeague?.disableonList ? 4 : selectedLeague?.tag?.id,
    leagueType: selectedLeague?.leagueType,
  };

  // List League Members

  const getLeagueMembers = (value) => {
    const isH2HLeague = selectedLeague?.leagueType === "Private H-T-H";

    const payload = {
      guid: userState?.data?.GUID,
      optType: 1,
      leagueid: leagueId,
      isHTHLeague: isH2HLeague ? 1 : 0,

      gamedayId: 1,
      pageNo: currentPage,
      topNo: leagueMembersConstants.ITEMS_PER_PAGE,
    };
    if (value) {
      payload.teamName = value;
    }

    if (type === "public") {
      dispatch(league.getPublicLeagueMembers(payload));
    } else {
      dispatch(league.getLeagueMembers(payload));
    }
    // dispatch(league.getLeagueMembers(payload));
  };

  const getLeagueMembersForManager = (value) => {
    const payload = {
      guid: userState?.data?.GUID,
      optType: 1,
      leagueid: leagueId,
      pageNo: currentPage,
      topNo: leagueMembersConstants.ITEMS_PER_PAGE,
    };

    if (value) {
      payload.userName = value;
    }

    if (getLeagueTypeId(selectedLeague?.leagueType) === 0) {
      dispatch(league.getLeagueMembersForManager(payload));
    }
    if (getLeagueTypeId(selectedLeague?.leagueType) === 1) {
      dispatch(league.getH2HLeagueMembersForManager(payload));
    }
    if (getLeagueTypeId(selectedLeague?.leagueType) === 2) {
      dispatch(league.getPublicLeagueMembersForManager(payload));
    }
  };

  const handleLoadMore = () => {
    if (isSearched) {
      if (
        selectedLeague &&
        (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin)
      ) {
        getLeagueMembersForManager(searchTerm);
      } else if (
        selectedLeague &&
        !(selectedLeague?.leagueAdmin || selectedLeague?.isAdmin)
      ) {
        getLeagueMembers(searchTerm);
      }
      return;
    }

    if (
      selectedLeague &&
      (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin)
    ) {
      getLeagueMembersForManager();
    } else if (
      selectedLeague &&
      !(selectedLeague?.leagueAdmin || selectedLeague?.isAdmin)
    ) {
      getLeagueMembers();
    }
  };

  useEffect(() => {
    if (leagueMembersSuccess) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [leagueMembersSuccess]);

  useEffect(() => {
    if (!selectedLeague) {
      navigate(getPath(translationState, "leagues"));
    }

    handleLoadMore();
    return () => {
      dispatch(league.resetLeagueMembers());
      dispatch(league.resetDeleteLeague());
      dispatch(league.resetReportLeague());
      dispatch(league.resetLeaveLeague());
      dispatch(league.resetRemoveMember());
    };
  }, []);

  // useEffect(() => {
  //   if (selectedLeague && (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin)) {
  //     getLeagueMembersForManager();
  //   } else if (selectedLeague && !(selectedLeague?.leagueAdmin || selectedLeague?.isAdmin)) {
  //     getLeagueMembers();
  //   }
  // }, [selectedLeague]);

  // Edit League Name

  const handleInput = (name) => {
    setName(name);
    dispatch(league.setLeagueName(name));
  };

  const toggleLeagueNamePopup = () => {
    setShowLeagueNamePopup((prev) => !prev);
  };

  const handleLeagueNameCancel = () => {
    toggleLeagueNamePopup();
  };

  const pushCancelLeagueDataLayer = () => {
    const dataLayerObject = {
      pageName: "Leagues",
      actionType: "cancel",
      leagueType: getLeagueType(),
      clickText: getTranslations(
        "manage_league_report_league_popup_cancel_button",
        "Cancel"
      ),
      navigationElement: "leagueSettings",
      path: pathname,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };
    pushToDataLayer("cancelLeague", dataLayerObject);
  };

  const handleLeagueNameConfirm = () => {
    const payload = {
      userLeagueId: selectedLeague?.leagueId,
      leagueName: encodeURIComponent(name?.trim()),
      platformId: PLATFORM_ID,
      platformVersion: PLATFORM_VERSION,
      platformCategory: PLATFORM_CATEGORY,
      teamNumber: 0,
    };

    if (getLeagueTypeId(selectedLeague?.leagueType) === 0) {
      dispatch(league.updatePrivateLeagueName(payload));
    }
    if (getLeagueTypeId(selectedLeague?.leagueType) === 1) {
      dispatch(league.updateH2HLeagueName(payload));
    }
    if (getLeagueTypeId(selectedLeague?.leagueType) === 2) {
      dispatch(league.updatePublicLeagueName(payload));
    }
    toggleLeagueNamePopup();
  };

  useEffect(() => {
    if (updateLeagueSuccess) {
      dispatch(league.setLeagueName(""));
    }
  }, [updateLeagueSuccess]);

  // Remove Member

  const toggleRemoveMember = () => {
    dispatch(league.toggleRemoveMember());
  };

  const handleRemoveMemberConfirm = () => {
    const payload = {
      optType: 1,
      platformId: PLATFORM_ID,
      platformVersion: PLATFORM_VERSION,
      platformCategory: PLATFORM_CATEGORY,
      leagueId: leagueId,
      memberTeamNumber: selectedMember?.userTeamNo,
      memberUserTeamId: selectedMember?.teamid,
      memberUserId: selectedMember?.teamid,
      memberUserSocialId: selectedMember?.socialId,
    };
    dispatch(league.removeMember(payload));
    setCurrentPage(1);
    setIsMemberRemoved(true);
  };

  useEffect(() => {
    if (removeMemberSuccess) {
      toggleRemoveMember();
    }
  }, [removeMemberSuccess]);

  // Report League

  const [selectedReport, setSelectedReport] = useState({});
  const handleSelectedReportChange = (value) => {
    setSelectedReport(value);
  };

  const getLeagueType = () => {
    if (selectedLeague?.legaueVipFlag) {
      return "VIP";
    }
    const leagueTypeId = getLeagueTypeId(selectedLeague?.leagueType);
    if (leagueTypeId === 0) {
      return getLeagueTypeForDL("private");
    }
    if (leagueTypeId === 1) {
      return getLeagueTypeForDL("h2h");
    }
    if (leagueTypeId === 2) {
      return getLeagueTypeForDL("public");
    }
  };

  const pushReportLeagueDataLayer = () => {
    const dataLayerObject = {
      pageName: "Leagues",
      actionType: "Report League",
      clickText: getTranslations(
        "manage_league_report_league_popup_title",
        "Report League"
      ),
      leagueType: getLeagueType(),
      path: pathname,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("manageLeague", dataLayerObject);
  };

  const toggleReportLeaguePopup = () => {
    setShowReportLeaguePopup((prev) => !prev);
    if (!showReportLeaguePopup) {
      pushReportLeagueDataLayer();
    }
  };

  const pushReportLeaguePopupDataLayer = (type = "CANCEL") => {
    const isCancel = type === "CANCEL";

    const dataLayerObject = {
      pageName: "Leagues",
      actionType: isCancel ? `reportLeague | Cancel` : selectedReport.text,
      clickText: isCancel
        ? getTranslations(
            "manage_league_report_league_popup_cancel_button",
            "Cancel"
          )
        : getTranslations(
            "manage_league_report_league_popup_confirm_button",
            "Submit"
          ),
      path: pathname,
      navigationElement: "reportLeagueOverlay",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    if (!isCancel) {
      dataLayerObject.leagueType = getLeagueType();
    }

    pushToDataLayer(
      isCancel ? "manageLeague" : "reportLeague",
      dataLayerObject
    );
  };

  const handleReportConfirm = () => {
    const payload = {
      userLeagueId: leagueId,
      teamNumber: 0,
      teamName: "",
      reportId: selectedReport.id,
      reportText: selectedReport.text,
      leagueName: encodeURIComponent(selectedLeague?.leagueName),
      platformId: PLATFORM_ID,
      platformVersion: PLATFORM_VERSION,
      platformCategory: PLATFORM_CATEGORY,
    };
    if (getLeagueTypeId(selectedLeague?.leagueType) === 0) {
      dispatch(league.reportPrivateLeague(payload));
    }
    if (getLeagueTypeId(selectedLeague?.leagueType) === 1) {
      dispatch(league.reportH2HLeague(payload));
    }
    if (getLeagueTypeId(selectedLeague?.leagueType) === 2) {
      dispatch(league.reportPublicLeague(payload));
    }
  };

  const handleReportToast = () => {
    setShowReportLeagueSuccessToast(true);
    setTimeout(() => {
      setShowReportLeagueSuccessToast(false);
    }, 2000);
  };

  const pushLeaveLeagueDataLayer = () => {
    const dataLayerObject = {
      pageName: "Leagues",
      actionType: "Leave League",
      clickText: getTranslations(
        "manage_league_leave_league_button",
        "Leave League"
      ),
      leagueType: getLeagueType(),
      path: pathname,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("manageLeague", dataLayerObject);
  };

  const pushLeaveLeaguePopupDataLayer = (type = "CANCEL") => {
    const isCancel = type === "CANCEL";

    const dataLayerObject = {
      pageName: "Leagues",
      actionType: `${isCancel ? "Cancel" : "Confirm Leaving League"}`,
      clickText: isCancel
        ? getTranslations(
            "manage_league_leave_league_popup_cancel_button",
            "Cancel"
          )
        : getTranslations(
            "manage_league_leave_league_popup_confirm_button",
            "Confirm"
          ),
      path: isCancel ? pathname : "/leagues",
      navigationElement: "leaveLeagueOverlay",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("manageLeague", dataLayerObject);
  };

  const pushDeleteLeagueDataLayer = () => {
    const dataLayerObject = {
      pageName: "Leagues",
      actionType: "Delete League",
      clickText: getTranslations(
        "manage_league_delete_league_button",
        "Delete League"
      ),
      path: pathname,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("manageLeague", dataLayerObject);
  };

  const pushDeleteLeaguePopupDataLayer = (type = "CANCEL") => {
    const isCancel = type === "CANCEL";

    const dataLayerObject = {
      pageName: "Leagues",
      actionType: `deleteLeague | ${isCancel ? "Cancel" : "Confirm"}`,
      clickText: isCancel
        ? getTranslations(
            "manage_league_delete_league_popup_cancel_button",
            "Cancel"
          )
        : getTranslations(
            "manage_league_delete_league_popup_confirm_button",
            "Delete"
          ),
      path: isCancel ? pathname : "/leagues",
      navigationElement: "deleteLeagueOverlay",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("manageLeague", dataLayerObject);
  };

  useEffect(() => {
    if (reportLeagueSuccess) {
      toggleReportLeaguePopup();
      handleReportToast();
      pushReportLeaguePopupDataLayer("SUBMIT");
    }
  }, [reportLeagueSuccess]);

  // Delete League

  useEffect(() => {
    if (deleteLeagueSuccess) {
      navigate(getPath(translationState, "leagues"));
      pushDeleteLeaguePopupDataLayer("CONFIRM");
    }
  }, [deleteLeagueSuccess]);

  const getErrorHeader = () => {
    if (
      updateLeagueErrorData?.RetVal === cf_user_league_create.PROFANITY_CHECK
    ) {
      return getTranslations(
        cf_user_league_create_error_headers[
          cf_user_league_create.PROFANITY_CHECK
        ],
        cf_user_league_create_error_headers[
          cf_user_league_create.PROFANITY_CHECK
        ]
      );
    }

    return "An error occurred";
  };

  const getErrorCaption = () => {
    if (
      updateLeagueErrorData?.RetVal === cf_user_league_create.PROFANITY_CHECK
    ) {
      return getTranslations(
        cf_user_league_create_error_messages[
          cf_user_league_create.PROFANITY_CHECK
        ],
        cf_user_league_create_error_messages[
          cf_user_league_create.PROFANITY_CHECK
        ]
      );
    } else if (
      updateLeagueErrorData?.RetVal ===
      cf_user_league_create.LEAGUE_NAME_MORE_THAN_50_CHARACTERS
    ) {
      return getTranslations(
        cf_user_league_create_error_messages[
          cf_user_league_create.LEAGUE_NAME_MORE_THAN_50_CHARACTERS
        ],
        cf_user_league_create_error_messages[
          cf_user_league_create.LEAGUE_NAME_MORE_THAN_50_CHARACTERS
        ]
      );
    }
    return "";
  };

  const resetError = () => dispatch(league.resetLeagueNameErrors());

  const updateSearchTerm = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value === "") {
      setCurrentPage(1);
      setIsSearched(false);
      setIsSearchResetted(true);
      dispatch(league.resetLeagueMembers());
    }
  };

  const handleSearch = () => {
    dispatch(league.resetLeagueMembers());
    if (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin) {
      getLeagueMembersForManager(searchTerm);
    } else {
      getLeagueMembers(searchTerm);
    }
  };

  const handleSearchSubmit = () => {
    setCurrentPage(1);
    setIsSearched(true);
    setIsSearchResetted(false);
  };

  const isLeagueNameValid = () => {
    return (
      name &&
      name.trim().length >= MIN_LEAGUE_NAME_CHARACTERS &&
      name.trim().length <= MAX_LEAGUE_NAME_CHARACTERS
    );
  };

  const pushEditLeagueConfirmDataLayer = (
    isNameChanged = false,
    isMaxTeamCountChanged = false
  ) => {
    const dataLayerObject = {
      pageName: "Leagues",
      actionType:
        isMaxTeamCountChanged && isNameChanged
          ? "League name Updated | Team Count Updated"
          : isNameChanged
          ? "League name Updated"
          : isMaxTeamCountChanged
          ? "Team Count Updated"
          : null,
      leagueType: getLeagueType(),
      clickText: getTranslations("edit_league_popup_confirm_button", "Confirm"),
      navigationElement: "leagueSettings",
      path: pathname,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("editLeague", dataLayerObject);
  };

  useEffect(() => {
    if (editLeagueFulfilled) {
      pushEditLeagueConfirmDataLayer(isNameChanged, isMaxTeamCountChanged);
    }
    if (updateLeagueSuccess) {
      pushEditLeagueConfirmDataLayer(true, false);
    }
  }, [
    editLeagueFulfilled,
    isMaxTeamCountChanged,
    isNameChanged,
    updateLeagueSuccess,
  ]);

  const handleEditLeagueConfirm = () => {
    setIsEditSubmit(true);
  };
  const updateEditLeagueOverlay = (value) => {
    setShowEditLeaguePopup(value);
  };
  const submitEditLeagueData = (values) => {
    if (
      getLeagueTypeId(selectedLeague?.leagueType) === 1 ||
      getLeagueTypeId(selectedLeague?.leagueType) === 2
    ) {
      const payload = {
        userLeagueId: selectedLeague?.leagueId,
        leagueName: encodeURIComponent(values?.leagueName?.trim()),
        platformId: PLATFORM_ID,
        platformVersion: PLATFORM_VERSION,
        platformCategory: PLATFORM_CATEGORY,
        teamNumber: 0,
      };

      if (getLeagueTypeId(selectedLeague?.leagueType) === 1) {
        dispatch(league.updateH2HLeagueName(payload));
      }
      if (getLeagueTypeId(selectedLeague?.leagueType) === 2) {
        dispatch(league.updatePublicLeagueName(payload));
      }
    } else {
      const payload = {
        userLeagueId: leagueId,
        leagueName: encodeURIComponent(values?.leagueName?.trim()),
        platformId: PLATFORM_ID,
        platformVersion: PLATFORM_VERSION,
        platformCategory: PLATFORM_CATEGORY,
        teamNumber: 0,
      };
      if (selectedLeague?.maxMembers !== values?.maxParticipants) {
        payload.maxMember = values?.maxParticipants;
      }

      setIsMaxTeamCountChanged(
        selectedLeague?.maxMembers !== values?.maxParticipants
      );
      setIsNameChanged(
        encodeURIComponent(values?.leagueName?.trim()) !==
          encodeURIComponent(selectedLeague?.leagueName?.trim())
      );

      dispatch(league.editLeague({ url: "updateuserleague", body: payload }));
    }

    setName(values?.leagueName);
    updateEditLeagueOverlay(false);
    setIsEditSubmit(false);
  };

  const canEditLeague = (values) => {
    if (
      values?.maxParticipants !== selectedLeague?.maxMembers ||
      selectedLeague?.leagueName !== values?.leagueName
    ) {
      if (values?.maxParticipants >= selectedLeague?.maxMembers + 10) {
        setEditLeagueConfirmState(false);
      } else if (
        selectedLeague?.leagueName?.trim() !== values?.leagueName?.trim() &&
        values?.leagueName?.trim().length >= MIN_LEAGUE_NAME_CHARACTERS &&
        values?.leagueName?.trim().length <= MAX_LEAGUE_NAME_CHARACTERS
      ) {
        setEditLeagueConfirmState(false);
      } else {
        setEditLeagueConfirmState(true);
      }
    } else {
      setEditLeagueConfirmState(true);
    }
  };

  return (
    <>
      <div className="si-league__manage-wrap">
        <div className="si-main__container">
          {leagueMembersLoading && !leagueMembers?.length && <SectionLoader />}
          {leagueMembersLoading && leagueMembers?.length && (
            <PageLoader isNoBG={true} />
          )}
          <div
            className={`si-main__row ${isMobile() ? "si-resetStyling" : ""}`}
          >
            <LeagueHeader
              hasBackButton={true}
              sectionTitle={getTranslations(
                "manage_league_header_text",
                "Leagues"
              )}
              component={
                <>
                  {/* <LeagueSelectBox /> */}
                  {isAdmin() &&
                  manageAllowedOptions.admin[
                    getLeagueTypeId(selectedLeague?.leagueType)
                  ]?.includes("delete") ? (
                    <LeagueDelete
                      leagueId={leagueId}
                      leagueType={getLeagueTypeId(selectedLeague?.leagueType)}
                      memberCount={leagueUserInfo?.leaguesUuUser}
                      pushDeleteLeagueDataLayer={pushDeleteLeagueDataLayer}
                      pushDeleteLeaguePopupDataLayer={
                        pushDeleteLeaguePopupDataLayer
                      }
                    />
                  ) : (
                    ""
                  )}
                  {!isAdmin() ? (
                    <div className="si-league__ctas">
                      {manageAllowedOptions.user[
                        getLeagueTypeId(selectedLeague?.leagueType)
                      ]?.includes("report") ? (
                        <CtaButton
                          btnCls="si-btn si-btn__secondary si-btn__secondary--outline"
                          btnText={getTranslations(
                            "manage_league_report_league_popup_title",
                            "Report League"
                          )}
                          ariaLabel="report league"
                          onClickProp={() => {
                            toggleReportLeaguePopup();
                          }}
                          btnState={
                            (leagueMembers &&
                              leagueMembers[0]?.leagueReportFlg) ||
                            reportLeagueSuccess ||
                            disableCTAs
                          }
                        />
                      ) : (
                        ""
                      )}
                      {manageAllowedOptions.user[
                        getLeagueTypeId(selectedLeague?.leagueType)
                      ]?.includes("leave") ? (
                        <LeaveLeague
                          leagueId={leagueId}
                          userTeams={
                            leagueMembers && leagueMembers[0]?.userTeamNo
                          }
                          pushLeaveLeagueDataLayer={pushLeaveLeagueDataLayer}
                          pushLeaveLeaguePopupDataLayer={
                            pushLeaveLeaguePopupDataLayer
                          }
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              }
              componentStickyFooterForMobile={
                selectedLeague?.leagueType === "Classic Public" ? false : true
              }
            />
          </div>
          <div className="si-main__row">
            <LeagueHeader
              titleComponent={
                <PvtPubCardInfo PvtPubCardConfig={PvtPubCardConfig} />
              }
              component={
                <>
                  {selectedLeague?.leagueType !== "Private H-T-H" &&
                    selectedLeague?.leagueType !== "Public" && (
                      <InputSearch
                        ariaLabel=" "
                        label={getTranslations(
                          "manage_league_search_member_placeholder",
                          "Search Member"
                        )}
                        handleInput={updateSearchTerm}
                        searchInput={searchTerm}
                        handleSearch={handleSearchSubmit}
                      />
                    )}
                  {isAnAdmin && (
                    <CtaButton
                      btnCls={`si-btn si-btn__secondary si-btn__secondary--outline ${
                        !isMobile() ? "" : "si-btn__icon"
                      }`}
                      btnText={
                        !isMobile()
                          ? getTranslations(
                              "edit_league_cta_text",
                              "Edit League"
                            )
                          : ""
                      }
                      iconCls={"f1i-edit"}
                      onClickProp={() => {
                        updateEditLeagueOverlay(true);
                      }}
                    />
                  )}
                </>
              }
            />
          </div>
          <div className="si-main__row">
            {leagueMembersError && (
              <NoDataFound
                noDataCaption={getTranslations(
                  "manage_league_no_team_found",
                  "No Team Found"
                )}
              />
            )}

            {currentLeagueAdmin &&
            currentLeagueAdmin?.[0]?.teamName?.length > 0 ? (
              <List
                leagueManageConfig={{
                  leagueManageCTA:
                    isAnAdmin &&
                    manageAllowedOptions.admin[
                      getLeagueTypeId(selectedLeague?.leagueType)
                    ]?.includes("remove"),
                  data: currentLeagueAdmin,
                  adminSocialId: leagueUserInfo?.socialid,
                  isAdminData: true,
                  maxTeams: maxTeam,
                }}
              />
            ) : null}

            {leagueMembers?.length > 0 ? (
              <List
                leagueManageConfig={{
                  leagueManageCTA:
                    isAnAdmin &&
                    manageAllowedOptions.admin[
                      getLeagueTypeId(selectedLeague?.leagueType)
                    ]?.includes("remove"),
                  data: leagueMembers,
                  adminSocialId: leagueUserInfo?.socialid,
                  isAdminData: false,
                  isAdmin: isAnAdmin,
                  maxTeams: maxTeam,
                }}
              />
            ) : null}
          </div>
        </div>
      </div>

      {hasMoreMembers && (
        <div className="si-main__row">
          <div className="si-league__member-cta">
            <LoadMoreCta handleLoadMore={() => handleLoadMore()} />
          </div>
        </div>
      )}
      {showRemoveMember && (
        <Overlays
          overlayTitle={getTranslations(
            "manage_league_remove_member_popup_title",
            "Remove!"
          )}
          overlaySubTitle={getTranslations(
            "manage_league_remove_member_popup_message",
            "Are you sure"
          )}
          btnPrimary={getTranslations(
            "manage_league_remove_member_popup_confim_button",
            "Remove"
          )}
          btnSecondary={getTranslations(
            "manage_league_remove_member_popup_cancel_button",
            "Cancel"
          )}
          overlayConfig={{ cancelBtn: true, showOverlaySub_Title: true }}
          onCancelClick={toggleRemoveMember}
          onConfirmClick={handleRemoveMemberConfirm}
        />
      )}

      {showLeagueNamePopup && (
        <Overlays
          overlayName="si-popup__wrap--teamName"
          overlayTitle={getTranslations(
            "manage_league_edit_name_header",
            "Name your League"
          )}
          overlaySubTitle={getTranslations(
            "manage_league_edit_name_sub_header",
            "What should we call your league?"
          )}
          btnPrimary={getTranslations(
            "manage_league_edit_name_confirm_button",
            "Save"
          )}
          overlayConfig={{ cancelBtn: true, showOverlaySub_Title: true }}
          component={
            <LeagueNameOverlay
              leagueName={selectedLeague?.leagueName}
              callback={handleInput}
              isLeagueNameValid={!!isLeagueNameValid()}
            />
          }
          confirmButtonState={!isLeagueNameValid()}
          onCancelClick={() => handleLeagueNameCancel()}
          onConfirmClick={() => handleLeagueNameConfirm()}
        />
      )}

      {showReportLeaguePopup && (
        <Overlays
          overlayName="si-popup__wrap--reportLeague"
          overlayTitle={getTranslations(
            "manage_league_report_league_popup_title",
            "Report League"
          )}
          overlaySubTitle={getTranslations(
            "manage_league_report_league_popup_message",
            "Please select a problem to highlight your concerns"
          )}
          btnPrimary={getTranslations(
            "manage_league_report_league_popup_confirm_button",
            "Submit"
          )}
          btnSecondary={getTranslations(
            "manage_league_report_league_popup_cancel_button",
            "Cancel"
          )}
          overlayConfig={{ cancelBtn: true, showOverlaySub_Title: true }}
          component={
            <ReportLeague handleOnChange={handleSelectedReportChange} />
          }
          confirmButtonState={!selectedReport?.text}
          onCancelClick={() => {
            toggleReportLeaguePopup();
            handleSelectedReportChange({});
            pushReportLeaguePopupDataLayer("CANCEL");
          }}
          onConfirmClick={() => {
            handleReportConfirm();
          }}
        />
      )}
      {showReportLeagueSuccessToast && (
        <ToastNotification
          type="generic"
          toastType="success"
          alertText={getTranslations(
            "manage_league_report_league_toast_message",
            "League is reported"
          )}
        />
      )}
      {updateLeagueError && (
        <ToastNotification
          handleClose={() => resetError()}
          type="alert"
          alertText={getErrorHeader()}
          alertCaption={getErrorCaption()}
        />
      )}
      {showEditLeaguePopup && (
        <Overlays
          overlayName="si-popup__wrap--leagues"
          overlayTitle={getTranslations(
            "edit_league_popup_title",
            "Edit League"
          )}
          overlaySubTitle={getTranslations("edit_leaue_popup_subtitle", "")}
          btnPrimary={getTranslations(
            "edit_league_popup_confirm_button",
            "Confirm"
          )}
          overlayConfig={{ cancelBtn: true, showOverlaySub_Title: false }}
          component={
            <EditALeague
              handleSelect={() => null}
              selectedLeagueType={null}
              leagueType={selectedLeague?.leagueType}
              currentLeagueName={selectedLeague?.leagueName}
              maxMembers={selectedLeague?.maxMembers}
              isUnlimitedMembers={isUnlimitedMembers}
              noOfTeamsPerUser={selectedLeague?.noOfTeams}
              isEditSubmit={isEditSubmit}
              submitEditLeagueData={submitEditLeagueData}
              canEditLeague={canEditLeague}
            />
          }
          onConfirmClick={handleEditLeagueConfirm}
          onCancelClick={() => {
            updateEditLeagueOverlay(false);
            pushCancelLeagueDataLayer();
          }}
          confirmButtonState={editLeagueConfirmState}
        />
      )}
    </>
  );
};

export { LeagueManages };
