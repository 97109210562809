import React from "react";

const ProgressBar = ({ progressConfig }) => {
  const { pgrData, pgrWidth, noProgress } = progressConfig;
  return (
    <>
      <div className="si-progress__wrap">
        <div className="si-progress__top">
          <div className="si-progress__grid">
            {pgrData.map((item, index) => (
              <div key={index} className="si-progress__grid-item">
                <div className="si-progress__lbl">{item.lbl}</div>
                <div className="si-progress__values">{item.value}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="si-progress__bot">
          <div
            className={`si-progress__bar ${noProgress ? "si-noPrg" : ""}`}
            aria-valuenow={pgrWidth}
            aria-valuemin="0"
            aria-valuemax={"100"}
          >
            <span style={{ width: `${pgrWidth}%` }}></span>
          </div>
        </div>
      </div>
    </>
  );
};

export { ProgressBar };
