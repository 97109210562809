import {
  FETCH_COUNTRIES_PENDING,
  FETCH_COUNTRIES_REJECTED,
  FETCH_COUNTRIES_FULFILLED,
} from "../../constants/country";

const country = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COUNTRIES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_COUNTRIES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_COUNTRIES_FULFILLED:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload.data,
      };
    default:
      return state;
  }
};
export default country;
