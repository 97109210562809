import {
  FETCH_USER_PRIVATE_LEAGUES,
  FETCH_USER_PUBLIC_LEAGUES,
  TOGGLE_CREATE_LEAGUE_POPUP,
  TOGGLE_JOIN_LEAGUE_POPUP,
  TOGGLE_SELECT_TEAM,
  TOGGLE_SHARE_LEAGUE_POPUP,
  TOGGLE_SUCCESS_JOIN_LEAGUE_POPUP,
  TOGGLE_JOIN_LEAGUE_CONFIRM_POPUP,
  SET_CREATE_LEAGUE_DATA,
  SET_CREATE_LEAGUE_TEAMS,
  CREATE_PRIVATE_LEAGUE,
  RESET_CREATE_PRIVATE_LEAGUE,
  SET_MAX_TEAMS,
  JOIN_H2H_LEAGUE,
  JOIN_PUBLIC_LEAGUE,
  FETCH_LEAGUE_INFO,
  JOIN_PRIVATE_LEAGUE,
  RESET_JOIN_PRIVATE_LEAGUE,
  RESET_CREATE_LEAGUE_TEAMS,
  GET_LEAGUE_MEMBERS,
  TOGGLE_COPY_POPUP,
  SEARCH_PUBLIC_LEAGUES,
  TOGGLE_REMOVE_MEMBER_POPUP,
  RESET_LEAGUE_MEMBERS,
  FETCH_USER_CLASSIC_PRIVATE_LEAGUES,
  FETCH_USER_H2H_PRIVATE_LEAGUES,
  SEARCH_PRIVATE_LEAGUES,
  SEARCH_H2H_LEAGUES,
  UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME,
  UPDATE_H2H_PRIVATE_LEAGUE_NAME,
  UPDATE_PUBLIC_LEAGUE_NAME,
  DELETE_LEAGUE,
  REPORT_PRIVATE_LEAGUE,
  REPORT_H2H_LEAGUE,
  REPORT_PUBLIC_LEAGUE,
  RESET_SEARCH_LEAGUES,
  RESET_USER_CLASSIC_PRIVATE_LEAGUES,
  RESET_USER_H2H_PRIVATE_LEAGUES,
  FETCH_USER_JOINED_PUBLIC_LEAGUES,
  RESET_USER_JOINED_PUBLIC_LEAGUES,
  SEARCH_USER_JOINED_PUBLIC_LEAGUES,
  GET_LEAGUE_MEMBERS_FOR_MANAGER,
  LEAVE_LEAGUE,
  REMOVE_LEAGUE_MEMBER,
  RESET_DELETE_LEAGUE,
  RESET_REPORT_LEAGUE,
  RESET_LEAVE_LEAGUE,
  RESET_REMOVE_MEMBER,
  SELECTED_LEAGUE,
  FETCH_TOP_PUBLIC_LEAGUES,
  SET_LEAGUE_NAME,
  RESET_LEAGUE_INFO,
  RESET_LEAGUE_NAME_ERRORS,
  FETCH_USER_VIP_PRIVATE_LEAGUES,
  SEARCH_VIP_LEAGUES,
  RESET_USER_VIP_PRIVATE_LEAGUES,
  DELETE_H2H_LEAGUE,
  RESET_CREATE_PRIVATE_LEAGUE_ERRORS,
  FETCH_USER_MINI_LEAGUES,
  JOIN_MINI_LEAGUE,
  GET_MINI_LEAGUES_PRIZES,
  RESET_ALL_MINI_LEAGUES,
  FETCH_ALL_MINI_LEAGUES,
  SEARCH_MINI_LEAGUES,
  RESET_SEARCH_MINI_LEAGUES,
  GET_LEAGUE_TYPES_WITH_COUNT,
  GET_H2H_SINGLE_RACE_RESULT,
  GET_H2H_RACE_WEEK_WISE_RESULT,
  FETCH_LEAGUES_FILTER_AND_SORT,
  APPLY_LEAGUE_FILTER,
  RESET_LEAGUE_FILTER,
  APPLY_LEAGUE_SORT,
  RESET_LEAGUE_SORT,
  FETCH_USER_STARTER_PUBLIC_LEAGUES,
  APPLY_LEAGUE_SEARCH,
  RESET_LEAGUE_SEARCH,
  FETCH_FEATURED_LEAGUES,
  FETCH_PINNED_LEAGUES,
  SEARCH_FILTER_USER_PUBLIC_LEAGUES,
  EDIT_LEAGUE,
  EDIT_LEAGUE_RESET,
  FETCH_PIN_UNPIN_LEAGUE,
  RESET_PIN_UNPIN_LEAGUE,
  EDIT_SELECTED_LEAGUE,
} from "../../constants/league";
import { feeds, league } from "../../apis/services";
import { pinUnpinnedLeague } from "../../apis/services/league";

export const fetchUserPrivateLeagues = (params) => {
  return {
    type: FETCH_USER_PRIVATE_LEAGUES,
    payload: league.getPrivateLeagues(params),
  };
};

export const fetchUserPublicLeagues = (params) => {
  return {
    type: FETCH_USER_PUBLIC_LEAGUES,
    payload: league.getPublicLeagues(params),
  };
};

export const fetchUserStarterPublicLeagues = (params) => {
  return {
    type: FETCH_USER_STARTER_PUBLIC_LEAGUES,
    payload: league.getStarterPublicLeagues(params),
  };
};

export const fetchUserMiniLeagues = (params) => {
  return {
    type: FETCH_USER_MINI_LEAGUES,
    payload: league.getMiniLeagues(params),
  };
};

export const fetchTopPublicLeagues = (params) => {
  return {
    type: FETCH_TOP_PUBLIC_LEAGUES,
    payload: feeds.getTopPublicLeagues(),
  };
};

export const searchFilterUserPublicLeagues = (params) => {
  return {
    type: SEARCH_FILTER_USER_PUBLIC_LEAGUES,
    payload: league.searchFilterUserPublicLeagues(params),
  };
};

export const toggleCreateLeagueOverlay = () => {
  return {
    type: TOGGLE_CREATE_LEAGUE_POPUP,
  };
};

export const toggleJoinLeagueOverlay = () => {
  return {
    type: TOGGLE_JOIN_LEAGUE_POPUP,
  };
};

export const toggleSelectTeam = () => {
  return {
    type: TOGGLE_SELECT_TEAM,
  };
};

export const toggleSuccessJoinLeagueOverlay = () => {
  return {
    type: TOGGLE_SUCCESS_JOIN_LEAGUE_POPUP,
  };
};

export const toggleSharePopUpOverlay = () => {
  return {
    type: TOGGLE_SHARE_LEAGUE_POPUP,
  };
};

export const toggleJoinLeagueConfirmOverlay = () => {
  return {
    type: TOGGLE_JOIN_LEAGUE_CONFIRM_POPUP,
  };
};

export const toggleRemoveMember = (params) => {
  return {
    type: TOGGLE_REMOVE_MEMBER_POPUP,
    payload: params,
  };
};

export const setCreateLeagueData = (params) => {
  return {
    type: SET_CREATE_LEAGUE_DATA,
    payload: params,
  };
};

export const setCreateLeagueTeams = (params) => {
  return {
    type: SET_CREATE_LEAGUE_TEAMS,
    payload: params,
  };
};

export const resetCreateLeagueTeams = (params) => {
  return {
    type: RESET_CREATE_LEAGUE_TEAMS,
  };
};

export const createPrivateLeague = (params) => {
  return {
    type: CREATE_PRIVATE_LEAGUE,
    payload: league.createPrivateLeague(params),
  };
};

export const createH2hLeague = (params) => {
  return {
    type: CREATE_PRIVATE_LEAGUE,
    payload: league.createH2hLeague(params),
  };
};

export const createPublicLeague = (params) => {
  return {
    type: CREATE_PRIVATE_LEAGUE,
    payload: league.createPublicLeague(params),
  };
};

export const resetCreatePrivateLeague = () => {
  return {
    type: RESET_CREATE_PRIVATE_LEAGUE,
  };
};

export const resetCreatePrivateLeagueErrors = () => {
  return {
    type: RESET_CREATE_PRIVATE_LEAGUE_ERRORS,
  };
};

export const setMaxTeams = (params) => {
  return {
    type: SET_MAX_TEAMS,
    payload: params,
  };
};

export const joinPrivateLeague = (params) => {
  return {
    type: JOIN_PRIVATE_LEAGUE,
    payload: league.joinPrivateLeague(params),
  };
};

export const resetJoinPrivateLeague = () => {
  return {
    type: RESET_JOIN_PRIVATE_LEAGUE,
  };
};

export const joinH2hLeague = (params) => {
  return {
    type: JOIN_H2H_LEAGUE,
    payload: league.joinH2hLeague(params),
  };
};

export const joinPublicLeague = (params) => {
  return {
    type: JOIN_PUBLIC_LEAGUE,
    payload: league.joinPublicLeague(params),
  };
};

export const joinMiniLeague = (params) => {
  return {
    type: JOIN_MINI_LEAGUE,
    payload: league.joinMiniLeague(params),
  };
};

export const getLeagueInfo = (params) => {
  return {
    type: FETCH_LEAGUE_INFO,
    payload: league.getLeagueInfo(params),
  };
};

export const resetLeagueInfo = () => {
  return {
    type: RESET_LEAGUE_INFO,
  };
};

export const getLeagueMembers = (params) => {
  return {
    type: GET_LEAGUE_MEMBERS,
    payload: league.getLeagueMembers(params),
  };
};

export const getPublicLeagueMembers = (params) => {
  return {
    type: GET_LEAGUE_MEMBERS,
    payload: league.getUserPublicLeagueMembers(params),
  };
};

export const getLeagueMembersForManager = (params) => {
  return {
    type: GET_LEAGUE_MEMBERS_FOR_MANAGER,
    payload: league.getLeagueMembersForManager(params),
  };
};

export const getH2HLeagueMembersForManager = (params) => {
  return {
    type: GET_LEAGUE_MEMBERS_FOR_MANAGER,
    payload: league.getH2HLeagueMembersForManager(params),
  };
};

export const getPublicLeagueMembersForManager = (params) => {
  return {
    type: GET_LEAGUE_MEMBERS_FOR_MANAGER,
    payload: league.getPublicLeagueMembersForManager(params),
  };
};

export const resetLeagueMembers = () => {
  return {
    type: RESET_LEAGUE_MEMBERS,
  };
};

export const toggleCopyPopUp = () => {
  return {
    type: TOGGLE_COPY_POPUP,
  };
};

export const searchMiniLeagues = (params) => {
  return {
    type: SEARCH_MINI_LEAGUES,
    payload: league.searchMiniLeague(params),
  };
};

export const resetSearchMiniLeagues = () => {
  return {
    type: RESET_SEARCH_MINI_LEAGUES,
  };
};

export const searchPublicLeagues = (params) => {
  return {
    type: SEARCH_PUBLIC_LEAGUES,
    payload: league.searchPublicLeague(params),
  };
};

export const searchPrivateLeagues = (params) => {
  return {
    type: SEARCH_PRIVATE_LEAGUES,
    payload: league.getClassicPrivateLeagues(params),
  };
};

export const searchH2HLeagues = (params) => {
  return {
    type: SEARCH_H2H_LEAGUES,
    payload: league.getH2HPrivateLeagues(params),
  };
};

export const searchVIPLeagues = (params) => {
  return {
    type: SEARCH_VIP_LEAGUES,
    payload: league.getVIPPrivateLeagues(params),
  };
};

export const searchUserJoinedPublicLeagues = (params) => {
  return {
    type: SEARCH_USER_JOINED_PUBLIC_LEAGUES,
    payload: league.getUserJoinedPublicLeagues(params),
  };
};

export const resetSearchLeagues = () => {
  return {
    type: RESET_SEARCH_LEAGUES,
  };
};

export const fetchUserClassicPrivateLeagues = (params) => {
  return {
    type: FETCH_USER_CLASSIC_PRIVATE_LEAGUES,
    payload: league.getClassicPrivateLeagues(params),
  };
};

export const fetchUserVIPPrivateLeagues = (params) => {
  return {
    type: FETCH_USER_VIP_PRIVATE_LEAGUES,
    // payload: league.getVIPPrivateLeagues(params),
    payload: league.getClassicPrivateLeagues(params),
  };
};

export const resetUserClassicPrivateLeagues = () => {
  return {
    type: RESET_USER_CLASSIC_PRIVATE_LEAGUES,
  };
};

export const fetchUserH2HPrivateLeagues = (params) => {
  return {
    type: FETCH_USER_H2H_PRIVATE_LEAGUES,
    payload: league.getH2HPrivateLeagues(params),
  };
};

export const resetUserH2HPrivateLeagues = () => {
  return {
    type: RESET_USER_H2H_PRIVATE_LEAGUES,
  };
};

export const resetUserVIPPrivateLeagues = () => {
  return {
    type: RESET_USER_VIP_PRIVATE_LEAGUES,
  };
};

export const fetchUserJoinedPublicLeagues = (params) => {
  return {
    type: FETCH_USER_JOINED_PUBLIC_LEAGUES,
    payload: league.getUserJoinedPublicLeagues(params),
  };
};

export const resetUserJoinedPublicLeagues = () => {
  return {
    type: RESET_USER_JOINED_PUBLIC_LEAGUES,
  };
};

export const fetchViewAllMiniLeagues = (params) => {
  return {
    type: FETCH_ALL_MINI_LEAGUES,
    payload: league.getAllMiniLeagues(params),
  };
};

export const fetchFeaturedLeagues = (params) => {
  return {
    type: FETCH_FEATURED_LEAGUES,
    payload: league.getAllFeaturedLeagues(params),
  };
};

export const fetchPinnedLeagues = (params) => {
  return {
    type: FETCH_PINNED_LEAGUES,
    payload: league.getAllPinnedLeagues(params),
  };
};

export const resetViewAllMiniLeagues = () => {
  return {
    type: RESET_ALL_MINI_LEAGUES,
  };
};

export const updatePrivateLeagueName = (params) => {
  return {
    type: UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME,
    payload: league.updatePrivateLeagueName(params),
  };
};

export const updateH2HLeagueName = (params) => {
  return {
    type: UPDATE_H2H_PRIVATE_LEAGUE_NAME,
    payload: league.updateH2HLeagueName(params),
  };
};

export const updatePublicLeagueName = (params) => {
  return {
    type: UPDATE_PUBLIC_LEAGUE_NAME,
    payload: league.updatePublicLeagueName(params),
  };
};

export const resetLeagueNameErrors = () => {
  return {
    type: RESET_LEAGUE_NAME_ERRORS,
  };
};

export const deleteLeague = (params) => {
  return {
    type: DELETE_LEAGUE,
    payload: league.deleteLeague(params),
  };
};

export const deleteH2HLeague = (params) => {
  return {
    type: DELETE_H2H_LEAGUE,
    payload: league.deleteH2HLeague(params),
  };
};

export const resetDeleteLeague = (params) => {
  return {
    type: RESET_DELETE_LEAGUE,
  };
};

export const reportPrivateLeague = (params) => {
  return {
    type: REPORT_PRIVATE_LEAGUE,
    payload: league.reportPrivateLeague(params),
  };
};

export const reportH2HLeague = (params) => {
  return {
    type: REPORT_H2H_LEAGUE,
    payload: league.reportH2HLeague(params),
  };
};

export const reportPublicLeague = (params) => {
  return {
    type: REPORT_PUBLIC_LEAGUE,
    payload: league.reportPublicLeague(params),
  };
};

export const resetReportLeague = () => {
  return {
    type: RESET_REPORT_LEAGUE,
  };
};

export const leaveLeague = (params) => {
  return {
    type: LEAVE_LEAGUE,
    payload: league.disjoinLeague(params),
  };
};

export const resetLeaveLeague = () => {
  return {
    type: RESET_LEAVE_LEAGUE,
  };
};

export const removeMember = (params) => {
  return {
    type: REMOVE_LEAGUE_MEMBER,
    payload: league.leagueOwnerAction(params),
  };
};

export const resetRemoveMember = () => {
  return {
    type: RESET_REMOVE_MEMBER,
  };
};

export const setSelectedLeague = (params) => {
  return {
    type: SELECTED_LEAGUE,
    payload: params,
  };
};

export const setLeagueName = (params) => {
  return {
    type: SET_LEAGUE_NAME,
    payload: params,
  };
};

export const getMiniLeaguesPrizes = (params) => {
  return {
    type: GET_MINI_LEAGUES_PRIZES,
    payload: feeds.getMiniLeaguesPrizes(),
  };
};

export const getLeagueTypesWithCount = (params) => {
  return {
    type: GET_LEAGUE_TYPES_WITH_COUNT,
    payload: league.getLeagueTypesWithCount(params),
  };
};

export const getH2HSingleRaceResult = (params) => {
  return {
    type: GET_H2H_SINGLE_RACE_RESULT,
    payload: league.getH2HSingleRaceResult(params),
  };
};

export const getH2HRaceWeekWiseResult = (params) => {
  return {
    type: GET_H2H_RACE_WEEK_WISE_RESULT,
    payload: league.getH2HRaceWeekWiseResult(params),
  };
};

export const getLeaguesFilterAndSort = (params) => {
  return {
    type: FETCH_LEAGUES_FILTER_AND_SORT,
    payload: feeds.getLeaguesFilterAndSort(params),
  };
};

export const applyLeagueFilter = (data) => {
  return {
    type: APPLY_LEAGUE_FILTER,
    payload: { data },
  };
};

export const resetLeagueFilter = () => {
  return {
    type: RESET_LEAGUE_FILTER,
  };
};

export const applyLeagueSort = (data) => {
  return {
    type: APPLY_LEAGUE_SORT,
    payload: { data },
  };
};

export const resetLeagueSort = () => {
  return {
    type: RESET_LEAGUE_SORT,
  };
};

export const applyLeagueSearch = (data) => {
  return {
    type: APPLY_LEAGUE_SEARCH,
    payload: { data },
  };
};

export const resetLeagueSearch = () => {
  return {
    type: RESET_LEAGUE_SEARCH,
  };
};

export const editLeague = (params) => {
  return {
    type: EDIT_LEAGUE,
    payload: league.editLeague(params),
  };
};

export const resetEditLeague = () => {
  return {
    type: EDIT_LEAGUE_RESET,
  };
};

export const getPinUnpinLeagueLeague = (params) => ({
  type: FETCH_PIN_UNPIN_LEAGUE,
  payload: pinUnpinnedLeague(params),
});

export const resetPinUnpinLeague = () => ({
  type: RESET_PIN_UNPIN_LEAGUE,
});

export const editSelectedLeague = (data) => {
  return {
    type: EDIT_SELECTED_LEAGUE,
    payload: { data },
  };
};
