import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";

const Badge = ({
  badgeConfig,
  badgeState,
  badgeThumb,
  badgeTitle,
  badgeError,
  badgeLbl,
  badgeCaption,
  isAnimate,
}) => {
  const { hidelbl, hideCaption, hideProgress, hideError } = badgeConfig;
  return (
    <>
      <div
        className={`si-badge__box ${badgeState ? "si-active" : ""} ${
          isAnimate ? "si-animate" : ""
        }`}
        aria-label={badgeTitle.toLowerCase()}
      >
        <div className="si-badge__thumb">
          <img
            src={`${config.IMG_BASE}badges/${badgeThumb}.svg?v=${IMAGE_VERSION}`}
            alt={badgeTitle.toLowerCase()}
          />
        </div>
        <h4 className="si-badge__title">
          <span>{badgeTitle}</span>
        </h4>
        {hidelbl && <div className="si-badge__lbl">{badgeLbl}</div>}
        {hideCaption && <div className="si-badge__caption">{badgeCaption}</div>}
        {hideError && <div className="si-badge__error">{badgeError}</div>}
        {hideProgress && (
          <div className="si-badge__progress">
            <section className="si-budgetBar" aria-label="">
              <label htmlFor={`budget-progress`}></label>

              <div className="si-budgetBar__progress">
                <progress
                  id={`budget-progress`}
                  value="60"
                  max="60"
                  // aria-valuemin="0"
                  // aria-valuemax="100"
                ></progress>
                <span
                  style={{
                    width: `${10 * 1.4}px`,
                  }}
                ></span>
              </div>
            </section>
          </div>
        )}
      </div>
    </>
  );
};

export { Badge };
