import React from "react";
import { NoDataFound } from "../NoDataFound";
import { getTranslations } from "../../Common/utils";

const PodiumCard = ({ podRank, podName, podPts }) => {
  return (
    <>
      <div className={`si-eot__podium-card si-podRank--${podRank}`}>
        <div className="si-eot__podium-cardName">{podName}</div>
        {podPts && (
          <div className="si-eot__podium-cardPts">
            <span>
              {podPts}{" "}
              {getTranslations("my_team_points_short", "pts")?.toLowerCase()}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export { PodiumCard };
