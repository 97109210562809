import React from "react";
import { TeamStatsSkeleton } from "../../TeamStatsSkeleton";
import { CtaButton } from "../../Buttons";
import { useSelector } from "react-redux";
import { MatchHeader } from "../../MatchHeader";
import { BoosterChips, NoDataFound, ProgressBar, PvtPubCardInfo } from "../..";
import { formatBoosterData } from "../../../Common/utils/booster";
import { PlayerTrends } from "../../PlayerCard/PlayerTrends";
import {
  decodeName,
  getTranslations,
  getYearForImagePath,
} from "../../../Common/utils";

const calculateProgressbarWidth = (transfers) => {
  if (transfers?.total === transfers?.free) {
    return 0;
  }

  return (transfers?.negative / transfers?.total) * 100;
};

const YourSeasonStatsCard = ({ teamStats }) => {
  const driverState = useSelector((state) => state.driver);
  const fixtureState = useSelector((state) => state.fixture);
  const gameplayState = useSelector((state) => state.gameplay);
  const {
    teamNo = null,
    points = null,
    transfers = null,
    raceweekStats = null,
    bestDrivers = null,
    chipsUsed = null,
    teamName = null,
  } = teamStats;
  const { boosters } = gameplayState;
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const bestRaceWeekFixture = fixtureState?.list?.find(
    (item) => item?.GamedayId === raceweekStats?.gamedayId
  );
  const bestDriver = bestDrivers?.find((item) => item?.type === "DRIVER");
  const bestConstructor = bestDrivers?.find(
    (item) => item?.type === "CONSTRUCTOR"
  );
  const bestDriverTeamId = driverState?.list?.find(
    (item) => item?.PlayerId === bestDriver?.playerId
  )?.TeamId;
  const boosterChipsList = boosters?.Value?.map((x) => formatBoosterData(x));
  const progressBarWidth = calculateProgressbarWidth(transfers);

  return (
    <>
      <div className={`si-yourSeasonStats__card si-team${teamNo}`}>
        <div className="si-yourSeasonStats__card-head">
          <div className="si-myTeamHeader__serial">
            <span className="si-myTeamHeader__serial-number">T{teamNo}</span>
            <span className="si-myTeamHeader__serial-text">
              {decodeName(teamName)}
            </span>
          </div>
          <div className="si-yourSeasonStats__pts">
            {points ? points : "-"}{" "}
            {getTranslations("my_team_points_short", "pts")}
          </div>
        </div>
        <div className="si-yourSeasonStats__card-body">
          {bestDrivers ? (
            <>
              <TeamStatsSkeleton
                customCls={"si-bestPerformRace__row"}
                title={getTranslations(
                  "your_season_stats_best_performing_raceweek",
                  "Best Performing Race Week"
                )}
                component={
                  <>
                    <div className="si-yourSeasonStats__fixture">
                      <div className="si-yourSeasonStats__fixture-top">
                        <MatchHeader
                          fixture={bestRaceWeekFixture}
                          tagName="h2"
                          MatchHeaderConfig={{
                            customCls: "si-fixture__sm",
                            MatchHeaderLHS: false,
                            MatchHeaderRHS: false,
                            MatchHeaderExtra: true,
                            FixtureDateNewLine: false,
                            ShowFixtureDate: false,
                            MatchFixtureInfo: false,
                          }}
                        />
                      </div>
                      <div className="si-yourSeasonStats__fixture-bot">
                        <MatchHeader
                          fixture={bestRaceWeekFixture}
                          tagName="h2"
                          MatchHeaderConfig={{
                            customCls: "si-fixture__sm",
                            MatchHeaderLHS: true,
                            MatchHeaderRHS: false,
                            MatchHeaderExtra: false,
                            FixtureDateNewLine: true,
                            ShowFixtureDate: false,
                            MatchFixtureInfo: false,
                          }}
                        />
                        <PlayerTrends
                          trendStatus={
                            raceweekStats?.points < 0 ? "si-down" : "si-up"
                          }
                          costText={`${raceweekStats?.points}`}
                          trendText={raceweekStats?.points}
                          slug={getTranslations("my_team_points_short", "pts")}
                          spanClass={"si-player__trends-pts"}
                          ariaLabel=""
                          showTrend={false}
                        />
                      </div>
                    </div>
                  </>
                }
              />
              <TeamStatsSkeleton
                customCls={"si-bestPerform__row"}
                title={getTranslations(
                  "your_season_stats_best_performing",
                  "Best Performing"
                )}
                titleContd={getTranslations(
                  "your_season_stats_driver_and_constructor",
                  "DR & CR"
                )}
                component={
                  <>
                    <PvtPubCardInfo
                      PvtPubCardConfig={{
                        conditionClsName: "si-driver",
                        showThumb: true,
                        imageSrc: "",
                        imageName: `${webConfigData?.imagePaths?.driversFront?.replace(
                          "{seasonYear}",
                          getYearForImagePath(webConfigData)
                        )}${bestDriver?.playerId}.png`,
                        defaultImageName: `${webConfigData?.imagePaths?.driversFront?.replace(
                          "{seasonYear}",
                          getYearForImagePath(webConfigData)
                        )}0.png`,
                        showRHS: false,
                        showBottomInfo: false,
                        teamID: bestDriverTeamId,
                      }}
                    />
                    <PvtPubCardInfo
                      PvtPubCardConfig={{
                        conditionClsName: null,
                        showThumb: true,
                        imageSrc: "",
                        imageName: `${webConfigData?.imagePaths?.teamLogos?.replace(
                          "{seasonYear}",
                          getYearForImagePath(webConfigData)
                        )}${bestConstructor?.playerId}.png`,
                        defaultImageName: `${webConfigData?.imagePaths?.teamLogos?.replace(
                          "{seasonYear}",
                          getYearForImagePath(webConfigData)
                        )}0.png`,
                        showRHS: false,
                        showBottomInfo: false,
                        teamID: bestConstructor?.playerId,
                      }}
                    />
                  </>
                }
              />
              <ProgressBar
                progressConfig={{
                  pgrData: [
                    {
                      lbl: getTranslations(
                        "your_season_stats_free_transfers",
                        "Free Transfers"
                      ),
                      value: transfers?.free,
                    },
                    {
                      lbl: getTranslations(
                        "your_season_stats_additional_transfers",
                        "Additional"
                      ),
                      value: transfers?.negative,
                    },
                  ],
                  pgrWidth: progressBarWidth,
                  noProgress: transfers?.total === 0,
                }}
              />
              <TeamStatsSkeleton
                customCls={"si-chip__row"}
                title={getTranslations(
                  "your_season_stats_chips_used",
                  "Chips Used"
                )}
                component={
                  <>
                    {boosterChipsList?.map((item, index) => {
                      const currentChipUserData = chipsUsed?.find(
                        (chip) => chip?.chipId === item?.id
                      );
                      const isChipUsed = !!currentChipUserData;

                      return (
                        <BoosterChips
                          key={index}
                          boosterCls={`${item.boosterCls} ${
                            isChipUsed ? "si-picked" : "si-disabled"
                          }`}
                          boosterIcon={item.boosterIcon}
                          boosterText={
                            isChipUsed
                              ? `R ${
                                  fixtureState?.list?.find(
                                    (x) =>
                                      x?.GamedayId ===
                                      currentChipUserData?.chipGamedayId
                                  )?.MeetingNumber
                                }`
                              : null
                          }
                          // boosterUsed={isBoosterUsed(item?.id)}
                        />
                      );
                    })}
                  </>
                }
              />
            </>
          ) : (
            <div className="si-prevRace__noData">
              <NoDataFound
                noDataCaption={getTranslations(
                  "your_season_stats_displayed_next_raceweek",
                  "Your Season Stats will be displayed after next Race week"
                )}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default YourSeasonStatsCard;
