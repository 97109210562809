import React, { useEffect, useState } from "react";
import { PlayerMiniCard } from "../PlayerMiniCard";
import {
  getTranslations,
  isMobile,
  levelThreeSorting,
  secondarySortBy,
  // sortBy,
} from "../../Common/utils";
import {
  formatStatValue,
  getPriceTrendClass,
} from "../../Common/utils/statistics";

const StatsList = ({
  columns = [],
  data = [],
  imageSrc = "players/front/",
  activeTab = 1,
  onClickProp,
}) => {
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [displayedData, setDisplayedData] = useState(data);
  useEffect(() => {
    setSortedColumn(columns?.[columns?.length - 1]?.key);
    setSortOrder("desc");
  }, [columns]);

  function handleSort(param) {
    if (param !== "statvalue") return;
    if (sortedColumn === param) {
      setSortOrder((prev) => (prev === "asec" ? "desc" : "asec"));
    } else {
      setSortedColumn(param);
      setSortOrder("desc");
    }
  }

  function isNumberStat(stat) {
    return stat === "curvalue" || stat === "statvalue";
  }

  useEffect(() => {
    const thirdLevelKey = activeTab === 2 ? "teamname" : "playername";

    if (sortedColumn) {
      let sortedData = levelThreeSorting(
        data,
        sortedColumn,
        isNumberStat(sortedColumn) ? "num" : "str",
        sortOrder,
        "curvalue",
        "num",
        "asec",
        thirdLevelKey,
        "str",
        "desc"
      );
      setDisplayedData(JSON.parse(JSON.stringify(sortedData)));
    }
  }, [data, sortedColumn, sortOrder]);

  return (
    <>
      <div className="si-stats__list">
        <div className="si-stats__list-grid">
          <ul>
            <li>
              {Array.isArray(columns) &&
                columns?.map((item, index) => (
                  <div
                    key={index}
                    className={`si-stats__list-item ${item?.key}`}
                    style={{
                      display: isMobile() && !item.showOnMobile ? "none" : "",
                    }}
                    onClick={() => handleSort(item?.key)}
                  >
                    {getTranslations(item?.title, item?.title)}
                    {sortedColumn === item?.key && (
                      <>
                        <i
                          className={
                            sortOrder === "asec" ? "f1i-ar-up" : "f1i-ar-down"
                          }
                        ></i>
                      </>
                    )}
                  </div>
                ))}
            </li>
            {Array.isArray(data) &&
              displayedData?.map((item) => (
                <li
                  key={item?.playerid}
                  onClick={() => onClickProp(item?.playerid)}
                >
                  {columns
                    ?.filter((x) => (isMobile() ? x?.showOnMobile : true))
                    ?.map((item1, index) => (
                      <div
                        key={`${item?.playerid}-${index}`}
                        className={`si-stats__list-item ${item1.key} ${
                          sortedColumn === item1?.key ? "si-active" : ""
                        }`}
                      >
                        {index === 0 ? (
                          <>
                            <div className="si-stats__srNo">{item.rnk}</div>
                            <PlayerMiniCard
                              teamID={item?.teamid || item?.playerid}
                              imageSrc={imageSrc}
                              imageName={`${item?.playerid}.png`}
                              PlayerMiniCardName={item?.FUllName}
                            />
                          </>
                        ) : (
                          <div
                            className={`si-stats__list-value ${getPriceTrendClass(
                              item1?.type,
                              item,
                              item1?.key
                            )}`}
                          >
                            {formatStatValue(item1?.type, item, item1?.key)}
                          </div>
                        )}
                        {/* {!isMobile() &&
                          item?.[item1?.key] === item?.teamname && (
                            <div className="si-stats__list-value">
                              {item?.[item1?.key]}
                            </div>
                          )}

                        {item?.[item1?.key] === item?.curvalue ? (
                          <div className="si-stats__list-value">
                            {item?.curvalue}
                          </div>
                        ) : (
                          ""
                        )}
                        {item?.[item1?.key] === item?.statvalue ? (
                          <div className="si-stats__list-value">
                            {item?.statvalue}
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    ))}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export { StatsList };
