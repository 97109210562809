import React from "react";
import { CtaButton } from "../Buttons";
import { PlayerMiniCard } from "../PlayerMiniCard";
import {
  formatStatValue,
  getPriceTrendClass,
} from "../../Common/utils/statistics";

const StatisticCard = ({
  StatsCardTitle,
  StatsCardCtaText,
  data = [],
  imageSrc,
  handleClick = () => {
    return null;
  },
  type = "text",
}) => {
  return (
    <>
      <div className="si-stats__card">
        <div className="si-stats__card-top">
          <h2 className="si-stats__card-title">{StatsCardTitle}</h2>
        </div>
        <div className="si-stats__card-mid">
          <div className="si-stats__card-list">
            <ul>
              {data.map((item, index) => (
                <li key={index}>
                  <div className="si-stats__srNo">{item?.rnk}</div>
                  <div className="si-stats__plyrDtls">
                    <PlayerMiniCard
                      teamID={item?.teamid || item?.playerid}
                      imageSrc={imageSrc}
                      imageName={`${item?.playerid}.png`}
                      PlayerMiniCardName={item.FUllName}
                      PlayerMiniCardTeam={item.TeamName}
                    />
                  </div>
                  <div
                    className={`si-stats__pts ${getPriceTrendClass(
                      type,
                      item,
                      "statvalue"
                    )}`}
                  >
                    {formatStatValue(type, item, "statvalue")}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="si-stats__card-bot">
          <CtaButton
            btnCls="si-btn si-btn__primary si-btn__primary--outline"
            btnText={StatsCardCtaText}
            ariaLabel={StatsCardCtaText}
            onClickProp={handleClick}
          />
        </div>
      </div>
    </>
  );
};

export { StatisticCard };
