import {
  FETCH_OVERALL_EOS_STATS_PENDING,
  FETCH_OVERALL_EOS_STATS_REJECTED,
  FETCH_OVERALL_EOS_STATS_FULFILLED,
  FETCH_USER_EOS_STATS_PENDING,
  FETCH_USER_EOS_STATS_REJECTED,
  FETCH_USER_EOS_STATS_FULFILLED,
  FETCH_EOS_CONFIG_PENDING,
  FETCH_EOS_CONFIG_REJECTED,
  FETCH_EOS_CONFIG_FULFILLED,
} from "../../constants/eos";

const initialState = {
  eosConfigLoading: false,
  eosConfigError: false,
  eosConfigFulfulled: false,
  eosConfigData: null,
  overallEosStatsLoading: false,
  overallEosStatsError: false,
  overallEosStatsFulfilled: false,
  overallEosStats: null,
  userEosStatsLoading: false,
  userEosStatsError: false,
  userEosStatsFulfilled: false,
  userEosStats: null,
};

const eos = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EOS_CONFIG_PENDING:
      return {
        ...state,
        eosConfigLoading: true,
        eosConfigError: false,
        eosConfigFulfulled: false,
      };
    case FETCH_EOS_CONFIG_REJECTED:
      return {
        ...state,
        eosConfigLoading: false,
        eosConfigError: true,
        eosConfigFulfulled: false,
      };
    case FETCH_EOS_CONFIG_FULFILLED:
      return {
        ...state,
        eosConfigLoading: false,
        eosConfigError: false,
        eosConfigFulfulled: true,
        eosConfigData: action.payload.data,
      };
    case FETCH_OVERALL_EOS_STATS_PENDING:
      return {
        ...state,
        overallEosStatsLoading: true,
        overallEosStatsError: false,
        overallEosStatsFulfilled: false,
      };
    case FETCH_OVERALL_EOS_STATS_REJECTED:
      return {
        ...state,
        overallEosStatsLoading: false,
        overallEosStatsError: true,
        overallEosStatsFulfilled: false,
      };
    case FETCH_OVERALL_EOS_STATS_FULFILLED:
      return {
        ...state,
        overallEosStatsLoading: false,
        overallEosStatsError: false,
        overallEosStatsFulfilled: true,
        overallEosStats: action.payload.data,
      };
    case FETCH_USER_EOS_STATS_PENDING:
      return {
        ...state,
        userEosStatsLoading: true,
        userEosStatsError: false,
        userEosStatsFulfilled: false,
      };
    case FETCH_USER_EOS_STATS_REJECTED:
      return {
        ...state,
        userEosStatsLoading: false,
        userEosStatsError: true,
        userEosStatsFulfilled: false,
      };
    case FETCH_USER_EOS_STATS_FULFILLED:
      return {
        ...state,
        userEosStatsLoading: false,
        userEosStatsError: false,
        userEosStatsFulfilled: true,
        userEosStats: action.payload.data,
      };
    default:
      return state;
  }
};

export default eos;
