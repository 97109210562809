import {
  FETCH_USER,
  FETCH_USER_FULFILLED,
  FETCH_USER_REJECTED,
  RESET_USER_REDUCER,
  SET_WEBVIEW,
  UPDATE_NEW_TEAM_COUNT,
  UPDATE_TEAM_COUNT,
} from "../../constants/user";
import { session } from "../../apis/services";

export const fetchUser = (params) => {
  return {
    type: FETCH_USER,
    payload: session.login(params),
  };
};

export const fetchUserFulfilled = (params) => {
  return {
    type: FETCH_USER_FULFILLED,
    payload: params,
  };
};

export const fetchUserRejected = (params) => {
  return {
    type: FETCH_USER_REJECTED,
    payload: params,
  };
};

export const setWebview = (params) => {
  return {
    type: SET_WEBVIEW,
    payload: params,
  };
};

export const updateTeamCount = () => {
  return {
    type: UPDATE_TEAM_COUNT,
  };
};

export const updateNewTeamCount = (params) => {
  return {
    type: UPDATE_NEW_TEAM_COUNT,
    payload: params,
  };
};

export const resetUserReducer = () => {
  return {
    type: RESET_USER_REDUCER,
  };
};
