import React, { useEffect, useRef, useState } from "react";

import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  IMAGE_VERSION,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import { CtaButton } from "../Buttons";
import {
  getTranslations,
  isAndroid,
  isIOS,
  isWebview,
} from "../../Common/utils";
import config from "../../Common/config";
import useDataLayer from "../../Common/hooks/useDataLayer";
import useIntersectionObserver from "../../Common/hooks/useIntersectionObserver";

const CHART_MAX_HEIGHT = 400;
const GRADIENT_COLORS = ["#8A75DB", "#F04DA3", "#0092E1"];

const GRADIENT_OFFSET_START = "5%";
const GRADIENT_OFFSET_END = "95%";
const GRADIENT_OPACITY_START = 0.2;
const GRADIENT_OPACITY_END = 0;
const GRADIENT_X1 = 0;
const GRADIENT_X2 = 0;
const GRADIENT_Y1 = 0;
const GRADIENT_Y2 = 1;

const AREA_STROKE_WIDTH = 2;

const MAX_WIDTH = 1272;

const Y_AXIS_WIDTH = 50;
const Y_AXIS_PADDING_TOP = 16;
const Y_AXIS_PADDING_BOTTOM = 24;
const Y_AXIS_TICK_COUNT = 9;

const X_AXIS_ELEMENT_WIDTH = 130;

const MAIN_AREA_CHART_MARGIN = {
  left: 0,
};
const Y_AXIS_AREA_CHART_MARGIN = {
  left: -10,
};

const CHART_SCROLL_CONTAINER =
  "#performance-chart > div > section > div > div > div.si-chart__body > div > div:nth-child(2)";

const Chart = ({
  chartData,
  userTeams,
  isAppWebview = false,
  isWebview = false,
  webConfigData,
}) => {
  const pushToDataLayer = useDataLayer();
  const [isDataPushed, setIsDataPushed] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const chartRef = useRef(null);
  const [activeChart, setActiveChart] = useState({
    all: true,
    0: false,
    1: false,
    2: false,
  });
  const [scrollLeft, setScrollLeft] = useState(0);
  const chartContainerRef = useRef(null);
  const breakPointsConfig = {
    mobile: { width: 360, brushEndIndex: 3 },
    desktop: { width: 1080, brushEndIndex: 9 },
  };

  const teamPerformanceChartClickDataLayer = (value) => {
    const dataLayerObject = {
      actionType: value === "all" ? "All Teams" : `Team ${value + 1}`,
      clickText:
        value === "all"
          ? getTranslations(
              "performance_chart_all_teams_cta",
              "ALL TEAMS",
              "caps"
            )
          : `${getTranslations("performance_chart_team_cta", "Team", "caps")} ${
              value + 1
            }`,
      path: "",
      locationInPage: "Teams Performance",
      componentType: isWebview ? WEBVIEW : isAppWebview ? "Fantasy App" : WEB,
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
    };
    if (!isAppWebview) pushToDataLayer("navigation_click", dataLayerObject);
    if (isAppWebview) {
      if (isAndroid()) {
        window?.mobileApp?.handleWebviewGTM(JSON.stringify(dataLayerObject));
      }

      if (isIOS()) {
        window.webkit.messageHandlers.handleWebviewGTM.postMessage(
          JSON.stringify({
            dataLayerObject,
          })
        );
      }
    }
  };
  const handleTeamClick = (value) => {
    teamPerformanceChartClickDataLayer(value);
    if (value === "all") {
      setActiveChart({
        all: true,
        0: false,
        1: false,
        2: false,
      });
    }
    if (value === 0) {
      setActiveChart({
        all: false,
        0: true,
        1: false,
        2: false,
      });
    }
    if (value === 1) {
      setActiveChart({
        all: false,
        0: false,
        1: true,
        2: false,
      });
    }
    if (value === 2) {
      setActiveChart({
        all: false,
        0: false,
        1: false,
        2: true,
      });
    }
  };

  const disablePrev = () => chartData && userTeams && scrollLeft === 0;

  const disableNext = () => {
    const chartScrollContainerElement = document.querySelector(
      CHART_SCROLL_CONTAINER
    );

    return (
      chartData &&
      userTeams &&
      scrollLeft + chartScrollContainerElement?.clientWidth >=
        chartScrollContainerElement?.scrollWidth
    );
  };

  function handlePrev() {
    const chartScrollContainerElement = document.querySelector(
      CHART_SCROLL_CONTAINER
    );

    if (chartScrollContainerElement.scrollLeft === 0) {
      setScrollLeft(0);
      return;
    }
    setScrollLeft(chartScrollContainerElement.scrollLeft - 100);
    chartScrollContainerElement.scrollLeft -= 100;
  }

  function handleNext() {
    const chartScrollContainerElement = document.querySelector(
      CHART_SCROLL_CONTAINER
    );

    setScrollLeft(chartScrollContainerElement.scrollLeft + 100);
    chartScrollContainerElement.scrollLeft += 100;
  }

  const isChartIntersectingMap = useIntersectionObserver(chartRef, {
    // threshold: isMobile() ? 0.5 : 1,
    threshold: 0.75,
  });

  useEffect(() => {
    const dataLayerObject = {
      actionType: "view",
      locationInPage: "Team Performance",
      pageName: "Home",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: isWebview ? WEBVIEW : isAppWebview ? "Fantasy App" : WEB,
    };

    if (chartRef.current) {
      isChartIntersectingMap.forEach((value, key) => {
        if (value && !isDataPushed) {
          pushToDataLayer("widget_impressions", dataLayerObject);
          setIsDataPushed(true);
        }
      });
    }
  }, [isChartIntersectingMap]);

  const renderDot = ({ payload, cx, cy }, key, index) => {
    const teamNumber = index + 1;
    const gamedaysCount = chartData?.length - 2;
    const currentTeamFirstPoints = chartData?.find(
      (item) => item?.[`points${teamNumber}`]
    );
    const isLastGamedayPoints =
      payload?.gameDayID === chartData?.[gamedaysCount]?.gameDayID;
    if (!payload?.[key] && payload?.[`points${teamNumber}`]) {
      if (
        currentTeamFirstPoints?.gameDayID === payload?.gameDayID ||
        isLastGamedayPoints
      ) {
        return (
          <circle
            cx={cx}
            cy={cy}
            r={5}
            stroke="transparent"
            strokeWidth={3}
            fill={GRADIENT_COLORS[index]}
          />
        );
      }
    }
    if (!payload?.[key]) return false;
    return (
      <g transform={`translate(${cx - 8},${cy - 8})`}>
        <image
          xlinkHref={`${config.IMG_BASE}boosters/${payload?.[key]?.id}.svg?v=${IMAGE_VERSION}`}
          x={0}
          y={0}
          width="24"
          textAnchor="middle"
        />
      </g>
    );
  };

  const renderCustomAxisTick = ({ x, y, payload }) => {
    if (!payload?.value) {
      return null;
    }
    return (
      <g
        className="recharts-x-axis-element si-chart__teamsWrp"
        transform={`translate(${x},${y})`}
      >
        <path
          d="M0 0H95H0ZM95 80.5H12C5.09644 80.5 -0.5 74.9036 -0.5 68H0.5C0.5 74.3513 5.64873 79.5 12 79.5H95V80.5ZM12 80.5C5.09644 80.5 -0.5 74.9036 -0.5 68V0H0.5V68C0.5 74.3513 5.64873 79.5 12 79.5V80.5ZM95 0V80V0Z"
          fill="#E8E8E9"
          mask="url(#path-1-inside-1_0_497)"
          transform={`translate(0,-10)`}
        />

        {/* <path
          d="M0 0H95H0ZM95 71H12C4.8203 71 -1 65.1797 -1 58H1C1 64.0751 5.92487 69 12 69H95V71ZM12 71C4.8203 71 -1 65.1797 -1 58V0H1V58C1 64.0751 5.92487 69 12 69V71ZM95 0V70V0Z"
          fill="#E8E8E9"
          // mask="url(#path-1-inside-1_0_497)"
          transform={`translate(0,-10)`}
        /> */}
        <g transform={`translate(8,0)`}>
          <image
            className="si-chart__flag"
            xlinkHref={`${config.IMG_BASE}${webConfigData?.imagePaths?.flags}${payload?.value}.svg?v=1.0`}
            x={0}
            y={0}
            width="24px"
            textAnchor="middle"
            clipPath="inset(0% round 2px)"
          />
          <text x={0} y={35} className="si-chart__teamName">
            {payload.value}
          </text>
          <text x={0} y={55} className="si-chart__round">
            {getTranslations("performance_chart_round", "Round")}{" "}
            {chartData?.[payload?.index]?.gameDayID}
          </text>
        </g>
      </g>
    );
  };

  const renderAreaChart = (
    <ResponsiveContainer width="100%" height="98%">
      <AreaChart
        width={breakPointsConfig.mobile.width}
        height={CHART_MAX_HEIGHT}
        data={chartData}
        margin={MAIN_AREA_CHART_MARGIN}
      >
        <defs>
          {userTeams.map((_, index) => (
            <linearGradient
              id={`color${index + 1}`}
              x1={GRADIENT_X1}
              y1={GRADIENT_Y1}
              x2={GRADIENT_X2}
              y2={GRADIENT_Y2}
            >
              <stop
                offset={GRADIENT_OFFSET_START}
                stopColor={GRADIENT_COLORS[index]}
                stopOpacity={GRADIENT_OPACITY_START}
              />
              <stop
                offset={GRADIENT_OFFSET_END}
                stopColor={GRADIENT_COLORS[index]}
                stopOpacity={GRADIENT_OPACITY_END}
              />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid horizontal={false} stroke="#E8E8E9" />
        <XAxis
          dataKey="name"
          interval={0}
          tick={renderCustomAxisTick}
          height={80}
          tickLine={false}
          padding={{ left: 24 }}
        />
        {/* <YAxis tickCount={9} /> */}
        <YAxis
          includeHidden
          tickCount={Y_AXIS_TICK_COUNT}
          padding={{ top: Y_AXIS_PADDING_TOP, bottom: Y_AXIS_PADDING_BOTTOM }}
          tickLine={false}
          className="si-chart__yAxis"
          width={0}
        />
        {/* <Tooltip /> */}
        {userTeams?.map((team, index) => {
          if (activeChart?.all || activeChart?.[index]) {
            return (
              <Area
                dot={(params) =>
                  renderDot(params, "hasBooster" + (index + 1), index)
                }
                type="monotone"
                dataKey={"points" + (index + 1)}
                stroke={GRADIENT_COLORS[index]}
                strokeWidth={AREA_STROKE_WIDTH}
                fillOpacity={1}
                fill={`url(#color${index + 1})`}
              />
            );
          } else {
            return null;
          }
        })}
      </AreaChart>
    </ResponsiveContainer>
  );

  const renderStickyYAxis = (
    <AreaChart
      width={Y_AXIS_WIDTH}
      height={CHART_MAX_HEIGHT * 0.98}
      data={chartData}
      margin={Y_AXIS_AREA_CHART_MARGIN}
    >
      <XAxis
        dataKey="name"
        interval={0}
        tick={renderCustomAxisTick}
        height={70}
        stroke={"#E8E8E9"}
      />
      <YAxis
        includeHidden
        tickCount={Y_AXIS_TICK_COUNT}
        padding={{ top: Y_AXIS_PADDING_TOP, bottom: Y_AXIS_PADDING_BOTTOM }}
        tickLine={false}
        className="si-chart__yAxis"
      />
      {userTeams?.map((team, index) => {
        if (activeChart?.all || activeChart?.[index]) {
          return (
            <Area
              dot={false}
              type="monotone"
              dataKey={"points" + (index + 1)}
              stroke={"#E8E8E9"}
              fillOpacity={1}
              fill={`url(#color${index + 1})`}
            />
          );
        } else {
          return null;
        }
      })}
    </AreaChart>
  );

  useEffect(() => {
    const chartContainer = chartContainerRef.current;
    if (!chartContainer || isScroll) return;

    const observer = new ResizeObserver(() => {
      const maxScrollLeft =
        chartContainer.scrollWidth - chartContainer.clientWidth;
      chartContainer.scrollLeft = maxScrollLeft;
      setScrollLeft(maxScrollLeft);
      setIsScroll(true);
    });

    observer.observe(chartContainer);

    return () => {
      observer.disconnect();
    };
  }, [chartData]);
  return (
    <>
      <section className="si-chart__wrap" ref={chartRef}>
        <div className="si-main__container">
          <div className="si-chart__container">
            <div className="si-chart__head">
              <h2 className="si-chart__title">
                <span>
                  {getTranslations(
                    "your_teams_perfromance_lbl",
                    "Your Teams' Performance"
                  )}
                </span>
              </h2>
              <div className="si-chart__ctas">
                <CtaButton
                  btnCls={`si-btn si-btn__team si-btn__team--default ${
                    activeChart?.all ? "si-active" : ""
                  }`}
                  btnText={getTranslations(
                    "performance_chart_all_teams_cta",
                    "ALL TEAMS",
                    "caps"
                  )}
                  onClickProp={() => handleTeamClick("all")}
                />
                {userTeams?.map((userTeam, index) => {
                  return (
                    <CtaButton
                      key={index}
                      btnCls={`si-btn si-btn__team si-btn__team--${index + 1} ${
                        activeChart?.[index] ? "si-active" : ""
                      }`}
                      btnText={`${getTranslations(
                        "performance_chart_team_cta",
                        "Team",
                        "caps"
                      )} ${index + 1}`}
                      onClickProp={() => handleTeamClick(index)}
                    />
                  );
                })}
              </div>
            </div>
            <div className="si-chart__body">
              <div
                style={{
                  backgroundColor: "var(--white)",
                  display: "flex",
                  maxWidth: MAX_WIDTH,
                }}
              >
                {renderStickyYAxis}
                <div
                  ref={chartContainerRef}
                  style={{
                    width: MAX_WIDTH - Y_AXIS_WIDTH,
                    height: "100%",
                    overflowX: "scroll",
                    marginLeft: "auto",
                  }}
                >
                  <div
                    style={{
                      width: chartData?.length * X_AXIS_ELEMENT_WIDTH,
                      height: CHART_MAX_HEIGHT,
                    }}
                  >
                    {renderAreaChart}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isAppWebview ? (
            <div className="si-chart__scroll-ctas">
              <CtaButton
                btnCls={`si-btn si-btn__secondary si-btn__secondary--outline`}
                btnText={getTranslations(
                  "performance_chart_previous",
                  "Previous",
                  "caps"
                )}
                btnState={disablePrev()}
                onClickProp={handlePrev}
              />
              <CtaButton
                btnCls={`si-btn si-btn__secondary si-btn__secondary--outline`}
                btnText={getTranslations(
                  "performance_chart_next",
                  "Next",
                  "caps"
                )}
                btnState={disableNext()}
                onClickProp={handleNext}
              />
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export { Chart };
