import React from "react";
import { getTranslations } from "../../../Common/utils";
import {
  PROFANE_AND_PROVISIONAL,
  UPDATED_BY_SYSTEM,
} from "../../../Common/constants";
import { Alert } from "../Alert";
import {
  leagueNameProfaneAndProvisionalViolationTranslation,
  leagueNameUpdatedBySystemViolationTranslation,
  leagueProfaneAndProvisionalTranslation,
  leagueUpdatedBySystemTranslation,
  profanityCoolNewNameLeagueTranslation,
  profanityCoolNewNameTeamTranslation,
  teamNameProfaneAndProvisionalViolationTranslation,
  teamNameUpdatedBySystemViolationTranslation,
  teamProfaneAndProvisionalTranslation,
  teamUpdatedBySystemTranslation,
} from "./profanity-translations";

const ProfanityOverlay = ({ webPurifyStatus, type }) => {
  const alertCaption =
    type === "team"
      ? webPurifyStatus === PROFANE_AND_PROVISIONAL
        ? teamNameProfaneAndProvisionalViolationTranslation
        : webPurifyStatus === UPDATED_BY_SYSTEM
        ? teamNameUpdatedBySystemViolationTranslation
        : null
      : type === "league"
      ? webPurifyStatus === PROFANE_AND_PROVISIONAL
        ? leagueNameProfaneAndProvisionalViolationTranslation
        : webPurifyStatus === UPDATED_BY_SYSTEM
        ? leagueNameUpdatedBySystemViolationTranslation
        : null
      : null;

  const alertCaption2 =
    type === "team"
      ? webPurifyStatus === PROFANE_AND_PROVISIONAL
        ? teamProfaneAndProvisionalTranslation
        : webPurifyStatus === UPDATED_BY_SYSTEM
        ? teamUpdatedBySystemTranslation
        : null
      : type === "league"
      ? webPurifyStatus === PROFANE_AND_PROVISIONAL
        ? leagueProfaneAndProvisionalTranslation
        : webPurifyStatus === UPDATED_BY_SYSTEM
        ? leagueUpdatedBySystemTranslation
        : null
      : null;

  const alertCaption3 =
    webPurifyStatus === UPDATED_BY_SYSTEM
      ? type === "team"
        ? profanityCoolNewNameTeamTranslation
        : type === "league"
        ? profanityCoolNewNameLeagueTranslation
        : ""
      : null;
  return (
    <>
      <div className="si-profanity__content">
        <p>{alertCaption}</p>
        <p>{alertCaption2}</p>
        {alertCaption3 ? (
          <Alert showAlertIcon={false} content={alertCaption3} />
        ) : null}
      </div>
    </>
  );
};

export { ProfanityOverlay };
