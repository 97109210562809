import React from "react";
import { getFormattedMonth, getTranslations } from "../../../Common/utils";

const MiniLeagueMatchTimer = ({
  locktime,
  label = getTranslations(
    "mini_league_team_lock_deadline",
    "Last date to join"
  ),
  showTimer = true,
  iconClass = "f1i-timer",
}) => {
  return (
    <div className="si-miniLeague__matchTimer">
      <div
        className="si-matchTimer__wrap si-matchTimer__wrap--miniLeague"
        aria-label={label}
      >
        <h2 className="si-matchTimer__title">
          <i className={iconClass}></i>
          <span>{label}</span>
        </h2>
        {showTimer && (
          <div className="si-matchTimer__watch">
            <span>
              {new Date(locktime).getDate()}{" "}
              {getFormattedMonth(new Date(locktime))}
            </span>
            <em>|</em>
            <span>
              {new Date(locktime).getHours() < 10
                ? "0" + new Date(locktime).getHours()
                : new Date(locktime).getHours()}
              :
              {new Date(locktime).getMinutes() < 10
                ? "0" + new Date(locktime).getMinutes()
                : new Date(locktime).getMinutes()}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export { MiniLeagueMatchTimer };
