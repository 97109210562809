import React, { useState, useEffect, useRef } from "react";
import { getTranslations, isMobile } from "../../Common/utils";
import useOnClickOutside from "../../Common/hooks/useOnClickOutside";

const ProvisionalPtsTooltip = ({ isTooltipTrue }, onClick) => {
  const [isActive, setIsActive] = useState(false);
  const toolTipButtonRef = useRef();

  const closeToolTip = () => setIsActive(false);
  const openToolTip = () => setIsActive(true);
  const toggleToolTip = () => {
    setIsActive((value) => !value);
  };

  useOnClickOutside(toolTipButtonRef, closeToolTip);

  useEffect(() => {
    if (isMobile()) {
      return;
    }

    const toolTipButtonElement = toolTipButtonRef.current;

    toolTipButtonElement.addEventListener("mouseenter", openToolTip);
    toolTipButtonElement.addEventListener("mouseleave", closeToolTip);

    return () => {
      toolTipButtonElement.removeEventListener("mouseenter", openToolTip);
      toolTipButtonElement.removeEventListener("mouseleave", closeToolTip);
    };
  }, []);

  useEffect(() => {
    let timer;

    if (isMobile() && isActive) {
      timer = setTimeout(closeToolTip, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isActive]);

  const handleTooltipClick = () => {
    if (isMobile()) {
      openToolTip();
    }
  };

  return (
    <>
      <div
        ref={toolTipButtonRef}
        className={`pp_icon ${isTooltipTrue ? "pp_icon--tooltip" : ""} ${
          isActive ? "si-active" : ""
        }`}
        onClick={handleTooltipClick}
      >
        <i className="f1i-provipts"></i>
        {isTooltipTrue && isActive ? (
          <div className="si-tooltip__box">
            {getTranslations(
              "match_status_provisional_points",
              "Provisional Points"
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export { ProvisionalPtsTooltip };
