import React from "react";

function CtaButton({
  forMobileNoSpan,
  btnCls,
  iconCls,
  btnText,
  ariaLabel,
  btnState = false,
  btnType = "button",
  extraAttri = {},
  btnConfig = {},
  onClickProp = (e) => e.preventDefault(),
  clickRef,
  children = null,
}) {
  const iconTag = iconCls ? <i className={iconCls}></i> : "";
  const spanTag = btnText ? <span>{btnText}</span> : "";

  const refProp = clickRef ? { ref: clickRef } : {};

  return (
    <>
      <button
        {...refProp}
        className={btnCls}
        type={btnType}
        aria-label={ariaLabel ? ariaLabel : btnText}
        disabled={btnState}
        onClick={(e) => onClickProp(e)}
        {...extraAttri}
        {...btnConfig}
      >
        {!forMobileNoSpan && spanTag}
        {iconTag}
        {children && children}
      </button>
    </>
  );
}

export { CtaButton };
