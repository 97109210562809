import React from "react";
import { useDispatch } from "react-redux";
import { gameplay } from "../../redux/actions";

const GridListViewBtn = () => {
  const dispatch = useDispatch();
  const handleChange = (e) => {
    e.stopPropagation();
    dispatch(gameplay.setSelectedView());
  };

  return (
    <>
      <form
        action=""
        method="get"
        role="switch"
        aria-checked="true"
        aria-label="Toggle to gridview or listview "
      >
        <label forhtml="gridViewToggle" className="md_switch">
          <input
            type="checkbox"
            id="gridViewToggle"
            onChange={(e) => handleChange(e)}
          />
          <span className="md_switch__toggle">
            {/* <div className="md_switch__toggle-icon">
              <i aria-hidden="true" className="f1i-grid"></i>
              <i aria-hidden="true" className="f1i-list"></i>
            </div> */}
          </span>
        </label>
      </form>
    </>
  );
};

export { GridListViewBtn };
