import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { gameplay } from "../../redux/actions/";
import { MAX_CONSTRUCTORS, MAX_DRIVERS } from "../../Common/constants";
import { getTranslations } from "../../Common/utils/index";

const PlayerListTabs = ({ isDisableConstructors }) => {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);
  const { selectedTab, selectedPlayers } = gameplayState;

  const setSelectedTab = (e, val) => {
    e.preventDefault();
    dispatch(gameplay.setSelectedTab(val));
  };
  const activeClass = (type) => {
    return selectedTab === type ? "si-active" : "";
  };
  const filteredPlayers = (skill = 1) => {
    return selectedPlayers?.filter((p) => p.Skill === skill);
  };

  const selectedDrivers = filteredPlayers(1);
  const selectedConstructors = filteredPlayers(2);

  let foo = `#`;
  return (
    <>
      <div className="si-cmo__tab">
        <ul>
          <li>
            <a
              href={foo}
              onClick={(e) => setSelectedTab(e, "drivers")}
              className={activeClass("drivers")}
              aria-label="Drivers"
            >
              <div className="si-cmo__tab-title">
                <span>{getTranslations("create_team_drivers", "Drivers")}</span>
              </div>
              <div className="si-cmo__tab-indicator">
                {[...Array(MAX_DRIVERS)].map((el, index) => (
                  <span
                    className={
                      index < selectedDrivers?.length ? "si-active" : ""
                    }
                    key={`driver-${index}`}
                  ></span>
                ))}
              </div>
            </a>
          </li>
          <li>
            <a
              href={foo}
              onClick={(e) => {
                if (isDisableConstructors) {
                  e.preventDefault();
                  return;
                }
                setSelectedTab(e, "constructors");
              }}
              className={`${activeClass("constructors")} ${
                isDisableConstructors ? "si-noClick" : ""
              }`}
              aria-label="Constructors"
            >
              <div className="si-cmo__tab-title">
                <span>
                  {getTranslations("create_team_constructors", "Constructors")}
                </span>
              </div>
              <div className="si-cmo__tab-indicator">
                {[...Array(MAX_CONSTRUCTORS)].map((el, index) => (
                  <span
                    className={
                      index < selectedConstructors?.length ? "si-active" : ""
                    }
                    key={`constructor-${index}`}
                  ></span>
                ))}
              </div>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export { PlayerListTabs };
