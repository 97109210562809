import React from "react";

const BestPerformanceCardMostRankPoints = ({
  extraCls,
  teamID,
  cardTitle,
  userTeamName,
  userRank,
  userStatsSlug,
}) => {
  return (
    <>
      <div
        className={`si-mstRnkPts__card ${teamID ? `si-team__${teamID}` : ""} ${
          extraCls ? extraCls : ""
        }`}
      >
        <div className="si-mstRnkPts__card-grid">
          <h2 className="si-mstRnkPts__card-title">{cardTitle}</h2>
          <div className="si-mstRnkPts__card-userStats">
            {userRank} <span>{userStatsSlug}</span>
          </div>
        </div>
        <div className="si-mstRnkPts__card-userTeamName">
          <span>{userTeamName}</span>
        </div>
      </div>
    </>
  );
};

export { BestPerformanceCardMostRankPoints };
