import {
  FETCH_INFO_POPUP_CONFIG_PENDING,
  FETCH_INFO_POPUP_CONFIG_REJECTED,
  FETCH_INFO_POPUP_CONFIG_FULFILLED,
  FETCH_ANNOUNCEMENT_POPUP_CONFIG_PENDING,
  FETCH_ANNOUNCEMENT_POPUP_CONFIG_REJECTED,
  FETCH_ANNOUNCEMENT_POPUP_CONFIG_FULFILLED,
} from "../../constants/announcements";

const announcements = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INFO_POPUP_CONFIG_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_INFO_POPUP_CONFIG_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_INFO_POPUP_CONFIG_FULFILLED:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        info: action.payload.data,
      };
    case FETCH_ANNOUNCEMENT_POPUP_CONFIG_PENDING:
      return {
        ...state,
        announcementLoading: true,
        announcementError: false,
        announcementSuccess: false,
        announcementData: null,
      };
    case FETCH_ANNOUNCEMENT_POPUP_CONFIG_REJECTED:
      return {
        ...state,
        announcementLoading: false,
        announcementError: true,
        announcementSuccess: false,
        announcementData: null,
      };
    case FETCH_ANNOUNCEMENT_POPUP_CONFIG_FULFILLED:
      return {
        ...state,
        announcementLoading: false,
        announcementError: false,
        announcementSuccess: true,
        announcementData: action.payload.data,
      };
    default:
      return state;
  }
};

export default announcements;
