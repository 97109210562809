import { LIVE_POINTS_CLASS } from "../../Common/constants";
import { getTranslations } from "../../Common/utils";
import { ProvisionalPtsTooltip } from "../ProvisionalPtsTooltip";

const TotalPointsSection = ({
  displayProvisionalTooltip = false,
  gdPoints = null,
  lateOnboardIcon = false,
  livePointsCondition = false,
}) => {
  return (
    <div
      className={`si-myTeamHeader__transfers ${
        livePointsCondition ? LIVE_POINTS_CLASS : ""
      }`}
    >
      <span>
        {livePointsCondition ? (
          <>
            {getTranslations("my_teams_projected_points", "Projected Points")}
            <i className="f1i-live"></i>
          </>
        ) : (
          getTranslations("my_teams_total_points", "Total Points")
        )}
        {displayProvisionalTooltip && (
          <ProvisionalPtsTooltip isTooltipTrue={true} />
        )}
      </span>

      <span>
        {gdPoints} {getTranslations("my_team_points_short", "pts")}{" "}
        {lateOnboardIcon && <i className="f1i-error si-negative"></i>}
      </span>
    </div>
  );
};

export { TotalPointsSection };
