import LeagueContextProvider from "./League/LeagueContextProvider";
import BoosterContextProvider from "./Boosters/BoosterContextProvider";
import ViewPromotionContextProvider from "./ViewPromotion/ViewPromotionContextProvider";

const Providers = ({ children }) => {
  return (
    <LeagueContextProvider>
      <BoosterContextProvider>
        <ViewPromotionContextProvider>{children}</ViewPromotionContextProvider>
      </BoosterContextProvider>
    </LeagueContextProvider>
  );
};

export default Providers;
