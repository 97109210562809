export const facebook = (options) => {
  window.open(
    "//www.facebook.com/sharer/sharer.php?u=" + options.url + "&picture=",
    "pop",
    "width=600, height=400, scrollbars=no"
  );
};

export const twitter = (options) => {
  const twtUrl =
    "https://twitter.com/intent/tweet?text=" +
    options.postTitle +
    " " +
    options.url;
  window.open(
    twtUrl,
    "Share_Page",
    "width=600,height=400, scrollbars=no, resizable=no, top=250, left=250",
    false
  );
};
