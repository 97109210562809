import { toNumber } from ".";
import { gameplay } from "../../redux/actions";

export const getUserMDStatus = (gameplayState, teamNo, gameDayId) => {
  const { userGameDays, userGameDaysV1 } = gameplayState;

  switch (
    userGameDaysV1 &&
    userGameDaysV1[teamNo] &&
    userGameDaysV1[teamNo]?.mddetails &&
    userGameDaysV1[teamNo]?.mddetails[gameDayId]?.mds
  ) {
    case 0:
      return "TRANSFER";
    case 1:
      return "LIVE";
    case 2:
      return "SUBS";
    case 3:
      return "VIEW POINTS";

    default:
      // ? TEMPORARY FIX
      return "TRANSFER";
    // return undefined;
  }
};

export const getPriceDifference = (current, old) => {
  let diff = current - old;
  return Math.abs(diff).toFixed(1);
};

export const toggleExtraTurboDriverPopup = (dispatch) => {
  dispatch(gameplay.toggleExtraTurboDriverPopup());
};

export const toggleTransferPopup = (dispatch) => {
  dispatch(gameplay.toggleTransferPopup());
};

export const removeBooster = (dispatch, selectedTeam, callback = () => {}) => {
  dispatch(gameplay.setTempBooster(undefined));
  setTimeout(() => {
    dispatch(gameplay.setSelectedBooster({ team: selectedTeam }));
    callback();
  }, 500);
};

const normalPlayerPoints = (player, team) => {
  const selectedPlayer = player;

  const qualifying_points_key = "QualifyingPoints";
  const race_points_key = "RacePoints";
  const sprint_points_key = "SprintPoints";
  if (
    selectedPlayer?.GamedayPoints === "" ||
    selectedPlayer?.GamedayPoints === null
  ) {
    return "-";
  }
  if (isNaN(Number(selectedPlayer?.GamedayPoints))) {
    return 0;
  }

  // Player points for Final Fix
  if (team?.boosterid === 3) {
    // For Old Player
    if (player?.isFinalFixOldPlayer) {
      let oldPlayerKey =
        team?.finalfxracecat === "R"
          ? "QualifyingPoints"
          : team?.finalfxracecat === "S"
          ? "SprintPoints"
          : "";

      if (oldPlayerKey === "QualifyingPoints") {
        return (
          toNumber(player?.[oldPlayerKey]) +
          toNumber(player?.[sprint_points_key])
        );
      }
      if (oldPlayerKey === "SprintPoints") {
        if (player?.[oldPlayerKey] === null || player?.[oldPlayerKey] === "") {
          return "-";
        }
        return Number(player?.[oldPlayerKey]);
      }
      return Number(player?.[oldPlayerKey]);
    }
    // For New Player
    if (player?.isFinalFixNewPlayer) {
      let newPlayerKey =
        team?.finalfxracecat === "S"
          ? "SprintPoints"
          : team?.finalfxracecat === "R"
          ? "RacePoints"
          : "";
      if (newPlayerKey === "SprintPoints") {
        if (player?.[newPlayerKey] === null || player?.[newPlayerKey] === "") {
          return "-";
        }
        return (
          Number(player?.[newPlayerKey]) + Number(player?.[race_points_key])
        );
      }
      return Number(player?.[newPlayerKey]);
    }
  }
  if (team?.boosterid === 5) {
    return Number(player?.NoNegativePoints);
  }
  if (team?.race_category === "S") {
    if (
      player?.[sprint_points_key] === null ||
      player?.[sprint_points_key] === ""
    ) {
      return "-";
    }

    if (
      isNaN(Number(player?.[sprint_points_key])) ||
      isNaN(Number(player?.[race_points_key]))
    ) {
      return 0;
    }

    return (
      Number(player?.[sprint_points_key]) + Number(player?.[race_points_key])
    );
  }
  if (team?.race_category === "R") {
    if (
      selectedPlayer?.RacePoints === "" ||
      selectedPlayer?.RacePoints === null
    ) {
      return "-";
    }
    return Number(selectedPlayer?.RacePoints);
  }
  return Number(selectedPlayer?.GamedayPoints);
};

const playerPoints = (player, team) => {
  if (normalPlayerPoints(player, team) === "-") {
    return "-";
  }
  // Player points for Final Fix
  if (team?.boosterid === 3) {
    // For Old Player
    if (player?.isFinalFixOldPlayer) {
      if (team?.selectedCaptain?.PlayerId == team?.finalfxnewplayerid) {
        return normalPlayerPoints(player, team) * 2;
      }
      return normalPlayerPoints(player, team);
    }
    // For New Player
    if (player?.isFinalFixNewPlayer) {
      if (player?.isCaptain) {
        return normalPlayerPoints(player, team) * 2;
      }
      return normalPlayerPoints(player, team);
    }
  }
  if (player?.isCaptain) {
    return normalPlayerPoints(player, team) * 2;
  }

  if (player?.isExtraDRS) {
    return normalPlayerPoints(player, team) * 3;
  }

  return normalPlayerPoints(player, team);
};

export const handleTotalPoints = (
  team,
  player,
  isSprintMatchPresent = false
) => {
  const isFinalFixChipApplied = !!team?.finalfxracecat;

  if (isFinalFixChipApplied && isSprintMatchPresent) {
    const playerQualifyingPoints = Number(player?.QualifyingPoints);
    const playerRacePoints = Number(player?.RacePoints);
    const playerSprintPoints = Number(player?.SprintPoints);

    const isCaptainReplaced =
      team?.selectedCaptain?.PlayerId === team?.finalfxnewplayerid;

    if (team?.finalfxracecat === "S") {
      // Final fix applied before Sprint race
      if (player?.isFinalFixOldPlayer) {
        // Return qualifying points total
        return isCaptainReplaced
          ? playerQualifyingPoints * 2
          : playerQualifyingPoints;
      } else if (player?.isFinalFixNewPlayer) {
        // Return sprint and race points total
        let points = playerSprintPoints + playerRacePoints;

        return isCaptainReplaced ? points * 2 : points;
      } else {
        return playerPoints(player, team);
      }
    } else if (team?.finalfxracecat === "R") {
      // Final fix applied after Sprint race
      if (player?.isFinalFixOldPlayer) {
        // Return qualifying and sprint points total
        let points = playerQualifyingPoints + playerSprintPoints;

        return isCaptainReplaced ? points * 2 : points;
      } else if (player?.isFinalFixNewPlayer) {
        // Return Race points total
        return isCaptainReplaced ? playerRacePoints * 2 : playerRacePoints;
      } else {
        return playerPoints(player, team);
      }
    } else {
      return playerPoints(player, team);
    }
  } else {
    return playerPoints(player, team);
  }
};
