import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BoosterChips } from "../BoosterChips";
import { CtaButton } from "../Buttons";
import { gameplay } from "../../redux/actions";
import { getTranslations, isAndroid, isIOS } from "../../Common/utils";
import { currentFixture, getMatchStatus } from "../../Common/utils/fixture";
import { getUserMDStatus } from "../../Common/utils/gameplay";
import { isFinalFix } from "../../Common/utils/team";
import useDataLayer from "../../Common/hooks/useDataLayer";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";

export const NoChipsActivated = () => {
  return (
    <>
      <div className="si-applyChips__noDataFound">
        <span>
          {getTranslations("my_teams_no_chips", "No Chips Activated")}
        </span>
      </div>
    </>
  );
};

const ApplyChips = ({
  selectedTeam,
  setSelectedTeam = () => {
    return null;
  },
  isSelectTeam = false,
  extraClass = "",
}) => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.user);
  const gameplayState = useSelector((state) => state.gameplay);
  const fixtureState = useSelector((state) => state.fixture);
  const constraintsState = useSelector((state) => state.constraints);
  const pushToDataLayer = useDataLayer();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const { selectedBooster } = gameplayState;
  const webview = userState?.webview;

  const pushApplyChipsDataLayer = () => {
    const dataLayerObject = {
      event: "applyChips",
      actionType: "Apply Chips",
      clickText: getTranslations("my_teams_apply_chips", "Apply Chips"),
      path: "/my-team",
      pageName: "My Team",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("applyChips", dataLayerObject);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (disableCTAs) {
      return;
    }
    pushApplyChipsDataLayer();
    setSelectedTeam(selectedTeam);
    dispatch(gameplay.toggleChipsPopup());
  };

  if (
    isSelectTeam &&
    (!selectedBooster || (selectedBooster && !selectedBooster[selectedTeam]))
  ) {
    return <NoChipsActivated />;
  }

  if (
    (getUserMDStatus(gameplayState, selectedTeam + 1, gameplayState?.mdid) ===
      "LIVE" ||
      getUserMDStatus(gameplayState, selectedTeam + 1, gameplayState?.mdid) ===
        "VIEW POINTS" ||
      (getUserMDStatus(gameplayState, selectedTeam + 1, gameplayState?.mdid) ===
        "SUBS" &&
        (gameplayState?.userGameDaysV1[
          gameplayState?.teams?.[selectedTeam]?.teamno
        ]?.ftmdid === gameplayState?.mdid ||
          !!gameplayState?.teams?.[selectedTeam]?.isfinalfixtaken))) &&
    (!selectedBooster || (selectedBooster && !selectedBooster[selectedTeam]))
  ) {
    return <NoChipsActivated />;
  }

  return (
    <>
      {selectedBooster && selectedBooster[selectedTeam] ? (
        <BoosterChips
          boosterCls={selectedBooster[selectedTeam]?.boosterCls}
          boosterIcon={selectedBooster[selectedTeam]?.boosterIcon}
          boosterText={selectedBooster[selectedTeam]?.boosterActivatedText}
        />
      ) : (
        <div className={`si-applyChips ${extraClass}`}>
          <div className="si-applyChips__grid" onClick={(e) => handleClick(e)}>
            <div className="si-applyChips__icon">
              <CtaButton
                btnCls="si-btn si-btn__primary"
                iconCls="f1i-chips"
                btnText=""
                ariaLabel=""
                btnState={disableCTAs}
              />
            </div>
            <div className="si-applyChips__cta">
              <CtaButton
                btnCls="si-btn si-btn__link"
                iconCls=""
                btnText={getTranslations("my_teams_apply_chips", "Apply Chips")}
                ariaLabel=""
                btnState={disableCTAs}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { ApplyChips };
