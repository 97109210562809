import React from "react";

const RadioButton = ({
  radioName,
  radioText,
  radioId,
  handleOnChange,
  checked = false,
  disabled = false,
  radioBtnClassName = "",
}) => {
  return (
    <>
      <div className={`si-radioBtn__wrap ${radioBtnClassName}`}>
        <div className="si-radioBtn ">
          <input
            type="radio"
            id={radioId}
            name={radioName}
            value={radioText}
            checked={checked}
            onChange={(e) => handleOnChange(e)}
            disabled={disabled}
            className={`${disabled ? "si-disabled" : ""}`}
          />
          <label htmlFor={radioId}>{radioText}</label>
        </div>
      </div>
    </>
  );
};

export { RadioButton };
