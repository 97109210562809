import {
  FETCH_ACHIEVEMENTS_PENDING,
  FETCH_ACHIEVEMENTS_FULFILLED,
  FETCH_ACHIEVEMENTS_REJECTED,
} from "../../constants/achievements";

const achievements = (state = {}, action) => {
  switch (action.type) {
    case FETCH_ACHIEVEMENTS_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_ACHIEVEMENTS_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_ACHIEVEMENTS_FULFILLED:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        achievements: action.payload.data,
      };
    default:
      return state;
  }
};

export default achievements;
