import React from "react";
import HeroBanner from "../../Components/League/HeroBanner";
import { createPortal } from "react-dom";
import { GoogleAds } from "../GoogleAds";

const LeaguesFallback = () => {
  return (
    <>
      {createPortal(
        <GoogleAds adKey="leaguesAboveFold" />,
        document.getElementById("si-google-ad-league-above-fold")
      )}
      <HeroBanner
        showGroupButtons={false}
        customCls="si-league__hero--default"
      />
      <GoogleAds adKey="leaguesBelowFold" />
    </>
  );
};

export { LeaguesFallback };
