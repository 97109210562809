import {
  FETCH_SEASON_HOMEPAGE_STATS_PENDING,
  FETCH_SEASON_HOMEPAGE_STATS_REJECTED,
  FETCH_SEASON_HOMEPAGE_STATS_FULFILLED,
  FETCH_USER_HOMEPAGE_STATS_PENDING,
  FETCH_USER_HOMEPAGE_STATS_REJECTED,
  FETCH_USER_HOMEPAGE_STATS_FULFILLED,
  FETCH_SEASON_FEATURED_MINI_LEAGUE_PENDING,
  FETCH_SEASON_FEATURED_MINI_LEAGUE_REJECTED,
  FETCH_SEASON_FEATURED_MINI_LEAGUE_FULFILLED,
} from "../../constants/homepage";

const initialState = {
  seasonHomepageStatsLoading: false,
  seasonHomepageStatsError: false,
  seasonHomepageStatsFulfilled: false,
  seasonHomepageStats: null,
  userHomepageStatsLoading: false,
  userHomepageStatsError: false,
  userHomepageStatsFulfilled: false,
  userHomepageStats: null,
  seasonFeaturedMiniLeaguePending: false,
  seasonFeaturedMiniLeagueRejected: false,
  seasonFeaturedMiniLeagueFulfiled: false,
  seasonFeaturedMiniLeagueData: null,
};

const homepage = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEASON_HOMEPAGE_STATS_PENDING:
      return {
        ...state,
        seasonHomepageStatsLoading: true,
        seasonHomepageStatsError: false,
        seasonHomepageStatsFulfilled: false,
        seasonHomepageStats: null,
      };
    case FETCH_SEASON_HOMEPAGE_STATS_REJECTED:
      return {
        ...state,
        seasonHomepageStatsLoading: false,
        seasonHomepageStatsError: true,
        seasonHomepageStatsFulfilled: false,
        seasonHomepageStats: null,
      };
    case FETCH_SEASON_HOMEPAGE_STATS_FULFILLED:
      return {
        ...state,
        seasonHomepageStatsLoading: false,
        seasonHomepageStatsError: false,
        seasonHomepageStatsFulfilled: true,
        seasonHomepageStats: action.payload.data,
      };
    case FETCH_USER_HOMEPAGE_STATS_PENDING:
      return {
        ...state,
        userHomepageStatsLoading: true,
        userHomepageStatsError: false,
        userHomepageStatsFulfilled: false,
        userHomepageStats: null,
      };
    case FETCH_USER_HOMEPAGE_STATS_REJECTED:
      return {
        ...state,
        userHomepageStatsLoading: false,
        userHomepageStatsError: true,
        userHomepageStatsFulfilled: false,
        userHomepageStats: null,
      };
    case FETCH_USER_HOMEPAGE_STATS_FULFILLED:
      return {
        ...state,
        userHomepageStatsLoading: false,
        userHomepageStatsError: false,
        userHomepageStatsFulfilled: true,
        userHomepageStats: action.payload.data,
      };
    case FETCH_SEASON_FEATURED_MINI_LEAGUE_PENDING:
      return {
        ...state,
        seasonFeaturedMiniLeaguePending: true,
        seasonFeaturedMiniLeagueRejected: false,
        seasonFeaturedMiniLeagueFulfiled: false,
        seasonFeaturedMiniLeagueData: null,
      };
    case FETCH_SEASON_FEATURED_MINI_LEAGUE_REJECTED:
      return {
        ...state,
        seasonFeaturedMiniLeaguePending: false,
        seasonFeaturedMiniLeagueRejected: true,
        seasonFeaturedMiniLeagueFulfiled: false,
        seasonFeaturedMiniLeagueData: null,
      };
    case FETCH_SEASON_FEATURED_MINI_LEAGUE_FULFILLED:
      return {
        ...state,
        seasonFeaturedMiniLeaguePending: false,
        seasonFeaturedMiniLeagueRejected: false,
        seasonFeaturedMiniLeagueFulfiled: true,
        seasonFeaturedMiniLeagueData: action.payload.data,
      };
    default:
      return state;
  }
};

export default homepage;
