import React from "react";
import config from "../../Common/config";
import {
  IMAGE_VERSION,
  getLeagueBadge,
  leagueBadgeKeys,
} from "../../Common/constants";
import { getTranslations, isMobile } from "../../Common/utils";
import { CtaButton } from "../Buttons";
import { FixDates } from "../Fixtures/FixDates";
import { FixRound } from "../Fixtures/FixRound";
import { PvtPubCardChips } from "../PvtPubCardChips";
import { currentFixture, getMatchStatus } from "../../Common/utils/fixture";
import { MatchData } from "../MatchHeader";
import { useSelector } from "react-redux";
import { ProvisionalPtsTooltip } from "../ProvisionalPtsTooltip";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";
import Profanity from "../../Components/Profanity";
import MatchStatus from "../MatchStatus";
import { getRank } from "../../Common/utils/league";

const PvtPubCardInfo = ({ PvtPubCardConfig }) => {
  const fixtureState = useSelector((state) => state.fixture);
  const {
    showPvtPubCardChips = false,
    showThumb = false,
    conditionClsName,
    teamID,
    leagueName,
    leagueCount,
    maxLeagueCount = null,
    imageSrc,
    imageName,
    showLeagueCount = true,
    type = 0,
    showRound = false,
    roundNo = "",
    showDate = false,
    fixDates = "",
    fixRoundLbl = false,
    editName = false,
    adminIcon = false,
    errorIcon = false,
    showProvisionalPts = false,
    handleEditClick = () => {
      return null;
    },
    showMaxTeams = false,
    maxTeams,
    userJoinedTeams = 0,
    defaultImageName = null,
    showProfanityIcon,
    webPurifyStatus,
    showRHS = true,
    showBottomInfo = true,
    leagueBadgeId = null,
    leagueType = null,
  } = PvtPubCardConfig;
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const isGlobal = () => {
    return (
      leagueType === "Global" ||
      leagueType === "Country" ||
      leagueType === "Team" ||
      leagueType === "Driver"
    );
  };

  return (
    <>
      <div className="si-league__card">
        {showThumb && (
          <div className="si-league__card-lhs">
            <div
              className={`si-league__card-thumb si-team__${teamID} ${conditionClsName} `}
            >
              <img
                src={`${config.IMG_BASE}${imageSrc}${imageName}?v=${IMAGE_VERSION}`}
                alt={imageName}
                loading="lazy"
                onError={(e) =>
                  (e.target.src = `${config.IMG_BASE}${imageSrc}${defaultImageName}?v=${IMAGE_VERSION}`)
                }
              />
            </div>
          </div>
        )}
        {showRHS && (
          <div className="si-league__card-rhs">
            <h3
              className={
                !editName
                  ? "si-league__card-title"
                  : "si-league__card-title si-editEnable"
              }
            >
              <span>{leagueName}</span>
              {errorIcon ? (
                <div className="si-league__card-icon si-error">
                  <i className="f1i-error"></i>
                </div>
              ) : adminIcon ? (
                showProfanityIcon ? (
                  <Profanity webPurifyStatus={webPurifyStatus} type="league" />
                ) : (
                  <div className="si-league__card-icon">
                    <i className="f1i-admin"></i>
                  </div>
                )
              ) : (
                ""
              )}
              {editName ? (
                <>
                  <CtaButton
                    btnCls="si-btn si-btn__icon"
                    iconCls="f1i-edit"
                    btnText=""
                    ariaLabel={getTranslations(
                      "manage_team_edit_team_name_desc",
                      "Click to edit your league name" // TODO: Change this
                    )}
                    btnState={disableCTAs}
                    onClickProp={() => handleEditClick()}
                  />
                </>
              ) : (
                ""
              )}
            </h3>
            {!!leagueBadgeId && (
              <MatchStatus
                {...getLeagueBadge(leagueBadgeKeys?.[leagueBadgeId])}
              />
            )}
          </div>
        )}
        {showBottomInfo && (
          <div className="si-league__card-below">
            {(showPvtPubCardChips ||
              showLeagueCount ||
              showMaxTeams ||
              showRound ||
              showDate) && (
              <div
                className={`si-league__card-info ${
                  showProvisionalPts ? "si-league__card-info--reset" : ""
                }`}
              >
                <div className="si-league__card-info-lhs">
                  {showPvtPubCardChips && (
                    <PvtPubCardChips PvtPubCardChipsNameConfig={type} />
                  )}
                  {showMaxTeams && (
                    <div className="si-league__card-caption">
                      <i className="f1i-teams"></i>
                      <span>
                        {/* {getTranslations(
                          "league_landing_public_league_max_team_per_user",
                          "Max team per user"
                        )} */}
                        <em>
                          {maxTeams ? `${userJoinedTeams}/${maxTeams}` : "-"}
                        </em>
                      </span>
                    </div>
                  )}
                  {showLeagueCount && (
                    <>
                      {`|`}
                      <div className="si-league__card-caption">
                        <span>
                          {getTranslations(
                            "league_card_total_team",
                            "Total Team:"
                          )}
                          <em>
                            {leagueCount ? leagueCount : 0}
                            {maxLeagueCount ? `/${maxLeagueCount}` : ""}
                          </em>
                          {/* {leagueCount < 1
                          ? getTranslations(
                              "league_landing_public_league_table_member",
                              "team"
                            )
                          : getTranslations(
                              "league_landing_public_league_table_members",
                              "members"
                            )} */}
                        </span>
                      </div>
                    </>
                  )}
                </div>
                {showProvisionalPts &&
                  getMatchStatus(currentFixture(fixtureState)?.MatchStatus) ===
                    "PROVISIONAL POINTS" &&
                  (isMobile() ? (
                    <ProvisionalPtsTooltip isTooltipTrue={true} />
                  ) : (
                    <MatchData
                      showFixtureData={false}
                      MatchStatusWrap={true}
                      fixtureState={fixtureState}
                    />
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export { PvtPubCardInfo };
