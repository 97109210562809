import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PLATFORM_CATEGORY,
  PLATFORM_ID,
  PLATFORM_VERSION,
} from "../../../Common/constants";
import { getTranslations, isMobile } from "../../../Common/utils";
import { league } from "../../../redux/actions";
import { CtaButton, Overlays } from "../../../ReusableComponents";
import useTournamentScanario from "../../../Common/hooks/useTournamentScanario";

const LeagueDelete = ({
  leagueId,
  leagueType,
  memberCount,
  pushDeleteLeagueDataLayer,
  pushDeleteLeaguePopupDataLayer,
}) => {
  const dispatch = useDispatch();
  const leagueState = useSelector((state) => state.league);
  const [showDeletePopup, setShowDeletePopup] = useState();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const { leagueUserInfo, leagueMembers, selectedLeague } = leagueState;

  const togglePopup = () => {
    setShowDeletePopup((prev) => !prev);
    if (!showDeletePopup) {
      pushDeleteLeagueDataLayer();
    }
  };

  const handleConfirm = () => {
    let teamNumber = 0;
    const isH2H =
      selectedLeague?.leagueType === "Private H-T-H" ||
      selectedLeague?.leagueType === "H-T-H Private" ||
      selectedLeague?.leagueType === "HTH Private";

    if (isH2H) {
      const teamid = leagueUserInfo?.teamid;
      const team = leagueMembers?.find((item) => item?.teamid === teamid);
      teamNumber = team?.userTeamNo?.[0];
    }

    const payload = {
      optType: 0,
      platformId: PLATFORM_ID,
      platformVersion: PLATFORM_VERSION,
      platformCategory: PLATFORM_CATEGORY,
      leagueId: leagueId,
      teamNumber: isH2H ? teamNumber : 0,
    };

    if (isH2H) {
      dispatch(league.deleteH2HLeague(payload));
    } else {
      dispatch(league.deleteLeague(payload));
    }

    togglePopup();
  };

  return (
    <>
      <div className="si-league__delete">
        <CtaButton
          btnCls={`si-btn si-btn__secondary si-btn__secondary--outline`}
          iconCls="f1i-delete"
          btnText={getTranslations(
            "manage_league_delete_league_button",
            "Delete League"
          )}
          ariaLabel={getTranslations(
            "manage_league_delete_league_popup_confirm_aria",
            "Click to delete your league"
          )}
          btnState={(leagueType !== 1 && memberCount > 1) || disableCTAs}
          onClickProp={() => togglePopup()}
        />
      </div>
      {showDeletePopup && (
        <Overlays
          overlayTitle={getTranslations(
            "manage_league_delete_league_popup_title",
            "Delete League"
          )}
          overlaySubTitle={getTranslations(
            "manage_league_delete_league_popup_message",
            "Are you sure you want to delete the league?"
          )}
          btnPrimary={getTranslations(
            "manage_league_delete_league_popup_confirm_button",
            "Delete"
          )}
          btnSecondary={getTranslations(
            "manage_league_delete_league_popup_cancel_button",
            "Cancel"
          )}
          overlayConfig={{ cancelBtn: true, showOverlaySub_Title: true }}
          onCancelClick={() => {
            togglePopup();
            pushDeleteLeaguePopupDataLayer("CANCEL");
          }}
          onConfirmClick={() => {
            handleConfirm();
          }}
        />
      )}
    </>
  );
};

export default LeagueDelete;
