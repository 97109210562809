import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { BoosterChips } from "../BoosterChips";
import { splitName } from "../../Common/utils";

const PlayerMiniCard = ({
  teamID,
  imageSrc,
  imageName,
  PlayerMiniCardName,
  PlayerMiniCardTeam,
  booster = false,
  extraClass = "",
  isNameSplit = false,
  showImage = true,
}) => {
  return (
    <>
      <div className={`si-miniCard__wrap ${extraClass}`}>
        {showImage && (
          <div className="si-miniCard__lhs">
            <div className={`si-miniCard__thumb si-team__${teamID}`}>
              <img
                src={`${config.IMG_BASE}${imageSrc}${imageName}?v=${IMAGE_VERSION}`}
                alt={imageName}
                loading="lazy"
              />
            </div>
          </div>
        )}
        <div className="si-miniCard__rhs">
          <div className="si-miniCard__name">
            {isNameSplit ? (
              <>
                <span>{splitName(PlayerMiniCardName)?.firstName}</span>
                <span>{splitName(PlayerMiniCardName)?.lastName}</span>
              </>
            ) : (
              <span>{PlayerMiniCardName}</span>
            )}
          </div>
          {PlayerMiniCardTeam && (
            <div className="si-miniCard__team">
              <span>{PlayerMiniCardTeam}</span>
            </div>
          )}
        </div>
        {booster && (
          <div className="si-miniCard__icon">
            <BoosterChips {...booster} />
          </div>
        )}
      </div>
    </>
  );
};

export { PlayerMiniCard };
