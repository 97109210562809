import axios from "../../axios";
import { getURLString } from "../../index";
const URLString = (url) => getURLString(BASE_ROUTE, url);
const ConsentURLString = (url) => getURLString(CONSENT_BASE_ROUTE, url);

const BASE_ROUTE = `services/session`;
const CONSENT_BASE_ROUTE = "services";
const LOGIN = `login`;

export function login(params) {
  return axios.post(URLString(LOGIN), params);
}

export function submitFeedbackQuestions(params) {
  const { url = "optinresponse", body } = params;

  return axios.post(ConsentURLString(url), body);
}

export function submitConstructorConsent(params) {
  const { url = "constructorconsent", query = { consent: 0 } } = params;

  return axios.post(ConsentURLString(`${url}/${query.consent}`));
}
