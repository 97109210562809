import { feeds } from "../../apis/services";
import {
  FETCH_OVERALL_EOS_STATS,
  FETCH_USER_EOS_STATS,
  FETCH_EOS_CONFIG,
} from "../../constants/eos";

export const getEOSConfig = () => ({
  type: FETCH_EOS_CONFIG,
  payload: feeds.getEOSConfig(),
});

export const getOverallEOSStats = (params) => ({
  type: FETCH_OVERALL_EOS_STATS,
  payload: feeds.getOverallEOSStats(params),
});

export const getUserEOSStats = (path, socialID) => ({
  type: FETCH_USER_EOS_STATS,
  payload: feeds.getUserEOSStats(path, socialID),
});
