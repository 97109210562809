import store from "../../redux/store";
import routes from "../../router/paths";
import {
  ACCESS_ANNUAL,
  ACCESS_MONTHLY,
  ADDITIONAL_SUBSCRIPTIONS_TYPE,
  DATA_REDIRECT_INTERNAL_VALUE,
  F1_TV_ACCESS_ANNUAL,
  F1_TV_ACCESS_MONTHLY,
  F1_TV_PRO_ANNUAL,
  F1_TV_PRO_MONTHLY,
  PROFANE_AND_PROVISIONAL,
  PRO_ANNUAL,
  PRO_MONTHLY,
  SUBSCRIPTION_PACKAGE_TYPE,
  UPDATED_BY_SYSTEM,
} from "../constants";
import { hasTakenBooster } from "./booster";
import { emojiRegex } from "./emoji-regex";

const get = (obj, path, defValue) => {
  // If path is not defined or it has false value
  if (!path) return undefined;
  // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  // Find value
  const result = pathArray.reduce(
    (prevObj, key) => prevObj && prevObj[key],
    obj
  );
  // If found value is undefined return default value; otherwise return the value
  return result === undefined ? defValue : result;
};

// export const isMobile = () => {
//   return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//     navigator.userAgent
//   );
// };

export const isMobile = () => {
  return /iphone|ipod|coreappios|android|nokia|blackberry|BB10|bada|tizen|mini|windows\sce|palm/i.test(
    navigator.userAgent.toLowerCase()
  );
};

export const isAndroid = () => {
  return /android/i.test(navigator.userAgent.toLowerCase());
};

export const isIOS = () => {
  return /iphone|ipod|coreappios/i.test(navigator.userAgent.toLowerCase());
};

export const isTablet = () => {
  return /Tablet|Trident|iPad/i.test(navigator.userAgent);
};

export const sortBy = (arrayOfObject, key, type, format = "asec") => {
  if (!arrayOfObject) return;
  switch (type) {
    case "number":
    case "num": {
      const numArr = arrayOfObject.sort(
        (a, b) => parseFloat(a[key]) - parseFloat(b[key])
      );
      return format === "desc" ? numArr.reverse() : numArr;
    }
    case "str":
    case "string": {
      const strSorted = arrayOfObject.sort((a, b) =>
        a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
      );
      return format === "desc" ? strSorted.reverse() : strSorted;
    }
    default:
      return;
  }
};

export const levelThreeSorting = (
  arrayOfObject,
  key,
  type,
  format = "asec",
  secondLevelKey,
  secondLevelType,
  secondLevelFormat = "asec",
  thirdLevelKey,
  thirdLevelType,
  thirdLevelFormat = "asec"
) => {
  if (!arrayOfObject) return;

  const compareValues = (obj1, obj2, key, type, format) => {
    const value1 = obj1?.[key];
    const value2 = obj2?.[key];

    switch (type) {
      case "number":
      case "num": {
        if (format === "asec") {
          return value1 - value2;
        } else {
          return value2 - value1;
        }
      }
      case "string":
      case "str": {
        if (format === "asec") {
          return value1 > value2 ? -1 : value2 > value1 ? 1 : 0;
        } else {
          return value1 > value2 ? 1 : value2 > value1 ? -1 : 0;
        }
      }
      default:
        return 0;
    }
  };

  const sortedArray = arrayOfObject.sort((a, b) => {
    const firstSortValue = compareValues(a, b, key, type, format);

    if (firstSortValue === 0) {
      const secondSortValue = compareValues(
        a,
        b,
        secondLevelKey,
        secondLevelType,
        secondLevelFormat
      );

      if (secondSortValue === 0) {
        return compareValues(
          a,
          b,
          thirdLevelKey,
          thirdLevelType,
          thirdLevelFormat
        );
      } else {
        return secondSortValue;
      }
    } else {
      return firstSortValue;
    }
  });

  return sortedArray;
};

export const secondarySortBy = (
  arrayOfObject,
  key,
  type,
  format = "asec",
  secondaryKey,
  secondaryType
) => {
  if (!arrayOfObject) return;

  switch (type) {
    case "number":
    case "num": {
      const numArr = arrayOfObject?.sort((a, b) => {
        const num1 = parseFloat(a?.[key]);
        const num2 = parseFloat(b?.[key]);
        if (num1 === num2 && !!secondaryKey && !!secondaryType) {
          if (secondaryType === "number" || secondaryType === "num") {
            return (
              parseFloat(a?.[secondaryKey]) - parseFloat(b?.[secondaryKey])
            );
          }
          if (secondaryType === "str" || secondaryType === "string") {
            return a?.[secondaryKey] > b?.[secondaryKey]
              ? -1
              : b?.[secondaryKey] > a?.[secondaryKey]
              ? 1
              : 0;
          }
        }
        return num1 - num2;
      });
      return format === "desc" ? numArr?.reverse() : numArr;
    }
    case "str":
    case "string": {
      const strSorted = arrayOfObject?.sort((a, b) => {
        if (a?.[key] === b?.[key] && !!secondaryKey && !!secondaryType) {
          if (secondaryType === "number" || secondaryType === "num") {
            return (
              parseFloat(a?.[secondaryKey]) - parseFloat(b?.[secondaryKey])
            );
          }
          if (secondaryType === "str" || secondaryType === "string") {
            return a?.[secondaryKey] > b?.[secondaryKey]
              ? -1
              : b?.[secondaryKey] > a?.[secondaryKey]
              ? 1
              : 0;
          }
        }
        return a?.[key] > b?.[key] ? 1 : b?.[key] > a?.[key] ? -1 : 0;
      });
      return format === "desc" ? strSorted?.reverse() : strSorted;
    }
    default:
      return;
  }
};

export const getTranslations = (key, defaultValue = "", type) => {
  try {
    const translation = window.translations;
    let value = defaultValue;
    if (translation && get(translation, [key], false)) {
      value = get(translation, [key], defaultValue);
    }
    if (type) {
      switch (type) {
        case "caps":
          value = value.toUpperCase();
          break;

        case "lowerCase":
          value = value.toLowerCase();
          break;

        case "capitalization":
          value = value.toLowerCase();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          break;

        case "titleCase":
          value = value
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          break;
        default:
          break;
      }
    }
    return value;
  } catch (e) {
    return defaultValue;
  }
};

export const getCookie = (c_name) => {
  let i,
    x,
    y,
    cookiesArray = document.cookie.split(";");
  for (i = 0; i < cookiesArray.length; i++) {
    x = cookiesArray[i].substr(0, cookiesArray[i].indexOf("="));
    y = cookiesArray[i].substr(cookiesArray[i].indexOf("=") + 1);
    x = x.replace(/^\s+|\s+$/g, "");
    if (x === c_name) {
      return window.decodeURIComponent(y);
    }
  }
  return false;
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const isWebview = () => {
  return document.location.href.includes("webview=true");
};

export const getBaseName = (
  path = "/",
  addLeadingSlash = true,
  addTrailingSlash = false
) => {
  try {
    const paths = path?.split("/");
    const leadingPath = addLeadingSlash ? "/" : "";
    const trailingPath = addTrailingSlash ? "/" : "";
    return paths?.length > 1 &&
      paths[1] === process.env.REACT_APP_ROUTER_WEBVIEW_BASE_NAME
      ? trailingPath +
          process.env.REACT_APP_ROUTER_WEBVIEW_BASE_NAME +
          leadingPath
      : process.env.REACT_APP_ROUTER_BASE_NAME;
  } catch (error) {
    return process.env.REACT_APP_ROUTER_BASE_NAME;
  }
};

export const day_keys = [
  "day_sunday",
  "day_monday",
  "day_tuesday",
  "day_wednesday",
  "day_thursday",
  "day_friday",
  "day_saturday",
];

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const decodeName = (name) => {
  if (!name) return "";
  let decodedName;
  try {
    decodedName = decodeURIComponent(name.replace(/\+/g, " "));
    return decodedName;
  } catch (e) {
    return name;
  }
};

export const getQueryParameters = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = new Proxy(urlSearchParams, {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  return params;
};

export const getPath = (translation, route, params = {}) => {
  let slug = routes[route].path;
  if (routes[route].parent) {
    slug = `${routes[route].parent}/${slug}`;
  }
  for (const key in params) {
    if (Object.hasOwnProperty.call(params, key)) {
      const value = params[key];
      slug = slug.replace(`:${key}`, value);
    }
  }
  // console.log(params, `/${translation?.language}/${slug}`);
  return `/${translation?.language || "en"}/${slug}`;
};

export const copyToClipboard = (id) => {
  let text = document.getElementById(id).innerHTML;
  navigator.clipboard.writeText(text);
  // alert("Copied to Clipboard");
};

export const scrollToTop = ({ top = 100 }) => {
  window.scroll({
    top: top,
    left: 100,
    behavior: "smooth",
  });
};

export const nFormatter = (num, digits = 0) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const handleF1Login = (
  extraParams = {},
  type = "login",
  translationState,
  userState
) => {
  if (window?.isIpadAppWebview) {
    window.webkit.messageHandlers.redirectTo.postMessage(
      JSON.stringify({
        redirect: DATA_REDIRECT_INTERNAL_VALUE,
        redirectTo: type,
      })
    );
    return;
  }
  const F1_LOGIN_BASE_URL = `${process.env.REACT_APP_F1_API_URL}/#/en/${type}?`;
  const webviewRedirect =
    window.location.origin +
    window.location.pathname.replace(
      translationState?.language,
      `${translationState?.language}/webview`
    );
  const F1_LOGIN_PARAMS = {
    redirect: decodeURIComponent(
      userState?.webview ? webviewRedirect : window.location.href
    ),
    lead_source: "web_fantasy",
    ...extraParams,
  };
  const params = new URLSearchParams(F1_LOGIN_PARAMS);
  const url = `${F1_LOGIN_BASE_URL}${params}`;

  window.location.href = url;
};

export const handleF1LoginCustomPath = (
  extraParams = {},
  type = "login",
  translationState,
  userState,
  customPath = "",
  removePath = ""
) => {
  const F1_LOGIN_BASE_URL = `${process.env.REACT_APP_F1_API_URL}/#/en/${type}?`;
  const webviewRedirect =
    window.location.origin +
    window.location.pathname.replace(
      translationState?.language,
      `${translationState?.language}/webview/`
    );
  const F1_LOGIN_PARAMS = {
    redirect: decodeURIComponent(
      userState?.webview
        ? webviewRedirect
        : window.location.href.replace(removePath, customPath)
    ),
    lead_source: "web_fantasy",
    ...extraParams,
  };
  const params = new URLSearchParams(F1_LOGIN_PARAMS);
  const url = `${F1_LOGIN_BASE_URL}${params}`;

  window.location.href = url;
};

export const handleF1Profile = (type = "my-account") => {
  if (type === "my-account") {
    const F1_LOGIN_BASE_URL = `${process.env.REACT_APP_F1_API_URL}/#/en/${type}?`;
    window.location.href = F1_LOGIN_BASE_URL;
  }
  if (type === "Subscribe") {
    const subscribeURL = "https://www.formula1.com/subscribe-to-f1-tv";
    window.location.href = subscribeURL;
  }
};

export const eachWordFirstLetterCapitalize = (text) => {
  return text?.replace(/(^\w|\s\w)/g, (m) => m?.toUpperCase());
};

export const formatTime = (time) => {
  return time < 10 ? "0" + time : time;
};

export const getValue = (value, defaultValue = "-") => {
  return value ? value : defaultValue;
};

export const getPoints = (value, defaultValue = "-") => {
  return !isNaN(value) && value !== null ? value : defaultValue;
};

const getDayMonthTranslationKey = (value) => {
  const driverStatsModalKey = "driver_stats_modal_";
  const dateMonthObject = {
    Jan: driverStatsModalKey + "january",
    Feb: driverStatsModalKey + "february",
    Mar: driverStatsModalKey + "march",
    Apr: driverStatsModalKey + "april",
    May: driverStatsModalKey + "may",
    Jun: driverStatsModalKey + "june",
    Jul: driverStatsModalKey + "july",
    Aug: driverStatsModalKey + "august",
    Sep: driverStatsModalKey + "september",
    Oct: driverStatsModalKey + "october",
    Nov: driverStatsModalKey + "november",
    Dec: driverStatsModalKey + "december",
    Sun: driverStatsModalKey + "sunday",
    Mon: driverStatsModalKey + "monday",
    Tue: driverStatsModalKey + "tuesday",
    Wed: driverStatsModalKey + "wednesday",
    Thu: driverStatsModalKey + "thursday",
    Fri: driverStatsModalKey + "friday",
    Sat: driverStatsModalKey + "saturday",
  };

  return dateMonthObject[value];
};

export const getFormattedDate = (dateObj) => {
  const day = dateObj.getDay();
  const date = dateObj.getDate();
  const month = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const hour = dateObj.getHours();
  const minutes = dateObj.getMinutes();

  const dayStrings = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const monthStrings = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = date <= 9 ? `0${date}` : date;
  const formattedHour = hour <= 9 ? `0${hour}` : hour;
  const formattedMinutes = minutes <= 9 ? `0${minutes}` : minutes;
  const formattedDay = getTranslations(
    getDayMonthTranslationKey(dayStrings[day]),
    dayStrings[day]
  );
  const formattedMonth = getTranslations(
    getDayMonthTranslationKey(monthStrings[month]),
    monthStrings[month]
  );

  return `${formattedDay}, ${formattedDate} ${formattedMonth}, ${year} ${formattedHour}:${formattedMinutes}`;
};

export const getFormattedMonth = (dateObj) => {
  const month = dateObj.getMonth();

  const monthStrings = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedMonth = getTranslations(
    getDayMonthTranslationKey(monthStrings[month]),
    monthStrings[month]
  );

  return formattedMonth;
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getDateISO8601Format = (dateString) => {
  if (!dateString) {
    return new Date();
  }
  let dateParts = dateString?.split(" ");
  let datePart = dateParts?.[0]?.split("/");
  const [month, date, year] = datePart;
  const formattedDatePart = `${year}-${month}-${date}`;
  let timePart = dateParts?.[1];
  let isoDateString = formattedDatePart + "T" + timePart;
  return isoDateString;
};

export const disableEmojiInput = (value) => {
  // Replace any emoji characters with an empty string
  value = value.replace(emojiRegex(), "");
  // Set the input value to the sanitized value
  return value;
};

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const setError = (retVal) => {
  return retVal
    ? getTranslations(`retval_${retVal}`, "")
    : getTranslations("error_page_title", "Something went wrong");
};

export const getObjectsFirstItem = (object) => {
  if (typeof object !== "object") return false;
  return Object.keys(object)?.length ? Object.keys(object)[0] : false;
};

export const getWebviewLink = () => {
  let routes = window.location.pathname.split("/");
  routes.splice(2, 0, "webview");
  return window.location.origin + routes.join("/");
};

export const toNumber = (number) => {
  return isNaN(Number(number)) ? 0 : Number(number);
};

export const multiply = (number, multiplyBy) => {
  return toNumber(number) * multiplyBy;
};

export const getLeagueTypeForDL = (type = undefined) => {
  if (type === "h2h") {
    return "Head 2 Head";
  } else if (type === "private") {
    return "Private";
  } else if (
    type === "public" ||
    type === "team" ||
    type === "global" ||
    type === "country" ||
    type === "driver"
  ) {
    return "Public";
  } else if (type === "mini") {
    return "Mini League";
  } else {
    return "Not Available";
  }
};

export const getSubscriptionPackages = (packages, type) => {
  const allSubscriptions = packages?.split(",");

  if (type === ADDITIONAL_SUBSCRIPTIONS_TYPE) {
    const additionalSubscriptionsList = [];

    allSubscriptions?.forEach((subscription) => {
      if (subscription === F1_TV_PRO_ANNUAL) {
        additionalSubscriptionsList.push(PRO_ANNUAL);
      }
      if (subscription === F1_TV_PRO_MONTHLY) {
        additionalSubscriptionsList.push(PRO_MONTHLY);
      }
      if (subscription === F1_TV_ACCESS_ANNUAL) {
        additionalSubscriptionsList.push(ACCESS_ANNUAL);
      }
      if (subscription === F1_TV_ACCESS_MONTHLY) {
        additionalSubscriptionsList.push(ACCESS_MONTHLY);
      }
    });

    return additionalSubscriptionsList?.join(", ");
  }

  if (type === SUBSCRIPTION_PACKAGE_TYPE) {
    if (allSubscriptions?.length === 1) {
      return packages;
    }

    if (allSubscriptions?.includes(F1_TV_PRO_ANNUAL)) {
      return F1_TV_PRO_ANNUAL;
    }

    if (allSubscriptions?.includes(F1_TV_PRO_MONTHLY)) {
      return F1_TV_PRO_MONTHLY;
    }

    if (allSubscriptions?.includes(F1_TV_ACCESS_ANNUAL)) {
      return F1_TV_ACCESS_ANNUAL;
    }

    if (allSubscriptions?.includes(F1_TV_ACCESS_MONTHLY)) {
      return F1_TV_ACCESS_MONTHLY;
    }
  }

  return packages;
};

export const convertCamelCase = (str = "") => {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const camelCaseMiniLeagueDL = (miniLeague = "") => {
  const camelCaseValue = convertCamelCase(miniLeague);

  if (camelCaseValue?.endsWith("League")) {
    return camelCaseValue?.substring(0, camelCaseValue.length - 6);
  }

  return camelCaseValue;
};

export const getPrize = (prizePosition = "") => {
  let prize = "";

  if (prizePosition?.includes("1")) {
    prize = "1st Prize";
  } else if (prizePosition?.includes("2")) {
    prize = "2nd Prize";
  } else if (prizePosition?.includes("3")) {
    prize = "3rd Prize";
  }

  return prize;
};

export const isIOSSafari = () => {
  const ua = window?.navigator?.userAgent;
  const iOS = ua?.match(/iPad/i) || ua?.match(/iPhone/i);
  const webkit = ua?.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua?.match(/CriOS/i);

  return iOSSafari;
};

export const getWebPurifyStatus = (
  isWebPurifyCalled,
  isSystemNameUpd,
  webPurifyResponse
) => {
  if (isWebPurifyCalled === 0 && isSystemNameUpd === 0) {
    return PROFANE_AND_PROVISIONAL;
  } else if (isWebPurifyCalled === 1 && isSystemNameUpd === 1) {
    return UPDATED_BY_SYSTEM;
  } else {
    return null;
  }
};

const userGameDaysKeys = {
  cugdid: "CuGdid",
  extradrstakengd: "extraDrstakengd",
  finalfixtakengd: "finalFixtakengd",
  ftgdid: "ftGdid",
  ftmdid: "ftMdid",
  isautopilottaken: "isAutopilottaken",
  isautopilottakengd: "isAutopilottakengd",
  isextradrstaken: "isExtradrstaken",
  isfinalfixtaken: "isFinalfixtaken",
  islastday: "isLastday",
  islateonboard: "islateonboard",
  islimitlesstaken: "isLimitlesstaken",
  isnonigativetaken: "isNonigativetaken",
  issystemnameupd: "isSystemnameupd",
  iswebpurifycalled: "isWebpurifycalled",
  iswildcardtaken: "isWildcardtaken",
  lastdaygdid: "lastDaygdid",
  limitlesstakengd: "limitLesstakengd",
  mddetails: "mdDetails",
  nonigativetakengd: "noNigativetakengd",
  teamcount: "teamCount",
  webpurifyresponse: "webPurifyresponse",
  wildcardtakengd: "wildCardtakengd",
};

const formatObjectKeys = (object, replaceKeys) => {
  const newObj = { ...object };
  for (const key in replaceKeys) {
    const newKey = replaceKeys?.[key];

    if (key in object) {
      const value = object[key];
      newObj[newKey] = value;
    }
  }
  newObj.teamid = object?.teamid;
  newObj.teamno = object?.teamno;
  return newObj;
};

export const getUserGameDaysWithOldFormat = (userGameDays) => {
  const userGameDaysOldWithFormat = {};

  const teamOne = 1,
    teamTwo = 2,
    teamThree = 3;

  if (teamOne in userGameDays) {
    userGameDaysOldWithFormat[teamOne] = formatObjectKeys(
      userGameDays[teamOne],
      userGameDaysKeys
    );
  }
  if (teamTwo in userGameDays) {
    userGameDaysOldWithFormat[teamTwo] = formatObjectKeys(
      userGameDays[teamTwo],
      userGameDaysKeys
    );
  }
  if (teamThree in userGameDays) {
    userGameDaysOldWithFormat[teamThree] = formatObjectKeys(
      userGameDays[teamThree],
      userGameDaysKeys
    );
  }

  return userGameDaysOldWithFormat;
};

export const getChartData = (userGameDays, fixturesList, boostersValue) => {
  const userGameDaysV1 = getUserGameDaysWithOldFormat(userGameDays);
  const gameDays = [];
  const userData = [];

  // Transform Object to Array of Objects
  for (const key in userGameDaysV1) {
    if (Object.hasOwnProperty.call(userGameDaysV1, key)) {
      const element = userGameDaysV1[key];
      gameDays.push(element);
    }
  }

  gameDays.forEach((x, i) => {
    for (const key in x.mdDetails) {
      if (Object.hasOwnProperty.call(x.mdDetails, key)) {
        const element = x.mdDetails[key];
        const location = fixturesList?.find(
          (x) => x.Gameday === key
        ).CircuitLocation;
        const MeetingNumber = fixturesList?.find(
          (x) => x.Gameday === key
        ).MeetingNumber;
        const index = userData.findIndex((x) => x.gameDayID === MeetingNumber);
        const teamNo = i + 1;
        if (index === -1) {
          userData.push({
            ...element,
            gameDayID: MeetingNumber,
            name: location,
            ["points" + teamNo]: element.pts,
            ["hasBooster" + teamNo]: hasTakenBooster(
              boostersValue,
              fixturesList?.find((x) => x.Gameday === key).GamedayId,
              x
            ),
          });
        } else {
          userData[index]["points" + teamNo] = element.pts;
          userData[index]["hasBooster" + teamNo] = hasTakenBooster(
            boostersValue,
            fixturesList?.find((x) => x.Gameday === key).GamedayId,
            x
          );
        }
      }
    }
  });

  const filteredUserData = userData?.filter((item) => item?.mds === 3);

  return filteredUserData;
};

export const getBoosterCategory = (BoosterType) => {
  switch (BoosterType) {
    case "autoPilot":
      return {
        boosterCls: "si-auto-pilot",
        boosterTitle: "Auto Pilot",
        boosterIcon: "f1i-auto-pilot",
        mostChip: {
          cnt: 268392,
        },
      };
    case "wildcard":
      return {
        boosterCls: "si-wildcard",
        boosterTitle: "Wildcard",
        boosterIcon: "f1i-wildcard",
      };
    case "extraDRS":
      return {
        boosterCls: "si-extra-drs",
        boosterTitle: "Extra DRS",
        boosterIcon: "f1i-extra-drs",
      };
    case "finalFix":
      return {
        boosterCls: "si-final-fix",
        boosterTitle: "Final Fix",
        boosterIcon: "f1i-final-fix",
      };
    case "limitless":
      return {
        boosterCls: "si-limitless",
        boosterTitle: "Limitless",
        boosterIcon: "f1i-limitless",
      };
    case "noNegative":
      return {
        boosterCls: "si-no-negative",
        boosterTitle: "No Negative",
        boosterIcon: "f1i-no-negative",
      };
    default:
      return "";
  }
};

export const splitName = (fullName) => {
  try {
    const [firstName, ...lastName] = fullName.split(" ").filter(Boolean);
    return {
      firstName: firstName,
      lastName: lastName.join(" "),
    };
  } catch (error) {
    return {
      firstName: "",
      lastName: fullName,
    };
  }
};

export const getYearForImagePath = (webConfigData, seasonYear = null) => {
  const currentSeasonYear =
    webConfigData?.myTeam?.completedRaces?.seasonYears?.find(
      (item) => item?.isCurrentYear
    )?.value;

  if (seasonYear) {
    return seasonYear;
  }

  return currentSeasonYear;
};

const SessionForRaceCategory = {
  Q: "Qualifying",
  S: "Sprint Qualifying",
  R: "Race",
};

const getSessionIndex = (SessionWisePointsData, raceCategory) => {
  const sessionIndex = SessionWisePointsData?.findIndex(
    (item) => item?.sessiontype === SessionForRaceCategory?.[raceCategory]
  );

  return sessionIndex;
};

const getFinalFixPoints = ({
  SessionWisePoints,
  playerSessionIndex,
  playerType = "OLD",
  isCaptainReplaced,
}) => {
  const playerRaceweekPointsData =
    playerType === "OLD"
      ? SessionWisePoints?.slice(0, playerSessionIndex)
      : SessionWisePoints?.slice(playerSessionIndex);

  const playerPoints = playerRaceweekPointsData?.reduce(
    (totalPoints, currentRaceWeekObj) => {
      return totalPoints + toNumber(currentRaceWeekObj?.points);
    },
    0
  );

  // * If final fix player is caption
  if (isCaptainReplaced) {
    return 2 * playerPoints;
  }
  return playerPoints;
};

export const playerPoints = ({ player, team, oppDetailedTeams }) => {
  const selectedTurboDriver = team?.team?.find((player) => player?.isCaptain);
  const selectedExtraTurboDriver = team?.team?.find(
    (player) => player?.isExtraDRS
  );
  const isFinalFix = team?.boosterid === 3;

  const SessionWisePoints = player?.SessionWisePoints;
  let key = "GamedayPoints";

  if (team?.boosterid === 5) {
    return player?.isCaptain
      ? 2 * Number(player?.NoNegativePoints)
      : Number(player?.NoNegativePoints);
  }

  // Player points for Final Fix
  if (isFinalFix) {
    // For Old Player

    const teamFinalFixRaceCategory = team?.finalfxracecat;

    const playerSessionIndex = getSessionIndex(
      SessionWisePoints,
      teamFinalFixRaceCategory
    );

    // * For Old player
    if (player?.isFinalFixOldPlayer) {
      return getFinalFixPoints({
        SessionWisePoints,
        playerSessionIndex,
        playerType: "OLD",
        isCaptainReplaced:
          team?.selectedCaptain?.PlayerId === team?.finalfxnewplayerid,
      });
    }
    // * For New Player
    if (player?.isFinalFixNewPlayer) {
      return getFinalFixPoints({
        SessionWisePoints,
        playerSessionIndex,
        playerType: "NEW",
        isCaptainReplaced:
          team?.selectedCaptain?.PlayerId === team?.finalfxnewplayerid,
      });
    }
  }

  // Player points for Late onboard
  if (team?.islateonboard) {
    const teamRaceCategory = team?.race_category;
    const lateOnboardSessionIndex = getSessionIndex(
      SessionWisePoints,
      teamRaceCategory
    );
    if (lateOnboardSessionIndex > 0) {
      const playerLateOnboardPointsData = SessionWisePoints?.slice(
        lateOnboardSessionIndex
      );

      const playerLateOnboardPoints = playerLateOnboardPointsData?.reduce(
        (totalPoints, currentRaceWeekObj) => {
          return totalPoints + toNumber(currentRaceWeekObj?.points);
        },
        0
      );

      if (
        selectedTurboDriver?.PlayerId === player?.PlayerId ||
        player?.isCaptain
      ) {
        return 2 * playerLateOnboardPoints;
      }

      return playerLateOnboardPoints;
    }
  }

  if (isNaN(Number(player?.[key])) || player?.[key] === "") {
    return "-";
  }

  // Player points for turbo driver
  if (
    !isNaN(Number(player?.[key])) &&
    (selectedTurboDriver?.PlayerId === player?.PlayerId || player?.isCaptain)
  ) {
    return Number(player?.[key]) * 2;
  }

  // Player points for extra turbo driver
  if (
    (!isNaN(Number(player?.[key])) &&
      selectedExtraTurboDriver?.PlayerId === player?.PlayerId) ||
    player?.isExtraDRS
  ) {
    return Number(player?.[key]) * 3;
  }
  return Number(player?.[key]);
};

export const getMiniLeagueBadgeId = (disableListValue, leagueItemKey) => {
  switch (disableListValue) {
    case 0:
      return leagueItemKey;
    case 1:
      return leagueItemKey;
    case 2:
      return 4;
    default:
      return null;
  }
};

export function isTruthyOrZero(value) {
  return value || value === 0;
}
