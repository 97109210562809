import React from "react";
import { getTranslations } from "../../Common/utils";

const PvtPubCardChips = ({ PvtPubCardChipsNameConfig }) => {
  const PvtPubCardChipsClassName = [
    "si-classic",
    "si-h2h",
    "si-public",
    "si-vip",
    "si-mini",
  ];
  const PvtPubCardChipsName = [
    getTranslations("create_league_popup_private_league_badge", "Private"),

    getTranslations("create_league_popup_h2h_league_badge", "H2H"),

    getTranslations("create_league_popup_public_league_badge", "Public"),

    getTranslations("create_league_popup_vip_league_badge", "VIP"),

    getTranslations("create_league_popup_mini_league_badge", "Mini"),
  ];
  return (
    <>
      <div
        className={`si-league__tag ${PvtPubCardChipsClassName[PvtPubCardChipsNameConfig]}`}
      >
        <span>{PvtPubCardChipsName[PvtPubCardChipsNameConfig]}</span>
      </div>
    </>
  );
};

export { PvtPubCardChips };
