export const FETCH_OVERALL_EOS_STATS = "FETCH_OVERALL_EOS_STATS";
export const FETCH_OVERALL_EOS_STATS_PENDING =
  "FETCH_OVERALL_EOS_STATS_PENDING";
export const FETCH_OVERALL_EOS_STATS_REJECTED =
  "FETCH_OVERALL_EOS_STATS_REJECTED";
export const FETCH_OVERALL_EOS_STATS_FULFILLED =
  "FETCH_OVERALL_EOS_STATS_FULFILLED";

export const FETCH_USER_EOS_STATS = "FETCH_USER_EOS_STATS";
export const FETCH_USER_EOS_STATS_PENDING = "FETCH_USER_EOS_STATS_PENDING";
export const FETCH_USER_EOS_STATS_REJECTED = "FETCH_USER_EOS_STATS_REJECTED";
export const FETCH_USER_EOS_STATS_FULFILLED = "FETCH_USER_EOS_STATS_FULFILLED";

export const FETCH_EOS_CONFIG = "FETCH_EOS_CONFIG";
export const FETCH_EOS_CONFIG_PENDING = "FETCH_EOS_CONFIG_PENDING";
export const FETCH_EOS_CONFIG_REJECTED = "FETCH_EOS_CONFIG_REJECTED";
export const FETCH_EOS_CONFIG_FULFILLED = "FETCH_EOS_CONFIG_FULFILLED";
