import React from "react";

const AffordableToggleSwitch = ({
  toggleLabelText,
  handleChecked,
  checked,
  leagueType,
  disabled = false,
}) => {
  return (
    <>
      <div
        className={`si-affordable__toggle ${
          leagueType == "public" || leagueType == "h2h" ? "si-disabled" : ""
        }`}
      >
        <div
          className="si-radioBtn"
          role="switch"
          aria-checked={checked}
          aria-label={toggleLabelText}
        >
          <div className="si-radioBtn__wrap">
            <input
              type="checkbox"
              name="radio-toggle"
              // value={checked}
              disabled={
                leagueType == "public" || leagueType == "h2h" || disabled
              }
              checked={checked}
              onChange={(e) => handleChecked(e.target.checked)}
            />
            <div className="si-radioBtn__lbl">
              <span>{toggleLabelText}</span>
            </div>
            <div className="si-radioBtn__action">
              <span className="si-radioBtn__toggle"></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AffordableToggleSwitch };
