import {
  FETCH_SEASON_FEATURED_MINI_LEAGUE,
  FETCH_SEASON_HOMEPAGE_STATS,
  FETCH_USER_HOMEPAGE_STATS,
} from "../../constants/homepage";
import { feeds, cards } from "../../apis/services";

export const getSeasonHomepageStats = (params) => ({
  type: FETCH_SEASON_HOMEPAGE_STATS,
  payload: feeds.getHomepageStats(params),
});

export const getUserHomepageStats = (params) => ({
  type: FETCH_USER_HOMEPAGE_STATS,
  payload: cards.getUserHomepageStats(params),
});

export const getSeasonFeaturedMiniLeague = (params) => ({
  type: FETCH_SEASON_FEATURED_MINI_LEAGUE,
  payload: cards.getSeasonFeaturedMiniLeagueData(params),
});
