const statisticsTableEventData = new Map();

statisticsTableEventData.set(
  "Overall Points",
  `driver_constructor_card_overall_points`
);
statisticsTableEventData.set(
  "Race Position",
  `driver_constructor_card_race_position`
);

statisticsTableEventData.set(
  "Qualifying Position",
  `driver_constructor_card_qualifying_position`
);

statisticsTableEventData.set(
  "Sprint Position",
  `driver_constructor_card_sprint_position`
);

statisticsTableEventData.set(
  "Race not classified ",
  `driver_constructor_card_race_not_classified`
);
statisticsTableEventData.set(
  "QF not classified ",
  `driver_constructor_qf_not_classified`
);
statisticsTableEventData.set("DQ Race", `driver_constructor_card_dq_race`);
statisticsTableEventData.set("DQ QF", `driver_constructor_card_dq_qf`);
statisticsTableEventData.set("DQ Sprint", `driver_constructor_card_dq_sprint`);
statisticsTableEventData.set(
  "Both Driver Q3",
  `driver_constructor_card_both_driver_q3`
);
statisticsTableEventData.set(
  "One Driver Q3",
  `driver_constructor_card_one_driver_q3`
);
statisticsTableEventData.set(
  "Both Driver Q2",
  `driver_constructor_card_both_driver_q2`
);
statisticsTableEventData.set(
  "One Driver Q2",
  `driver_constructor_card_one_driver_q2`
);
statisticsTableEventData.set(
  "No Driver Q2",
  `driver_constructor_card_no_driver_q2`
);
statisticsTableEventData.set(
  "Sprint position gained",
  `driver_constructor_card_sprint_position_gained`
);
statisticsTableEventData.set(
  "Sprint Position lost",
  `driver_constructor_card_sprint_position_lost`
);
statisticsTableEventData.set(
  "Race Fastest lap",
  `driver_constructor_card_race_fastest_lap`
);
statisticsTableEventData.set(
  "Driver Of Day",
  `driver_constructor_card_driver_of_day`
);
statisticsTableEventData.set(
  "Sprint overtake bonus",
  `driver_constructor_card_sprint_overtake_bonus`
);
statisticsTableEventData.set(
  "Race position gained",
  `driver_constructor_card_race_position_gained`
);
statisticsTableEventData.set(
  "Race Position lost",
  `driver_constructor_card_race_position_lost`
);
statisticsTableEventData.set(
  "Race Fastest lap",
  `driver_constructor_card_race_fastest_lap`
);
statisticsTableEventData.set(
  "Fastest Pitstop",
  `driver_constructor_card_fastest_pitstop`
);
statisticsTableEventData.set(
  "2nd Fastest Pitstop",
  `driver_constructor_2nd_fastest_pitstop`
);
statisticsTableEventData.set(
  "3rd Fastest Pitstop",
  `driver_constructor_3rd_fastest_pitstop`
);
statisticsTableEventData.set(
  "race overtake bonus",
  `driver_constructor_race_overtake_bonus`
);

export { statisticsTableEventData };

export function getPriceTrendClass(type, item, key) {
  if (type === "price trend" && item?.[key] < 0) {
    return "si-down";
  } else if (type === "price trend" && item?.[key] > 0) {
    return "si-up";
  } else {
    return "";
  }
}

export function formatStatValue(type, item, key) {
  if (type === "price" || type === "price trend") {
    return `$${Math.abs(item?.[key])}M`;
  } else if (type === "percentage") {
    return `${item?.[key]}%`;
  } else {
    return `${item?.[key]}`;
  }
}
