import React from "react";

const SectionHeadingWithCount = ({ extraCls, heading, count }) => {
  return (
    <>
      <h3 className={`si-secHeadCount__title ${extraCls ? extraCls : ""}`}>
        <span>{heading}</span>
        {count ? <em>{`(${count})`}</em> : ""}
      </h3>
    </>
  );
};

export { SectionHeadingWithCount };
