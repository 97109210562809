import {
  FETCH_CIRCUIT_CONFIGURATIONS_PENDING,
  FETCH_CIRCUIT_CONFIGURATIONS_REJECTED,
  FETCH_CIRCUIT_CONFIGURATIONS_FULFILLED,
} from "../../constants/circuit";

const circuit = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CIRCUIT_CONFIGURATIONS_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_CIRCUIT_CONFIGURATIONS_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_CIRCUIT_CONFIGURATIONS_FULFILLED:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload.data,
      };
    default:
      return state;
  }
};
export default circuit;
