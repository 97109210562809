import React from "react";
import { useSelector } from "react-redux";
import { getTranslations } from "../../Common/utils";
import { MAX_BUDGET } from "../../Common/constants";
import { isLimitless, infiniteIcon } from "../../Common/utils/team";

const PlayerBudget = (playerBudgetConfig) => {
  const gameplayState = useSelector((state) => state.gameplay);

  const budget = isNaN(playerBudgetConfig?.budget)
    ? gameplayState?.budget || 0
    : playerBudgetConfig?.budget;
  const {
    hideBugdetProgress = false,
    playerBudgetLabelKey = "create_team_cost_cap",
    showWarning = false,
    index = undefined,
    isLimitless = false,
  } = playerBudgetConfig;

  return (
    <>
      <section
        className="si-budgetBar"
        aria-label={getTranslations("create_team_budget_desc", "").replace(
          "{{REMAINING_AMOUNT}}",
          MAX_BUDGET - budget
        )}
      >
        <label htmlFor={`budget-progress`}>
          <span>{getTranslations(playerBudgetLabelKey, "Cost Cap")}:</span>
          {isLimitless ? (
            <em
              dangerouslySetInnerHTML={{
                __html: infiniteIcon(gameplayState),
              }}
            ></em>
          ) : (
            <em>
              ${budget?.toFixed(1)}M{" "}
              {showWarning ? <i className="f1i-error si-negative"></i> : ""}
            </em>
          )}
        </label>
        {hideBugdetProgress ? (
          ""
        ) : (
          <div className="si-budgetBar__progress">
            <progress
              id={`budget-progress`}
              value={isLimitless ? MAX_BUDGET : budget}
              max={MAX_BUDGET}
              // aria-valuemin="0"
              // aria-valuemax="100"
            ></progress>
            <span
              style={{
                width: `${(isLimitless ? MAX_BUDGET : budget) * 1.4}px`,
              }}
            ></span>
          </div>
        )}
      </section>
    </>
  );
};

export { PlayerBudget };
