export const FETCH_USER_PRIVATE_LEAGUES = "FETCH_USER_PRIVATE_LEAGUES";
export const FETCH_USER_PRIVATE_LEAGUES_PENDING =
  "FETCH_USER_PRIVATE_LEAGUES_PENDING";
export const FETCH_USER_PRIVATE_LEAGUES_REJECTED =
  "FETCH_USER_PRIVATE_LEAGUES_REJECTED";
export const FETCH_USER_PRIVATE_LEAGUES_FULFILLED =
  "FETCH_USER_PRIVATE_LEAGUES_FULFILLED";

export const FETCH_USER_PUBLIC_LEAGUES = "FETCH_USER_PUBLIC_LEAGUES";
export const FETCH_USER_PUBLIC_LEAGUES_PENDING =
  "FETCH_USER_PUBLIC_LEAGUES_PENDING";
export const FETCH_USER_PUBLIC_LEAGUES_REJECTED =
  "FETCH_USER_PUBLIC_LEAGUES_REJECTED";
export const FETCH_USER_PUBLIC_LEAGUES_FULFILLED =
  "FETCH_USER_PUBLIC_LEAGUES_FULFILLED";

export const FETCH_TOP_PUBLIC_LEAGUES = "FETCH_TOP_PUBLIC_LEAGUES";
export const FETCH_TOP_PUBLIC_LEAGUES_PENDING =
  "FETCH_TOP_PUBLIC_LEAGUES_PENDING";
export const FETCH_TOP_PUBLIC_LEAGUES_REJECTED =
  "FETCH_TOP_PUBLIC_LEAGUES_REJECTED";
export const FETCH_TOP_PUBLIC_LEAGUES_FULFILLED =
  "FETCH_TOP_PUBLIC_LEAGUES_FULFILLED";
export const RESET_TOP_PUBLIC_LEAGUES = "RESET_TOP_PUBLIC_LEAGUES";

export const FETCH_USER_MINI_LEAGUES = "FETCH_USER_MINI_LEAGUES";
export const FETCH_USER_MINI_LEAGUES_PENDING =
  "FETCH_USER_MINI_LEAGUES_PENDING";
export const FETCH_USER_MINI_LEAGUES_REJECTED =
  "FETCH_USER_MINI_LEAGUES_REJECTED";
export const FETCH_USER_MINI_LEAGUES_FULFILLED =
  "FETCH_USER_MINI_LEAGUES_FULFILLED";

export const TOGGLE_CREATE_LEAGUE_POPUP = "TOGGLE_CREATE_LEAGUE_POPUP";
export const TOGGLE_JOIN_LEAGUE_POPUP = "TOGGLE_JOIN_LEAGUE_POPUP";
export const TOGGLE_SELECT_TEAM = "TOGGLE_SELECT_TEAM";
export const TOGGLE_SUCCESS_JOIN_LEAGUE_POPUP =
  "TOGGLE_SUCCESS_JOIN_LEAGUE_POPUP";
export const TOGGLE_SHARE_LEAGUE_POPUP = "TOGGLE_SHARE_LEAGUE_POPUP";
export const TOGGLE_JOIN_LEAGUE_CONFIRM_POPUP =
  "TOGGLE_JOIN_LEAGUE_CONFIRM_POPUP";
export const TOGGLE_COPY_POPUP = "TOGGLE_COPY_POPUP";
export const TOGGLE_REMOVE_MEMBER_POPUP = "TOGGLE_REMOVE_MEMBER_POPUP";

export const SET_CREATE_LEAGUE_DATA = "SET_CREATE_LEAGUE_DATA";
export const SET_CREATE_LEAGUE_TEAMS = "SET_CREATE_LEAGUE_TEAMS";
export const RESET_CREATE_LEAGUE_TEAMS = "RESET_CREATE_LEAGUE_TEAMS";

export const CREATE_PRIVATE_LEAGUE = "CREATE_PRIVATE_LEAGUE";
export const CREATE_PRIVATE_LEAGUE_PENDING = "CREATE_PRIVATE_LEAGUE_PENDING";
export const CREATE_PRIVATE_LEAGUE_REJECTED = "CREATE_PRIVATE_LEAGUE_REJECTED";
export const CREATE_PRIVATE_LEAGUE_FULFILLED =
  "CREATE_PRIVATE_LEAGUE_FULFILLED";
export const RESET_CREATE_PRIVATE_LEAGUE = "RESET_CREATE_PRIVATE_LEAGUE";
export const RESET_CREATE_PRIVATE_LEAGUE_ERRORS =
  "RESET_CREATE_PRIVATE_LEAGUE_ERRORS";
export const SET_MAX_TEAMS = "SET_MAX_TEAMS";

export const JOIN_PRIVATE_LEAGUE = "JOIN_PRIVATE_LEAGUE";
export const JOIN_PRIVATE_LEAGUE_PENDING = "JOIN_PRIVATE_LEAGUE_PENDING";
export const JOIN_PRIVATE_LEAGUE_REJECTED = "JOIN_PRIVATE_LEAGUE_REJECTED";
export const JOIN_PRIVATE_LEAGUE_FULFILLED = "JOIN_PRIVATE_LEAGUE_FULFILLED";
export const RESET_JOIN_PRIVATE_LEAGUE = "RESET_JOIN_PRIVATE_LEAGUE";

export const JOIN_H2H_LEAGUE = "JOIN_H2H_LEAGUE";
export const JOIN_H2H_LEAGUE_PENDING = "JOIN_H2H_LEAGUE_PENDING";
export const JOIN_H2H_LEAGUE_REJECTED = "JOIN_H2H_LEAGUE_REJECTED";
export const JOIN_H2H_LEAGUE_FULFILLED = "JOIN_H2H_LEAGUE_FULFILLED";
export const RESET_JOIN_H2H_LEAGUE = "RESET_JOIN_H2H_LEAGUE";

export const JOIN_PUBLIC_LEAGUE = "JOIN_PUBLIC_LEAGUE";
export const JOIN_PUBLIC_LEAGUE_PENDING = "JOIN_PUBLIC_LEAGUE_PENDING";
export const JOIN_PUBLIC_LEAGUE_REJECTED = "JOIN_PUBLIC_LEAGUE_REJECTED";
export const JOIN_PUBLIC_LEAGUE_FULFILLED = "JOIN_PUBLIC_LEAGUE_FULFILLED";
export const RESET_JOIN_PUBLIC_LEAGUE = "RESET_JOIN_PUBLIC_LEAGUE";

export const JOIN_MINI_LEAGUE = "JOIN_MINI_LEAGUE";
export const JOIN_MINI_LEAGUE_PENDING = "JOIN_MINI_LEAGUE_PENDING";
export const JOIN_MINI_LEAGUE_REJECTED = "JOIN_MINI_LEAGUE_REJECTED";
export const JOIN_MINI_LEAGUE_FULFILLED = "JOIN_MINI_LEAGUE_FULFILLED";
export const RESET_JOIN_MINI_LEAGUE = "RESET_JOIN_MINI_LEAGUE";

export const FETCH_LEAGUE_INFO = "FETCH_LEAGUE_INFO";
export const FETCH_LEAGUE_INFO_PENDING = "FETCH_LEAGUE_INFO_PENDING";
export const FETCH_LEAGUE_INFO_REJECTED = "FETCH_LEAGUE_INFO_REJECTED";
export const FETCH_LEAGUE_INFO_FULFILLED = "FETCH_LEAGUE_INFO_FULFILLED";
export const RESET_LEAGUE_INFO = "RESET_LEAGUE_INFO";

export const GET_LEAGUE_MEMBERS = "GET_LEAGUE_MEMBERS";
export const GET_LEAGUE_MEMBERS_PENDING = "GET_LEAGUE_MEMBERS_PENDING";
export const GET_LEAGUE_MEMBERS_REJECTED = "GET_LEAGUE_MEMBERS_REJECTED";
export const GET_LEAGUE_MEMBERS_FULFILLED = "GET_LEAGUE_MEMBERS_FULFILLED";
export const RESET_LEAGUE_MEMBERS = "RESET_LEAGUE_MEMBERS";

export const GET_LEAGUE_MEMBERS_FOR_MANAGER = "GET_LEAGUE_MEMBERS_FOR_MANAGER";
export const GET_LEAGUE_MEMBERS_FOR_MANAGER_PENDING =
  "GET_LEAGUE_MEMBERS_FOR_MANAGER_PENDING";
export const GET_LEAGUE_MEMBERS_FOR_MANAGER_REJECTED =
  "GET_LEAGUE_MEMBERS_FOR_MANAGER_REJECTED";
export const GET_LEAGUE_MEMBERS_FOR_MANAGER_FULFILLED =
  "GET_LEAGUE_MEMBERS_FOR_MANAGER_FULFILLED";

export const SEARCH_PUBLIC_LEAGUES = "SEARCH_PUBLIC_LEAGUES";
export const SEARCH_PUBLIC_LEAGUES_PENDING = "SEARCH_PUBLIC_LEAGUES_PENDING";
export const SEARCH_PUBLIC_LEAGUES_REJECTED = "SEARCH_PUBLIC_LEAGUES_REJECTED";
export const SEARCH_PUBLIC_LEAGUES_FULFILLED =
  "SEARCH_PUBLIC_LEAGUES_FULFILLED";

export const SEARCH_MINI_LEAGUES = "SEARCH_MINI_LEAGUES";
export const SEARCH_MINI_LEAGUES_PENDING = "SEARCH_MINI_LEAGUES_PENDING";
export const SEARCH_MINI_LEAGUES_REJECTED = "SEARCH_MINI_LEAGUES_REJECTED";
export const SEARCH_MINI_LEAGUES_FULFILLED = "SEARCH_MINI_LEAGUES_FULFILLED";
export const RESET_SEARCH_MINI_LEAGUES = "RESET_SEARCH_MINI_LEAGUES";

export const SEARCH_PRIVATE_LEAGUES = "SEARCH_PRIVATE_LEAGUES";
export const SEARCH_PRIVATE_LEAGUES_PENDING = "SEARCH_PRIVATE_LEAGUES_PENDING";
export const SEARCH_PRIVATE_LEAGUES_REJECTED =
  "SEARCH_PRIVATE_LEAGUES_REJECTED";
export const SEARCH_PRIVATE_LEAGUES_FULFILLED =
  "SEARCH_PRIVATE_LEAGUES_FULFILLED";

export const SEARCH_H2H_LEAGUES = "SEARCH_H2H_LEAGUES";
export const SEARCH_H2H_LEAGUES_PENDING = "SEARCH_H2H_LEAGUES_PENDING";
export const SEARCH_H2H_LEAGUES_REJECTED = "SEARCH_H2H_LEAGUES_REJECTED";
export const SEARCH_H2H_LEAGUES_FULFILLED = "SEARCH_H2H_LEAGUES_FULFILLED";

export const SEARCH_VIP_LEAGUES = "SEARCH_VIP_LEAGUES";
export const SEARCH_VIP_LEAGUES_PENDING = "SEARCH_VIP_LEAGUES_PENDING";
export const SEARCH_VIP_LEAGUES_REJECTED = "SEARCH_VIP_LEAGUES_REJECTED";
export const SEARCH_VIP_LEAGUES_FULFILLED = "SEARCH_VIP_LEAGUES_FULFILLED";
export const RESET_SEARCH_LEAGUES = "RESET_SEARCH_LEAGUES";

export const SEARCH_USER_JOINED_PUBLIC_LEAGUES =
  "SEARCH_USER_JOINED_PUBLIC_LEAGUES";
export const SEARCH_USER_JOINED_PUBLIC_LEAGUES_PENDING =
  "SEARCH_USER_JOINED_PUBLIC_LEAGUES_PENDING";
export const SEARCH_USER_JOINED_PUBLIC_LEAGUES_REJECTED =
  "SEARCH_USER_JOINED_PUBLIC_LEAGUES_REJECTED";
export const SEARCH_USER_JOINED_PUBLIC_LEAGUES_FULFILLED =
  "SEARCH_USER_JOINED_PUBLIC_LEAGUES_FULFILLED";

export const FETCH_USER_CLASSIC_PRIVATE_LEAGUES =
  "FETCH_USER_CLASSIC_PRIVATE_LEAGUES";
export const FETCH_USER_CLASSIC_PRIVATE_LEAGUES_PENDING =
  "FETCH_USER_CLASSIC_PRIVATE_LEAGUES_PENDING";
export const FETCH_USER_CLASSIC_PRIVATE_LEAGUES_REJECTED =
  "FETCH_USER_CLASSIC_PRIVATE_LEAGUES_REJECTED";
export const FETCH_USER_CLASSIC_PRIVATE_LEAGUES_FULFILLED =
  "FETCH_USER_CLASSIC_PRIVATE_LEAGUES_FULFILLED";
export const RESET_USER_CLASSIC_PRIVATE_LEAGUES =
  "RESET_USER_CLASSIC_PRIVATE_LEAGUES";

export const FETCH_USER_VIP_PRIVATE_LEAGUES = "FETCH_USER_VIP_PRIVATE_LEAGUES";
export const FETCH_USER_VIP_PRIVATE_LEAGUES_PENDING =
  "FETCH_USER_VIP_PRIVATE_LEAGUES_PENDING";
export const FETCH_USER_VIP_PRIVATE_LEAGUES_REJECTED =
  "FETCH_USER_VIP_PRIVATE_LEAGUES_REJECTED";
export const FETCH_USER_VIP_PRIVATE_LEAGUES_FULFILLED =
  "FETCH_USER_VIP_PRIVATE_LEAGUES_FULFILLED";
export const RESET_USER_VIP_PRIVATE_LEAGUES = "RESET_USER_VIP_PRIVATE_LEAGUES";

export const FETCH_USER_H2H_PRIVATE_LEAGUES = "FETCH_USER_H2H_PRIVATE_LEAGUES";
export const FETCH_USER_H2H_PRIVATE_LEAGUES_PENDING =
  "FETCH_USER_H2H_PRIVATE_LEAGUES_PENDING";
export const FETCH_USER_H2H_PRIVATE_LEAGUES_REJECTED =
  "FETCH_USER_H2H_PRIVATE_LEAGUES_REJECTED";
export const FETCH_USER_H2H_PRIVATE_LEAGUES_FULFILLED =
  "FETCH_USER_H2H_PRIVATE_LEAGUES_FULFILLED";
export const RESET_USER_H2H_PRIVATE_LEAGUES = "RESET_USER_H2H_PRIVATE_LEAGUES";

export const FETCH_USER_JOINED_PUBLIC_LEAGUES =
  "FETCH_USER_JOINED_PUBLIC_LEAGUES";
export const FETCH_USER_JOINED_PUBLIC_LEAGUES_PENDING =
  "FETCH_USER_JOINED_PUBLIC_LEAGUES_PENDING";
export const FETCH_USER_JOINED_PUBLIC_LEAGUES_REJECTED =
  "FETCH_USER_JOINED_PUBLIC_LEAGUES_REJECTED";
export const FETCH_USER_JOINED_PUBLIC_LEAGUES_FULFILLED =
  "FETCH_USER_JOINED_PUBLIC_LEAGUES_FULFILLED";
export const RESET_USER_JOINED_PUBLIC_LEAGUES =
  "RESET_USER_JOINED_PUBLIC_LEAGUES";

export const FETCH_ALL_MINI_LEAGUES = "FETCH_ALL_MINI_LEAGUES";
export const FETCH_ALL_MINI_LEAGUES_PENDING = "FETCH_ALL_MINI_LEAGUES_PENDING";
export const FETCH_ALL_MINI_LEAGUES_REJECTED =
  "FETCH_ALL_MINI_LEAGUES_REJECTED";
export const FETCH_ALL_MINI_LEAGUES_FULFILLED =
  "FETCH_ALL_MINI_LEAGUES_FULFILLED";
export const RESET_ALL_MINI_LEAGUES = "RESET_ALL_MINI_LEAGUES";

export const RESET_LEAGUE_NAME_ERRORS = "RESET_LEAGUE_NAME_ERRORS";

export const UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME =
  "UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME";
export const UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_PENDING =
  "UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_PENDING";
export const UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_REJECTED =
  "UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_REJECTED";
export const UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_FULFILLED =
  "UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_FULFILLED";

export const UPDATE_H2H_PRIVATE_LEAGUE_NAME = "UPDATE_H2H_PRIVATE_LEAGUE_NAME";
export const UPDATE_H2H_PRIVATE_LEAGUE_NAME_PENDING =
  "UPDATE_H2H_PRIVATE_LEAGUE_NAME_PENDING";
export const UPDATE_H2H_PRIVATE_LEAGUE_NAME_REJECTED =
  "UPDATE_H2H_PRIVATE_LEAGUE_NAME_REJECTED";
export const UPDATE_H2H_PRIVATE_LEAGUE_NAME_FULFILLED =
  "UPDATE_H2H_PRIVATE_LEAGUE_NAME_FULFILLED";

export const UPDATE_PUBLIC_LEAGUE_NAME = "UPDATE_PUBLIC_LEAGUE_NAME";
export const UPDATE_PUBLIC_LEAGUE_NAME_PENDING =
  "UPDATE_PUBLIC_LEAGUE_NAME_PENDING";
export const UPDATE_PUBLIC_LEAGUE_NAME_REJECTED =
  "UPDATE_PUBLIC_LEAGUE_NAME_REJECTED";
export const UPDATE_PUBLIC_LEAGUE_NAME_FULFILLED =
  "UPDATE_PUBLIC_LEAGUE_NAME_FULFILLED";

export const DELETE_LEAGUE = "DELETE_LEAGUE";
export const DELETE_LEAGUE_PENDING = "DELETE_LEAGUE_PENDING";
export const DELETE_LEAGUE_REJECTED = "DELETE_LEAGUE_REJECTED";
export const DELETE_LEAGUE_FULFILLED = "DELETE_LEAGUE_FULFILLED";
export const RESET_DELETE_LEAGUE = "RESET_DELETE_LEAGUE";

export const DELETE_H2H_LEAGUE = "DELETE_H2H_LEAGUE";
export const DELETE_H2H_LEAGUE_PENDING = "DELETE_H2H_LEAGUE_PENDING";
export const DELETE_H2H_LEAGUE_REJECTED = "DELETE_H2H_LEAGUE_REJECTED";
export const DELETE_H2H_LEAGUE_FULFILLED = "DELETE_H2H_LEAGUE_FULFILLED";
export const RESET_DELETE_H2H_LEAGUE = "RESET_DELETE_H2H_LEAGUE";

export const REPORT_PRIVATE_LEAGUE = "REPORT_PRIVATE_LEAGUE";
export const REPORT_PRIVATE_LEAGUE_PENDING = "REPORT_PRIVATE_LEAGUE_PENDING";
export const REPORT_PRIVATE_LEAGUE_REJECTED = "REPORT_PRIVATE_LEAGUE_REJECTED";
export const REPORT_PRIVATE_LEAGUE_FULFILLED =
  "REPORT_PRIVATE_LEAGUE_FULFILLED";

export const REPORT_H2H_LEAGUE = "REPORT_H2H_LEAGUE";
export const REPORT_H2H_LEAGUE_PENDING = "REPORT_H2H_LEAGUE_PENDING";
export const REPORT_H2H_LEAGUE_REJECTED = "REPORT_H2H_LEAGUE_REJECTED";
export const REPORT_H2H_LEAGUE_FULFILLED = "REPORT_H2H_LEAGUE_FULFILLED";

export const REPORT_PUBLIC_LEAGUE = "REPORT_PUBLIC_LEAGUE";
export const REPORT_PUBLIC_LEAGUE_PENDING = "REPORT_PUBLIC_LEAGUE_PENDING";
export const REPORT_PUBLIC_LEAGUE_REJECTED = "REPORT_PUBLIC_LEAGUE_REJECTED";
export const REPORT_PUBLIC_LEAGUE_FULFILLED = "REPORT_PUBLIC_LEAGUE_FULFILLED";
export const RESET_REPORT_LEAGUE = "RESET_REPORT_LEAGUE";

export const REMOVE_LEAGUE_MEMBER = "REMOVE_LEAGUE_MEMBER";
export const REMOVE_LEAGUE_MEMBER_PENDING = "REMOVE_LEAGUE_MEMBER_PENDING";
export const REMOVE_LEAGUE_MEMBER_REJECTED = "REMOVE_LEAGUE_MEMBER_REJECTED";
export const REMOVE_LEAGUE_MEMBER_FULFILLED = "REMOVE_LEAGUE_MEMBER_FULFILLED";
export const RESET_REMOVE_MEMBER = "RESET_REMOVE_MEMBER";

export const LEAVE_LEAGUE = "LEAVE_LEAGUE";
export const LEAVE_LEAGUE_PENDING = "LEAVE_LEAGUE_PENDING";
export const LEAVE_LEAGUE_REJECTED = "LEAVE_LEAGUE_REJECTED";
export const LEAVE_LEAGUE_FULFILLED = "LEAVE_LEAGUE_FULFILLED";
export const RESET_LEAVE_LEAGUE = "RESET_LEAVE_LEAGUE";

export const SELECTED_LEAGUE = "SELECTED_LEAGUE";
export const SET_LEAGUE_NAME = "SET_LEAGUE_NAME";

export const GET_MINI_LEAGUES_PRIZES = "GET_MINI_LEAGUES_PRIZES";
export const GET_MINI_LEAGUES_PRIZES_PENDING =
  "GET_MINI_LEAGUES_PRIZES_PENDING";
export const GET_MINI_LEAGUES_PRIZES_REJECTED =
  "GET_MINI_LEAGUES_PRIZES_REJECTED";
export const GET_MINI_LEAGUES_PRIZES_FULFILLED =
  "GET_MINI_LEAGUES_PRIZES_FULFILLED";

export const GET_LEAGUE_TYPES_WITH_COUNT = "GET_LEAGUE_TYPES_WITH_COUNT";
export const GET_LEAGUE_TYPES_WITH_COUNT_PENDING =
  "GET_LEAGUE_TYPES_WITH_COUNT_PENDING";
export const GET_LEAGUE_TYPES_WITH_COUNT_REJECTED =
  "GET_LEAGUE_TYPES_WITH_COUNT_REJECTED";
export const GET_LEAGUE_TYPES_WITH_COUNT_FULFILLED =
  "GET_LEAGUE_TYPES_WITH_COUNT_FULFILLED";

export const GET_H2H_SINGLE_RACE_RESULT = "GET_H2H_SINGLE_RACE_RESULT";
export const GET_H2H_SINGLE_RACE_RESULT_PENDING =
  "GET_H2H_SINGLE_RACE_RESULT_PENDING";
export const GET_H2H_SINGLE_RACE_RESULT_REJECTED =
  "GET_H2H_SINGLE_RACE_RESULT_REJECTED";
export const GET_H2H_SINGLE_RACE_RESULT_FULFILLED =
  "GET_H2H_SINGLE_RACE_RESULT_FULFILLED";

export const GET_H2H_RACE_WEEK_WISE_RESULT = "GET_H2H_RACE_WEEK_WISE_RESULT";
export const GET_H2H_RACE_WEEK_WISE_RESULT_PENDING =
  "GET_H2H_RACE_WEEK_WISE_RESULT_PENDING";
export const GET_H2H_RACE_WEEK_WISE_RESULT_REJECTED =
  "GET_H2H_RACE_WEEK_WISE_RESULT_REJECTED";
export const GET_H2H_RACE_WEEK_WISE_RESULT_FULFILLED =
  "GET_H2H_RACE_WEEK_WISE_RESULT_FULFILLED";

export const FETCH_LEAGUES_FILTER_AND_SORT = "FETCH_LEAGUES_FILTER_AND_SORT";
export const FETCH_LEAGUES_FILTER_AND_SORT_PENDING =
  "FETCH_LEAGUES_FILTER_AND_SORT_PENDING";
export const FETCH_LEAGUES_FILTER_AND_SORT_REJECTED =
  "FETCH_LEAGUES_FILTER_AND_SORT_REJECTED";
export const FETCH_LEAGUES_FILTER_AND_SORT_FULFILLED =
  "FETCH_LEAGUES_FILTER_AND_SORT_FULFILLED";

export const APPLY_LEAGUE_FILTER = "APPLY_LEAGUE_FILTER";
export const RESET_LEAGUE_FILTER = "RESET_LEAGUE_FILTER";
export const APPLY_LEAGUE_SORT = "APPLY_LEAGUE_SORT";
export const RESET_LEAGUE_SORT = "RESET_LEAGUE_SORT";
export const APPLY_LEAGUE_SEARCH = "APPLY_LEAGUE_SEARCH";
export const RESET_LEAGUE_SEARCH = "RESET_LEAGUE_SEARCH";

export const FETCH_USER_STARTER_PUBLIC_LEAGUES =
  "FETCH_USER_STARTER_PUBLIC_LEAGUES";
export const FETCH_USER_STARTER_PUBLIC_LEAGUES_PENDING =
  "FETCH_USER_STARTER_PUBLIC_LEAGUES_PENDING";
export const FETCH_USER_STARTER_PUBLIC_LEAGUES_REJECTED =
  "FETCH_USER_STARTER_PUBLIC_LEAGUES_REJECTED";
export const FETCH_USER_STARTER_PUBLIC_LEAGUES_FULFILLED =
  "FETCH_USER_STARTER_PUBLIC_LEAGUES_FULFILLED";

export const FETCH_FEATURED_LEAGUES = "FETCH_FEATURED_LEAGUES";
export const FETCH_FEATURED_LEAGUES_PENDING = "FETCH_FEATURED_LEAGUES_PENDING";
export const FETCH_FEATURED_LEAGUES_REJECTED =
  "FETCH_FEATURED_LEAGUES_REJECTED";
export const FETCH_FEATURED_LEAGUES_FULFILLED =
  "FETCH_FEATURED_LEAGUES_FULFILLED";

export const FETCH_PINNED_LEAGUES = "FETCH_PINNED_LEAGUES";
export const FETCH_PINNED_LEAGUES_PENDING = "FETCH_PINNED_LEAGUES_PENDING";
export const FETCH_PINNED_LEAGUES_REJECTED = "FETCH_PINNED_LEAGUES_REJECTED";
export const FETCH_PINNED_LEAGUES_FULFILLED = "FETCH_PINNED_LEAGUES_FULFILLED";

export const SEARCH_FILTER_USER_PUBLIC_LEAGUES =
  "SEARCH_FILTER_USER_PUBLIC_LEAGUES";
export const SEARCH_FILTER_USER_PUBLIC_LEAGUES_PENDING =
  "SEARCH_FILTER_USER_PUBLIC_LEAGUES_PENDING";
export const SEARCH_FILTER_USER_PUBLIC_LEAGUES_REJECTED =
  "SEARCH_FILTER_USER_PUBLIC_LEAGUES_REJECTED";
export const SEARCH_FILTER_USER_PUBLIC_LEAGUES_FULFILLED =
  "SEARCH_FILTER_USER_PUBLIC_LEAGUES_FULFILLED";

export const EDIT_LEAGUE = "EDIT_LEAGUE";
export const EDIT_LEAGUE_PENDING = "EDIT_LEAGUE_PENDING";
export const EDIT_LEAGUE_REJECTED = "EDIT_LEAGUE_REJECTED";
export const EDIT_LEAGUE_FULFILLED = "EDIT_LEAGUE_FULFILLED";
export const EDIT_LEAGUE_RESET = "EDIT_LEAGUE_RESET";

export const FETCH_PIN_UNPIN_LEAGUE = "FETCH_PIN_UNPIN_LEAGUE";
export const FETCH_PIN_UNPIN_LEAGUE_PENDING = "FETCH_PIN_UNPIN_LEAGUE_PENDING";
export const FETCH_PIN_UNPIN_LEAGUE_REJECTED =
  "FETCH_PIN_UNPIN_LEAGUE_REJECTED";
export const FETCH_PIN_UNPIN_LEAGUE_FULFILLED =
  "FETCH_PIN_UNPIN_LEAGUE_FULFILLED";
export const RESET_PIN_UNPIN_LEAGUE = "RESET_PIN_UNPIN_LEAGUE";

export const EDIT_SELECTED_LEAGUE = "EDIT_SELECTED_LEAGUE";
