import { leagueMembersConstants } from "../../../Common/constants";
import { decodeName } from "../../../Common/utils";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer } from "redux-persist";
import {
  FETCH_USER_PRIVATE_LEAGUES_PENDING,
  FETCH_USER_PRIVATE_LEAGUES_REJECTED,
  FETCH_USER_PRIVATE_LEAGUES_FULFILLED,
  FETCH_USER_PUBLIC_LEAGUES_PENDING,
  FETCH_USER_PUBLIC_LEAGUES_REJECTED,
  FETCH_USER_PUBLIC_LEAGUES_FULFILLED,
  TOGGLE_CREATE_LEAGUE_POPUP,
  TOGGLE_JOIN_LEAGUE_POPUP,
  TOGGLE_SELECT_TEAM,
  TOGGLE_SUCCESS_JOIN_LEAGUE_POPUP,
  TOGGLE_SHARE_LEAGUE_POPUP,
  TOGGLE_JOIN_LEAGUE_CONFIRM_POPUP,
  SET_CREATE_LEAGUE_DATA,
  SET_CREATE_LEAGUE_TEAMS,
  CREATE_PRIVATE_LEAGUE_REJECTED,
  CREATE_PRIVATE_LEAGUE_FULFILLED,
  RESET_CREATE_PRIVATE_LEAGUE,
  CREATE_PRIVATE_LEAGUE_PENDING,
  SET_MAX_TEAMS,
  FETCH_LEAGUE_INFO_PENDING,
  FETCH_LEAGUE_INFO_REJECTED,
  FETCH_LEAGUE_INFO_FULFILLED,
  RESET_LEAGUE_INFO,
  JOIN_PRIVATE_LEAGUE_PENDING,
  JOIN_PRIVATE_LEAGUE_REJECTED,
  JOIN_PRIVATE_LEAGUE_FULFILLED,
  JOIN_H2H_LEAGUE_PENDING,
  JOIN_H2H_LEAGUE_REJECTED,
  JOIN_H2H_LEAGUE_FULFILLED,
  JOIN_PUBLIC_LEAGUE_PENDING,
  JOIN_PUBLIC_LEAGUE_REJECTED,
  JOIN_PUBLIC_LEAGUE_FULFILLED,
  RESET_JOIN_PRIVATE_LEAGUE,
  RESET_CREATE_LEAGUE_TEAMS,
  GET_LEAGUE_MEMBERS_REJECTED,
  GET_LEAGUE_MEMBERS_FULFILLED,
  GET_LEAGUE_MEMBERS_PENDING,
  RESET_JOIN_PUBLIC_LEAGUE,
  RESET_JOIN_H2H_LEAGUE,
  TOGGLE_COPY_POPUP,
  RESET_SEARCH_PUBLIC_LEAGUES,
  SEARCH_PUBLIC_LEAGUES_FULFILLED,
  SEARCH_PUBLIC_LEAGUES_REJECTED,
  SEARCH_PUBLIC_LEAGUES_PENDING,
  TOGGLE_REMOVE_MEMBER_POPUP,
  RESET_LEAGUE_MEMBERS,
  FETCH_USER_CLASSIC_PRIVATE_LEAGUES_PENDING,
  FETCH_USER_H2H_PRIVATE_LEAGUES_PENDING,
  FETCH_USER_CLASSIC_PRIVATE_LEAGUES_REJECTED,
  FETCH_USER_CLASSIC_PRIVATE_LEAGUES_FULFILLED,
  FETCH_USER_H2H_PRIVATE_LEAGUES_REJECTED,
  FETCH_USER_H2H_PRIVATE_LEAGUES_FULFILLED,
  RESET_SEARCH_H2H_LEAGUES,
  SEARCH_H2H_LEAGUES_FULFILLED,
  SEARCH_H2H_LEAGUES_REJECTED,
  SEARCH_H2H_LEAGUES_PENDING,
  RESET_SEARCH_PRIVATE_LEAGUES,
  SEARCH_PRIVATE_LEAGUES_FULFILLED,
  SEARCH_PRIVATE_LEAGUES_REJECTED,
  SEARCH_PRIVATE_LEAGUES_PENDING,
  UPDATE_PUBLIC_LEAGUE_NAME_REJECTED,
  UPDATE_PUBLIC_LEAGUE_NAME_FULFILLED,
  UPDATE_PUBLIC_LEAGUE_NAME_PENDING,
  UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_PENDING,
  UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME,
  UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_REJECTED,
  UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_FULFILLED,
  UPDATE_H2H_PRIVATE_LEAGUE_NAME_PENDING,
  UPDATE_H2H_PRIVATE_LEAGUE_NAME_REJECTED,
  UPDATE_H2H_PRIVATE_LEAGUE_NAME_FULFILLED,
  DELETE_LEAGUE_PENDING,
  DELETE_LEAGUE_REJECTED,
  DELETE_LEAGUE_FULFILLED,
  REPORT_PRIVATE_LEAGUE_PENDING,
  REPORT_PRIVATE_LEAGUE_REJECTED,
  REPORT_PRIVATE_LEAGUE_FULFILLED,
  REPORT_H2H_LEAGUE_PENDING,
  REPORT_H2H_LEAGUE_REJECTED,
  REPORT_H2H_LEAGUE_FULFILLED,
  REPORT_PUBLIC_LEAGUE_PENDING,
  REPORT_PUBLIC_LEAGUE_REJECTED,
  REPORT_PUBLIC_LEAGUE_FULFILLED,
  RESET_SEARCH_LEAGUES,
  RESET_USER_CLASSIC_PRIVATE_LEAGUES,
  RESET_USER_H2H_PRIVATE_LEAGUES,
  FETCH_USER_JOINED_PUBLIC_LEAGUES_PENDING,
  FETCH_USER_JOINED_PUBLIC_LEAGUES_REJECTED,
  FETCH_USER_JOINED_PUBLIC_LEAGUES_FULFILLED,
  RESET_USER_JOINED_PUBLIC_LEAGUES,
  SEARCH_USER_JOINED_PUBLIC_LEAGUES_FULFILLED,
  SEARCH_USER_JOINED_PUBLIC_LEAGUES_REJECTED,
  SEARCH_USER_JOINED_PUBLIC_LEAGUES_PENDING,
  GET_LEAGUE_MEMBERS_FOR_MANAGER_PENDING,
  GET_LEAGUE_MEMBERS_FOR_MANAGER_REJECTED,
  GET_LEAGUE_MEMBERS_FOR_MANAGER_FULFILLED,
  LEAVE_LEAGUE_PENDING,
  LEAVE_LEAGUE_REJECTED,
  LEAVE_LEAGUE_FULFILLED,
  REMOVE_LEAGUE_MEMBER_PENDING,
  REMOVE_LEAGUE_MEMBER_REJECTED,
  REMOVE_LEAGUE_MEMBER_FULFILLED,
  RESET_DELETE_LEAGUE,
  RESET_REPORT_LEAGUE,
  RESET_REMOVE_MEMBER,
  RESET_LEAVE_LEAGUE,
  SELECTED_LEAGUE,
  GET_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED,
  GET_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED,
  RESET_USER_PRIVATE_LEAGUE_LEADER_BOARD,
  GET_PUBLIC_LEAGUE_LEADER_BOARD_PENDING,
  GET_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED,
  GET_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED,
  RESET_PUBLIC_LEAGUE_LEADER_BOARD,
  FETCH_TOP_PUBLIC_LEAGUES_PENDING,
  FETCH_TOP_PUBLIC_LEAGUES_REJECTED,
  FETCH_TOP_PUBLIC_LEAGUES_FULFILLED,
  GET_PRIVATE_LEAGUE_LEADER_BOARD_PENDING,
  RESET_PRIVATE_LEAGUE_LEADER_BOARD,
  GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED,
  RESET_USER_PUBLIC_LEAGUE_LEADER_BOARD,
  SET_LEAGUE_NAME,
  RESET_LEAGUE_NAME_ERRORS,
  FETCH_USER_VIP_PRIVATE_LEAGUES_PENDING,
  FETCH_USER_VIP_PRIVATE_LEAGUES_REJECTED,
  FETCH_USER_VIP_PRIVATE_LEAGUES_FULFILLED,
  RESET_USER_VIP_PRIVATE_LEAGUES,
  SEARCH_VIP_LEAGUES_PENDING,
  SEARCH_VIP_LEAGUES_REJECTED,
  SEARCH_VIP_LEAGUES_FULFILLED,
  DELETE_H2H_LEAGUE_PENDING,
  DELETE_H2H_LEAGUE_REJECTED,
  DELETE_H2H_LEAGUE_FULFILLED,
  RESET_DELETE_H2H_LEAGUE,
  RESET_CREATE_PRIVATE_LEAGUE_ERRORS,
  FETCH_USER_MINI_LEAGUES_PENDING,
  FETCH_USER_MINI_LEAGUES_REJECTED,
  FETCH_USER_MINI_LEAGUES_FULFILLED,
  JOIN_MINI_LEAGUE_PENDING,
  JOIN_MINI_LEAGUE_REJECTED,
  JOIN_MINI_LEAGUE_FULFILLED,
  RESET_JOIN_MINI_LEAGUE,
  GET_MINI_LEAGUES_PRIZES_PENDING,
  GET_MINI_LEAGUES_PRIZES_REJECTED,
  GET_MINI_LEAGUES_PRIZES_FULFILLED,
  FETCH_ALL_MINI_LEAGUES_PENDING,
  FETCH_ALL_MINI_LEAGUES_REJECTED,
  FETCH_ALL_MINI_LEAGUES_FULFILLED,
  RESET_ALL_MINI_LEAGUES,
  SEARCH_MINI_LEAGUES_PENDING,
  SEARCH_MINI_LEAGUES_REJECTED,
  SEARCH_MINI_LEAGUES_FULFILLED,
  RESET_SEARCH_MINI_LEAGUES,
  GET_LEAGUE_TYPES_WITH_COUNT_FULFILLED,
  GET_LEAGUE_TYPES_WITH_COUNT_PENDING,
  GET_LEAGUE_TYPES_WITH_COUNT_REJECTED,
  GET_H2H_SINGLE_RACE_RESULT_PENDING,
  GET_H2H_SINGLE_RACE_RESULT_REJECTED,
  GET_H2H_SINGLE_RACE_RESULT_FULFILLED,
  GET_H2H_RACE_WEEK_WISE_RESULT_PENDING,
  GET_H2H_RACE_WEEK_WISE_RESULT_REJECTED,
  GET_H2H_RACE_WEEK_WISE_RESULT_FULFILLED,
  FETCH_LEAGUES_FILTER_AND_SORT_PENDING,
  FETCH_LEAGUES_FILTER_AND_SORT_REJECTED,
  FETCH_LEAGUES_FILTER_AND_SORT_FULFILLED,
  APPLY_LEAGUE_FILTER,
  RESET_LEAGUE_FILTER,
  APPLY_LEAGUE_SORT,
  RESET_LEAGUE_SORT,
  FETCH_USER_STARTER_PUBLIC_LEAGUES_PENDING,
  FETCH_USER_STARTER_PUBLIC_LEAGUES_REJECTED,
  FETCH_USER_STARTER_PUBLIC_LEAGUES_FULFILLED,
  APPLY_LEAGUE_SEARCH,
  RESET_LEAGUE_SEARCH,
  FETCH_FEATURED_LEAGUES_PENDING,
  FETCH_FEATURED_LEAGUES_REJECTED,
  FETCH_FEATURED_LEAGUES_FULFILLED,
  FETCH_PINNED_LEAGUES_PENDING,
  FETCH_PINNED_LEAGUES_REJECTED,
  FETCH_PINNED_LEAGUES_FULFILLED,
  SEARCH_FILTER_USER_PUBLIC_LEAGUES_PENDING,
  SEARCH_FILTER_USER_PUBLIC_LEAGUES_REJECTED,
  SEARCH_FILTER_USER_PUBLIC_LEAGUES_FULFILLED,
  EDIT_LEAGUE_PENDING,
  EDIT_LEAGUE_REJECTED,
  EDIT_LEAGUE_FULFILLED,
  EDIT_LEAGUE_RESET,
  FETCH_PIN_UNPIN_LEAGUE_PENDING,
  FETCH_PIN_UNPIN_LEAGUE_REJECTED,
  FETCH_PIN_UNPIN_LEAGUE_FULFILLED,
  RESET_PIN_UNPIN_LEAGUE,
  EDIT_SELECTED_LEAGUE,
} from "../../constants/league";

import {
  dummyManageLeagueForUser,
  dummyManageLeagueForManager,
  dummyManageHTHLeagueForManager,
} from "../../../ReusableComponents/LeagueManage/dummyManageLeague";

const initialState = {
  maxTeams: 1,
};

const formatData = (data, key) => {
  return data?.map((data) => ({
    ...data,
    [key]: decodeName(data[key]),
  }));
};

const league = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_PRIVATE_LEAGUES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_USER_PRIVATE_LEAGUES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_USER_PRIVATE_LEAGUES_FULFILLED:
      try {
        let privateLeagueData =
          formatData(action.payload.data.leaguesdata, "leagueName") || [];
        let totalClassicPrivateLeagues = action.payload.data.leaguesclassiccnt;
        let totalH2HLeagues = action.payload.data.leagueshthscnt;
        let totalVIPLeagues = action.payload.data.leaguesvipcnt;
        return {
          ...state,
          loading: false,
          error: false,
          success: true,
          privateLeagues: privateLeagueData,
          totalClassicPrivateLeagues: totalClassicPrivateLeagues,
          totalH2HLeagues: totalH2HLeagues,
          totalVIPLeagues: totalVIPLeagues,
        };
      } catch (error) {
        return {
          ...state,
          loading: false,
          error: true,
          success: false,
        };
      }
    case FETCH_USER_STARTER_PUBLIC_LEAGUES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_USER_STARTER_PUBLIC_LEAGUES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_USER_STARTER_PUBLIC_LEAGUES_FULFILLED:
      let starterPublicLeagueData = action.payload.data.leaguesdata.map(
        (data) => ({
          ...data,
          leagueName: decodeName(data.leagueName),
        })
      );
      let maxStarterPublicLeagues = action.payload.data.leaguespubliccnt;
      let starterPublicHasMore = maxStarterPublicLeagues > 4;
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        starterPublicLeagues: starterPublicLeagueData,
        maxStarterUserJoinedPublicLeagues: maxStarterPublicLeagues,
        starterUserJoinedPublicHasMore: starterPublicHasMore,
      };
    case FETCH_USER_PUBLIC_LEAGUES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_USER_PUBLIC_LEAGUES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_USER_PUBLIC_LEAGUES_FULFILLED:
      let publicLeagueData = action.payload.data.Details.map((data) => ({
        ...data,
        leagueName: decodeName(data.leagueName),
      }));

      if (state?.publicLeagues) {
        publicLeagueData = [...state.publicLeagues, ...publicLeagueData];
      }

      const publicLeagueCount = action.payload.data?.Count.leagueCount;
      const sponsoredPublicLeagueCount =
        action.payload.data?.Count?.sponsorleagueCount;
      let maxPublicLeagues = publicLeagueCount + sponsoredPublicLeagueCount;
      let publicHasMore = publicLeagueData?.length < maxPublicLeagues;

      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        publicLeagues: publicLeagueData,
        maxUserJoinedPublicLeagues: maxPublicLeagues,
        userJoinedPublicHasMore: publicHasMore,
      };
    case FETCH_USER_MINI_LEAGUES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_USER_MINI_LEAGUES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_USER_MINI_LEAGUES_FULFILLED:
      try {
        let userMiniLeagueData =
          formatData(action.payload.data.leagueusersdata, "leagueName") || [];
        let otherMiniLeagueData =
          formatData(action.payload.data.leaguesalldata, "leagueName") || [];
        let totalMiniLeagues = action.payload.data.leaguesallminicnt;
        let totalUserMiniLeagues = action.payload.data.leaguesusrsminicnt;
        return {
          ...state,
          loading: false,
          error: false,
          success: true,
          userMiniLeagues: userMiniLeagueData,
          otherMiniLeagues: otherMiniLeagueData,
          totalMiniLeagues: totalMiniLeagues,
          totalUserMiniLeagues: totalUserMiniLeagues,
        };
      } catch (error) {
        return {
          ...state,
          loading: false,
          error: true,
          success: false,
        };
      }
    case FETCH_TOP_PUBLIC_LEAGUES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_TOP_PUBLIC_LEAGUES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_TOP_PUBLIC_LEAGUES_FULFILLED:
      let topPublicLeaguesData = formatData(action.payload.data, "leagueName");
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        topPublicLeagues: topPublicLeaguesData,
      };
    case TOGGLE_CREATE_LEAGUE_POPUP:
      return {
        ...state,
        toggleCreateOverlay: !state.toggleCreateOverlay,
      };
    case TOGGLE_JOIN_LEAGUE_POPUP:
      return {
        ...state,
        toggleJoinOverlay: !state.toggleJoinOverlay,
      };
    case TOGGLE_SELECT_TEAM:
      return {
        ...state,
        toggleSelectTeamClicked: !state.toggleSelectTeamClicked,
      };
    case TOGGLE_SUCCESS_JOIN_LEAGUE_POPUP:
      return {
        ...state,
        toggleSuccessLeagueJoined: !state.toggleSuccessLeagueJoined,
      };
    case TOGGLE_SHARE_LEAGUE_POPUP:
      return {
        ...state,
        toggleSharePopup: !state.toggleSharePopup,
      };
    case TOGGLE_JOIN_LEAGUE_CONFIRM_POPUP:
      return {
        ...state,
        toggleJoinLeagueConfirmPopUp: !state.toggleJoinLeagueConfirmPopUp,
      };
    case TOGGLE_REMOVE_MEMBER_POPUP:
      let selectedMember = undefined;
      if (
        state.showRemoveMember === false ||
        state.showRemoveMember === undefined
      ) {
        selectedMember = action.payload;
      }
      return {
        ...state,
        showRemoveMember: !state.showRemoveMember,
        selectedMember: selectedMember,
      };
    case REMOVE_LEAGUE_MEMBER_PENDING:
      return {
        ...state,
        removeMemberLoading: true,
        removeMemberError: false,
        removeMemberSuccess: false,
      };
    case REMOVE_LEAGUE_MEMBER_REJECTED:
      return {
        ...state,
        removeMemberLoading: false,
        removeMemberError: true,
        removeMemberSuccess: false,
      };
    case REMOVE_LEAGUE_MEMBER_FULFILLED:
      let allLeagueMembers = state.leagueMembers;
      const removeMemberIndex = allLeagueMembers.findIndex(
        (x) => x.socialId === state.selectedMember.socialId
      );
      allLeagueMembers.splice(removeMemberIndex, 1);
      return {
        ...state,
        removeMemberLoading: false,
        removeMemberError: false,
        removeMemberSuccess: true,
        leagueMembers: allLeagueMembers,
      };
    case RESET_REMOVE_MEMBER:
      return {
        ...state,
        removeMemberLoading: false,
        removeMemberError: false,
        removeMemberSuccess: false,
        selectedMember: undefined,
      };
    case SET_CREATE_LEAGUE_DATA:
      return {
        ...state,
        createLeagueData: JSON.parse(JSON.stringify(action.payload)),
        maxTeams: action.payload.maxTeams,
      };
    case SET_CREATE_LEAGUE_TEAMS:
      let createLeagueSelectedTeams = state.createLeagueTeams || [];
      let createLeagueSelectedTeamsIndex =
        createLeagueSelectedTeams &&
        createLeagueSelectedTeams?.findIndex((x) => x === action.payload);

      if (
        createLeagueSelectedTeamsIndex !== undefined &&
        createLeagueSelectedTeamsIndex !== -1
      ) {
        createLeagueSelectedTeams.splice(createLeagueSelectedTeamsIndex, 1);
      } else {
        createLeagueSelectedTeams.push(action.payload);
      }
      return {
        ...state,
        createLeagueTeams: createLeagueSelectedTeams,
      };

    case RESET_CREATE_LEAGUE_TEAMS: {
      let createLeagueSelectedTeams = [];
      return {
        ...state,
        createLeagueTeams: createLeagueSelectedTeams,
      };
    }

    case CREATE_PRIVATE_LEAGUE_PENDING:
      return {
        ...state,
        createPrivateLeagueLoading: true,
        createPrivateLeagueError: false,
        createPrivateLeagueSuccess: false,
      };
    case CREATE_PRIVATE_LEAGUE_REJECTED:
      return {
        ...state,
        createPrivateLeagueLoading: false,
        createPrivateLeagueError: true,
        createPrivateLeagueSuccess: false,
        createPrivateLeagueErrorData: action.payload.data,
      };
    case CREATE_PRIVATE_LEAGUE_FULFILLED:
      return {
        ...state,
        createPrivateLeagueLoading: false,
        createPrivateLeagueError: false,
        createPrivateLeagueSuccess: true,
        createPrivateLeagueSuccessData: action.payload.data,
      };
    case RESET_CREATE_PRIVATE_LEAGUE:
      return {
        ...state,
        createPrivateLeagueLoading: false,
        createPrivateLeagueError: false,
        createPrivateLeagueSuccess: false,
        createPrivateLeagueSuccessData: undefined,
      };
    case RESET_CREATE_PRIVATE_LEAGUE_ERRORS:
      return {
        ...state,
        createPrivateLeagueLoading: false,
        createPrivateLeagueError: false,
        createPrivateLeagueSuccess: false,
        createPrivateLeagueErrorData: undefined,
      };
    case SET_MAX_TEAMS:
      return {
        ...state,
        maxTeams: action.payload,
      };

    case FETCH_LEAGUE_INFO_PENDING:
      return {
        ...state,
        leagueInfoLoading: true,
        leagueInfoError: false,
        leagueInfoSuccess: false,
      };
    case FETCH_LEAGUE_INFO_REJECTED:
      return {
        ...state,
        leagueInfoLoading: false,
        leagueInfoError: true,
        leagueInfoSuccess: false,
        leagueInfoErrorData: action.payload.data,
      };
    case FETCH_LEAGUE_INFO_FULFILLED:
      let leagueInfoData = {
        ...action.payload.data,
        leagueName: decodeName(action.payload.data.leagueName),
      };
      return {
        ...state,
        leagueInfoLoading: false,
        leagueInfoError: false,
        leagueInfoSuccess: true,
        leagueInfoSuccessData: leagueInfoData,
        maxTeams: leagueInfoData.noOfteam || 1,
      };
    case RESET_LEAGUE_INFO:
      return {
        ...state,
        leagueInfoLoading: false,
        leagueInfoError: false,
        leagueInfoSuccess: false,
        leagueInfoSuccessData: undefined,
        maxTeams: undefined,
      };
    case JOIN_PRIVATE_LEAGUE_PENDING:
      return {
        ...state,
        joinPrivateLeagueLoading: true,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: false,
      };
    case JOIN_PRIVATE_LEAGUE_REJECTED:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: true,
        joinPrivateLeagueSuccess: false,
        joinPrivateLeagueErrorData: action.payload.data,
      };
    case JOIN_PRIVATE_LEAGUE_FULFILLED:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: true,
        joinPrivateLeagueSuccessData: action.payload.data,
      };
    case RESET_JOIN_PRIVATE_LEAGUE:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: false,
        joinPrivateLeagueErrorData: undefined,
      };
    case GET_LEAGUE_MEMBERS_PENDING:
      return {
        ...state,
        leagueMembersLoading: true,
        leagueMembersError: false,
        leagueMembersSuccess: false,
      };
    case GET_LEAGUE_MEMBERS_REJECTED:
      let errorState = {};

      if (action.payload?.message === "No Data Found") {
        errorState = {
          hasMoreMembers: false,
        };
      }
      return {
        ...state,
        leagueMembersLoading: false,
        leagueMembersError: true,
        leagueMembersSuccess: false,
        ...errorState,
      };
    case GET_LEAGUE_MEMBERS_FULFILLED:
      let hasMoreMembers = true;
      let members = action.payload.data.member;
      const adminInfoForUser = action.payload.data.adminInfo;
      let maxMembers;
      if (state?.maxMembers) {
        maxMembers = state?.maxMembers;
      } else {
        maxMembers = action.payload?.data?.adminInfo?.memeberCount;
      }
      members = members?.map((x) => ({
        ...x,
        // teamName: decodeName(x.teamName),
      }));
      if (members?.length < leagueMembersConstants.ITEMS_PER_PAGE) {
        hasMoreMembers = false;
      }
      let leagueMembers = members;
      if (state.leagueMembers) {
        leagueMembers = [...state.leagueMembers, ...leagueMembers];
      }
      if (leagueMembers?.length >= maxMembers) {
        hasMoreMembers = false;
      }

      return {
        ...state,
        leagueMembersLoading: false,
        leagueMembersError: false,
        leagueMembersSuccess: true,
        leagueUserInfo: action.payload.data.adminInfo,
        leagueAdmin: [adminInfoForUser],
        leagueMembers: leagueMembers,
        hasMoreMembers: hasMoreMembers,
        maxMembers: maxMembers,
      };
    case GET_LEAGUE_MEMBERS_FOR_MANAGER_PENDING:
      return {
        ...state,
        leagueMembersLoading: true,
        leagueMembersError: false,
        leagueMembersSuccess: false,
      };
    case GET_LEAGUE_MEMBERS_FOR_MANAGER_REJECTED:
      return {
        ...state,
        leagueMembersLoading: false,
        leagueMembersError: true,
        leagueMembersSuccess: false,
      };
    case GET_LEAGUE_MEMBERS_FOR_MANAGER_FULFILLED:
      let hasMoreManagerMembers = true;
      let managerMembers = action?.payload?.data?.member;
      const adminInfoForManager = action?.payload?.data?.adminInfo;
      managerMembers = managerMembers?.map((x) => ({
        ...x,
        // teamName: decodeName(x.teamName),
      }));
      if (managerMembers?.length < leagueMembersConstants.ITEMS_PER_PAGE) {
        hasMoreManagerMembers = false;
      }
      let managerMaxMembers;
      if (state?.maxMembers) {
        managerMaxMembers = state?.maxMembers;
      } else {
        managerMaxMembers = action.payload?.data?.adminInfo?.leaguesUuUser;
      }
      let leagueManagerMembers = managerMembers || [];
      if (state.leagueMembers && leagueManagerMembers) {
        leagueManagerMembers = [
          ...state.leagueMembers,
          ...leagueManagerMembers,
        ];
      }
      if (leagueManagerMembers?.length >= managerMaxMembers) {
        hasMoreManagerMembers = false;
      }

      const managerDataFromLeagueMembers = leagueManagerMembers?.find(
        (item) => item?.socialId === adminInfoForManager?.socialid
      );

      return {
        ...state,
        leagueMembersLoading: false,
        leagueMembersError: false,
        leagueMembersSuccess: true,
        leagueUserInfo: action.payload.data.adminInfo,
        leagueAdmin: [
          {
            ...adminInfoForManager,
            ...managerDataFromLeagueMembers,
          },
        ],
        leagueMembers: leagueManagerMembers,
        hasMoreMembers: hasMoreManagerMembers,
        maxMembers: managerMaxMembers,
      };
    case RESET_LEAGUE_MEMBERS:
      return {
        ...state,
        leagueMembersLoading: false,
        leagueMembersError: false,
        leagueMembersSuccess: false,
        leagueMembers: [],
        hasMoreMembers: undefined,
        isAdmin: undefined,
        leagueAdmin: undefined,
        maxMembers: undefined,
      };
    case JOIN_H2H_LEAGUE_PENDING:
      return {
        ...state,
        joinPrivateLeagueLoading: true,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: false,
      };
    case JOIN_H2H_LEAGUE_REJECTED:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: true,
        joinPrivateLeagueSuccess: false,
        joinPrivateLeagueErrorData: action.payload.data,
      };
    case JOIN_H2H_LEAGUE_FULFILLED:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: true,
        joinPrivateLeagueSuccessData: action.payload.data,
      };
    case RESET_JOIN_H2H_LEAGUE:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: false,
        joinPrivateLeagueErrorData: undefined,
      };
    case JOIN_PUBLIC_LEAGUE_PENDING:
    case JOIN_MINI_LEAGUE_PENDING:
      return {
        ...state,
        joinPrivateLeagueLoading: true,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: false,
      };
    case JOIN_PUBLIC_LEAGUE_REJECTED:
    case JOIN_MINI_LEAGUE_REJECTED:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: true,
        joinPrivateLeagueSuccess: false,
        joinPrivateLeagueErrorData: action.payload.data,
      };
    case JOIN_PUBLIC_LEAGUE_FULFILLED:
    case JOIN_MINI_LEAGUE_FULFILLED:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: true,
        joinPrivateLeagueSuccessData: action.payload.data,
      };
    case RESET_JOIN_PUBLIC_LEAGUE:
    case RESET_JOIN_MINI_LEAGUE:
      return {
        ...state,
        joinPrivateLeagueLoading: false,
        joinPrivateLeagueError: false,
        joinPrivateLeagueSuccess: false,
        joinPrivateLeagueErrorData: undefined,
      };
    case TOGGLE_COPY_POPUP:
      return {
        ...state,
        showCopyPopup: !state.showCopyPopup,
      };
    case SEARCH_PUBLIC_LEAGUES_PENDING:
      return {
        ...state,
        searchLoading: true,
        searchError: false,
        searchSuccess: false,
      };
    case SEARCH_PUBLIC_LEAGUES_REJECTED:
      return {
        ...state,
        searchLoading: false,
        searchError: true,
        searchSuccess: false,
        searchLeagueData: null,
      };
    case SEARCH_PUBLIC_LEAGUES_FULFILLED:
      let publicLeagueSearchData = formatData(
        action.payload.data,
        "leagueName"
      );
      return {
        ...state,
        searchLoading: false,
        searchError: false,
        searchSuccess: true,
        searchLeagueData: publicLeagueSearchData,
      };
    case SEARCH_PRIVATE_LEAGUES_PENDING:
      return {
        ...state,
        searchLoading: true,
        searchError: false,
        searchSuccess: false,
      };
    case SEARCH_PRIVATE_LEAGUES_REJECTED:
      return {
        ...state,
        searchLoading: false,
        searchError: true,
        searchSuccess: false,
        searchLeagueData: null,
      };
    case SEARCH_PRIVATE_LEAGUES_FULFILLED:
      let privateLeagueSearchData = formatData(
        action.payload.data.leaguesdata,
        "leagueName"
      );
      return {
        ...state,
        searchLoading: false,
        searchError: false,
        searchSuccess: true,
        searchLeagueData: privateLeagueSearchData,
      };
    case SEARCH_H2H_LEAGUES_PENDING:
      return {
        ...state,
        searchLoading: true,
        searchError: false,
        searchSuccess: false,
      };
    case SEARCH_H2H_LEAGUES_REJECTED:
      return {
        ...state,
        searchLoading: false,
        searchError: true,
        searchSuccess: false,
        searchLeagueData: null,
      };
    case SEARCH_H2H_LEAGUES_FULFILLED:
      let h2hLeagueSearchData = formatData(
        action.payload.data.leaguesdata,
        "leagueName"
      );
      return {
        ...state,
        searchLoading: false,
        searchError: false,
        searchSuccess: true,
        searchLeagueData: h2hLeagueSearchData,
      };
    case SEARCH_VIP_LEAGUES_PENDING:
      return {
        ...state,
        searchLoading: true,
        searchError: false,
        searchSuccess: false,
      };
    case SEARCH_VIP_LEAGUES_REJECTED:
      return {
        ...state,
        searchLoading: false,
        searchError: true,
        searchSuccess: false,
        searchLeagueData: null,
      };
    case SEARCH_VIP_LEAGUES_FULFILLED:
      let vipLeagueSearchData = formatData(
        action.payload.data.leaguesdata,
        "leagueName"
      );
      return {
        ...state,
        searchLoading: false,
        searchError: false,
        searchSuccess: true,
        searchLeagueData: vipLeagueSearchData,
      };
    case SEARCH_USER_JOINED_PUBLIC_LEAGUES_PENDING:
      return {
        ...state,
        searchLoading: true,
        searchError: false,
        searchSuccess: false,
      };
    case SEARCH_USER_JOINED_PUBLIC_LEAGUES_REJECTED:
      return {
        ...state,
        searchLoading: false,
        searchError: true,
        searchSuccess: false,
      };
    case SEARCH_USER_JOINED_PUBLIC_LEAGUES_FULFILLED:
      let userJoinedPublicLeagueSearchData = formatData(
        action.payload.data.leaguesdata,
        "leagueName"
      );
      return {
        ...state,
        searchLoading: false,
        searchError: false,
        searchSuccess: true,
        searchLeagueData: userJoinedPublicLeagueSearchData,
      };
    case RESET_SEARCH_LEAGUES:
      return {
        ...state,
        searchLoading: false,
        searchError: false,
        searchSuccess: false,
        searchLeagueData: [],
      };
    case FETCH_USER_CLASSIC_PRIVATE_LEAGUES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        classicPrivateLeagues: null,
      };
    case FETCH_USER_CLASSIC_PRIVATE_LEAGUES_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        classicPrivateLeagues: null,
      };
    case FETCH_USER_CLASSIC_PRIVATE_LEAGUES_FULFILLED:
      let classicPrivateLeagueData = action?.payload?.data?.Details?.map(
        (data) => ({
          ...data,
          leagueName: decodeName(decodeName(data.leagueName)),
        })
      );
      // let maxClassicPrivateLeagues = 0;
      // if (action.payload.data.leaguescnt) {
      //   maxClassicPrivateLeagues = action.payload.data.leaguescnt;
      // } else {
      //   maxClassicPrivateLeagues = state.maxClassicPrivateLeagues;
      // }
      // let classicPrivateHasMore = true;
      // if (state.classicPrivateLeagues) {
      //   classicPrivateLeagueData = [
      //     ...state.classicPrivateLeagues,
      //     ...classicPrivateLeagueData,
      //   ];
      // }

      // if (classicPrivateLeagueData?.length === maxClassicPrivateLeagues) {
      //   classicPrivateHasMore = false;
      // }
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        classicPrivateLeagues: classicPrivateLeagueData,
        // classicPrivateHasMore: classicPrivateHasMore,
        // maxClassicPrivateLeagues: maxClassicPrivateLeagues,
      };
    case RESET_USER_CLASSIC_PRIVATE_LEAGUES:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        classicPrivateLeagues: undefined,
        // classicPrivateHasMore: undefined,
        // maxClassicPrivateLeagues: undefined,
      };

    case FETCH_USER_VIP_PRIVATE_LEAGUES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_USER_VIP_PRIVATE_LEAGUES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_USER_VIP_PRIVATE_LEAGUES_FULFILLED:
      let vipPrivateLeagueData = action?.payload?.data?.VipLeague?.map(
        (data) => ({
          ...data,
          leagueName: decodeName(decodeName(data.leagueName)),
        })
      );
      // let maxVIPPrivateLeagues = 0;
      // if (action.payload.data.leaguescnt) {
      //   maxVIPPrivateLeagues = action.payload.data.leaguescnt;
      // } else {
      //   maxVIPPrivateLeagues = state.maxVIPPrivateLeagues;
      // }
      // let vipPrivateHasMore = true;
      // if (state.classicPrivateLeagues) {
      //   vipPrivateLeagueData = [
      //     ...state.classicPrivateLeagues,
      //     ...vipPrivateLeagueData,
      //   ];
      // }

      // if (vipPrivateLeagueData?.length === maxVIPPrivateLeagues) {
      //   vipPrivateHasMore = false;
      // }
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        vipPrivateLeagues: vipPrivateLeagueData,
        // vipPrivateHasMore: vipPrivateHasMore,
        // maxVIPPrivateLeagues: maxVIPPrivateLeagues,
      };
    case RESET_USER_VIP_PRIVATE_LEAGUES:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        vipPrivateLeagues: undefined,
        // vipPrivateHasMore: undefined,
        // maxVIPPrivateLeagues: undefined,
      };
    case FETCH_USER_H2H_PRIVATE_LEAGUES_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        h2hPrivateLeagues: null,
      };
    case FETCH_USER_H2H_PRIVATE_LEAGUES_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        h2hPrivateLeagues: null,
      };
    case FETCH_USER_H2H_PRIVATE_LEAGUES_FULFILLED:
      let h2hPrivateLeagueData = action?.payload?.data?.Details?.map(
        (data) => ({
          ...data,
          leagueName: decodeName(decodeName(data.leagueName)),
          users: data?.users?.map((user) => ({
            ...user,
            teamName: decodeName(decodeName(user?.teamName)),
          })),
        })
      );
      // let maxClassicH2HLeagues = 0;
      // if (action.payload.data.leaguescnt) {
      //   maxClassicH2HLeagues = action.payload.data.leaguescnt;
      // } else {
      //   maxClassicH2HLeagues = state.maxClassicH2HLeagues;
      // }
      // let h2hPrivateHasMore = false;
      // if (state.h2hPrivateLeagueData) {
      //   classicPrivateLeagueData = [
      //     ...state.h2hPrivateLeagueData,
      //     ...classicPrivateLeagueData,
      //   ];
      // }

      // if (h2hPrivateLeagueData?.length === maxClassicH2HLeagues) {
      //   h2hPrivateHasMore = false;
      // }
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        h2hPrivateLeagues: h2hPrivateLeagueData,
        // h2hPrivateHasMore: h2hPrivateHasMore,
        // maxClassicH2HLeagues: maxClassicH2HLeagues,
      };
    case RESET_USER_H2H_PRIVATE_LEAGUES:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        h2hPrivateLeagues: undefined,
        h2hPrivateHasMore: undefined,
        maxClassicH2HLeagues: undefined,
      };
    case FETCH_USER_JOINED_PUBLIC_LEAGUES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_USER_JOINED_PUBLIC_LEAGUES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_USER_JOINED_PUBLIC_LEAGUES_FULFILLED:
      let userJoinedPublicLeagueData = action.payload.data.leaguesdata.map(
        (data) => ({
          ...data,
          leagueName: decodeName(decodeName(data.leagueName)),
        })
      );
      let maxUserJoinedPublicLeagues = 0;
      if (action.payload.data.leaguespubliccnt) {
        maxUserJoinedPublicLeagues = action.payload.data.leaguespubliccnt;
      } else {
        maxUserJoinedPublicLeagues = state.maxUserJoinedPublicLeagues;
      }
      let userJoinedPublicHasMore = true;
      if (state?.userJoinedPublicLeagues) {
        userJoinedPublicLeagueData = [
          ...state.userJoinedPublicLeagues,
          ...userJoinedPublicLeagueData,
        ];
      } else {
        userJoinedPublicLeagueData = [
          ...state.publicLeagues,
          ...userJoinedPublicLeagueData,
        ];
      }

      if (userJoinedPublicLeagueData?.length === maxUserJoinedPublicLeagues) {
        userJoinedPublicHasMore = false;
      }
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        userJoinedPublicLeagues: userJoinedPublicLeagueData,
        userJoinedPublicHasMore: userJoinedPublicHasMore,
        maxUserJoinedPublicLeagues: maxUserJoinedPublicLeagues,
      };
    case RESET_USER_JOINED_PUBLIC_LEAGUES:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        userJoinedPublicLeagues: undefined,
        userJoinedPublicHasMore: undefined,
        maxUserJoinedPublicLeagues: undefined,
        publicLeagues: undefined,
      };
    case FETCH_ALL_MINI_LEAGUES_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_ALL_MINI_LEAGUES_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_ALL_MINI_LEAGUES_FULFILLED:
      let miniLeaguesData = action?.payload?.data?.Details?.map((data) => ({
        ...data,
        leagueName: decodeName(decodeName(data.leagueName)),
        users: data?.users?.map((user) => ({
          ...user,
          teamName: decodeName(decodeName(user?.teamName)),
        })),
      }));
      // let userMiniLeagueData =
      //   action.payload.data?.leagueusersdata?.map((data) => ({
      //     ...data,
      //     leagueName: decodeName(data?.leagueName),
      //   })) || [];
      // let otherMiniLeagueData =
      //   action.payload.data?.leaguesalldata?.map((data) => ({
      //     ...data,
      //     leagueName: decodeName(data?.leagueName),
      //   })) || [];
      // let maxMiniLeagues = 0;
      // if (action.payload.data.leaguesallminicnt) {
      //   maxMiniLeagues = action.payload.data.leaguesallminicnt;
      // } else {
      //   maxMiniLeagues = state.maxMiniLeagues;
      // }
      // let miniLeagueHasMore = true;
      // if (state?.userJoinedMiniLeagues) {
      //   userMiniLeagueData = [
      //     ...state.userJoinedMiniLeagues,
      //     ...userMiniLeagueData,
      //   ];
      // }

      // if (state?.canJoinMiniLeagues) {
      //   otherMiniLeagueData = [
      //     ...state.canJoinMiniLeagues,
      //     ...otherMiniLeagueData,
      //   ];
      // }

      // if (
      //   userMiniLeagueData?.length + otherMiniLeagueData?.length ===
      //   maxMiniLeagues
      // ) {
      //   miniLeagueHasMore = false;
      // }
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        miniLeagues: miniLeaguesData,
        // userJoinedMiniLeagues: userMiniLeagueData,
        // canJoinMiniLeagues: otherMiniLeagueData,
        // miniLeagueHasMore: miniLeagueHasMore,
        // maxMiniLeagues: maxMiniLeagues,
      };
    case SEARCH_MINI_LEAGUES_PENDING:
      return {
        ...state,
        searchMiniLeaguesLoading: true,
        searchMiniLeaguesError: false,
        searchMiniLeaguesSuccess: false,
      };
    case SEARCH_MINI_LEAGUES_REJECTED:
      return {
        ...state,
        searchMiniLeaguesLoading: false,
        searchMiniLeaguesError: true,
        searchMiniLeaguesSuccess: false,
      };
    case SEARCH_MINI_LEAGUES_FULFILLED:
      let userMiniLeagueSearchData =
        action.payload.data?.leagueusersdata?.map((data) => ({
          ...data,
          leagueName: decodeName(data?.leagueName),
        })) || [];
      let otherMiniLeagueSearchData =
        action.payload.data?.leaguesalldata?.map((data) => ({
          ...data,
          leagueName: decodeName(data?.leagueName),
        })) || [];
      let maxMiniLeaguesSearched = 0;
      if (action.payload.data.leaguesallminicnt) {
        maxMiniLeaguesSearched = action.payload.data.leaguesallminicnt;
      } else {
        maxMiniLeaguesSearched = state.maxMiniLeaguesSearched;
      }
      let miniLeagueSearchedHasMore = true;
      if (state?.userJoinedSearchedMiniLeagues) {
        userMiniLeagueSearchData = [
          ...state.userJoinedSearchedMiniLeagues,
          ...userMiniLeagueSearchData,
        ];
      }

      if (state?.canJoinSearchedMiniLeagues) {
        otherMiniLeagueSearchData = [
          ...state.canJoinSearchedMiniLeagues,
          ...otherMiniLeagueSearchData,
        ];
      }

      if (
        userMiniLeagueSearchData?.length + otherMiniLeagueSearchData?.length ===
        maxMiniLeaguesSearched
      ) {
        miniLeagueSearchedHasMore = false;
      }

      return {
        ...state,
        searchMiniLeaguesLoading: false,
        searchMiniLeaguesError: false,
        searchMiniLeaguesSuccess: true,
        userJoinedSearchedMiniLeagues: userMiniLeagueSearchData,
        canJoinSearchedMiniLeagues: otherMiniLeagueSearchData,
        miniLeagueSearchedHasMore: miniLeagueSearchedHasMore,
        maxMiniLeaguesSearched: maxMiniLeaguesSearched,
      };
    case RESET_ALL_MINI_LEAGUES:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        userJoinedMiniLeagues: undefined,
        canJoinMiniLeagues: undefined,
        miniLeagueHasMore: undefined,
        maxMiniLeagues: undefined,
      };
    case RESET_SEARCH_MINI_LEAGUES:
      return {
        ...state,
        searchMiniLeaguesLoading: false,
        searchMiniLeaguesError: false,
        searchMiniLeaguesSuccess: false,
        userJoinedSearchedMiniLeagues: undefined,
        canJoinSearchedMiniLeagues: undefined,
        miniLeagueSearchedHasMore: undefined,
        maxMiniLeaguesSearched: undefined,
      };
    case UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_PENDING:
      return {
        ...state,
        updateLeagueLoading: true,
        updateLeagueError: false,
        updateLeagueSuccess: false,
      };

    case UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_REJECTED:
      return {
        ...state,
        updateLeagueLoading: false,
        updateLeagueError: true,
        updateLeagueSuccess: false,
        updateLeagueErrorData: action.payload.data,
      };

    case UPDATE_CLASSIC_PRIVATE_LEAGUE_NAME_FULFILLED:
      return {
        ...state,
        updateLeagueLoading: false,
        updateLeagueError: false,
        updateLeagueSuccess: true,
        selectedLeague: {
          ...state.selectedLeague,
          leagueName: state.selectedLeagueName,
        },
      };
    case UPDATE_H2H_PRIVATE_LEAGUE_NAME_PENDING:
      return {
        ...state,
        updateLeagueLoading: true,
        updateLeagueError: false,
        updateLeagueSuccess: false,
      };

    case UPDATE_H2H_PRIVATE_LEAGUE_NAME_REJECTED:
      return {
        ...state,
        updateLeagueLoading: false,
        updateLeagueError: true,
        updateLeagueSuccess: false,
        updateLeagueErrorData: action.payload.data,
      };

    case RESET_LEAGUE_NAME_ERRORS:
      return {
        ...state,
        updateLeagueLoading: false,
        updateLeagueError: false,
        updateLeagueSuccess: false,
        updateLeagueErrorData: null,
      };

    case UPDATE_H2H_PRIVATE_LEAGUE_NAME_FULFILLED:
      return {
        ...state,
        updateLeagueLoading: false,
        updateLeagueError: false,
        updateLeagueSuccess: true,
        selectedLeague: {
          ...state.selectedLeague,
          leagueName: decodeName(action.payload.data?.body?.leagueName),
        },
      };
    case UPDATE_PUBLIC_LEAGUE_NAME_PENDING:
      return {
        ...state,
        updateLeagueLoading: true,
        updateLeagueError: false,
        updateLeagueSuccess: false,
      };

    case UPDATE_PUBLIC_LEAGUE_NAME_REJECTED:
      return {
        ...state,
        updateLeagueLoading: false,
        updateLeagueError: true,
        updateLeagueSuccess: false,
        updateLeagueErrorData: action.payload.data,
      };

    case UPDATE_PUBLIC_LEAGUE_NAME_FULFILLED:
      return {
        ...state,
        updateLeagueLoading: false,
        updateLeagueError: false,
        updateLeagueSuccess: true,
        selectedLeague: {
          ...state.selectedLeague,
          leagueName: decodeName(action.payload.data?.body?.leagueName),
        },
      };
    case DELETE_LEAGUE_PENDING:
      return {
        ...state,
        deleteLeagueLoading: true,
        deleteLeagueError: false,
        deleteLeagueSuccess: false,
      };
    case DELETE_LEAGUE_REJECTED:
      return {
        ...state,
        deleteLeagueLoading: false,
        deleteLeagueError: true,
        deleteLeagueSuccess: false,
      };
    case DELETE_LEAGUE_FULFILLED:
      return {
        ...state,
        deleteLeagueLoading: false,
        deleteLeagueError: false,
        deleteLeagueSuccess: true,
      };
    case RESET_DELETE_LEAGUE:
      return {
        ...state,
        deleteLeagueLoading: false,
        deleteLeagueError: false,
        deleteLeagueSuccess: false,
      };
    case DELETE_H2H_LEAGUE_PENDING:
      return {
        ...state,
        deleteLeagueLoading: true,
        deleteLeagueError: false,
        deleteLeagueSuccess: false,
      };
    case DELETE_H2H_LEAGUE_REJECTED:
      return {
        ...state,
        deleteLeagueLoading: false,
        deleteLeagueError: true,
        deleteLeagueSuccess: false,
      };
    case DELETE_H2H_LEAGUE_FULFILLED:
      return {
        ...state,
        deleteLeagueLoading: false,
        deleteLeagueError: false,
        deleteLeagueSuccess: true,
      };
    case REPORT_PRIVATE_LEAGUE_PENDING:
      return {
        ...state,
        reportLeagueLoading: true,
        reportLeagueError: false,
        reportLeagueSuccess: false,
      };
    case REPORT_PRIVATE_LEAGUE_REJECTED:
      return {
        ...state,
        reportLeagueLoading: false,
        reportLeagueError: true,
        reportLeagueSuccess: false,
      };
    case REPORT_PRIVATE_LEAGUE_FULFILLED:
      return {
        ...state,
        reportLeagueLoading: false,
        reportLeagueError: false,
        reportLeagueSuccess: true,
      };
    case REPORT_H2H_LEAGUE_PENDING:
      return {
        ...state,
        reportLeagueLoading: true,
        reportLeagueError: false,
        reportLeagueSuccess: false,
      };
    case REPORT_H2H_LEAGUE_REJECTED:
      return {
        ...state,
        reportLeagueLoading: false,
        reportLeagueError: true,
        reportLeagueSuccess: false,
      };
    case REPORT_H2H_LEAGUE_FULFILLED:
      return {
        ...state,
        reportLeagueLoading: false,
        reportLeagueError: false,
        reportLeagueSuccess: true,
      };
    case REPORT_PUBLIC_LEAGUE_PENDING:
      return {
        ...state,
        reportLeagueLoading: true,
        reportLeagueError: false,
        reportLeagueSuccess: false,
      };
    case REPORT_PUBLIC_LEAGUE_REJECTED:
      return {
        ...state,
        reportLeagueLoading: false,
        reportLeagueError: true,
        reportLeagueSuccess: false,
      };
    case REPORT_PUBLIC_LEAGUE_FULFILLED:
      return {
        ...state,
        reportLeagueLoading: false,
        reportLeagueError: false,
        reportLeagueSuccess: true,
      };
    case RESET_REPORT_LEAGUE:
      return {
        ...state,
        reportLeagueLoading: false,
        reportLeagueError: false,
        reportLeagueSuccess: false,
      };
    case LEAVE_LEAGUE_PENDING:
      return {
        ...state,
        leaveLeagueLoading: true,
        leaveLeagueError: false,
        leaveLeagueSuccess: false,
      };
    case LEAVE_LEAGUE_REJECTED:
      return {
        ...state,
        leaveLeagueLoading: false,
        leaveLeagueError: true,
        leaveLeagueSuccess: false,
      };
    case LEAVE_LEAGUE_FULFILLED:
      return {
        ...state,
        leaveLeagueLoading: false,
        leaveLeagueError: false,
        leaveLeagueSuccess: true,
      };
    case RESET_LEAVE_LEAGUE:
      return {
        ...state,
        leaveLeagueLoading: false,
        leaveLeagueError: false,
        leaveLeagueSuccess: false,
      };
    case SELECTED_LEAGUE:
      return {
        ...state,
        selectedLeague: action.payload,
      };
    case SET_LEAGUE_NAME:
      return {
        ...state,
        selectedLeagueName: action.payload,
      };
    case GET_MINI_LEAGUES_PRIZES_PENDING:
      return {
        ...state,
        miniLeaguesPrizesLoading: true,
        miniLeaguesPrizesError: false,
        miniLeaguesPrizesSuccess: false,
        miniLeaguesPrizesData: null,
      };
    case GET_MINI_LEAGUES_PRIZES_REJECTED:
      return {
        ...state,
        miniLeaguesPrizesLoading: false,
        miniLeaguesPrizesError: true,
        miniLeaguesPrizesSuccess: false,
        miniLeaguesPrizesData: null,
      };
    case GET_MINI_LEAGUES_PRIZES_FULFILLED:
      return {
        ...state,
        miniLeaguesPrizesLoading: false,
        miniLeaguesPrizesError: false,
        miniLeaguesPrizesSuccess: true,
        miniLeaguesPrizesData: action.payload.data,
      };
    case GET_LEAGUE_TYPES_WITH_COUNT_PENDING:
      return {
        ...state,
        leagueTypesWithCountLoading: true,
        leagueTypesWithCountError: false,
        leagueTypesWithCountSuccess: false,
        // leagueTypesWithCountData: null,
      };
    case GET_LEAGUE_TYPES_WITH_COUNT_REJECTED:
      return {
        ...state,
        leagueTypesWithCountLoading: false,
        leagueTypesWithCountError: true,
        leagueTypesWithCountSuccess: false,
        leagueTypesWithCountData: null,
      };
    case GET_LEAGUE_TYPES_WITH_COUNT_FULFILLED:
      return {
        ...state,
        leagueTypesWithCountLoading: false,
        leagueTypesWithCountError: false,
        leagueTypesWithCountSuccess: true,
        leagueTypesWithCountData: action.payload.data,
      };
    case GET_H2H_SINGLE_RACE_RESULT_PENDING:
      return {
        ...state,
        h2hSingleRaceResultLoading: true,
        h2hSingleRaceResultError: false,
        h2hSingleRaceResultSuccess: false,
        h2hSingleRaceResultData: null,
      };
    case GET_H2H_SINGLE_RACE_RESULT_REJECTED:
      return {
        ...state,
        h2hSingleRaceResultLoading: false,
        h2hSingleRaceResultError: true,
        h2hSingleRaceResultSuccess: false,
        h2hSingleRaceResultData: null,
      };
    case GET_H2H_SINGLE_RACE_RESULT_FULFILLED:
      const h2hSingleRaceResultData = action.payload.data.map((data) => ({
        ...data,
        teamName: decodeName(data?.teamName),
      }));
      return {
        ...state,
        h2hSingleRaceResultLoading: false,
        h2hSingleRaceResultError: false,
        h2hSingleRaceResultSuccess: true,
        h2hSingleRaceResultData: h2hSingleRaceResultData,
      };
    case GET_H2H_RACE_WEEK_WISE_RESULT_PENDING:
      return {
        ...state,
        h2hRaceWeekWiseResultLoading: true,
        h2hRaceWeekWiseResultError: false,
        h2hRaceWeekWiseResultSuccess: false,
        h2hRaceWeekWiseResultData: null,
      };
    case GET_H2H_RACE_WEEK_WISE_RESULT_REJECTED:
      return {
        ...state,
        h2hRaceWeekWiseResultLoading: false,
        h2hRaceWeekWiseResultError: true,
        h2hRaceWeekWiseResultSuccess: false,
        h2hRaceWeekWiseResultData: null,
      };
    case GET_H2H_RACE_WEEK_WISE_RESULT_FULFILLED:
      return {
        ...state,
        h2hRaceWeekWiseResultLoading: false,
        h2hRaceWeekWiseResultError: false,
        h2hRaceWeekWiseResultSuccess: true,
        h2hRaceWeekWiseResultData: action.payload.data,
      };
    case FETCH_LEAGUES_FILTER_AND_SORT_PENDING:
      return {
        ...state,
        leaguesFilterAndSortLoading: true,
        leaguesFilterAndSortError: false,
        leaguesFilterAndSortFulfilled: false,
        leaguesFilterAndSortData: null,
      };
    case FETCH_LEAGUES_FILTER_AND_SORT_REJECTED:
      return {
        ...state,
        leaguesFilterAndSortLoading: false,
        leaguesFilterAndSortError: true,
        leaguesFilterAndSortFulfilled: false,
        leaguesFilterAndSortData: null,
      };
    case FETCH_LEAGUES_FILTER_AND_SORT_FULFILLED:
      return {
        ...state,
        leaguesFilterAndSortLoading: false,
        leaguesFilterAndSortError: false,
        leaguesFilterAndSortFulfilled: true,
        leaguesFilterAndSortData: action.payload.data,
      };
    case APPLY_LEAGUE_FILTER:
      return {
        ...state,
        leagueFilterValues: action.payload.data,
      };
    case RESET_LEAGUE_FILTER:
      return {
        ...state,
        leagueFilterValues: null,
      };
    case APPLY_LEAGUE_SORT:
      return {
        ...state,
        leagueSortValues: action.payload.data,
      };
    case RESET_LEAGUE_SORT:
      return {
        ...state,
        leagueSortValues: null,
      };
    case APPLY_LEAGUE_SEARCH:
      return {
        ...state,
        leagueSearchFilter: action.payload.data,
      };
    case RESET_LEAGUE_SEARCH:
      return {
        ...state,
        leagueSearchFilter: null,
      };
    case FETCH_FEATURED_LEAGUES_PENDING:
      return {
        ...state,
        featuredLeaguesLoading: true,
        featuredLeaguesError: false,
        featuredLeaguesFulfilled: false,
        featuredLeaguesData: null,
      };
    case FETCH_FEATURED_LEAGUES_REJECTED:
      return {
        ...state,
        featuredLeaguesLoading: false,
        featuredLeaguesError: true,
        featuredLeaguesFulfilled: false,
        featuredLeaguesData: null,
      };
    case FETCH_FEATURED_LEAGUES_FULFILLED:
      let featuredLeaguesData = action.payload.data.Details.map((data) => ({
        ...data,
        leagueName: decodeName(data.leagueName),
      }));
      return {
        ...state,
        featuredLeaguesLoading: false,
        featuredLeaguesError: false,
        featuredLeaguesFulfilled: true,
        featuredLeaguesData: featuredLeaguesData,
      };
    case FETCH_PINNED_LEAGUES_PENDING:
      return {
        ...state,
        pinnedLeaguesLoading: true,
        pinnedLeaguesError: false,
        pinnedLeaguesFulfilled: false,
        pinnedLeaguesData: null,
      };
    case FETCH_PINNED_LEAGUES_REJECTED:
      return {
        ...state,
        pinnedLeaguesLoading: false,
        pinnedLeaguesError: true,
        pinnedLeaguesFulfilled: false,
        pinnedLeaguesData: null,
      };
    case FETCH_PINNED_LEAGUES_FULFILLED:
      let pinnedLeaguesData = action.payload.data.Details.map((data) => {
        const usersData = data?.users?.map((item) => ({
          ...item,
          teamName: decodeName(item?.teamName),
        }));
        return {
          ...data,
          leagueName: decodeName(data.leagueName),
          users: usersData,
        };
      });
      return {
        ...state,
        pinnedLeaguesLoading: false,
        pinnedLeaguesError: false,
        pinnedLeaguesFulfilled: true,
        pinnedLeaguesData: pinnedLeaguesData,
      };
    case SEARCH_FILTER_USER_PUBLIC_LEAGUES_PENDING:
      return {
        ...state,
        searchFilterPublicLeaguesLoading: true,
        searchFilterPublicLeaguesRejected: false,
        searchFilterPublicLeaguesFulfilled: false,
        searchFilterPublicLeaguesData: null,
      };
    case SEARCH_FILTER_USER_PUBLIC_LEAGUES_REJECTED:
      return {
        ...state,
        searchFilterPublicLeaguesLoading: false,
        searchFilterPublicLeaguesRejected: true,
        searchFilterPublicLeaguesFulfilled: false,
        searchFilterPublicLeaguesData: null,
      };
    case SEARCH_FILTER_USER_PUBLIC_LEAGUES_FULFILLED:
      let searchFilterPublicLeaguesData = action?.payload?.data?.map(
        (data) => ({
          ...data,
          leagueName: decodeName(decodeName(data.leagueName)),
          users: data?.users?.map((user) => ({
            ...user,
            teamName: decodeName(decodeName(user?.teamName)),
          })),
        })
      );
      return {
        ...state,
        searchFilterPublicLeaguesLoading: false,
        searchFilterPublicLeaguesRejected: false,
        searchFilterPublicLeaguesFulfilled: true,
        searchFilterPublicLeaguesData: searchFilterPublicLeaguesData,
      };
    case EDIT_LEAGUE_PENDING:
      return {
        ...state,
        editLeaguePending: true,
        editLeagueRejected: false,
        editLeagueFulfilled: false,
        editLeagueData: null,
      };
    case EDIT_LEAGUE_REJECTED:
      return {
        ...state,
        editLeaguePending: false,
        editLeagueRejected: true,
        editLeagueFulfilled: false,
        editLeagueData: null,
      };
    case EDIT_LEAGUE_FULFILLED:
      const updatedSelectedLeagueDetails = action.payload.data?.body;

      const updatedSelectedLeague = {
        ...state?.selectedLeague,
      };

      updatedSelectedLeague.leagueName = decodeName(
        updatedSelectedLeagueDetails?.leagueName
      );

      if (updatedSelectedLeagueDetails?.maxMember) {
        updatedSelectedLeague.maxMembers =
          updatedSelectedLeagueDetails?.maxMember;
        updatedSelectedLeague.maxMemberCount =
          updatedSelectedLeagueDetails?.maxMember;
      }
      return {
        ...state,
        editLeaguePending: false,
        editLeagueRejected: false,
        editLeagueFulfilled: true,
        editLeagueData: action.payload.data?.Data,
        selectedLeague: updatedSelectedLeague,
      };
    case EDIT_LEAGUE_RESET: {
      return {
        ...state,
        editLeaguePending: false,
        editLeagueRejected: false,
        editLeagueFulfilled: false,
        editLeagueData: null,
      };
    }
    case FETCH_PIN_UNPIN_LEAGUE_PENDING:
      return {
        ...state,
        pinUnpinLeagueLoading: true,
        pinUnpinLeagueError: false,
        pinUnpinLeagueFulfilled: false,
        pinUnpinLeagueData: null,
      };
    case FETCH_PIN_UNPIN_LEAGUE_REJECTED:
      return {
        ...state,
        pinUnpinLeagueLoading: false,
        pinUnpinLeagueError: true,
        pinUnpinLeagueFulfilled: false,
        pinUnpinLeagueData: action.payload.data,
      };
    case FETCH_PIN_UNPIN_LEAGUE_FULFILLED:
      return {
        ...state,
        pinUnpinLeagueLoading: false,
        pinUnpinLeagueError: false,
        pinUnpinLeagueFulfilled: true,
        pinUnpinLeagueData: action.payload.data,
      };
    case RESET_PIN_UNPIN_LEAGUE:
      return {
        ...state,
        pinUnpinLeagueLoading: false,
        pinUnpinLeagueError: false,
        pinUnpinLeagueFulfilled: false,
        pinUnpinLeagueData: null,
      };
    case EDIT_SELECTED_LEAGUE: {
      const selectedLeagueUpdatedData = action.payload.data;
      const selectedLeagueData = {
        ...state.selectedLeague,
        ...selectedLeagueUpdatedData,
      };

      return {
        ...state,
        selectedLeague: selectedLeagueData,
      };
    }
    default:
      return state;
  }
};

export default league;
