import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLeagueTypeForDL,
  getTranslations,
  isAndroid,
  isIOS,
  isMobile,
} from "../../../Common/utils";
import { league } from "../../../redux/actions";
import {
  Congratulations,
  CtaButton,
  Overlays,
  ToastNotification,
} from "../../../ReusableComponents";
import { useLocation } from "react-router-dom";
import { getLeagueTypeName } from "../../../Common/utils/league";
import { currentFixture } from "../../../Common/utils/fixture";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../../Common/constants";
import useDataLayer from "../../../Common/hooks/useDataLayer";
import useTournamentScanario from "../../../Common/hooks/useTournamentScanario";

const LeagueShare = (props) => {
  const { leagueName, leagueType, leagueCode, leagueId, maxTeams } = props;

  const location = useLocation();
  const pushToDataLayer = useDataLayer();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const { pathname } = location;
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const dispatch = useDispatch();
  const leagueState = useSelector((state) => state.league);
  const fixtureState = useSelector((state) => state.fixture);
  const userState = useSelector((state) => state.user);
  const {
    toggleSuccessLeagueJoined,
    toggleSharePopup,
    showCopyPopup,
    selectedLeague,
  } = leagueState;
  const webview = userState?.webview;

  const toggleSuccessJoinLeagueOverlay = () => {
    dispatch(league.toggleSuccessJoinLeagueOverlay());
  };

  const handleShareConfirm = () => {
    dispatch(league.toggleSharePopUpOverlay());
  };

  const pushShareLeagueDataLayer = () => {
    const leagueType = getLeagueTypeName(selectedLeague?.leagueType);

    const dataLayerObject = {
      actionType: "Share league",
      clickText: getTranslations(
        "create_league_completed_confirm_button",
        "Share League"
      ),
      path: pathname,
      pageName: "Leagues", // (Ex:  Create Team)
      leagueType: !!selectedLeague?.legaueVipFlag
        ? "VIP"
        : getLeagueTypeForDL(leagueType),
      raceName: currentFixture(fixtureState)?.MeetingOfficialName,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("joinLeague", dataLayerObject);
  };

  const getShareLeagueOverlayObject = (isClose = false) => ({
    pageName: "Leagues",
    actionType: `${isClose ? "Close" : "Share League"}`,
    navigationElement: "shareleagueOverlay",
    path: pathname,
    clickText: isClose
      ? getTranslations("create_league_completed_cancel_button", "Close")
      : getTranslations(
          "create_league_completed_confirm_button",
          "Share league"
        ),
    platform: isAndroid()
      ? FANTASY_ANDROID
      : isIOS()
      ? FANTASY_IOS
      : FANTASY_WEBSITE,
    componentType: webview ? WEBVIEW : WEB,
  });

  const handleShareLeagueOverlayConfirm = () => {
    const dataLayerObject = getShareLeagueOverlayObject();
    pushToDataLayer("leagueShare", dataLayerObject);
  };

  const handleShareLeagueOverlayCancel = () => {
    const dataLayerObject = getShareLeagueOverlayObject(true);
    pushToDataLayer("leagueShare", dataLayerObject);
  };

  return (
    <>
      <div className="si-league__share">
        <CtaButton
          btnCls={`si-btn ${
            !isMobile()
              ? "si-btn__secondary si-btn__secondary--outline"
              : "si-btn__icon"
          }`}
          iconCls="f1i-share"
          btnText={
            !isMobile()
              ? getTranslations(
                  "create_league_completed_confirm_button",
                  "Share League"
                )
              : ""
          }
          ariaLabel={getTranslations(
            "league_members_share_aria",
            "Click to share your league"
          )}
          btnState={disableCTAs}
          onClickProp={() => {
            pushShareLeagueDataLayer();
            toggleSuccessJoinLeagueOverlay();
          }}
        />
      </div>

      {toggleSuccessLeagueJoined && (
        <Overlays
          overlayName="si-popup__wrap--leagues"
          overlayTitle={getTranslations(
            "create_league_completed_confirm_button",
            "Share league"
          )}
          btnPrimary={getTranslations(
            "create_league_completed_confirm_button",
            "Share league"
          )}
          btnPrimaryIcon="f1i-share"
          btnSecondary={getTranslations(
            "create_league_completed_cancel_button",
            "Close"
          )}
          overlayConfig={{ cancelBtn: true, showOverlaySub_Title: true }}
          component={
            <Congratulations
              leagueName={leagueName}
              leagueType={leagueType}
              leagueCode={leagueCode}
              leagueId={leagueId}
              maxTeams={maxTeams}
            />
          }
          onCancelClick={() => {
            handleShareLeagueOverlayCancel();
            if (toggleSharePopup) {
              handleShareConfirm();
            }
            toggleSuccessJoinLeagueOverlay();
          }}
          onConfirmClick={() => {
            handleShareLeagueOverlayConfirm();
            handleShareConfirm();
          }}
        />
      )}

      {toggleSharePopup && (
        <ToastNotification toastType={""} type={"socialsharing"} />
      )}
      {showCopyPopup && (
        <ToastNotification
          toastType={""}
          type={"generic"}
          alertText={getTranslations(
            "copied_to_clipboard_toast_message",
            "Copied to clipboard"
          )}
        />
      )}
    </>
  );
};

export default LeagueShare;
