import React, { useState, useRef, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { league } from "../../../redux/actions";
import { getTranslations } from "../../../Common/utils";
import InputSelectBox from "../../Forms/inputSelectBox";
import InputCheckbox from "../../Forms/inputCheckbox";
import { initialFilterStateValues, noOfTeamsData } from "./initial-values";
import { useSearchParams } from "react-router-dom";
import { LeagueContext } from "../../../store/League/LeagueContext";

const LeaguesFilter = ({
  isLeaguesFilterReset,
  toggleLeaguesFilterReset,
  isLeaguesFilterApply,
  toggleLeaguesFilterApply,
  toggleLeagueFilterPopup,
  isJoinLeaguePage = null,
  getEnabledOrDisabledFilterApplyButton = () => {},
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const leagueState = useSelector((state) => state.league);
  const { updateTriggerClassicPublicLeagueRefetch } = useContext(LeagueContext);
  let minGap = 0;

  const leagueTypeKey = searchParams.get("type");

  const [anyFieldChanged, setAnyFieldChanged] = useState(false);

  const leagueFilterValues = leagueState?.leagueFilterValues;
  const leaguesFilterAndSortData = leagueState?.leaguesFilterAndSortData;

  const leaguesFilterData = leaguesFilterAndSortData?.filter;
  const commonFilterData = leaguesFilterData?.common;
  const specialTagFilterData = leaguesFilterData?.specialtag;

  const noOfTeamsRef = useRef();
  const minNoOfTeamsRef = useRef();
  const maxNoOfTeamsRef = useRef();
  const [isUnlimitedNoOfTeams, setIsUnlimitedNoOfTeams] = useState(
    leagueFilterValues?.league_filter_max_no_of_teams?.isUnlimitedNoOfTeams ??
      initialFilterStateValues?.unlimitedNoOfTeams
  );

  //   * Actual values to store in Redux
  const [minNoOfTeams, setMinNoOfTeams] = useState(
    leagueFilterValues?.league_filter_max_no_of_teams?.minNoOfTeams ??
      initialFilterStateValues?.minNoOfTeamsValue
  );
  const [maxNoOfTeams, setMaxNoOfTeams] = useState(
    leagueFilterValues?.league_filter_max_no_of_teams?.maxNoOfTeams ??
      initialFilterStateValues?.maxNoOfTeamsValue
  );

  const toggleUnlimitedNoOfTeams = () => {
    setAnyFieldChanged(true);
    setIsUnlimitedNoOfTeams((value) => !value);
  };
  const handleMinNoOfTeamsSliderChange = (e) => {
    e.stopPropagation();
    setAnyFieldChanged(true);
    if (parseInt(e.target.value) - parseInt(maxNoOfTeams) <= minGap) {
      setMinNoOfTeams(e.target.value);
    }
  };
  const handleMaxNoOfTeamsSliderChange = (e) => {
    e.stopPropagation();
    setAnyFieldChanged(true);
    if (parseInt(minNoOfTeams) - parseInt(e.target.value) <= minGap) {
      setMaxNoOfTeams(e.target.value);
    }
  };
  const slideMinNoOfTeams = () => {
    setAnyFieldChanged(true);
    if (parseInt(minNoOfTeams) - parseInt(maxNoOfTeams) <= minGap) {
      setMinNoOfTeams(parseInt(maxNoOfTeams) - minGap);
    }
  };
  const slideMaxNoOfTeams = () => {
    setAnyFieldChanged(true);
    if (parseInt(maxNoOfTeams) - parseInt(minNoOfTeams) <= minGap) {
      setMaxNoOfTeams(parseInt(minNoOfTeams) + minGap);
    }
  };

  useEffect(() => {
    setAnyFieldChanged(true);
    const fillNoOfTeamsColor = () => {
      const percent1 =
        (minNoOfTeams / initialFilterStateValues?.maxNoOfTeamsValue) * 100;
      const percent2 =
        (maxNoOfTeams / initialFilterStateValues?.maxNoOfTeamsValue) * 100;

      noOfTeamsRef.current.style.background = `linear-gradient(to right, hsl(240, 2%, 91%) ${percent1}% , hsl(2, 100%, 44%) ${percent1}% , hsl(2, 100%, 44%) ${percent2}%, hsl(240, 2%, 91%) ${percent2}%)`;
    };

    fillNoOfTeamsColor();
  }, [minNoOfTeams, maxNoOfTeams]);

  useEffect(() => {
    if (isUnlimitedNoOfTeams) {
      setMinNoOfTeams(initialFilterStateValues?.minNoOfTeamsValue);
      setMaxNoOfTeams(initialFilterStateValues?.maxNoOfTeamsValue);
    }
  }, [isUnlimitedNoOfTeams]);

  const leagueOccupancyRef = useRef();
  const minLeagueOccupancyRef = useRef();
  const maxLeagueOccupancyRef = useRef();

  //   *Actual values to store in Redux
  const [minLeagueOccupancy, setMinLeagueOccupancy] = useState(
    leagueFilterValues?.league_filter_league_occupancy?.minLeagueOccupancy ??
      initialFilterStateValues?.leagueOccupancyMinValue
  );
  const [maxLeagueOccupancy, setMaxLeagueOccupancy] = useState(
    leagueFilterValues?.league_filter_league_occupancy?.maxLeagueOccupancy ??
      initialFilterStateValues?.leagueOccupancyMaxValue
  );

  const handleMinOccupancySliderChange = (e) => {
    e.stopPropagation();
    setAnyFieldChanged(true);
    if (parseInt(e.target.value) - parseInt(maxLeagueOccupancy) <= minGap) {
      setMinLeagueOccupancy(e.target.value);
    }
  };
  const handleMaxOccupancySliderChange = (e) => {
    e.stopPropagation();
    setAnyFieldChanged(true);
    if (parseInt(minLeagueOccupancy) - parseInt(e.target.value) <= minGap) {
      setMaxLeagueOccupancy(e.target.value);
    }
  };
  const slideMinOccupancy = () => {
    setAnyFieldChanged(true);
    if (parseInt(minLeagueOccupancy) - parseInt(maxLeagueOccupancy) <= minGap) {
      setMinLeagueOccupancy(parseInt(maxLeagueOccupancy) - minGap);
    }
  };
  const slideMaxOccupancy = () => {
    setAnyFieldChanged(true);
    if (parseInt(maxLeagueOccupancy) - parseInt(minLeagueOccupancy) <= minGap) {
      setMaxLeagueOccupancy(parseInt(minLeagueOccupancy) + minGap);
    }
  };

  useEffect(() => {
    setAnyFieldChanged(true);
    const fillNoOfTeamsColor = () => {
      const percent1 =
        (minLeagueOccupancy /
          initialFilterStateValues?.leagueOccupancyMaxValue) *
        100;
      const percent2 =
        (maxLeagueOccupancy /
          initialFilterStateValues?.leagueOccupancyMaxValue) *
        100;

      leagueOccupancyRef.current.style.background = `linear-gradient(to right, hsl(240, 2%, 91%) ${percent1}% , hsl(2, 100%, 44%) ${percent1}% , hsl(2, 100%, 44%) ${percent2}%, hsl(240, 2%, 91%) ${percent2}%)`;
    };

    fillNoOfTeamsColor();
  }, [minLeagueOccupancy, maxLeagueOccupancy]);

  const [noOfTeams, setNoOfTeams] = useState(
    leagueFilterValues?.league_filter_no_of_teams?.value ??
      initialFilterStateValues?.noOfTeams?.value
  );
  const [noOfTeamsInputBlurred, setNoOfTeamsInputBlurred] = useState(false);

  const updateNoOfTeams = (e) => {
    setAnyFieldChanged(true);
    setNoOfTeams(e.target.value);
  };

  const [isOnlyMyLeague, setIsOnlyMyLeague] = useState(
    leagueFilterValues?.league_filter_only_my_league ??
      initialFilterStateValues?.isOnlyMyLeague
  );
  const updateOnlyMyLeague = () => {
    setAnyFieldChanged(true);
    setIsOnlyMyLeague((value) => !value);
  };

  const [isSponsorLeagues, setIsSponsorLeagues] = useState(
    leagueFilterValues?.league_filter_sponcer_league ??
      initialFilterStateValues?.isSponsorLeagues
  );
  const updateSponsorLeagues = () => {
    setAnyFieldChanged(true);
    setIsSponsorLeagues((value) => !value);
  };

  const [isLastChanceToJoin, setIsLastChanceToJoin] = useState(
    leagueFilterValues?.league_filter_special_tag_last_chance_to_join_ ??
      initialFilterStateValues?.isLastChanceToJoin
  );
  const updateLastDateToJoin = () => {
    setAnyFieldChanged(true);
    setIsLastChanceToJoin((value) => !value);
  };

  const [isPopular, setIsPopular] = useState(
    leagueFilterValues?.league_filter_special_tag_popular_ ??
      initialFilterStateValues?.isPopular
  );
  const updatePopular = () => {
    setAnyFieldChanged(true);
    setIsPopular((value) => !value);
  };

  const [isNew, setIsNew] = useState(
    leagueFilterValues?.league_filter_special_tag_new_ ??
      initialFilterStateValues?.isNew
  );
  const updateNew = () => {
    setAnyFieldChanged(true);
    setIsNew((value) => !value);
  };

  //   * Reset League Filters
  useEffect(() => {
    if (isLeaguesFilterReset) {
      setIsUnlimitedNoOfTeams(initialFilterStateValues?.unlimitedNoOfTeams);
      setMinNoOfTeams(initialFilterStateValues?.minNoOfTeamsValue);
      setMaxNoOfTeams(initialFilterStateValues?.maxNoOfTeamsValue);
      setMinLeagueOccupancy(initialFilterStateValues?.leagueOccupancyMinValue);
      setMaxLeagueOccupancy(initialFilterStateValues?.leagueOccupancyMaxValue);
      setNoOfTeams(initialFilterStateValues?.noOfTeams?.value);
      setNoOfTeamsInputBlurred(false);
      setIsOnlyMyLeague(initialFilterStateValues?.isOnlyMyLeague);
      setIsSponsorLeagues(initialFilterStateValues?.isSponsorLeagues);
      setIsLastChanceToJoin(initialFilterStateValues?.isLastChanceToJoin);
      setIsPopular(initialFilterStateValues?.isPopular);
      setIsNew(initialFilterStateValues?.isNew);

      dispatch(league.resetLeagueFilter());
      toggleLeaguesFilterReset();
      if (leagueTypeKey === "public-classic") {
        updateTriggerClassicPublicLeagueRefetch(true);
      }
      setAnyFieldChanged(false);
    }
  }, [
    isLeaguesFilterReset,
    toggleLeaguesFilterReset,
    dispatch,
    leagueTypeKey,
    toggleLeagueFilterPopup,
    updateTriggerClassicPublicLeagueRefetch,
  ]);

  //   * Apply League Filters
  useEffect(() => {
    if (isLeaguesFilterApply) {
      const payload = {
        league_filter_max_no_of_teams: {
          isUnlimitedNoOfTeams,
          minNoOfTeams,
          maxNoOfTeams,
        },
        league_filter_league_occupancy: {
          minLeagueOccupancy,
          maxLeagueOccupancy,
        },
        league_filter_no_of_teams: noOfTeamsInputBlurred
          ? noOfTeamsData?.find((item) => item?.value === noOfTeams)
          : null,
        league_filter_only_my_league: isOnlyMyLeague,
        league_filter_sponcer_league: isSponsorLeagues,
        league_filter_special_tag_last_chance_to_join_: isLastChanceToJoin,
        league_filter_special_tag_popular_: isPopular,
        league_filter_special_tag_new_: isNew,
      };

      dispatch(league.applyLeagueFilter(payload));
      toggleLeaguesFilterApply();
      toggleLeagueFilterPopup();
    }
  }, [
    isLeaguesFilterApply,
    toggleLeaguesFilterApply,
    toggleLeagueFilterPopup,
    isUnlimitedNoOfTeams,
    minNoOfTeams,
    maxNoOfTeams,
    minLeagueOccupancy,
    maxLeagueOccupancy,
    noOfTeams,
    isOnlyMyLeague,
    isSponsorLeagues,
    isLastChanceToJoin,
    isPopular,
    isNew,
    dispatch,
    noOfTeamsInputBlurred,
  ]);

  const getEnabledOrDisabledFieldForLeagueType = (applicableLeagueType) => {
    if (isJoinLeaguePage) {
      return !applicableLeagueType?.joined;
    }
    switch (leagueTypeKey) {
      case "featured":
        return !applicableLeagueType?.feature;
      case "pinned":
        return !applicableLeagueType?.pinned;
      case "mini":
        return !applicableLeagueType?.mini;
      case "private-classic":
        return !applicableLeagueType?.classic;
      case "private-hth":
        return !applicableLeagueType?.hth;
      case "public-classic":
        return !applicableLeagueType?.public;
      default:
        return true;
    }
  };

  const getDisabledValueForField = (key, type = "common") => {
    if (type === "common") {
      const fieldFilterData = commonFilterData?.find(
        (item) => item?.name === key
      );

      const applicableLeagueTypesForField = fieldFilterData?.applicable;

      return getEnabledOrDisabledFieldForLeagueType(
        applicableLeagueTypesForField
      );
    }
    if (type === "specialtag") {
      const fieldFilterData = specialTagFilterData?.find(
        (item) => item?.name === key
      );
      const applicableLeagueTypesForField = fieldFilterData?.applicable;

      return getEnabledOrDisabledFieldForLeagueType(
        applicableLeagueTypesForField
      );
    }
  };

  // * Disable the Confirm button intiially
  useEffect(() => {
    const isMaxNoOfTeamsInitialState = () => {
      if (
        !isUnlimitedNoOfTeams &&
        minNoOfTeams === initialFilterStateValues.minNoOfTeamsValue &&
        maxNoOfTeams === initialFilterStateValues.maxNoOfTeamsValue
      ) {
        return true;
      }
      return false;
    };

    const isLeagueOccupancyInitialState = () => {
      if (
        minLeagueOccupancy ===
          initialFilterStateValues.leagueOccupancyMinValue &&
        maxLeagueOccupancy === initialFilterStateValues.leagueOccupancyMaxValue
      ) {
        return true;
      }
      return false;
    };

    const isNoOfTeamsPerUserInitialState = () => {
      return (
        noOfTeams === initialFilterStateValues.noOfTeams?.value &&
        !noOfTeamsInputBlurred
      );
    };

    const isOnlyMyLeagueInitialState = () => {
      return isOnlyMyLeague === initialFilterStateValues.isOnlyMyLeague;
    };

    const isSponsorLeaguesInitialState = () => {
      return isSponsorLeagues === initialFilterStateValues.isSponsorLeagues;
    };

    const isLastChanceToJoinInitialState = () => {
      return isLastChanceToJoin === initialFilterStateValues.isLastChanceToJoin;
    };
    const isPopularInitialState = () => {
      return isPopular === initialFilterStateValues.isPopular;
    };

    const isNewInitialState = () => {
      return isNew === initialFilterStateValues.isNew;
    };

    if (
      anyFieldChanged &&
      (!isMaxNoOfTeamsInitialState() ||
        !isLeagueOccupancyInitialState() ||
        !isNoOfTeamsPerUserInitialState() ||
        !isOnlyMyLeagueInitialState() ||
        !isSponsorLeaguesInitialState() ||
        !isLastChanceToJoinInitialState() ||
        !isPopularInitialState() ||
        !isNewInitialState())
    ) {
      getEnabledOrDisabledFilterApplyButton(false);
    } else {
      getEnabledOrDisabledFilterApplyButton(true);
    }
  }, [
    anyFieldChanged,
    getEnabledOrDisabledFilterApplyButton,
    isLastChanceToJoin,
    isNew,
    isOnlyMyLeague,
    isPopular,
    isSponsorLeagues,
    isUnlimitedNoOfTeams,
    maxLeagueOccupancy,
    maxNoOfTeams,
    minLeagueOccupancy,
    minNoOfTeams,
    noOfTeams,
    noOfTeamsInputBlurred,
  ]);

  return (
    <>
      <div className="si-filter__wrap">
        <div className="si-filter__container">
          <h2 className="si-filter__title">
            <span>
              {getTranslations(
                "max_number_of_teams_league_filter_title",
                "Max. Number of teams"
              )}
            </span>
          </h2>

          <div className="si-affordable__toggle">
            <form
              className="si-radioBtn"
              action=""
              method="get"
              role="switch"
              aria-checked={false}
              aria-label=""
            >
              <div className="si-radioBtn__wrap">
                <input
                  type="checkbox"
                  name="radio-toggle"
                  id="unlimited_"
                  className={`${
                    getDisabledValueForField(
                      "league_filter_max_no_of_teams",
                      "common"
                    )
                      ? "si-disabled"
                      : ""
                  }`}
                  checked={isUnlimitedNoOfTeams}
                  onChange={toggleUnlimitedNoOfTeams}
                  disabled={getDisabledValueForField(
                    "league_filter_max_no_of_teams",
                    "common"
                  )}
                />
                <div className="si-radioBtn__lbl">
                  <span>
                    {getTranslations(
                      "unlimited_league_filter_title",
                      "Unlimited"
                    )}
                  </span>
                </div>
                <div className="si-radioBtn__action">
                  <span className="si-radioBtn__toggle"></span>
                </div>
              </div>
            </form>
          </div>
          <div className="si-filter__ranger">
            <div className="si-filter__ranger-head ">
              <span>{minNoOfTeams}</span>
              <span>{maxNoOfTeams}</span>
            </div>
            <div
              className={`si-filter__ranger-body ${
                getDisabledValueForField(
                  "league_filter_max_no_of_teams",
                  "common"
                ) || isUnlimitedNoOfTeams
                  ? "si-disabled"
                  : ""
              }`}
            >
              <div className="container">
                <div className="slider-track" ref={noOfTeamsRef}></div>
                <input
                  type="range"
                  min={initialFilterStateValues?.minNoOfTeamsValue}
                  max={initialFilterStateValues?.maxNoOfTeamsValue}
                  value={minNoOfTeams}
                  ref={minNoOfTeamsRef}
                  disabled={
                    getDisabledValueForField(
                      "league_filter_max_no_of_teams",
                      "common"
                    ) || isUnlimitedNoOfTeams
                  }
                  onChange={handleMinNoOfTeamsSliderChange}
                  onInput={() => slideMinNoOfTeams()}
                />
                <input
                  type="range"
                  min={initialFilterStateValues?.minNoOfTeamsValue}
                  max={initialFilterStateValues?.maxNoOfTeamsValue}
                  value={maxNoOfTeams}
                  ref={maxNoOfTeamsRef}
                  disabled={
                    getDisabledValueForField(
                      "league_filter_max_no_of_teams",
                      "common"
                    ) || isUnlimitedNoOfTeams
                  }
                  onChange={handleMaxNoOfTeamsSliderChange}
                  onInput={() => slideMaxNoOfTeams()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="si-filter__container">
          <h2 className="si-filter__title">
            <span>
              {getTranslations(
                "league_occupancy_filter_title",
                "League Occupancy %"
              )}
            </span>
          </h2>
          <div className="si-filter__ranger">
            <div className="si-filter__ranger-head ">
              <span>{minLeagueOccupancy}%</span>
              <span>{maxLeagueOccupancy}%</span>
            </div>
            <div
              className={`si-filter__ranger-body ${
                getDisabledValueForField(
                  "league_filter_league_occupancy",
                  "common"
                )
                  ? "si-disabled"
                  : ""
              }`}
            >
              <div className="container">
                <div className="slider-track" ref={leagueOccupancyRef}></div>
                <input
                  type="range"
                  min={initialFilterStateValues?.leagueOccupancyMinValue}
                  max={initialFilterStateValues?.leagueOccupancyMaxValue}
                  value={minLeagueOccupancy}
                  ref={minLeagueOccupancyRef}
                  disabled={getDisabledValueForField(
                    "league_filter_league_occupancy",
                    "common"
                  )}
                  onChange={handleMinOccupancySliderChange}
                  onInput={() => slideMinOccupancy()}
                />
                <input
                  type="range"
                  min={initialFilterStateValues?.leagueOccupancyMinValue}
                  max={initialFilterStateValues?.leagueOccupancyMaxValue}
                  value={maxLeagueOccupancy}
                  ref={maxLeagueOccupancyRef}
                  disabled={getDisabledValueForField(
                    "league_filter_league_occupancy",
                    "common"
                  )}
                  onChange={handleMaxOccupancySliderChange}
                  onInput={() => slideMaxOccupancy()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="si-filter__container">
          <div
            className={`si-affordable__toggle si-affordable__toggle--selectBox ${
              getDisabledValueForField("league_filter_no_of_teams", "common")
                ? "si-disabled"
                : ""
            }`}
          >
            <div className="si-input ">
              <label className="si-lbl" htmlFor="noOfTeamPerUser">
                {getTranslations(
                  "league_filter_no_of_teams",
                  "No. of teams per user"
                )}
              </label>
              <InputSelectBox
                selectBoxId={"noOfTeamPerUser"}
                isLeagueTypeSelect={true}
                handleChange={updateNoOfTeams}
                selectedOption={noOfTeams}
                options={noOfTeamsData}
                disabled={getDisabledValueForField(
                  "league_filter_no_of_teams",
                  "common"
                )}
                handleBlur={() => {
                  setNoOfTeamsInputBlurred(true);
                  setAnyFieldChanged(true);
                }}
              />
            </div>
          </div>
          <div
            className={`si-affordable__toggle ${
              getDisabledValueForField("league_filter_only_my_league", "common")
                ? "si-disabled"
                : ""
            }`}
          >
            <form
              className="si-radioBtn"
              action=""
              method="get"
              role="switch"
              aria-checked={false}
              aria-label=""
            >
              <div className="si-radioBtn__wrap">
                <input
                  type="checkbox"
                  name="radio-toggle"
                  id="onlyMyLeague"
                  checked={isOnlyMyLeague}
                  onChange={updateOnlyMyLeague}
                  disabled={getDisabledValueForField(
                    "league_filter_only_my_league",
                    "common"
                  )}
                  className={`${
                    getDisabledValueForField(
                      "league_filter_only_my_league",
                      "common"
                    )
                      ? "si-disabled"
                      : ""
                  }`}
                />
                <div className="si-radioBtn__lbl">
                  <span>
                    {getTranslations(
                      "league_filter_only_my_league",
                      "Only My League"
                    )}
                  </span>
                </div>
                <div className="si-radioBtn__action">
                  <span className="si-radioBtn__toggle"></span>
                </div>
              </div>
            </form>
          </div>
          <div
            className={`si-affordable__toggle ${
              getDisabledValueForField("league_filter_sponcer_league", "common")
                ? "si-disabled"
                : ""
            }`}
          >
            <form
              className="si-radioBtn"
              action=""
              method="get"
              role="switch"
              aria-checked={false}
              aria-label=""
            >
              <div className="si-radioBtn__wrap">
                <input
                  type="checkbox"
                  name="radio-toggle"
                  id="sponsorLeague"
                  checked={isSponsorLeagues}
                  onChange={updateSponsorLeagues}
                  disabled={getDisabledValueForField(
                    "league_filter_sponcer_league",
                    "common"
                  )}
                  className={`${
                    getDisabledValueForField(
                      "league_filter_sponcer_league",
                      "common"
                    )
                      ? "si-disabled"
                      : ""
                  }`}
                />
                <div className="si-radioBtn__lbl">
                  <span>
                    {getTranslations(
                      "league_filter_sponcer_league",
                      "Sponsor Leagues"
                    )}
                  </span>
                </div>
                <div className="si-radioBtn__action">
                  <span className="si-radioBtn__toggle"></span>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="si-filter__container si-filter__container--bg">
          <h2 className="si-filter__title">
            <span>
              {getTranslations("special_tags_filter_title", "Special tags")}
            </span>
          </h2>
          <div className="si-filter__checkbox">
            <ul>
              <li>
                <InputCheckbox
                  checkboxID={"checkBox1"}
                  label={getTranslations(
                    "league_filter_special_tag_popular_",
                    "Popular"
                  )}
                  onChange={updatePopular}
                  checkboxState={getDisabledValueForField(
                    "league_filter_special_tag_popular_",
                    "specialtag"
                  )}
                  checked={isPopular}
                />
              </li>
              <li>
                <InputCheckbox
                  checkboxID={"checkBox2"}
                  label={getTranslations(
                    "league_filter_special_tag_last_chance_to_join_",
                    "Last Chance to Join"
                  )}
                  onChange={updateLastDateToJoin}
                  checked={isLastChanceToJoin}
                  checkboxState={getDisabledValueForField(
                    "league_filter_special_tag_last_chance_to_join_",
                    "specialtag"
                  )}
                />
              </li>
              <li>
                <InputCheckbox
                  checkboxID={"checkBox3"}
                  label={getTranslations(
                    "league_filter_special_tag_new_",
                    "New"
                  )}
                  onChange={updateNew}
                  checked={isNew}
                  checkboxState={getDisabledValueForField(
                    "league_filter_special_tag_new_",
                    "specialtag"
                  )}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export { LeaguesFilter };
