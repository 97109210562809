import React from "react";

const InputSelectBox = ({
  handleChange = () => {},
  selectBoxLbl,
  isLeagueTypeSelect = null,
  selectBoxId,
  selectedOption = "",
  options = [],
  valueKey = null,
  disabled = false,
  handleBlur = () => {},
}) => {
  return (
    <>
      <div className="si-input__selectbox">
        <form className="" action="" method="" aria-label="">
          <div className="si-form__control">
            <div className="si-input ">
              {selectBoxLbl && (
                <label className="si-lbl" htmlFor={selectBoxId}>
                  {selectBoxLbl}
                </label>
              )}
              <select
                id={selectBoxId}
                name={selectBoxId}
                aria-disabled="true"
                aria-expanded="true"
                aria-label=""
                className={`${disabled ? "si-disabled" : ""}`}
                disabled={disabled}
                value={
                  selectedOption?.value ? selectedOption?.value : selectedOption
                }
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={(e) => handleBlur(e)}
              >
                {options?.map((item, index) => {
                  if (item?.child) {
                    return (
                      <optgroup
                        label={
                          isLeagueTypeSelect
                            ? item?.optionText
                              ? item?.optionText
                              : item?.name
                            : item?.value
                        }
                      >
                        {item?.child?.map((childItem, index) => {
                          if (isLeagueTypeSelect && !childItem?.count) {
                            return null;
                          }
                          return (
                            <option key={index} value={childItem?.value}>
                              {childItem?.optionText
                                ? childItem?.optionText
                                : childItem?.value}
                            </option>
                          );
                        })}
                      </optgroup>
                    );
                  }
                  return (
                    <option key={index} value={item?.value}>
                      {item?.optionText ? item?.optionText : item?.value}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default InputSelectBox;
