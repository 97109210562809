import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Accordion } from "../Accordion";
import AccordionChild from "../Accordion/AccordionChild";
import { miniLeaguesData, getMiniLeaguesData } from "./miniLeaguesData";
import MiniLeagueBody from "../MiniLeagueBody";
import MiniLeagueHeadLHS from "../MiniLeagueHeadLHS";
import { league } from "../../redux/actions";

const PrizeWrap = ({ prizePara }) => {
  const [miniLeagues, setMiniLeagues] = useState([]);
  const [activeIndex, setActiveIndex] = useState(miniLeagues?.[0]?.isActive);
  const [isUpdateAccordion, setIsUpdateAccordion] = useState(false);
  const dispatch = useDispatch();
  const leagueState = useSelector((state) => state.league);
  const { miniLeaguesPrizesSuccess, miniLeaguesPrizesData } = leagueState;

  useEffect(() => {
    dispatch(league.getMiniLeaguesPrizes());
  }, []);

  useEffect(() => {
    if (miniLeaguesPrizesSuccess) {
      const updatedMiniLeaguesData = getMiniLeaguesData(miniLeaguesPrizesData);
      setMiniLeagues(updatedMiniLeaguesData);
    }
  }, [miniLeaguesPrizesSuccess, miniLeaguesPrizesData]);

  const updateMiniLeagues = (data) => setMiniLeagues(data);

  const updateActiveIndex = (index, value) => {
    if (!value) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
    setIsUpdateAccordion(true);
  };

  return (
    <>
      <Accordion
        data={miniLeagues}
        activeIndex={activeIndex}
        isUpdateAccordion={isUpdateAccordion}
        updateData={updateMiniLeagues}
        accordionChild={miniLeagues?.map((miniLeague, index) => {
          return (
            <AccordionChild
              key={index}
              accordionConfig={{ showAccordRHS: false }}
              headComponentLHS={<MiniLeagueHeadLHS miniLeague={miniLeague} />}
              bodyComponent={
                <MiniLeagueBody
                  miniLeague={miniLeague}
                  showViewMiniLeagues={true}
                />
              }
              isActive={miniLeague?.isActive}
              index={index}
              updateActiveIndex={updateActiveIndex}
            />
          );
        })}
      />
    </>
  );
};

export { PrizeWrap };
