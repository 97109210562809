import { feeds, leaderboard } from "../../apis/services";
import {
  GET_COUNTRY_LEAGUE_LEADER_BOARD,
  GET_DRIVER_LEAGUE_LEADER_BOARD,
  GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD,
  GET_GLOBAL_LEAGUE_LEADER_BOARD,
  GET_H2H_LEAGUE_LEADER_BOARD,
  GET_MINI_LEAGUE_LEADER_BOARD,
  GET_PUBLIC_LEAGUE_LEADER_BOARD,
  GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD,
  GET_USER_COUNTRY_LEAGUE_LEADER_BOARD,
  GET_USER_DRIVER_LEAGUE_LEADER_BOARD,
  GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD,
  GET_USER_GLOBAL_LEAGUE_LEADER_BOARD,
  GET_USER_MINI_LEAGUE_LEADER_BOARD,
  GET_USER_PRIVATE_LEAGUE_LEADER_BOARD,
  GET_USER_PUBLIC_LEAGUE_LEADER_BOARD,
  RESET_LEADER_BOARD,
} from "../../constants/leaderboard";

export const getH2HLeaderBoard = (params) => {
  return {
    type: GET_H2H_LEAGUE_LEADER_BOARD,
    payload: leaderboard.getH2HLeaderBoard(params),
  };
};

export const getUserPrivateLeaderBoard = (params) => {
  return {
    type: GET_USER_PRIVATE_LEAGUE_LEADER_BOARD,
    payload: leaderboard.getUserPrivateLeaderBoard(params),
  };
};

export const getUserPublicLeaderBoard = (params) => {
  return {
    type: GET_USER_PUBLIC_LEAGUE_LEADER_BOARD,
    payload: leaderboard.getUserPublicLeaderBoard(params),
  };
};

export const getPublicLeaderBoard = (params) => {
  return {
    type: GET_PUBLIC_LEAGUE_LEADER_BOARD,
    payload: feeds.getPublicLeaderBoard(params),
  };
};

export const resetLeaderBoard = () => {
  return {
    type: RESET_LEADER_BOARD,
  };
};

export const getGlobalLeaderBoard = (params) => {
  return {
    type: GET_GLOBAL_LEAGUE_LEADER_BOARD,
    payload: feeds.getGlobalLeaderBoard(params),
  };
};

export const getUnjoinedSponsoredPrivateLeaderBoard = (params) => {
  return {
    type: GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD,
    payload: feeds.getUnjoinedSponsoredPrivateLeaderBoard(params),
  };
};

export const getUserGlobalLeaderBoard = (params) => {
  return {
    type: GET_USER_GLOBAL_LEAGUE_LEADER_BOARD,
    payload: leaderboard.getUserGlobalLeaderBoard(params),
  };
};

export const getFavoriteTeamLeaderBoard = (params) => {
  return {
    type: GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD,
    payload: feeds.getFavoriteTeamLeaderBoard(params),
  };
};

export const getUserFavoriteTeamLeaderBoard = (params) => {
  return {
    type: GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD,
    payload: leaderboard.getUserFavoriteTeamLeaderBoard(params),
  };
};

export const getCountryLeaderBoard = (params) => {
  return {
    type: GET_COUNTRY_LEAGUE_LEADER_BOARD,
    payload: feeds.getCountryLeaderBoard(params),
  };
};

export const getUserCountryLeaderBoard = (params) => {
  return {
    type: GET_USER_COUNTRY_LEAGUE_LEADER_BOARD,
    payload: leaderboard.getUserCountryLeaderBoard(params),
  };
};

export const getDriverLeaderBoard = (params) => {
  return {
    type: GET_DRIVER_LEAGUE_LEADER_BOARD,
    payload: feeds.getDriverLeaderBoard(params),
  };
};

export const getUserDriverLeaderBoard = (params) => {
  return {
    type: GET_USER_DRIVER_LEAGUE_LEADER_BOARD,
    payload: leaderboard.getUserDriverLeaderBoard(params),
  };
};

export const getUserMiniLeagueLeaderBoard = (params) => {
  return {
    type: GET_USER_MINI_LEAGUE_LEADER_BOARD,
    payload: leaderboard.getUserMiniLeagueLeaderBoard(params),
  };
};

export const getMiniLeagueLeaderBoard = (params) => {
  return {
    type: GET_MINI_LEAGUE_LEADER_BOARD,
    payload: feeds.getMiniLeagueLeaderBoard(params),
  };
};
