import {
  FETCH_SEASON_PERFORMANCE_FULFILLED,
  FETCH_SEASON_PERFORMANCE_PENDING,
  FETCH_SEASON_PERFORMANCE_REJECTED,
} from "../../constants/seasonPerformance";

const initialState = {
  seasonPerformanceLoading: false,
  seasonPerformanceError: false,
  seasonPerformanceFulfilled: false,
  seasonPerformanceData: null,
};

const seasonPerformance = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SEASON_PERFORMANCE_PENDING:
      return {
        ...state,
        seasonPerformanceLoading: true,
        seasonPerformanceError: false,
        seasonPerformanceFulfilled: false,
        seasonPerformanceData: null,
      };
    case FETCH_SEASON_PERFORMANCE_REJECTED:
      return {
        ...state,
        seasonPerformanceLoading: false,
        seasonPerformanceError: true,
        seasonPerformanceFulfilled: false,
        seasonPerformanceData: null,
      };
    case FETCH_SEASON_PERFORMANCE_FULFILLED:
      return {
        ...state,
        seasonPerformanceLoading: false,
        seasonPerformanceError: false,
        seasonPerformanceFulfilled: true,
        seasonPerformanceData: action.payload.data,
      };
    default:
      return state;
  }
};

export default seasonPerformance;
