import React from "react";

const InputField = ({
  inputID,
  label,
  inputType,
  ariaLabel,
  placeholderLabel,
  isDisabled = false,
  inputText = "",
  handleInput,
}) => {
  return (
    <>
      <div className="si-input ">
        <label className="si-lbl" htmlFor={inputID}>
          {label}
        </label>
        <input
          type={inputType}
          id={inputID}
          placeholder={placeholderLabel}
          spellCheck="false"
          aria-label={ariaLabel}
          disabled={isDisabled}
          value={inputText}
          onChange={handleInput}
        />
      </div>
    </>
  );
};

export { InputField };
