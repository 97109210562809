import React from "react";

const SectionTitle = ({ pageTitle, children }) => {
  return (
    <>
      <h2 className="si-league__heading">
        <span>{pageTitle}</span>
        {children}
      </h2>
    </>
  );
};

export default SectionTitle;
