import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getLeagueTypeForDL,
  getPath,
  getTranslations,
  isAndroid,
  isIOS,
} from "../../../Common/utils";
import { league } from "../../../redux/actions";
import { CreateALeague, Overlays } from "../../../ReusableComponents";
import useDataLayer from "../../../Common/hooks/useDataLayer";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  MAX_LEAGUE_NAME_CHARACTERS,
  MIN_LEAGUE_NAME_CHARACTERS,
  WEB,
  WEBVIEW,
} from "../../../Common/constants";

const CreateLeagueOverlay = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { toggleCreateOverlay, createLeagueData } = useSelector(
    (state) => state.league
  );
  const translationState = useSelector((state) => state.translation);
  const userState = useSelector((state) => state.user);

  const pushToDataLayer = useDataLayer();

  const webview = userState?.webview;

  const leagueType = createLeagueData?.leagueType;

  const pushSelectTeamsAndCancelDataLayer = (type = "CANCEL") => {
    const dataLayerObject = {
      actionType: `${type === "CANCEL" ? "Cancel" : "Select Teams"}`,
      clickText: type === "CANCEL" ? "Cancel" : "Select Teams",
      path: type === "CANCEL" ? "/leagues" : "/leagues/select-team",
      pageName: "Leagues",
      navigationElement: "leagueTypeSelectionOverlay",
      leagueType: getLeagueTypeForDL(leagueType),
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("createLeague", dataLayerObject);
  };

  const toggleCreateLeagueOverlay = () => {
    pushSelectTeamsAndCancelDataLayer("CANCEL");
    dispatch(league.toggleCreateLeagueOverlay());
  };

  const handleConfirm = () => {
    pushSelectTeamsAndCancelDataLayer("SELECT_TEAMS");
    navigate(getPath(translationState, "leagueSelectTeam"), {
      state: {
        type: "create-league",
      },
    });
  };

  const canCreateLeague = () => {
    let isValid = true;
    for (const key in createLeagueData) {
      if (Object.hasOwnProperty.call(createLeagueData, key)) {
        const element = createLeagueData[key];
        if (
          key === "leagueName" &&
          (!element.trim() ||
            element.trim().length < MIN_LEAGUE_NAME_CHARACTERS ||
            element.trim().length > MAX_LEAGUE_NAME_CHARACTERS)
        ) {
          isValid = false;
        } else if (
          key === "maxParticipants" &&
          createLeagueData?.leagueType !== "h2h" &&
          element < 5
        ) {
          isValid = false;
        } else if (!element) {
          isValid = false;
        }
      }
    }
    return isValid;
  };

  if (!toggleCreateOverlay) {
    return <></>;
  }
  return (
    toggleCreateOverlay && (
      <>
        <Overlays
          overlayName="si-popup__wrap--leagues"
          overlayTitle={getTranslations(
            "create_league_popup_title",
            "Create a League"
          )}
          overlaySubTitle={getTranslations(
            "create_league_popup_sub_title",
            "Create a league and invite your friends."
          )}
          btnPrimary={getTranslations(
            "create_league_popup_confirm_button",
            "Select Teams"
          )}
          overlayConfig={{ cancelBtn: true, showOverlaySub_Title: true }}
          component={<CreateALeague handleSelect={() => null} />}
          onConfirmClick={handleConfirm}
          onCancelClick={toggleCreateLeagueOverlay}
          confirmButtonState={!canCreateLeague()}
        />
      </>
    )
  );
};

export default CreateLeagueOverlay;
