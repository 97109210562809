import {
  GET_GEOLOCATION,
  SAVE_GEOLOCATION_FROM_SESSION_STORAGE,
} from "../../constants/geolocation";
import * as feeds from "../../apis/services/feeds";

export const getGeolocation = () => {
  return {
    type: GET_GEOLOCATION,
    payload: feeds.getGeolocation(),
  };
};

export const saveGeolocationDataFromSessionStorage = (geolocationData) => {
  return {
    type: SAVE_GEOLOCATION_FROM_SESSION_STORAGE,
    payload: { data: geolocationData },
  };
};
