import React from "react";
import config from "../../../Common/config";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import { useSelector } from "react-redux";
import { getYearForImagePath } from "../../../Common/utils";
function PlayerTeamJersyNo({
  PlayerTeamJersyNo,
  player,
  currentSeasonYear = null,
}) {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  return (
    <>
      <div className="si-player__jersey">
        <img
          src={`${CLOUDINARY_IMAGE_BASE_URL}${webConfigData?.imagePaths?.jerseyNo?.replace(
            "{seasonYear}",
            getYearForImagePath(webConfigData, currentSeasonYear)
          )}${PlayerTeamJersyNo}.svg?v=${IMAGE_VERSION}`}
          alt={player?.PlayerId}
        />
      </div>
    </>
  );
}

export { PlayerTeamJersyNo };
