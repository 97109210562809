import React, { useEffect } from "react";

const Accordion = ({
  data,
  activeIndex,
  isUpdateAccordion,
  updateData,
  accordionChild,
  isDisabled,
  accordionId = "",
}) => {
  useEffect(() => {
    if (isUpdateAccordion) {
      if (activeIndex === -1) {
        const updatedData = data.map((item) => ({ ...item, isActive: false }));
        updateData(updatedData);
        return;
      }
      const updatedData = data.map((item, index) => {
        const { isActive } = item;

        return {
          ...item,
          isActive: index === activeIndex ? !isActive : false,
        };
      });

      updateData(updatedData);
    }
  }, [activeIndex, isUpdateAccordion]);

  return (
    <>
      <div
        id={`accordionGroup ${accordionId}`}
        className={`si-accordion ${isDisabled ? "si-disabled" : ""}`}
      >
        {accordionChild}
      </div>
    </>
  );
};

export { Accordion };
