import { RE_RENDER_LOCAL_STORAGE_KEY } from "../../../Common/constants";
import {
  FETCH_USER_PENDING,
  FETCH_USER_REJECTED,
  FETCH_USER_FULFILLED,
  SET_WEBVIEW,
  UPDATE_TEAM_COUNT,
  UPDATE_NEW_TEAM_COUNT,
  RESET_USER_REDUCER,
} from "../../constants/user";

const user = (state = {}, action) => {
  switch (action.type) {
    case FETCH_USER_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_USER_REJECTED:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        errorData: action.payload.data,
      };
    case FETCH_USER_FULFILLED:
      localStorage.setItem(RE_RENDER_LOCAL_STORAGE_KEY, Date.now());
      //? Handled this because backend can't
      action.payload.data.FirstName = action.payload.data.FirstName?.replace(
        /\u0000/gi,
        ""
      );
      action.payload.data.LastName = action.payload.data.LastName?.replace(
        /\u0000/gi,
        ""
      );

      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload.data,
      };
    case SET_WEBVIEW:
      return { ...state, webview: true };
    case UPDATE_TEAM_COUNT:
      const TeamCount = state.data.TeamCount + 1;
      return { ...state, data: { ...state.data, TeamCount } };
    case UPDATE_NEW_TEAM_COUNT:
      return { ...state, data: { ...state.data, TeamCount: action.payload } };
    case RESET_USER_REDUCER: {
      return {};
    }
    default:
      return state;
  }
};

export default user;
