import React from "react";

const Tabs = ({
  data,
  active,
  handleClick = () => {
    return null;
  },
  showIconText = "",
  showIcon = false,
  iconClass = "",
  type = "text",
  disabledData = "",
}) => {
  const activeClass = (name) => {
    return name === active ? "si-active" : "";
  };

  return (
    <>
      <div className="si-tabs__wrap">
        {type === "text" ? (
          <ul>
            {data.map((name, index) => (
              <li key={index}>
                <button
                  className={`${activeClass(name)} ${
                    !Array.isArray(disabledData)
                      ? disabledData === name
                        ? "si-disabled"
                        : ""
                      : disabledData.includes(name)
                      ? "si-disabled"
                      : ""
                  }`}
                  onClick={() => handleClick(name)}
                  aria-label={name}
                  disabled={
                    !Array.isArray(disabledData)
                      ? disabledData === name
                      : disabledData.includes(name)
                      ? true
                      : false
                  }
                >
                  {name}
                  {showIconText === name && showIcon && (
                    <i className={iconClass}></i>
                  )}
                </button>
              </li>
            ))}
          </ul>
        ) : type === "object" ? (
          <ul>
            {data.map((item, index) => (
              <li key={index}>
                <button
                  className={`${activeClass(item.id)} ${
                    item?.isDisabled ? "si-disabled" : ""
                  }`}
                  onClick={() => handleClick(item.id)}
                  aria-label={item.text}
                  disabled={item?.isDisabled}
                >
                  {item.text}
                  {showIconText === item.id && showIcon && (
                    <i className={iconClass}></i>
                  )}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export { Tabs };
