import {
  FETCH_TRANSLATIONS_PENDING,
  FETCH_TRANSLATIONS_REJECTED,
  FETCH_TRANSLATIONS_FULFILLED,
  SET_LANGUAGE,
} from "../../constants/translation";

const translation = (state = {}, action) => {
  switch (action.type) {
    case FETCH_TRANSLATIONS_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_TRANSLATIONS_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_TRANSLATIONS_FULFILLED:
      window.translations = action.payload.data;
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: action.payload.data,
      };
    case SET_LANGUAGE:
      if(document.body.classList.contains("si-lang")) {
        document.body.classList.replace(/si-lang-*/, `si-lang-${action.payload}`)
      } else {
        document.body.classList.add(`si-lang-${action.payload}`)
      }
      
      return { ...state, language: action.payload };
    default:
      return state;
  }
};

export default translation;
