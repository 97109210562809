import React from "react";
import {
  getFixtureDateForLeagues,
  getFixtureDates,
  getFixtureMonth,
} from "../../../Common/utils/fixture";

const FixDates = ({ fixDates, fixtureStart, fixtureEnd, showNewLine }) => {
  const fixDatesWithMonth = getFixtureDateForLeagues([
    fixtureStart,
    fixtureEnd,
  ]);

  const fixDate = getFixtureDates([fixtureStart, fixtureEnd]);
  const fixMonthStart = getFixtureMonth(fixtureStart);
  const fixMonthEnd = getFixtureMonth(fixtureEnd);

  const monthText =
    fixMonthStart === fixMonthEnd
      ? fixMonthEnd
      : `${fixMonthStart} - ${fixMonthEnd}`;

  return (
    <>
      <div className="si-fix__dates">
        <div className="si-fix__dates-text">
          {!showNewLine ? (
            <span>{fixDates ? fixDates : fixDatesWithMonth}</span>
          ) : (
            <>
              <span>{fixDate}</span>
              <span>{monthText}</span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { FixDates };
