import React from "react";
import { Overlays } from "..";
import { getTranslations } from "../../../Common/utils";

const Announcement = ({
  show = false,
  title,
  description,
  cancelCTA,
  confirmCTA,
  imageURL,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  return (
    show && (
      <Overlays
        overlayName="si-popup__wrap--announcement"
        overlayTitle={title}
        overlaySubTitle={description}
        announcementText={getTranslations("announcement_tag", "Announcement")}
        btnPrimary={confirmCTA}
        btnSecondary={cancelCTA}
        overlayConfig={{
          cancelBtn: !!cancelCTA,
          submitBtn: !!confirmCTA,
          overlayHeaderThumbnail: true,
          showOverlaySub_Title: true,
          announcementTag: true,
        }}
        overlayThumbnailName={imageURL ? imageURL : "announcements/default.jpg"}
        onCancelClick={onCancel}
        onConfirmClick={onConfirm}
        confirmButtonState={""}
      />
    )
  );
};

export { Announcement };
