import {
  FETCH_FEEDBACK_QUESTIONS,
  SUBMIT_CONSTRUCTOR_CONSENT,
  SUBMIT_FEEDBACK_QUESTIONS,
} from "../../constants/complete-your-profile";
import { feeds, session } from "../../apis/services";

export function getFeedbackQuestions(params) {
  return {
    type: FETCH_FEEDBACK_QUESTIONS,
    payload: feeds.getFeedbackQuestions(params),
  };
}

export function submitFeedbackQuestions(params) {
  return {
    type: SUBMIT_FEEDBACK_QUESTIONS,
    payload: session.submitFeedbackQuestions(params),
  };
}

export function submitConstructorConsent(params) {
  return {
    type: SUBMIT_CONSTRUCTOR_CONSENT,
    payload: session.submitConstructorConsent(params),
  };
}
