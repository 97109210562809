import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { getTranslations } from "../../Common/utils";
import { CtaButton } from "../Buttons";

const PageNotFound = ({
  pageTitle,
  pageCaption,
  pageThumb,
  pageCta,
  pageCtaText,
  handleButtonClick = () => {},
}) => {
  return (
    <>
      <section className="si-pageNotFound__wrap">
        <div className="si-main__container">
          <div className="si-main__row">
            {pageTitle && (
              <h2 className="si-pageNotFound__title">{pageTitle}</h2>
            )}
            {pageThumb && (
              <div className="si-pageNotFound__thumb">
                <img
                  src={`${config.IMG_BASE}${pageThumb}?v=${IMAGE_VERSION}`}
                  alt={pageThumb}
                  loading="lazy"
                />
              </div>
            )}{" "}
            {pageCaption && (
              <p className="si-pageNotFound__caption" dangerouslySetInnerHTML={{
                __html: pageCaption
              }}></p>
            )}
            {pageCta && (
              <div className="si-pageNotFound__cta">
                <CtaButton
                  btnCls="si-btn si-btn__primary"
                  btnText={getTranslations(pageCtaText, "Reload")}
                  ariaLabel={getTranslations(pageCtaText, "Reload")}
                  onClickProp={() => handleButtonClick()}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export { PageNotFound };
