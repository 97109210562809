import React from "react";
import { PlayerCard } from "../../PlayerCard";

const RemovePlayer = ({
  player,
  playerCardConfig,
  isTurboDriver,
  isExtraTurboDriver = false,
  dataText = "",
  showPoints = false,
  isLateOnBoard = false,
  isFinalFix = false,
  team = {},
  currentSeasonYear = null,
}) => {
  return (
    <>
      <PlayerCard
        player={player}
        playerCardConfig={playerCardConfig}
        teamCls={player}
        ariaLabel=""
        isTurboDriver={isTurboDriver}
        isExtraTurboDriver={isExtraTurboDriver}
        dataText={dataText}
        showPoints={showPoints}
        isLateOnBoard={isLateOnBoard}
        isFinalFix={isFinalFix}
        team={team}
        currentSeasonYear={currentSeasonYear}
      />
    </>
  );
};

export { RemovePlayer };
