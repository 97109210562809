import { useSelector } from "react-redux";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useDataLayer from "../../Common/hooks/useDataLayer";
import { isAndroid, isIOS } from "../../Common/utils";
import { useCallback } from "react";

export const useViewFullScheduleDL = () => {
  const userState = useSelector((state) => state.user);
  const pushToDataLayer = useDataLayer();
  const webview = userState?.webview;

  const handleViewFullScheduleDL = useCallback(
    ({ path, actionType, clickText, locationInPage, pageName }) => {
      const viewFullScheduleDataLayerObject = {
        actionType,
        clickText,
        locationInPage,
        path,
        pageName,
        platform: isAndroid()
          ? FANTASY_ANDROID
          : isIOS()
          ? FANTASY_IOS
          : FANTASY_WEBSITE,
        componentType: webview ? WEBVIEW : WEB,
      };

      pushToDataLayer("navigation_click", viewFullScheduleDataLayerObject);
    },
    [pushToDataLayer, webview]
  );

  return { handleViewFullScheduleDL };
};
