import {
  FETCH_ANNOUNCEMENT_POPUP_CONFIG,
  FETCH_INFO_POPUP_CONFIG,
} from "../../constants/announcements";
import { feeds } from "../../apis/services";

export const fetchInfoPopupConfig = (params) => ({
  type: FETCH_INFO_POPUP_CONFIG,
  payload: feeds.getInfoPopupConfig(params),
});

export const fetchAnnounementConfig = (params) => ({
  type: FETCH_ANNOUNCEMENT_POPUP_CONFIG,
  payload: feeds.getAnnouncementConfig(params),
});
