import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatBoosterData } from "../../Common/utils/booster";
import { gameplay } from "../../redux/actions";
import {
  BoosterChips,
  DriverConstructorInfo,
  Overlays,
} from "../../ReusableComponents";
import PlayerButton from "../PlayerCard/PlayerButton";

const PlayerInfo = ({
  hasCTAs = true,
  isOppositeTeam = false,
  selectedGameday = null,
  pushTurboDriverDataLayer = () => {},
}) => {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);

  const {
    showPlayerPopup,
    popupPlayer,
    selectedTurboDriver,
    selectedExtraTurboDriver,
    boosters,
  } = gameplayState;

  const togglePlayerPopup = (player) => {
    dispatch(gameplay.togglePlayerPopup(player));
  };

  const handleClose = (player) => {
    dispatch(gameplay.setTempExtraTurboDriver(player));
    setTimeout(() => {
      dispatch(gameplay.setExtraTurboDriver());
    }, 100);
  };

  const boosterChipsList = boosters?.Value?.map((x) => formatBoosterData(x));
  return (
    <>
      {showPlayerPopup && (
        <Overlays
          overlayName="si-popup__wrap--driCon"
          customFooter={
            hasCTAs ? (
              <>
                {selectedExtraTurboDriver?.PlayerId ===
                  popupPlayer?.PlayerId && (
                  <BoosterChips
                    boosterCls={
                      boosterChipsList?.find((x) => x.id === 6)?.boosterCls
                    }
                    boosterIcon={
                      boosterChipsList?.find((x) => x.id === 6)?.boosterIcon
                    }
                    boosterText={
                      boosterChipsList?.find((x) => x.id === 6)?.boosterText
                    }
                    hideBoosterClose={false}
                    handleClose={() => handleClose(null)}
                  />
                )}
                <PlayerButton
                  player={popupPlayer}
                  ctaAppendClass="si-btn__secondary"
                  type="info"
                  isTurboDriver={
                    selectedTurboDriver?.PlayerId === popupPlayer?.PlayerId
                  }
                  pushTurboDriverDataLayer={pushTurboDriverDataLayer}
                />
              </>
            ) : (
              ""
            )
          }
          overlayConfig={{
            closeBtn: true,
            showPlayerCardHeader: true,
            submitBtn: false,
          }}
          component={
            <DriverConstructorInfo
              player={popupPlayer}
              isOppositeTeam={isOppositeTeam}
              selectedGameday={selectedGameday}
            />
          }
          player={popupPlayer}
          onConfirmClick={() => togglePlayerPopup()}
          onCancelClick={() => togglePlayerPopup()}
        />
      )}
    </>
  );
};

export { PlayerInfo };
