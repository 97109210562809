import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { getTranslations, isMobile } from "../../Common/utils";
import { CtaButton } from "../Buttons";
import { FixDates } from "../Fixtures/FixDates";
import { FixRound } from "../Fixtures/FixRound";
import { PvtPubCardChips } from "../PvtPubCardChips";
import { currentFixture, getMatchStatus } from "../../Common/utils/fixture";
import { MatchData } from "../MatchHeader";
import { useSelector } from "react-redux";
import { MiniLeagueMatchTimer } from "../Overlays/MiniLeague/matchTimer";

const MiniLeagueCardInfo = ({ MiniLeagueCardInfoConfig }) => {
  const fixtureState = useSelector((state) => state.fixture);
  const {
    showPvtPubCardChips = false,
    showThumb = false,
    conditionClsName,
    teamID,
    leagueName,
    leagueCount,
    imageSrc,
    imageName,
    showLeagueCount = true,
    type = 0,
    showRound = false,
    roundNo = "",
    showDate = false,
    fixDates = "",
    fixRoundLbl = false,
    editName = false,
    adminIcon = false,
    errorIcon = false,
    showProvisionalPts = false,
    locktime,
    handleEditClick = () => {
      return null;
    },
    showMaxTeams = false,
    maxTeams,
  } = MiniLeagueCardInfoConfig;

  return (
    <>
      <div className="si-miniLeagueCardBox">
        <div className="si-league__card">
          {showThumb && (
            <div className="si-league__card-lhs">
              <div
                className={`si-league__card-thumb si-noPad  si-team__${teamID} ${conditionClsName} `}
              >
                <img
                  src={`${config.IMG_BASE}${imageSrc}${imageName}?v=${IMAGE_VERSION}`}
                  alt={imageName}
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = `${config.IMG_BASE}${imageSrc}0.png?v=${IMAGE_VERSION}`;
                  }}
                />
              </div>
            </div>
          )}
          <div className="si-league__card-rhs">
            <h3
              className={
                !editName
                  ? "si-league__card-title"
                  : "si-league__card-title si-editEnable"
              }
            >
              <span>{leagueName}</span>
            </h3>

            {(showPvtPubCardChips ||
              showLeagueCount ||
              showMaxTeams ||
              showRound ||
              showDate) && (
              <div
                className={`si-league__card-info ${
                  showProvisionalPts ? "si-league__card-info--reset" : ""
                }`}
              >
                <div className="si-league__card-info-lhs">
                  {showPvtPubCardChips && (
                    <PvtPubCardChips PvtPubCardChipsNameConfig={type} />
                  )}
                  {showLeagueCount && (
                    <div className="si-league__card-caption">
                      <span>
                        {leagueCount ? leagueCount : 0}{" "}
                        {leagueCount < 1
                          ? getTranslations(
                              "league_landing_public_league_table_member",
                              "team"
                            )
                          : getTranslations(
                              "league_landing_public_league_table_members",
                              "members"
                            )}
                      </span>
                    </div>
                  )}
                  {showMaxTeams && (
                    <div className="si-league__card-caption">
                      <span>
                        {getTranslations(
                          "mini_league_card_max_teams",
                          "Max team per user"
                        )}
                        : <em>{maxTeams ? maxTeams : "-"}</em>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {!!locktime && <MiniLeagueMatchTimer locktime={locktime} />}
      </div>
    </>
  );
};

export { MiniLeagueCardInfo };
