import React from "react";

const AccordionChild = ({
  accordionConfig,
  headComponentLHS,
  headComponentRHS,
  bodyComponent,
  index,
  isActive,
  updateActiveIndex,
  innerRef = null,
  accordionBoxExtraClass = "",
}) => {
  const {
    showAccordLHS = true,
    showAccordRHS = true,
    showAccordArrow = true,
  } = accordionConfig;

  const handleOpen = () => updateActiveIndex(index, !isActive);

  return (
    <>
      <div
        className={`si-accordion__box ${accordionBoxExtraClass}`}
        ref={innerRef}
      >
        <button
          type="button"
          aria-expanded={isActive}
          className="si-accordion__trigger"
          aria-controls={`section${index}`}
          id={`accordion${index}`}
          onClick={handleOpen}
        >
          <div className="si-player__card-accord ">
            {showAccordLHS && (
              <div className="si-player__card-accord--lhs">
                {headComponentLHS}
              </div>
            )}
            {showAccordRHS && (
              <div className="si-player__card-accord--rhs">
                {headComponentRHS}
              </div>
            )}
          </div>

          {showAccordArrow && (
            <div className="si-accordion__icon">
              <i className="f1i-chevron-down"></i>
            </div>
          )}
        </button>
        <div
          id={`section${index}`}
          role="region"
          aria-labelledby={`accordion${index}`}
          className="si-accordion__body"
          hidden={!isActive}
        >
          {bodyComponent}
        </div>
      </div>
    </>
  );
};

export default AccordionChild;
