import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPath, scrollToTop } from "../Common/utils";
import { isUserLoggedIn } from "../Common/utils/user";

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const translationState = useSelector((state) => state.translation);

  useEffect(() => {
    //scroll Top
    scrollToTop(100);
    if (!isUserLoggedIn(userState)) {
      navigate(getPath(translationState, "home"));
    }
  });
  return children;
};

export default ProtectedRoute;
