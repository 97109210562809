import { getYearForImagePath, nFormatter } from ".";
import { league } from "../../redux/actions";
import { currentFixture, getMatchStatus } from "./fixture";

export const getSponsoredLeagueImageSrc = (leagueType, imageType) => {
  if (leagueType === "Classic Public" || leagueType === "PUBLIC CLASSIC") {
    return `sponsored-public/${imageType}`;
  }
  if (leagueType === "classic" || leagueType === "Classic Private") {
    return `sponsored-private/${imageType}`;
  }
  return null;
};

export const getSponsoredLeagueDefaultImageName = (leagueType, imageType) => {
  if (leagueType === "Classic Public" || leagueType === "PUBLIC CLASSIC") {
    return `sponsored-public/${imageType}/0.png`;
  }
  if (leagueType === "classic") {
    return `sponsored-private/${imageType}/0.png`;
  }
  return null;
};

export const getLeagueImageName = (
  leagueType,
  leagueName,
  leagueId,
  webConfigData
) => {
  switch (leagueType) {
    case "Global":
      return "chequered-flag.svg";
    case "Country":
      return `${webConfigData?.imagePaths?.countries}${leagueName}.svg`;
    case "Team":
      return `${webConfigData?.imagePaths?.teamLogos?.replace(
        "{seasonYear}",
        getYearForImagePath(webConfigData)
      )}${leagueId}.png`;
    case "Driver":
      return `${webConfigData?.imagePaths?.driversFront?.replace(
        "{seasonYear}",
        getYearForImagePath(webConfigData)
      )}${leagueId}.png`;
    case "Mini":
      return `mini-leagues/logo/${leagueId}.png`;
    default:
      return null;
  }
};

export const getDefaultLeagueImageName = (leagueType, webConfigData) => {
  switch (leagueType) {
    case "Global":
      return "chequered-flag.svg";
    case "Country":
      return `${webConfigData?.imagePaths?.countries}0.svg`;
    case "Team":
      return `${webConfigData?.imagePaths?.teamLogos?.replace(
        "{seasonYear}",
        getYearForImagePath(webConfigData)
      )}0.png`;
    case "Driver":
      return `${webConfigData?.imagePaths?.driversFront?.replace(
        "{seasonYear}",
        getYearForImagePath(webConfigData)
      )}0.png`;
    case "Mini":
      return `mini-leagues/logo/0.png`;
    default:
      return null;
  }
};

export const getLeagueTeamID = (leagueType, leagueId, driverState) => {
  switch (leagueType) {
    case "Team":
      return leagueId;
    case "Driver":
      return driverState?.list?.find((x) => x.PlayerId == leagueId)?.TeamId;
    default:
      return undefined;
  }
};

export const getLeagueConditionClsName = (leagueType) => {
  switch (leagueType) {
    case "Driver":
      return "si-driver";
    default:
      return undefined;
  }
};

export const getLeagueTypeId = (leagueType) => {
  switch (leagueType) {
    case "C.Private":
    case "private":
    case "Classic Private":
    case "classic":
      return 0;
    case "h2h":
    case "Private H-T-H":
    case "HTH Private":
      return 1;
    case "public":
    case "Public":
    case "PUBLIC":
    case "Classic Public":
      return 2;
    case "Vip Private":
      return 3;
    case "Mini":
      return 4;

    default:
      return undefined;
  }
};

export const manageAllowedOptions = {
  admin: {
    0: ["remove", "delete", "edit"],
    1: ["delete", "edit"],
    2: ["edit"],
  },
  user: {
    0: ["report", "leave"],
    1: ["report"],
    2: ["report"],
  },
};

export const handleSelectedLeague = (selectedLeague, dispatch) => {
  dispatch(league.setSelectedLeague(selectedLeague));
};

export const getLeagueTypeName = (leagueType) => {
  switch (leagueType) {
    case "C.Private":
    case "Private":
    case "Classic Private":
    case "classic":
    case "Vip Private":
      return "private";
    case "Private H-T-H":
    case "H-T-H Private":
    case "HTH Private":
      return "h2h";
    case "Public":
    case "PUBLIC":
    case "public":
    case "Classic Public":
    case "PUBLIC CLASSIC":
      return "public";
    case "Global":
      return "global";
    case "Country":
      return "country";
    case "Team":
      return "team";
    case "Driver":
      return "driver";
    case "Mini":
      return "mini";
    default:
      return undefined;
  }
};

export const getRank = (rank) => {
  return isNaN(rank) ? "--" : rank;

  /*
  return isNaN(rank)
    ? "--"
    : rank < 100000
    ? rank
    : nFormatter(rank, rank < 1000000 ? 1 : 3);
    */
};

export const h2hCurrentUser = (users) =>
  users?.find((user) => user?.isLeft === 1);

export const getH2HCurrentUserTeam = (
  gameplayState,
  user,
  selectedRaceOption = {}
) => {
  if (selectedRaceOption?.id !== 0) {
    return gameplayState?.raceweekTeams?.find((x) => x.teamno === user?.teamNo);
  }
  return gameplayState?.teams?.find((x) => x.teamno === user?.teamNo);
};

export const getH2HUserRaceWeekWiseSummary = (leagueState) => {
  const data = leagueState?.h2hRaceWeekWiseResultData?.map((x) =>
    h2hCurrentUser(x.raceWeekDetailsHth)
  );

  return [
    {
      type: "W",
      count: data?.filter((x) => x?.battleResult === "W")?.length,
    },
    {
      type: "D",
      count: data?.filter((x) => x?.battleResult === "D")?.length,
    },
    {
      type: "L",
      count: data?.filter((x) => x?.battleResult === "L")?.length,
    },
  ];
};

export const h2hOpponentUser = (users) =>
  users?.find((user) => user?.isLeft === 0);

export const getH2HOpponentUserTeam = (gameplayState) =>
  gameplayState?.h2hOppTeam?.teamDetails?.[0];

export const isH2HYetToJoin = (users) => h2hOpponentUser(users) === undefined;
export const isH2HLeaderboardGenerated = (fixtureState, startRaceDay) => {
  if (startRaceDay === 0) return false;
  return ["PROVISIONAL POINTS", "PC DONE"].includes(
    getMatchStatus(
      fixtureState?.list?.find((x) => x.RaceDay === startRaceDay)?.MatchStatus
    )
  );
};
export const isH2HTeamLock = (
  fixtureState,
  startRaceDay,
  selectedRaceOption
) => {
  if (selectedRaceOption?.value !== "All Races") {
    return false;
  }
  const currentFixtureSession = currentFixture(fixtureState);
  const currentFixtureSessionGamedayId = currentFixtureSession?.GamedayId;

  const allFixturesWithCurrentGamedayId = fixtureState?.list?.filter(
    (item) => item?.GamedayId === currentFixtureSessionGamedayId
  );

  const isCurrentFixtureFirstSession =
    allFixturesWithCurrentGamedayId?.[0]?.SessionType ===
    currentFixtureSession?.SessionType;

  return (
    ["CURRENT", "UPCOMING"].includes(
      getMatchStatus(
        fixtureState?.list?.find((x) => x.RaceDay === startRaceDay)?.MatchStatus
      )
    ) ||
    (isCurrentFixtureFirstSession &&
      getMatchStatus(currentFixture(fixtureState)?.MatchStatus) === "CURRENT")
  );
};
