import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { getTranslations, getYearForImagePath } from "../../Common/utils";
import { currentFixture } from "../../Common/utils/fixture";
import { CtaButton } from "../Buttons";
import { MatchHeader } from "../MatchHeader";
import { PlayerCard } from "../PlayerCard";
function Overlays({
  overlayName,
  overlayThumbnailName,
  overlayErrorThumbnail = "",
  overlayTitle,
  overlaySubTitle,
  overlaySubTitle2,
  overlaySubTitle3,
  btnPrimary,
  btnSecondary = getTranslations("turbo_driver_modal_cancel_button", "Cancel"),
  btnPrimaryIcon,
  index,
  overlayConfig,
  announcementText,
  onCancelClick = () => {
    return null;
  },
  onConfirmClick = () => {
    return null;
  },
  component,
  confirmButtonState,
  player,
  playerCardConfig,
  customFooter = null,
  onResetClick = () => {
    return null;
  },
  resetButtonState = false,
}) {
  const {
    closeBtn = false,
    submitBtn = true,
    cancelBtn = false,
    showOverlayTitle = true,
    showOverlaySub_Title = false,
    showOverlaySub_Title2 = false,
    showOverlaySub_Title3 = false,
    overlayHeaderThumbnail = false,
    showMatchHeader = false,
    showPlayerCardHeader = false,
    noOverlayFoot = true,
    isReset = false,
    announcementTag = false,
  } = overlayConfig;

  const fixtureState = useSelector((state) => state.fixture);
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  useEffect(() => {
    const BODY_CLASS = "noBodyScroll";
    document.body.classList.add(BODY_CLASS);
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.body.classList.remove(BODY_CLASS);
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const escFunction = useCallback((event) => {
    if (event.key === "Escape" && cancelBtn) {
      onCancelClick();
    }
  }, []);

  return (
    <>
      <div className={`si-popup__wrap ${overlayName} si-fade in`}>
        {/* in | out */}
        <div className="si-popup__container">
          <div className="si-popup__container-border">
            <img
              src={`${config.IMG_BASE}announcement-stroke.svg?v=${IMAGE_VERSION}`}
              alt="stroke"
              loading="lazy"
            />
          </div>
          {closeBtn && (
            <button className="si-popup__close" onClick={() => onCancelClick()}>
              <i className="f1i-close"></i>
            </button>
          )}
          <div className="si-popup__head">
            {overlayHeaderThumbnail && (
              <div className="si-popup__thumbnail">
                <img
                  src={`${config.IMG_BASE}${overlayThumbnailName}?v=${IMAGE_VERSION}`}
                  alt={overlayTitle}
                  loading="lazy"
                  onError={(e) => {
                    e.target.src = `${config.IMG_BASE}${overlayErrorThumbnail}?v=${IMAGE_VERSION}`;
                  }}
                />
              </div>
            )}

            {announcementTag && (
              <div className="si-announcement__tag">
                <i className="f1i-megaphone"></i>
                <span>{announcementText}</span>
              </div>
            )}

            {showOverlayTitle && (
              <>
                <div className="si-popup__heading">
                  <span>{overlayTitle}</span>
                </div>
                {isReset && (
                  <div className="si-popup__heading-cta">
                    <CtaButton
                      btnCls="si-btn si-btn__link"
                      // iconCls={btnPrimaryIcon}
                      btnText={getTranslations(
                        "player_filter_header_reset_button",
                        "Reset"
                      )}
                      ariaLabel={getTranslations(
                        "player_filter_header_reset_button",
                        "Reset"
                      )}
                      onClickProp={onResetClick}
                      btnState={resetButtonState}
                    />
                  </div>
                )}
              </>
            )}
            {showOverlaySub_Title && (
              <div className="si-popup__heading-sub">
                <span>{overlaySubTitle}</span>
              </div>
            )}
            {showOverlaySub_Title2 && (
              <div className="si-popup__heading-sub">
                <span>{overlaySubTitle2}</span>
              </div>
            )}
            {showOverlaySub_Title3 && (
              <div className="si-popup__heading-sub">
                <span>{overlaySubTitle3}</span>
              </div>
            )}
            {showMatchHeader && (
              <MatchHeader
                tagName="h2"
                MatchHeaderConfig={{ MatchHeaderRHS: false }}
                fixture={currentFixture(fixtureState)}
              />
            )}
            {showPlayerCardHeader && (
              <PlayerCard
                player={player}
                playerCardConfig={{
                  ...playerCardConfig,

                  playerType: webConfigData?.imagePaths?.driversLeft?.replace(
                    "{seasonYear}",
                    getYearForImagePath(webConfigData)
                  ),
                  teamLogo: true,
                  jersyNo: player?.Skill == 1,
                  playerTrendsLabel: true,
                  hidePlayerCardBot: false,
                  addingExtraClass:
                    player?.Skill == 1 ? "si-driver" : "si-constructor",
                  isPlayerPlaying: player.IsActive === "1",
                }}
                teamCls={player}
                ariaLabel=""
              />
            )}
          </div>
          <div className="si-popup__body">{component}</div>
          {noOverlayFoot && (
            <div className="si-popup__foot">
              {cancelBtn && (
                <CtaButton
                  btnCls="si-btn si-btn__secondary si-btn__secondary--outline"
                  iconCls=""
                  btnText={btnSecondary}
                  ariaLabel={btnSecondary}
                  onClickProp={onCancelClick}
                />
              )}
              {submitBtn && (
                <CtaButton
                  btnCls="si-btn si-btn__primary"
                  iconCls={btnPrimaryIcon}
                  btnText={btnPrimary}
                  ariaLabel={btnPrimary}
                  onClickProp={onConfirmClick}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !confirmButtonState) {
                      e.preventDefault();
                      onConfirmClick();
                    }
                  }}
                  btnState={confirmButtonState}
                />
              )}
              {customFooter && customFooter}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export { Overlays };
