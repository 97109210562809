import React from "react";
import { getTranslations } from "../../Common/utils";

const SingleRaceWeekStatCard = ({
  heading,
  component,
  teamName,
  rank,
  statColumn,
  suffix,
}) => {
  return (
    <>
      <div className="si-sinRacSats__card">
        <h2 className="si-sinRacSats__card-title">{heading}</h2>
        <div className="si-sinRacSats__card-fixture">{component}</div>
        <div className="si-sinRacSats__card-list">
          <ul>
            <li>
              <span>
                {getTranslations(
                  "single_raceweek_stats_team_name",
                  "Team Name"
                )}
              </span>
              <span>{teamName}</span>
            </li>
            <li>
              <span>{statColumn}</span>
              <span>
                {rank} {suffix}
              </span>
            </li>
          </ul>
        </div>
        {/* <div className="si-sinRacSats__card-list">
          <ul>
            {statsConfig.map((item, index) => (
              <li key={index}>
                <span key={index}>{item.data}</span>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </>
  );
};

export { SingleRaceWeekStatCard };
