// import { Route, Routes, BrowserRouter } from "react-router-dom";
// import Home from "./Components/Home";
import Router from "./router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import configureStore from "./redux/store";
const { store, persistor } = configureStore();

function App() {
  return (
    <>
      <div className="si-main">
        {/* <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} /> */}
        {/* <Route path="/matches" element={<Matches />} />
            <Route path="/gameplay" element={<Gameplay />} />
            <Route path="/leaderboard" element={<Leaderboard />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/terms" element={<Terms />} /> */}
        {/* </Routes>
        </BrowserRouter> */}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router />
            <div id="si-profanity" />
          </PersistGate>
        </Provider>
      </div>
    </>
  );
}

export default App;
