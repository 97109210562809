import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../Common/config";
import { circuit } from "../../../redux/actions";
import {
  days,
  day_keys,
  formatTime,
  getTranslations,
  getValue,
  getYearForImagePath,
} from "../../../Common/utils";
import { currentFixture } from "../../../Common/utils/fixture";
import SectionLoader from "../../Loaders/SectionLoader";
import { raceNames } from "../../../Common/utils/circuit";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";

const Circuit = () => {
  const dispatch = useDispatch();
  const circuitState = useSelector((state) => state.circuit);
  const fixtureState = useSelector((state) => state.fixture);
  const translationState = useSelector((state) => state.translation);

  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  // const currentFixture = fixtureState?.list?.find((x) => x.MHIsCurrent == 1);
  useEffect(() => {
    if (!circuitState.loading || !circuitState.success) {
      // dispatch(
      //   circuit.fetchCircuits({ languageCode: translationState?.language })
      // );
      dispatch(circuit.fetchCircuits({ languageCode: "en" }));
    }

    return null;
  }, []);

  // const [index, setIndex] = useState(0);
  // const currentFixture =
  //   fixtureState?.list &&
  //   Array.isArray(fixtureState?.list) &&
  //   fixtureState?.list[index];
  // const [maxFixtures, setMaxFixtures] = useState(null);

  // useEffect(() => {
  //   setMaxFixtures(fixtureState?.list?.length);
  // }, [fixtureState]);

  // function refreshFixture() {
  //   console.log(index, maxFixtures);
  //   if (index <= maxFixtures) {
  //     setIndex((prev) => prev + 1);
  //   }
  // }
  // useEffect(() => {
  //   const timerId = setInterval(refreshFixture, 1000);
  //   return function cleanup() {
  //     clearInterval(timerId);
  //   };
  // }, []);

  const currentCircuit = circuitState?.data?.find(
    (x) => x.MeetingId === currentFixture(fixtureState).MeetingId
  );

  const ScheduleList = currentCircuit?.MeetingSessions?.map((x) => ({
    id: x.id,
    label: getTranslations(
      `circuit_modal_${x.sessionName?.toLowerCase()?.replace(/ /g, "_")}`,
      x.sessionName
    ),
    value: `${getTranslations(
      day_keys[new Date(x.sessionStartDateIso8601).getDay()],
      days[new Date(x.sessionStartDateIso8601).getDay()]
    )} ${formatTime(
      new Date(x.sessionStartDateIso8601).getHours()
    )}:${formatTime(
      new Date(x.sessionStartDateIso8601).getMinutes()
    )} - ${formatTime(
      new Date(x.sessionEndDateIso8601).getHours()
    )}:${formatTime(new Date(x.sessionEndDateIso8601).getMinutes())}`,
  }));

  const CircuitList = [
    {
      id: 0,
      label: getTranslations("circuit_modal_circuit_name", "Name"),
      value: getValue(currentCircuit?.CircuitOfficialName),
    },
    {
      id: 1,
      label: getTranslations(
        "circuit_modal_circuit_first_grand_prix",
        "First Grand Prix"
      ),
      value: getValue(currentCircuit?.VenueFirstSeason),
    },
    {
      id: 2,
      label: getTranslations(
        "circuit_modal_circuit_no_of_laps",
        "Number of Laps"
      ),
      value: getValue(currentCircuit?.ScheduledLapCount),
    },
    {
      id: 3,
      label: getTranslations("circuit_modal_circuit_length", "Circuit Length"),
      value: `${getValue(currentCircuit?.TrackLength)} km`,
    },
    {
      id: 4,
      label: getTranslations(
        "circuit_modal_circuit_race_distance",
        "Race Distance"
      ),
      value: `${getValue(currentCircuit?.ScheduledDistance)} km`,
    },
    {
      id: 5,
      label: getTranslations("circuit_modal_circuit_lap_record", "Lap Record"),
      value: `${getValue(currentCircuit?.FastestLapTime)} - ${getValue(
        currentCircuit?.FastestLapDriver
      )}`, // add (YEAR) when available
    },
  ];
  return (
    <>
      <section className="si-circuit__wrap">
        {circuitState.loading && <SectionLoader />}
        <div className="si-circuit__box">
          <h2 className="si-circuit__title">
            <span>
              {getTranslations("circuit_modal_schedule_title", "Schedule")}
            </span>
          </h2>
          <div className="si-circuit__list">
            <ul>
              {ScheduleList?.map((item, index) => (
                <li key={item.id}>
                  <div className="si-circuit__list-label">
                    <span>{item.label}</span>
                  </div>
                  <div className="si-circuit__list-value">
                    <span>{item.value}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="si-circuit__box">
          <h2 className="si-circuit__title">
            <span>
              {getTranslations("circuit_modal_circuit_title", "Circuit")}
            </span>
          </h2>
          <div className="si-circuit__thumbnail">
            {!circuitState.loading && (
              <img
                src={`${CLOUDINARY_IMAGE_BASE_URL}${webConfigData?.imagePaths?.circuitsTrackInfo?.replace(
                  "{seasonYear}",
                  getYearForImagePath(webConfigData)
                )}${currentCircuit?.MeetingLocation}.png?v=${IMAGE_VERSION}`}
                alt={"a"}
                loading="lazy"
              />
            )}
          </div>
          <div className="si-circuit__list">
            <ul>
              {CircuitList?.map((item, index) => (
                <li key={item.id}>
                  <div className="si-circuit__list-label">
                    <span>{item.label}</span>
                  </div>
                  <div className="si-circuit__list-value">
                    <span>{item.value}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export { Circuit };
