import React from "react";
import SectionCaption from "../../Components/League/SectionCaption";
import SectionTitle from "../../Components/League/SectionTitle";
import { BackButton } from "../BackButton";
import PropTypes from "prop-types";
import { isMobile } from "../../Common/utils";

const LeagueHeader = ({
  component,
  hasBackButton = false,
  onBackButtonClick = () => {
    return null;
  },
  sectionTitle = "",
  sectionCaption = "",
  titleComponent = null,
  hasCustomBackAction = false,
  componentStickyFooterForMobile = false,
}) => {
  return (
    <div className="si-league__header">
      <div className="si-league__mid">
        {hasBackButton && (
          <div className="si-league__mid-lhs">
            <BackButton
              hasCustomBackAction={hasCustomBackAction}
              callBackFunction={onBackButtonClick}
            />
          </div>
        )}
        <div className="si-league__mid-rhs">
          {sectionTitle && <SectionTitle pageTitle={sectionTitle} />}
          {sectionCaption && <SectionCaption pageCaption={sectionCaption} />}
          {titleComponent && titleComponent}
        </div>
      </div>
      <div
        className={`si-league__statsManagement ${
          componentStickyFooterForMobile && isMobile()
            ? "si-leagueLanding__stickyFoot"
            : ""
        }`}
      >
        {component && component}
      </div>
    </div>
  );
};

LeagueHeader.propTypes = {
  component: PropTypes.element,
  hasBackButton: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  sectionTitle: PropTypes.string,
  sectionCaption: PropTypes.string,
  titleComponent: PropTypes.element,
};

export { LeagueHeader };
