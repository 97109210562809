import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTranslations,
  getWebPurifyStatus,
  isAndroid,
  isIOS,
} from "../../../Common/utils";
import { gameplay, league } from "../../../redux/actions";
import {
  CtaButton,
  TransfersCounts,
  BackButton,
  FixRound,
  FixDates,
} from "../../../ReusableComponents";
import { isInfinite, isUnlimitedTransfers } from "../../../Common/utils/team";
import SectionLoader from "../../../ReusableComponents/Loaders/SectionLoader";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  WEB,
  WEBVIEW,
} from "../../../Common/constants";
import useDataLayer from "../../../Common/hooks/useDataLayer";
import useTournamentScanario from "../../../Common/hooks/useTournamentScanario";
import Profanity from "../../Profanity";

const MyTeamHeader = ({
  teamNumber,
  team,
  type,
  isSelectTeam,
  showCheckbox = false,
  index,
  showRound = false,
  showDate = false,
  fixRoundLbl,
  roundNo,
  fixDates,
  hasCustomBackAction = false,
  onBack,
}) => {
  const dispatch = useDispatch();
  const pushToDataLayer = useDataLayer();
  const leagueState = useSelector((state) => state.league);
  const gameplayState = useSelector((state) => state.gameplay);
  const userState = useSelector((state) => state.user);

  const mixApiState = useSelector((state) => state.mixapi);
  const isProfanityEnabled = mixApiState?.data?.conf?.ipe;
  const teams = gameplayState?.teams;

  const currentTeam = teams?.find((team) => team?.teamno === index + 1);
  const isWebPurifyCalled = currentTeam?.iswebpurifycalled;
  const isSystemNameUpd = currentTeam?.issystemnameupd;
  const webPurifyResponse = currentTeam?.webpurifyresponse;

  const webPurifyStatus = getWebPurifyStatus(
    isWebPurifyCalled,
    isSystemNameUpd,
    webPurifyResponse
  );

  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const webview = userState?.webview;

  const toggleTeamNamePopup = () => {
    dispatch(gameplay.toggleTeamNamePopup());
  };

  const toggleSelectTeam = () => {
    dispatch(league.setCreateLeagueTeams(index + 1));
  };

  const pushTeamSelectionCheckboxDataLayer = (actionType) => {
    const dataLayerObject = {
      pageName: "Leagues",
      actionType,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("selectTeamForLeague", dataLayerObject);
  };

  const handleChange = (e) => {
    e.stopPropagation();
    if (!leagueState?.createLeagueTeams?.includes(index + 1)) {
      pushTeamSelectionCheckboxDataLayer("check");
    } else {
      pushTeamSelectionCheckboxDataLayer("uncheck");
    }
    toggleSelectTeam();
  };

  const isChecked = () => {
    if (leagueState?.createLeagueTeams?.includes(index + 1) === undefined) {
      return false;
    }
    return leagueState?.createLeagueTeams?.includes(index + 1);
  };

  useEffect(() => {
    if (
      showCheckbox &&
      leagueState.maxTeams === 3 &&
      gameplayState?.teams?.length === 3
    ) {
      toggleSelectTeam();
    } else if (
      leagueState?.leagueInfoSuccessData?.extno?.includes(index + 1) &&
      !isChecked()
    ) {
      toggleSelectTeam();
    }
  }, []);

  const transfersMade = gameplayState?.teams?.[index]?.team_info?.userSubsleft;
  const hasMadeExtraTransfers = transfersMade < 0;

  return (
    <>
      <div className="si-myTeamHeader__grid">
        <div className="si-myTeamHeaderGrid">
          <div className="si-myTeamHeaderGrid-lft">
            {" "}
            {type === "manage" && (
              <BackButton
                hasCustomBackAction={hasCustomBackAction}
                callBackFunction={onBack}
              />
            )}
            <div className="si-myTeamHeader__serial">
              <span>{teamNumber?.toString()}</span>
            </div>
            <h2
              className={`si-myTeamHeader__name ${
                type === "manage" && ` si-myTeamHeader__name--edit`
              } `}
            >
              <>
                <span>{team.name}</span>
                {type === "manage" && (
                  <>
                    {isProfanityEnabled && webPurifyStatus ? (
                      <Profanity
                        webPurifyStatus={webPurifyStatus}
                        type="team"
                      />
                    ) : null}
                    <CtaButton
                      btnCls="si-btn si-btn__link si-btn__icon"
                      iconCls="f1i-edit"
                      btnText=""
                      ariaLabel={getTranslations(
                        "manage_team_edit_team_name_desc",
                        "Click to edit your team name"
                      )}
                      btnState={disableCTAs}
                      onClickProp={() => toggleTeamNamePopup()}
                    />
                  </>
                )}
              </>
            </h2>
            {type !== "manage" && isProfanityEnabled && webPurifyStatus ? (
              <Profanity webPurifyStatus={webPurifyStatus} type="team" />
            ) : null}
          </div>
          {(!isSelectTeam || showCheckbox || showRound || showDate) && (
            <div className="si-myTeamHeaderGrid-rgt">
              {!isSelectTeam ? (
                <TransfersCounts
                  transfersDetails={{
                    transfersTitle: hasMadeExtraTransfers
                      ? getTranslations(
                          "manage_team_transfers_made",
                          "Transfers Made"
                        )
                      : getTranslations(
                          "manage_team_free_transfers",
                          "Free Transfers"
                        ),
                    availableTransfers: team.availableTransfers,
                    maxTransfers: team.maxTransfers,
                  }}
                  hasMadeExtraTransfers={hasMadeExtraTransfers}
                  transfersMade={transfersMade}
                  isInfinite={
                    isInfinite(gameplayState, index) ||
                    isUnlimitedTransfers(
                      team.availableTransfers,
                      team.maxTransfers
                    )
                  }
                />
              ) : null}
              {showCheckbox && (
                <>
                  <div className="si-myTeam__checkbox">
                    <div className="si-form__control">
                      <div className="si-input ">
                        <div className="si-checkBox">
                          <input
                            type="checkbox"
                            id={`selectTeam-${index}`}
                            checked={isChecked()}
                            disabled={
                              isChecked() == false
                                ? leagueState?.createLeagueTeams?.length >=
                                  (leagueState?.createLeagueData?.maxTeams ||
                                    leagueState?.maxTeams)
                                : leagueState?.leagueInfoSuccessData?.extno?.includes(
                                    index + 1
                                  )
                            }
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <label htmlFor={`selectTeam-${index}`}></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {(showRound || showDate) && (
                <div className="si-myTeamHeaderGrid-fix">
                  {" "}
                  {showRound && (
                    <FixRound
                      fixRoundConfig={{ fixRoundLbl: fixRoundLbl, roundNo }}
                    />
                  )}
                  {showDate && <FixDates fixDates={fixDates} />}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MyTeamHeader;
