import {
  FETCH_WEB_CONFIG_PENDING,
  FETCH_WEB_CONFIG_REJECTED,
  FETCH_WEB_CONFIG_FULFILLED,
} from "../../constants/webconfig";

const initialState = {
  webConfigLoading: false,
  webConfigError: false,
  webConfigFulfulled: false,
  webConfigData: null,
};

const webconfig = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WEB_CONFIG_PENDING:
      return {
        ...state,
        webConfigLoading: true,
        webConfigError: false,
        webConfigFulfulled: false,
        webConfigData: null,
      };
    case FETCH_WEB_CONFIG_REJECTED:
      return {
        ...state,
        webConfigLoading: false,
        webConfigError: true,
        webConfigFulfulled: false,
        webConfigData: null,
      };
    case FETCH_WEB_CONFIG_FULFILLED:
      return {
        ...state,
        webConfigLoading: false,
        webConfigError: false,
        webConfigFulfulled: true,
        webConfigData: action.payload.data,
      };
    default:
      return state;
  }
};

export default webconfig;
