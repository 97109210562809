import { createContext } from "react";

export const LeagueContext = createContext({
  leagueCode: "",
  sponsorChecked: "",
  triggerClassicPublicLeagueRefetch: false,
  updateLeagueCode: () => {},
  updateSponsorChecked: () => {},
  updateTriggerClassicPublicLeagueRefetch: () => {},
});
