import React from "react";
import {
  PROFANE_AND_PROVISIONAL,
  UPDATED_BY_SYSTEM,
} from "../../../Common/constants";

const ProfanityAlertIcon = ({ webPurifyStatus, setShowAlertPopup }) => {
  const iconColorClass =
    webPurifyStatus === PROFANE_AND_PROVISIONAL
      ? ""
      : webPurifyStatus === UPDATED_BY_SYSTEM
      ? "si-success"
      : "";

  return (
    <>
      <div
        className={`si-league__card-icon si-profanity ${iconColorClass}`}
        onClick={(e) => {
          e.stopPropagation();
          setShowAlertPopup(true);
        }}
      >
        <i className="f1i-profanity"></i>
      </div>
    </>
  );
};

export { ProfanityAlertIcon };
