export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_PENDING = "FETCH_USER_PENDING";
export const FETCH_USER_REJECTED = "FETCH_USER_REJECTED";
export const FETCH_USER_FULFILLED = "FETCH_USER_FULFILLED";

export const SET_WEBVIEW = "SET_WEBVIEW";

export const UPDATE_TEAM_COUNT = "UPDATE_TEAM_COUNT";
export const UPDATE_NEW_TEAM_COUNT = "UPDATE_NEW_TEAM_COUNT";

export const RESET_USER_REDUCER = "RESET_USER_REDUCER";
