import React, { useContext, useEffect, useState } from "react";

import { RadioButton } from "../../RadioButton";
import { radioValues1, radioValues2, leagueSortValues } from "./initial-values";
import { useDispatch, useSelector } from "react-redux";
import { league } from "../../../redux/actions";
import { useSearchParams } from "react-router-dom";
import { LeagueContext } from "../../../store/League/LeagueContext";

const LeaguesSort = ({
  isLeaguesSortReset,
  toggleLeaguesSortReset,
  isLeaguesSortApply,
  toggleLeaguesSortApply,
  toggleLeagueSortPopup,
  getEnabledOrDisabledSortApplyButton = () => {},
}) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const leagueState = useSelector((state) => state.league);
  const { updateTriggerClassicPublicLeagueRefetch } = useContext(LeagueContext);

  const [anyFieldChanged, setAnyFieldChanged] = useState(false);

  const leagueTypeKey = searchParams.get("type");
  const leaguesFilterAndSortData = leagueState?.leaguesFilterAndSortData;

  const leaguesSortData = leaguesFilterAndSortData?.Sort;
  const tagSortData = leaguesSortData?.tag;
  const orderSortData = leaguesSortData?.order;

  const leagueSortValuesData = leagueState?.leagueSortValues;
  const [radioOneValue, setRadioOneValue] = useState(() => {
    if (leagueSortValuesData?.league_sort_sort_recently_created_) {
      return { value: leagueSortValues.recentlyCreated };
    }
    if (leagueSortValuesData?.league_sort_sort_last_chance_to_join_) {
      return { value: leagueSortValues?.lastChanceToJoin };
    }
    if (leagueSortValuesData?.league_sort_sort_popular_) {
      return { value: leagueSortValues?.popular };
    }
    if (leagueSortValuesData?.league_sort_sort_new_) {
      return { value: leagueSortValues?.new };
    }
    return null;
  });
  const [radioTwoValue, setRadioTwoValue] = useState(() => {
    if (leagueSortValuesData?.league_sort__asscending_) {
      return { value: leagueSortValues?.ascending };
    }
    if (leagueSortValuesData?.league_sort__descending_) {
      return { value: leagueSortValues?.descending };
    }
    return null;
  });

  const handleOnChangeRadioValues1 = (e) => {
    setAnyFieldChanged(true);
    const requiredValue = radioValues1?.find(
      (item) => item?.value === e.target.value
    );
    setRadioOneValue(requiredValue);
  };

  const handleOnChangeRadioValues2 = (e) => {
    setAnyFieldChanged(true);
    const requiredValue = radioValues2?.find(
      (item) => item?.value === e.target.value
    );
    setRadioTwoValue(requiredValue);
  };

  // * Reset League sort values
  useEffect(() => {
    if (isLeaguesSortReset) {
      setRadioOneValue(null);
      setRadioTwoValue(null);

      setAnyFieldChanged(false);
      dispatch(league.resetLeagueSort());
      toggleLeaguesSortReset();
      if (leagueTypeKey === "public-classic") {
        updateTriggerClassicPublicLeagueRefetch(true);
      }
      return;
    }
  }, [
    isLeaguesSortReset,
    toggleLeaguesSortReset,
    dispatch,
    leagueTypeKey,
    toggleLeagueSortPopup,
    updateTriggerClassicPublicLeagueRefetch,
  ]);

  // * Disable the Confirm button initially
  useEffect(() => {
    if (!!radioOneValue || !!radioTwoValue) {
      getEnabledOrDisabledSortApplyButton(false);
    } else {
      getEnabledOrDisabledSortApplyButton(true);
    }
  }, [radioOneValue, radioTwoValue, getEnabledOrDisabledSortApplyButton]);

  // * Apply League sort values
  useEffect(() => {
    if (isLeaguesSortApply) {
      const payload = {};
      if (!!radioOneValue?.value) {
        switch (radioOneValue?.value) {
          case leagueSortValues.recentlyCreated:
            payload.league_sort_sort_recently_created_ = true;
            break;
          case leagueSortValues.lastChanceToJoin:
            payload.league_sort_sort_last_chance_to_join_ = true;
            break;
          case leagueSortValues.popular:
            payload.league_sort_sort_popular_ = true;
            break;
          case leagueSortValues.new:
            payload.league_sort_sort_new_ = true;
            break;
          default:
            break;
        }
      }

      if (!!radioTwoValue?.value) {
        switch (radioTwoValue?.value) {
          case leagueSortValues.ascending:
            payload.league_sort__asscending_ = true;
            break;
          case leagueSortValues.descending:
            payload.league_sort__descending_ = true;
            break;
          default:
            break;
        }
      }

      if (!!Object.keys(payload).length) {
        dispatch(league.applyLeagueSort(payload));
        toggleLeaguesSortApply();
        toggleLeagueSortPopup();
      }
    }
  }, [
    isLeaguesSortApply,
    radioOneValue,
    radioTwoValue,
    toggleLeaguesSortApply,
    toggleLeagueSortPopup,
    dispatch,
  ]);

  const getEnabledOrDisabledFieldForLeagueType = (applicableLeagueType) => {
    switch (leagueTypeKey) {
      case "featured":
        return !applicableLeagueType?.feature;
      case "pinned":
        return !applicableLeagueType?.pinned;
      case "mini":
        return !applicableLeagueType?.mini;
      case "private-classic":
        return !applicableLeagueType?.classic;
      case "private-hth":
        return !applicableLeagueType?.hth;
      case "public-classic":
        return !applicableLeagueType?.public;
      default:
        return true;
    }
  };

  const getDisabledValueForField = (key, type = "tag") => {
    if (type === "tag") {
      const fieldFilterData = tagSortData?.find((item) => item?.name === key);
      const applicableLeagueTypesForField = fieldFilterData?.applicable;

      return getEnabledOrDisabledFieldForLeagueType(
        applicableLeagueTypesForField
      );
    }
    if (type === "order") {
      const fieldFilterData = orderSortData?.find((item) => item?.name === key);
      const applicableLeagueTypesForField = fieldFilterData?.applicable;

      return getEnabledOrDisabledFieldForLeagueType(
        applicableLeagueTypesForField
      );
    }
  };

  return (
    <>
      <div className="si-filter__wrap">
        <div className="si-filter__container si-filter__container--bg">
          <form className="" aria-label="">
            <div className="si-league__report-radio si-filter__checkbox">
              <ul>
                {radioValues1.map((item, index) => (
                  <li key={index}>
                    <RadioButton
                      radioName="leagueSort"
                      radioText={item.value}
                      radioId={`radioValues1_${index}`}
                      checked={item.value === radioOneValue?.value}
                      handleOnChange={(e) => handleOnChangeRadioValues1(e)}
                      disabled={getDisabledValueForField(item?.nameKey, "tag")}
                      radioBtnClassName={
                        getDisabledValueForField(item?.nameKey, "tag")
                          ? "si-disabled"
                          : ""
                      }
                    />
                  </li>
                ))}
              </ul>
            </div>
          </form>
        </div>
        <div className="si-filter__container si-filter__container--bg">
          <form className="" aria-label="">
            <div className="si-league__report-radio si-filter__checkbox">
              <ul>
                {radioValues2.map((item, index) => (
                  <li key={index}>
                    <RadioButton
                      radioName="leagueSort"
                      radioText={item.value}
                      checked={item?.value === radioTwoValue?.value}
                      radioId={`radioValues2_${index}`}
                      handleOnChange={(e) => handleOnChangeRadioValues2(e)}
                      disabled={getDisabledValueForField(
                        item?.nameKey,
                        "order"
                      )}
                      radioBtnClassName={
                        getDisabledValueForField(item?.nameKey, "order")
                          ? "si-disabled"
                          : ""
                      }
                    />
                  </li>
                ))}
              </ul>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { LeaguesSort };
