export const FETCH_SEASON_HOMEPAGE_STATS = "FETCH_SEASON_HOMEPAGE_STATS";
export const FETCH_SEASON_HOMEPAGE_STATS_PENDING =
  "FETCH_SEASON_HOMEPAGE_STATS_PENDING";
export const FETCH_SEASON_HOMEPAGE_STATS_REJECTED =
  "FETCH_SEASON_HOMEPAGE_STATS_REJECTED";
export const FETCH_SEASON_HOMEPAGE_STATS_FULFILLED =
  "FETCH_SEASON_HOMEPAGE_STATS_FULFILLED";

export const FETCH_USER_HOMEPAGE_STATS = "FETCH_USER_HOMEPAGE_STATS";
export const FETCH_USER_HOMEPAGE_STATS_PENDING =
  "FETCH_USER_HOMEPAGE_STATS_PENDING";
export const FETCH_USER_HOMEPAGE_STATS_REJECTED =
  "FETCH_USER_HOMEPAGE_STATS_REJECTED";
export const FETCH_USER_HOMEPAGE_STATS_FULFILLED =
  "FETCH_USER_HOMEPAGE_STATS_FULFILLED";

export const FETCH_SEASON_FEATURED_MINI_LEAGUE =
  "FETCH_SEASON_FEATURED_MINI_LEAGUE";
export const FETCH_SEASON_FEATURED_MINI_LEAGUE_PENDING =
  "FETCH_SEASON_FEATURED_MINI_LEAGUE_PENDING";
export const FETCH_SEASON_FEATURED_MINI_LEAGUE_REJECTED =
  "FETCH_SEASON_FEATURED_MINI_LEAGUE_REJECTED";
export const FETCH_SEASON_FEATURED_MINI_LEAGUE_FULFILLED =
  "FETCH_SEASON_FEATURED_MINI_LEAGUE_FULFILLED";
