import React from "react";
import { getTranslations } from "../../Common/utils";

const MatchRecord = ({ customCls, heading, dataMatchRecord }) => {
  const getMatchRecordStatus = (type) => {
    switch (type) {
      case "W": // Won
        return (
          <div className="si-matchRecord__box-lbl si-won">
            {getTranslations("won_text_match_record", "W")}
          </div>
        );
      case "L": //Loss
        return (
          <div className="si-matchRecord__box-lbl si-loss">
            {getTranslations("loss_text_match_record", "L")}
          </div>
        );
      case "D": // Draw
        return (
          <div className="si-matchRecord__box-lbl si-draw">
            {getTranslations("draw_text_match_record", "D")}
          </div>
        );
      default:
        return "";
    }
  };
  return (
    <>
      <div className={`si-matchRecord__wrap ${customCls ? customCls : ""}`}>
        <div className="si-matchRecord__container">
          {heading && (
            <h2 className="si-matchRecord__title">
              {heading} {!dataMatchRecord?.length && "-"}
            </h2>
          )}
          <div className="si-matchRecord__list">
            <ul>
              {dataMatchRecord?.length &&
                dataMatchRecord?.map((item, index) => (
                  <li key={index}>
                    <div className="si-matchRecord__box">
                      {getMatchRecordStatus(item?.type)}
                      {item?.count !== undefined && item?.count !== null && (
                        <div className="si-matchRecord__box-nums">
                          {item?.count}
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              {}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export { MatchRecord };
