import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { CtaButton } from "../Buttons";

const NoDataFound = ({
  noDataThumb,
  noDataTitle,
  noDataCaption,
  noDataCta,
  noDataConfig,
}) => {
  return (
    <>
      <div className="si-noData__wrap">
        {noDataThumb ? (
          <div className="si-noData__thumb">
            <img
              src={`${config.IMG_BASE}${noDataThumb}?v=${IMAGE_VERSION}`}
              alt={noDataTitle.toLowerCase()}
            />
          </div>
        ) : (
          ""
        )}
        {noDataTitle ? (
          <h2 className="si-noData__title">
            <span>{noDataTitle}</span>
          </h2>
        ) : (
          ""
        )}
        {noDataCaption ? (
          <div className="si-noData__caption">{noDataCaption}</div>
        ) : (
          ""
        )}
        {noDataCta ? (
          <div className="si-noData__cta">
            <CtaButton
              btnCls="si-btn si-btn__link"
              btnText={noDataCta}
              ariaLabel={noDataCta}
              {...noDataConfig}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export { NoDataFound };
