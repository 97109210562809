export const FETCH_STATISTICS = "FETCH_STATISTICS";
export const FETCH_STATISTICS_PENDING = "FETCH_STATISTICS_PENDING";
export const FETCH_STATISTICS_REJECTED = "FETCH_STATISTICS_REJECTED";
export const FETCH_STATISTICS_FULFILLED = "FETCH_STATISTICS_FULFILLED";
export const FETCH_RAW_STATISTICS = "FETCH_RAW_STATISTICS";
export const FETCH_RAW_STATISTICS_PENDING = "FETCH_RAW_STATISTICS_PENDING";
export const FETCH_RAW_STATISTICS_REJECTED = "FETCH_RAW_STATISTICS_REJECTED";
export const FETCH_RAW_STATISTICS_FULFILLED = "FETCH_RAW_STATISTICS_FULFILLED";

export const FETCH_DRIVER_STATISTICS = "FETCH_DRIVER_STATISTICS";
export const FETCH_DRIVER_STATISTICS_PENDING =
  "FETCH_DRIVER_STATISTICS_PENDING";
export const FETCH_DRIVER_STATISTICS_REJECTED =
  "FETCH_DRIVER_STATISTICS_REJECTED";
export const FETCH_DRIVER_STATISTICS_FULFILLED =
  "FETCH_DRIVER_STATISTICS_FULFILLED";

export const FETCH_CONSTRUCTOR_STATISTICS = "FETCH_CONSTRUCTOR_STATISTICS";
export const FETCH_CONSTRUCTOR_STATISTICS_PENDING =
  "FETCH_CONSTRUCTOR_STATISTICS_PENDING";
export const FETCH_CONSTRUCTOR_STATISTICS_REJECTED =
  "FETCH_CONSTRUCTOR_STATISTICS_REJECTED";
export const FETCH_CONSTRUCTOR_STATISTICS_FULFILLED =
  "FETCH_CONSTRUCTOR_STATISTICS_FULFILLED";
