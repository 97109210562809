import React, { useState } from "react";

import { BoosterContext } from "./BoosterContext";

const BoosterContextProvider = ({ children }) => {
  const [isBoosterApplyRunning, setIsBoosterApplyRunning] = useState(false);

  const value = {
    isBoosterApplyRunning,
    updateIsBoosterApplyRunning: () =>
      setIsBoosterApplyRunning((value) => !value),
    updateBoosterApplyFalse: () => setIsBoosterApplyRunning(false),
  };

  return (
    <BoosterContext.Provider value={value}>{children}</BoosterContext.Provider>
  );
};

export default BoosterContextProvider;
