import { decodeName } from "../../../Common/utils";
import {
  GET_COUNTRY_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_COUNTRY_LEAGUE_LEADER_BOARD_PENDING,
  GET_COUNTRY_LEAGUE_LEADER_BOARD_REJECTED,
  GET_DRIVER_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_DRIVER_LEAGUE_LEADER_BOARD_PENDING,
  GET_DRIVER_LEAGUE_LEADER_BOARD_REJECTED,
  GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_PENDING,
  GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_REJECTED,
  GET_GLOBAL_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_GLOBAL_LEAGUE_LEADER_BOARD_PENDING,
  GET_GLOBAL_LEAGUE_LEADER_BOARD_REJECTED,
  GET_H2H_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_H2H_LEAGUE_LEADER_BOARD_PENDING,
  GET_H2H_LEAGUE_LEADER_BOARD_REJECTED,
  GET_MINI_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_MINI_LEAGUE_LEADER_BOARD_PENDING,
  GET_MINI_LEAGUE_LEADER_BOARD_REJECTED,
  GET_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_PUBLIC_LEAGUE_LEADER_BOARD_PENDING,
  GET_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_DRIVER_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_USER_DRIVER_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_DRIVER_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_MINI_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_USER_MINI_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_MINI_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED,
  GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_PENDING,
  GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED,
  GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_PENDING,
  GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED,
  GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED,
  RESET_LEADER_BOARD,
} from "../../constants/leaderboard";

const formatData = (data, key) => {
  return data?.map((data) => ({
    ...data,
    [key]: decodeName(decodeName(data?.[key])),
  }));
};

const initialState = {};

const leaderboard = (state = initialState, action) => {
  switch (action.type) {
    case GET_H2H_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_H2H_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_H2H_LEAGUE_LEADER_BOARD_FULFILLED:
      let h2hLeaderBoard = [];
      h2hLeaderBoard = formatData(action.payload.data, "temname");
      h2hLeaderBoard = h2hLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));

      let h2hLeagueInfo;
      if (state?.leagueInfo) {
        h2hLeagueInfo = state?.leagueInfo;
      } else if (action.payload.data?.leagueInfo) {
        h2hLeagueInfo = {
          ...action.payload.data?.leagueInfo,
          leagueName: decodeName(action.payload.data?.leagueInfo?.leagueName),
        };
      }

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        leagueInfo: h2hLeagueInfo,
        leaderBoard: h2hLeaderBoard,
        hasMore: false,
      };
    case GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_USER_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED:
      let userPrivateLeaderBoard = [];

      if (state?.userLeaderBoard) {
        userPrivateLeaderBoard = state?.userLeaderBoard;
      } else if (action.payload.data?.userRank) {
        userPrivateLeaderBoard = formatData(
          action.payload.data?.userRank,
          "teamName"
        );
      }
      userPrivateLeaderBoard = userPrivateLeaderBoard.map((x) => ({
        ...x,
        points: x.ovPoints,
      }));

      let privateLeaderBoard = [];
      if (state?.leaderBoard) {
        privateLeaderBoard = [
          ...state?.leaderBoard,
          ...formatData(action.payload.data?.memRank, "teamName"),
        ];
      } else if (action.payload.data?.memRank) {
        privateLeaderBoard = formatData(
          action.payload.data?.memRank,
          "teamName"
        );
      }
      privateLeaderBoard = privateLeaderBoard.map((x) => ({
        ...x,
        points: x.ovPoints,
      }));
      let memberCount = 0;
      if (state?.memberCount) {
        memberCount = state.memberCount;
      } else {
        memberCount = action.payload.data?.leagueInfo?.memCount;
      }

      let hasMore = false;
      if (privateLeaderBoard?.length < memberCount) {
        hasMore = true;
      }
      let leagueInfo;
      if (state?.leagueInfo) {
        leagueInfo = state?.leagueInfo;
      } else if (action.payload.data?.leagueInfo) {
        leagueInfo = {
          ...action.payload.data?.leagueInfo,
          leagueName: decodeName(action.payload.data?.leagueInfo?.leagueName),
        };
      }
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        leagueInfo: leagueInfo,
        userLeaderBoard: userPrivateLeaderBoard,
        leaderBoard: privateLeaderBoard,
        memberCount: memberCount,
        hasMore: hasMore,
      };
    case GET_PUBLIC_LEAGUE_LEADER_BOARD_PENDING:
    case GET_MINI_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED:
    case GET_MINI_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED:
    case GET_MINI_LEAGUE_LEADER_BOARD_FULFILLED:
      let publicLeaderBoard = [];
      if (state?.leaderBoard) {
        publicLeaderBoard = [
          ...state?.leaderBoard,
          ...formatData(action.payload.data.Value, "temname"),
        ];
      } else if (action.payload.data) {
        publicLeaderBoard = formatData(action.payload.data.Value, "temname");
      }

      let publicMemberCount = 0;
      if (state?.memberCount) {
        publicMemberCount = state.memberCount;
      } else {
        publicMemberCount = action.payload.data?.leagueInfo?.memCount;
      }

      let publicHasMore = false;
      if (publicLeaderBoard?.length < publicMemberCount) {
        publicHasMore = true;
      }
      let publicLeagueInfo;
      if (state?.leagueInfo) {
        publicLeagueInfo = state?.leagueInfo;
      } else if (action.payload.data?.leagueInfo) {
        publicLeagueInfo = {
          ...action.payload.data?.leagueInfo,
          leagueName: decodeName(action.payload.data?.leagueInfo?.leagueName),
        };
      }

      publicLeaderBoard = publicLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        leagueInfo: publicLeagueInfo,
        leaderBoard: publicLeaderBoard,
        memberCount: publicMemberCount,
        hasMore: publicHasMore,
      };
    case GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_PENDING:
    case GET_USER_MINI_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_REJECTED:
    case GET_USER_MINI_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_USER_PUBLIC_LEAGUE_LEADER_BOARD_FULFILLED:
    case GET_USER_MINI_LEAGUE_LEADER_BOARD_FULFILLED:
      let userPublicLeaderBoard = [];
      if (action.payload.data) {
        userPublicLeaderBoard = formatData(action.payload.data, "temname");
      }
      userPublicLeaderBoard = userPublicLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        userLeaderBoard: userPublicLeaderBoard,
      };
    case GET_GLOBAL_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_GLOBAL_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_GLOBAL_LEAGUE_LEADER_BOARD_FULFILLED:
      let globalLeaderBoard = [];
      if (state?.leaderBoard) {
        globalLeaderBoard = [
          ...state?.leaderBoard,
          ...formatData(action.payload.data.Value, "temname"),
        ];
      } else if (action.payload.data.Value) {
        globalLeaderBoard = formatData(action.payload.data.Value, "temname");
      }

      let globalMemberCount = 0;
      if (state?.memberCount) {
        globalMemberCount = state.memberCount;
      } else {
        globalMemberCount = action.payload.data?.leagueInfo?.memCount;
      }

      let globalHasMore = false;
      if (globalLeaderBoard?.length < globalMemberCount) {
        globalHasMore = true;
      }
      let globalLeagueInfo;
      if (state?.leagueInfo) {
        globalLeagueInfo = state?.leagueInfo;
      } else if (action.payload.data?.leagueInfo) {
        globalLeagueInfo = {
          ...action.payload.data?.leagueInfo,
          leagueName: decodeName(action.payload.data?.leagueInfo?.leagueName),
        };
      }

      globalLeaderBoard = globalLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        leagueInfo: globalLeagueInfo,
        leaderBoard: globalLeaderBoard,
        memberCount: globalMemberCount,
        hasMore: globalHasMore,
      };
    case GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_USER_GLOBAL_LEAGUE_LEADER_BOARD_FULFILLED:
      let userGlobalLeaderBoard = [];
      if (action.payload.data) {
        userGlobalLeaderBoard = formatData(action.payload.data, "temname");
      }
      userGlobalLeaderBoard = userGlobalLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        userLeaderBoard: userGlobalLeaderBoard,
      };
    case GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_FULFILLED:
      let teamLeaderBoard = [];
      if (state?.leaderBoard) {
        teamLeaderBoard = [
          ...state?.leaderBoard,
          ...formatData(action.payload.data.Value, "temname"),
        ];
      } else if (action.payload.data.Value) {
        teamLeaderBoard = formatData(action.payload.data.Value, "temname");
      }

      let teamMemberCount = 0;
      if (state?.memberCount) {
        teamMemberCount = state.memberCount;
      } else {
        teamMemberCount = action.payload.data?.leagueInfo?.memCount;
      }

      let teamHasMore = false;
      if (teamLeaderBoard?.length < teamMemberCount) {
        teamHasMore = true;
      }
      let teamLeagueInfo;
      if (state?.leagueInfo) {
        teamLeagueInfo = state?.leagueInfo;
      } else if (action.payload.data?.leagueInfo) {
        teamLeagueInfo = {
          ...action.payload.data?.leagueInfo,
          leagueName: decodeName(action.payload.data?.leagueInfo?.leagueName),
        };
      }

      teamLeaderBoard = teamLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        leagueInfo: teamLeagueInfo,
        leaderBoard: teamLeaderBoard,
        memberCount: teamMemberCount,
        hasMore: teamHasMore,
      };
    case GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_USER_FAVORITE_TEAM_LEAGUE_LEADER_BOARD_FULFILLED:
      let userTeamLeaderBoard = [];
      if (action.payload.data) {
        userTeamLeaderBoard = formatData(action.payload.data, "temname");
      }
      userTeamLeaderBoard = userTeamLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        userLeaderBoard: userTeamLeaderBoard,
      };
    case GET_COUNTRY_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_COUNTRY_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_COUNTRY_LEAGUE_LEADER_BOARD_FULFILLED:
      let countryLeaderBoard = [];
      if (state?.leaderBoard) {
        countryLeaderBoard = [
          ...state?.leaderBoard,
          ...formatData(action.payload.data.Value, "temname"),
        ];
      } else if (action.payload.data.Value) {
        countryLeaderBoard = formatData(action.payload.data.Value, "temname");
      }

      let countryMemberCount = 0;
      if (state?.memberCount) {
        countryMemberCount = state.memberCount;
      } else {
        countryMemberCount = action.payload.data?.leagueInfo?.memCount;
      }

      let countryHasMore = false;
      if (countryMemberCount?.length < countryMemberCount) {
        teamHasMore = true;
      }
      let countryLeagueInfo;
      if (state?.leagueInfo) {
        countryLeagueInfo = state?.leagueInfo;
      } else if (action.payload.data?.leagueInfo) {
        countryLeagueInfo = {
          ...action.payload.data?.leagueInfo,
          leagueName: decodeName(action.payload.data?.leagueInfo?.leagueName),
        };
      }

      countryLeaderBoard = countryLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        leagueInfo: countryLeagueInfo,
        leaderBoard: countryLeaderBoard,
        memberCount: countryMemberCount,
        hasMore: countryHasMore,
      };
    case GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_USER_COUNTRY_LEAGUE_LEADER_BOARD_FULFILLED:
      let userCountryLeaderBoard = [];
      if (action.payload.data) {
        userCountryLeaderBoard = formatData(action.payload.data, "temname");
      }
      userCountryLeaderBoard = userCountryLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        userLeaderBoard: userCountryLeaderBoard,
      };
    case GET_DRIVER_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_DRIVER_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_DRIVER_LEAGUE_LEADER_BOARD_FULFILLED:
      let driverLeaderBoard = [];
      if (state?.leaderBoard) {
        driverLeaderBoard = [
          ...state?.leaderBoard,
          ...formatData(action.payload.data, "temname"),
        ];
      } else if (action.payload.data.Value) {
        driverLeaderBoard = formatData(action.payload.data.Value, "temname");
      }

      let driverMemberCount = 0;
      if (state?.memberCount) {
        driverMemberCount = state.memberCount;
      } else {
        driverMemberCount = action.payload.data?.leagueInfo?.memCount;
      }

      let driverHasMore = false;
      if (driverMemberCount?.length < driverMemberCount) {
        driverHasMore = true;
      }
      let driverLeagueInfo;
      if (state?.leagueInfo) {
        driverLeagueInfo = state?.leagueInfo;
      } else if (action.payload.data?.leagueInfo) {
        driverLeagueInfo = {
          ...action.payload.data?.leagueInfo,
          leagueName: decodeName(action.payload.data?.leagueInfo?.leagueName),
        };
      }

      driverLeaderBoard = driverLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        leagueInfo: driverLeagueInfo,
        leaderBoard: driverLeaderBoard,
        memberCount: driverMemberCount,
        hasMore: driverHasMore,
      };
    case GET_USER_DRIVER_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_USER_DRIVER_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_USER_DRIVER_LEAGUE_LEADER_BOARD_FULFILLED:
      let userDriverLeaderBoard = [];
      if (action.payload.data) {
        userDriverLeaderBoard = formatData(action.payload.data, "temname");
      }

      userDriverLeaderBoard = userDriverLeaderBoard.map((x) => ({
        ...x,
        teamName: x.temname,
      }));
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        userLeaderBoard: userDriverLeaderBoard,
      };
    case RESET_LEADER_BOARD:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: false,
        userLeaderBoard: undefined,
        leaderBoard: undefined,
        hasMore: undefined,
        memberCount: undefined,
        leagueInfo: undefined,
      };
    case GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_PENDING:
      return {
        ...state,
        leaderBoardLoading: true,
        leaderBoardError: false,
        leaderBoardSuccess: false,
      };
    case GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_REJECTED:
      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: true,
        leaderBoardSuccess: false,
      };
    case GET_UNJOINED_SPONSORED_PRIVATE_LEAGUE_LEADER_BOARD_FULFILLED:
      let sponsoredPrivateLeaderboard = formatData(
        action.payload.data?.memRank,
        "teamName"
      );

      let sponsoredPrivateMemberCount =
        action.payload.data?.leagueInfo?.memCount;

      sponsoredPrivateLeaderboard = sponsoredPrivateLeaderboard.map((x) => ({
        ...x,
        points: x.ovPoints,
      }));

      let sponsoredPrivateInfo = {
        ...action.payload.data?.leagueInfo,
        leagueName: decodeName(action.payload.data?.leagueInfo?.leagueName),
      };

      return {
        ...state,
        leaderBoardLoading: false,
        leaderBoardError: false,
        leaderBoardSuccess: true,
        leagueInfo: sponsoredPrivateInfo,
        leaderBoard: sponsoredPrivateLeaderboard,
        memberCount: sponsoredPrivateMemberCount,
      };
    default:
      return state;
  }
};

export default leaderboard;
