import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import useOnClickOutside from "../../Common/hooks/useOnClickOutside";
import { getTranslations, isMobile } from "../../Common/utils";
import { CtaButton } from "../Buttons";

const LanguageDropdown = ({ onClick = () => {} }) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const lang = [
    {
      text: getTranslations("menu_language_english", "English"),
      mobText: getTranslations("menu_language_english_short", "en"),
      code: "en",
    },
    {
      text: getTranslations("menu_language_spanish", "Spanish"),
      mobText: getTranslations("menu_language_spanish_short", "es"),
      code: "es",
    },
    {
      text: getTranslations("menu_language_german", "German"),
      mobText: getTranslations("menu_language_german_short", "de"),
      code: "de",
    },
    {
      text: getTranslations("menu_language_french", "French"),
      mobText: getTranslations("menu_language_french_short", "fr"),
      code: "fr",
    },
    {
      text: getTranslations("menu_language_italian", "Italian"),
      mobText: getTranslations("menu_language_italian_short", "it"),
      code: "it",
    },
    {
      text: getTranslations("menu_language_dutch", "Dutch"),
      mobText: getTranslations("menu_language_dutch_short", "nl"),
      code: "nl",
    },
    {
      text: getTranslations(
        "menu_language_brazilian_portuguese",
        "Brazilian - Portuguese"
      ),
      mobText: getTranslations(
        "menu_language_brazilian_portuguese_short",
        "pt-br"
      ),
      code: "pt-br",
    },
  ];
  const translationState = useSelector((state) => state.translation);
  const languageButtonRef = useRef();
  const languageDropDownRef = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpen = () => {
    setIsMenuOpen((prev) => {
      return !prev;
    });
  };

  useOnClickOutside(
    languageButtonRef,
    () => setIsMenuOpen(false),
    languageDropDownRef
  );

  const getLastPath = () => {
    const paths = location.pathname.split("/");
    return paths.slice(2).join("/");
  };
  const getSearchParamsForIpad = () => {
    const isIpadAppWebview = searchParams.get("isIpadAppWebview");
    if (isIpadAppWebview === "true") {
      return "?isIpadAppWebview=true ";
    }
    return "";
  };
  const getURL = (code) =>
    `${
      window.location.origin
    }/${code}/${getLastPath()}${getSearchParamsForIpad()}`;

  const selectedLanguage = () => {
    const langObject = lang?.find((x) => x.code === translationState?.language);
    if (isMobile()) {
      return langObject?.mobText;
    } else {
      return langObject?.text;
    }
  };

  return (
    <>
      <div className="si-lang__wrap">
        <div className="si-lang__cta">
          <i className="f1i-global"></i>
          <CtaButton
            btnCls="si-btn si-btn__lang"
            btnType="submit"
            iconCls="f1i-chevron-down"
            btnText={selectedLanguage()}
            ariaLabel="language button"
            extraAttri={{ "aria-expanded": isMenuOpen, "aria-haspopup": true }}
            onClickProp={() => handleMenuOpen()}
            clickRef={languageButtonRef}
          />
          <div className="si-lang__list" ref={languageDropDownRef}>
            <ul>
              {lang?.map((i, index) => (
                <li key={index}>
                  <a
                    href={getURL(i.code)}
                    title={isMobile() ? i.mobText : i.text.toLowerCase()}
                    aria-label={i.text.toLowerCase()}
                    onClick={() => onClick(i.code.toUpperCase())}
                  >
                    {isMobile() ? i.text : i.text}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export { LanguageDropdown };
