import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  PLATFORM_CATEGORY,
  PLATFORM_ID,
  PLATFORM_VERSION,
} from "../../../Common/constants";
import { getPath, getTranslations } from "../../../Common/utils";
import { league } from "../../../redux/actions";
import { CtaButton, Overlays } from "../../../ReusableComponents";
import useTournamentScanario from "../../../Common/hooks/useTournamentScanario";

const LeaveLeague = ({
  leagueId,
  userTeams,
  pushLeaveLeagueDataLayer,
  pushLeaveLeaguePopupDataLayer,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const [showLeavePopup, setShowLeavePopup] = useState();
  const disableCTAs = isEos || lastGamedayRaceLocked;
  const togglePopup = () => {
    setShowLeavePopup((prev) => !prev);
  };

  const leagueState = useSelector((state) => state.league);
  const translationState = useSelector((state) => state.translation);
  const { leaveLeagueSuccess } = leagueState;

  const handleConfirm = () => {
    const payload = {
      optType: 0,
      platformId: PLATFORM_ID,
      platformVersion: PLATFORM_VERSION,
      platformCategory: PLATFORM_CATEGORY,
      leagueId: leagueId,
      teamNumber: userTeams,
    };
    dispatch(league.leaveLeague(payload));
    togglePopup();
  };

  useEffect(() => {
    if (leaveLeagueSuccess) {
      navigate(getPath(translationState, "leagues"));
      pushLeaveLeaguePopupDataLayer("CONFIRM");
    }
  }, [leaveLeagueSuccess]);

  return (
    <>
      <CtaButton
        btnCls="si-btn si-btn__secondary"
        btnText={getTranslations(
          "manage_league_leave_league_button",
          "Leave League"
        )}
        ariaLabel={getTranslations(
          "manage_league_leave_league_button",
          "Leave League"
        )}
        btnState={disableCTAs}
        onClickProp={() => {
          togglePopup();
          if (!showLeavePopup) {
            pushLeaveLeagueDataLayer();
          }
        }}
      />
      {showLeavePopup && (
        <Overlays
          overlayTitle={getTranslations(
            "manage_league_leave_league_popup_title",
            "Leave League"
          )}
          overlaySubTitle={getTranslations(
            "manage_league_leave_league_popup_message",
            "Are you sure you want to leave this league ?"
          )}
          btnPrimary={getTranslations(
            "manage_league_leave_league_popup_confirm_button",
            "Confirm"
          )}
          btnSecondary={getTranslations(
            "manage_league_leave_league_popup_cancel_button",
            "Cancel"
          )}
          overlayConfig={{ cancelBtn: true, showOverlaySub_Title: true }}
          onCancelClick={() => {
            togglePopup();
            pushLeaveLeaguePopupDataLayer("CANCEL");
          }}
          onConfirmClick={() => {
            handleConfirm();
          }}
        />
      )}
    </>
  );
};

export { LeaveLeague };
