export const noOfTeamsData = [{ value: "1" }, { value: "2" }, { value: "3" }];

export const initialFilterStateValues = {
  unlimitedNoOfTeams: false,
  minNoOfTeamsValue: 10,
  maxNoOfTeamsValue: 99999999,
  leagueOccupancyMinValue: 0,
  leagueOccupancyMaxValue: 100,
  noOfTeams: noOfTeamsData?.[0],
  isOnlyMyLeague: false,
  isSponsorLeagues: false,
  isLastChanceToJoin: false,
  isPopular: false,
  isNew: false,
};
