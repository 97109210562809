import React from "react";

const SectionCaption = ({ pageCaption }) => {
  return (
    <>
      <h3 className="si-league__heading-sub">
        <span>{pageCaption}</span>
      </h3>
    </>
  );
};

export default SectionCaption;
