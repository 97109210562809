import React from "react";
import {
  decodeName,
  getPath,
  getTranslations,
  isMobile,
} from "../../../Common/utils";
import { PreviousRaceBody } from "../PreviousRaceBody";
import { CtaButton } from "../../Buttons";
import { NoDataFound } from "../../NoDataFound";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { currentFixture } from "../../../Common/utils/fixture";
import useTournamentScanario from "../../../Common/hooks/useTournamentScanario";

const PreviousRaceCard = ({
  previousFixture,
  previousRaceResults = [],
  activeAccordions,
  updateActiveAccordion,
}) => {
  const navigate = useNavigate();
  const translationState = useSelector((state) => state.translation);
  const userState = useSelector((state) => state.user);
  const fixtureState = useSelector((state) => state.fixture);
  const TeamCount = userState?.data?.TeamCount;
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const handleCreateTeam = () => {
    navigate(getPath(translationState, "createTeam"));
  };

  return (
    <>
      <div className="si-prevRace__list">
        {Array.from("x".repeat(TeamCount))?.map((_, index) => {
          const item = previousRaceResults?.[index];
          const currentTeamNo = index + 1;

          const bestDriverConstructors = item?.bestDrivers;
          const bestDriver = bestDriverConstructors?.find(
            (item) => item?.type === "DRIVER"
          );
          const bestConstructor = bestDriverConstructors?.find(
            (item) => item?.type === "CONSTRUCTOR"
          );

          const teamName = item?.teamName;
          const racePoints = item?.racePoints;
          const racePointsDifference = item?.racePointsDifference;
          const chipUsed = item?.chipsUsed?.[0];

          const isActiveAccordion = () => {
            if (currentTeamNo === 1 && !!activeAccordions?.teamOneAccordion) {
              return true;
            }
            if (currentTeamNo === 2 && !!activeAccordions?.teamTwoAccordion) {
              return true;
            }
            if (currentTeamNo === 3 && !!activeAccordions?.teamThreeAccordion) {
              return true;
            }
            return false;
          };

          const previousRaceBodyProps = item?.bestDrivers
            ? {
                teamNo: currentTeamNo,
                racePoints,
                racePointsDifference,
                chipUsed,
                bestDriver,
                bestConstructor,
                previousFixture,
              }
            : { isNewTeam: true };

          return (
            <>
              <div
                key={index}
                className={`si-prevRace__card si-team${currentTeamNo}`}
              >
                <div
                  className="si-prevRace__card-head"
                  onClick={() => updateActiveAccordion(currentTeamNo)}
                >
                  <div key={index} className="si-myTeamHeader__serial">
                    <span className="si-myTeamHeader__serial-number">
                      T{currentTeamNo}
                    </span>
                    <span className="si-myTeamHeader__serial-text">
                      {decodeName(item?.teamName)}
                    </span>
                    {isMobile() && (
                      <div className="si-myTeamHeader__serial-cta">
                        <CtaButton
                          btnCls={"si-btn si-btn__icon"}
                          iconCls={
                            isActiveAccordion()
                              ? "f1i-chevron-up"
                              : "f1i-chevron-down"
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
                {!isMobile() && (
                  <div className="si-prevRace__card-body">
                    <PreviousRaceBody {...previousRaceBodyProps} />
                  </div>
                )}
                {isMobile() && isActiveAccordion() && (
                  <div className="si-prevRace__card-body">
                    <PreviousRaceBody {...previousRaceBodyProps} />
                  </div>
                )}
              </div>
            </>
          );
        })}
        {TeamCount < 3 && (
          <div className="si-prevRace__addCta">
            {!isMobile() ? (
              <NoDataFound
                noDataCaption={getTranslations(
                  "previous_race_results_add_teams",
                  "Add teams to view their performances"
                )}
                noDataCta={true}
                noDataConfig={{
                  btnCls:
                    "si-btn si-btn__icon si-btn__icon--primary si-btn__radius",
                  iconCls: "f1i-add",
                  onClickProp: handleCreateTeam,
                  btnState:
                    !currentFixture(fixtureState)?.IsActiveForNewUser ||
                    disableCTAs,
                }}
              />
            ) : (
              <CtaButton
                btnCls={"si-btn si-btn__secondary si-btn__secondary--outline"}
                iconCls={"f1i-add"}
                btnText={getTranslations("create_team_cta_lbl", "Create Team")}
                onClickProp={handleCreateTeam}
                btnState={
                  !currentFixture(fixtureState)?.IsActiveForNewUser ||
                  disableCTAs
                }
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default PreviousRaceCard;
