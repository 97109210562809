import React from "react";
import config from "../../../Common/config";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../../Common/constants";
import { getTranslations, getYearForImagePath } from "../../../Common/utils";
import { useSelector } from "react-redux";
function PlayerThumbnail({
  player,
  playerType,
  isNotPlaying = false,
  notPlayingOnlyIcon = true,
  currentSeasonYear = null,
}) {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const playerImage = `${CLOUDINARY_IMAGE_BASE_URL}${playerType}${player?.PlayerId}.png?v=${IMAGE_VERSION}`;
  const carImage = `${CLOUDINARY_IMAGE_BASE_URL}${webConfigData?.imagePaths?.constructors?.replace(
    "{seasonYear}",
    getYearForImagePath(webConfigData, currentSeasonYear)
  )}${player?.PlayerId}.png?v=${IMAGE_VERSION}`;
  const path = player?.Skill === 1 ? playerImage : carImage;
  return (
    <>
      <div className="si-player__thumbnail">
        <img src={path} alt={player?.PlayerId} loading="lazy" />
        {isNotPlaying && (
          <div className="si-player__status">
            {notPlayingOnlyIcon ? (
              <i className="f1i-not-playing"></i>
            ) : (
              <>
                <i className="f1i-not-playing"></i>{" "}
                <span>
                  {getTranslations("player_playing_text", "Not playing")}
                </span>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export { PlayerThumbnail };
