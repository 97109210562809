import React from "react";
import { TOTAL } from "../../Common/constants";
import { capitalizeFirstLetter, getTranslations } from "../../Common/utils";
import { statisticsTableEventData } from "../../Common/utils/statistics";

const StatisticsTable = ({ tableData, isNoNegativeStrikeOut }) => {
  return (
    <table className="si-tbl">
      <thead>
        <tr>
          <th className="si-performance__tbl-eve">
            {getTranslations("driver_stats_modal_event", "Event")}
          </th>
          <th className="si-performance__tbl-fre"></th>
          <th className="si-performance__tbl-poi">
            {getTranslations("driver_stats_modal_points", "Points")}
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData
          ?.filter((item) => item?.Event && item?.Event !== TOTAL)
          ?.map(({ Event, Frequency, Value, addClass = "" }, index) => (
            <tr key={index}>
              <td className="si-performance__tbl-eve">
                {capitalizeFirstLetter(
                  getTranslations(statisticsTableEventData.get(Event), Event)
                )}
              </td>
              <td className="si-performance__tbl-fre">
                {Frequency ? Frequency : "-"}
              </td>
              <td
                className={`si-performance__tbl-poi ${addClass} ${
                  parseInt(Value) < 0 ? "si-negative" : "si-positive"
                } `}
              >
                <span
                  className={` ${
                    isNoNegativeStrikeOut && parseInt(Value) < 0
                      ? "si-strikeOut"
                      : ""
                  }`}
                >
                  {Value ? Value : "-"}
                </span>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default StatisticsTable;
