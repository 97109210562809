import {
  FETCH_FEEDBACK_QUESTIONS_FULFILLED,
  FETCH_FEEDBACK_QUESTIONS_PENDING,
  FETCH_FEEDBACK_QUESTIONS_REJECTED,
  SUBMIT_CONSTRUCTOR_CONSENT_FULFILLED,
  SUBMIT_CONSTRUCTOR_CONSENT_PENDING,
  SUBMIT_CONSTRUCTOR_CONSENT_REJECTED,
  SUBMIT_FEEDBACK_QUESTIONS_FULFILLED,
  SUBMIT_FEEDBACK_QUESTIONS_PENDING,
  SUBMIT_FEEDBACK_QUESTIONS_REJECTED,
} from "../../constants/complete-your-profile";

const initialState = {
  feedbackQuestionsLoading: false,
  feedbackQuestionsError: false,
  feedbackQuestionsFulfilled: false,
  feedbackQuestionsData: null,
  submitFeedbackQuestionsLoading: false,
  submitFeedbackQuestionsError: false,
  submitFeedbackQuestionsFulfilled: false,
  submitFeedbackQuestionsData: null,
  submitConstructorConsentLoading: false,
  submitConstructorConsentError: false,
  submitConstructorConsentFulfilled: false,
  submitConstructorConsentData: null,
};

export default function completeYourProfileReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case FETCH_FEEDBACK_QUESTIONS_PENDING:
      return {
        ...state,
        feedbackQuestionsLoading: true,
        feedbackQuestionsError: false,
        feedbackQuestionsFulfilled: false,
        feedbackQuestionsData: null,
      };
    case FETCH_FEEDBACK_QUESTIONS_REJECTED:
      return {
        ...state,
        feedbackQuestionsLoading: false,
        feedbackQuestionsError: true,
        feedbackQuestionsFulfilled: false,
        feedbackQuestionsData: null,
      };
    case FETCH_FEEDBACK_QUESTIONS_FULFILLED:
      return {
        ...state,
        feedbackQuestionsLoading: false,
        feedbackQuestionsError: false,
        feedbackQuestionsFulfilled: true,
        feedbackQuestionsData: action.payload.data,
      };
    case SUBMIT_FEEDBACK_QUESTIONS_PENDING:
      return {
        ...state,
        submitFeedbackQuestionsLoading: true,
        submitFeedbackQuestionsError: false,
        submitFeedbackQuestionsFulfilled: false,
        submitFeedbackQuestionsData: null,
      };
    case SUBMIT_FEEDBACK_QUESTIONS_REJECTED:
      return {
        ...state,
        submitFeedbackQuestionsLoading: false,
        submitFeedbackQuestionsError: true,
        submitFeedbackQuestionsFulfilled: false,
        submitFeedbackQuestionsData: null,
      };
    case SUBMIT_FEEDBACK_QUESTIONS_FULFILLED:
      return {
        ...state,
        submitFeedbackQuestionsLoading: false,
        submitFeedbackQuestionsError: false,
        submitFeedbackQuestionsFulfilled: true,
        submitFeedbackQuestionsData: action.payload.data,
      };
    case SUBMIT_CONSTRUCTOR_CONSENT_PENDING:
      return {
        ...state,
        submitConstructorConsentLoading: true,
        submitConstructorConsentError: false,
        submitConstructorConsentFulfilled: false,
        submitConstructorConsentData: null,
      };
    case SUBMIT_CONSTRUCTOR_CONSENT_REJECTED:
      return {
        ...state,
        submitConstructorConsentLoading: false,
        submitConstructorConsentError: true,
        submitConstructorConsentFulfilled: false,
        submitConstructorConsentData: null,
      };
    case SUBMIT_CONSTRUCTOR_CONSENT_FULFILLED:
      return {
        ...state,
        submitConstructorConsentLoading: false,
        submitConstructorConsentError: false,
        submitConstructorConsentFulfilled: true,
        submitConstructorConsentData: action.payload.data,
      };
    default:
      return state;
  }
}
