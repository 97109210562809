import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useDataLayerLeagueMenuClick from "../../Common/hooks/useDataLayerLeagueMenuClick";
import { getTranslations } from "../../Common/utils";
import { Accordion } from "../Accordion";
import AccordionChild from "../Accordion/AccordionChild";
import { SectionHeadingWithCount } from "../SectionHeadingWithCount";

const LeagueTypeAccordion = ({
  leagueTypeItem,
  activeItem,
  updateActiveLeagueType = () => {},
  currentActiveItem,
  accordionType = "private",
}) => {
  const [accordionItem, setAccordionItem] = useState([leagueTypeItem]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isUpdateAccordion, setIsUpdateAccordion] = useState(false);
  let [searchParams] = useSearchParams();
  const updateActiveIndex = (index, value) => {
    if (!value) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
    setIsUpdateAccordion(true);
  };
  const updateListWithAccordion = (data) => setAccordionItem(data);
  const dataLayerLeagueMenuClicked = useDataLayerLeagueMenuClick();

  useEffect(() => {
    const leagueTypeName = searchParams.get("type");

    if (accordionType === "public") {
      if (
        leagueTypeName?.includes("public") &&
        currentActiveItem?.parentName === "public"
      ) {
        setAccordionItem([{ ...leagueTypeItem, isActive: true }]);
      }
    }
    if (accordionType === "private") {
      if (
        leagueTypeName?.includes("private") &&
        currentActiveItem?.parentName === "private"
      ) {
        setAccordionItem([{ ...leagueTypeItem, isActive: true }]);
      }
    }
  }, [
    currentActiveItem?.parentName,
    leagueTypeItem,
    searchParams,
    accordionType,
  ]);

  return (
    <Accordion
      accordionId={leagueTypeItem?.type}
      data={accordionItem}
      activeIndex={activeIndex}
      updateData={updateListWithAccordion}
      isUpdateAccordion={isUpdateAccordion}
      accordionChild={accordionItem?.map((item, index) => {
        const name = item?.name;
        const count = item?.count;
        const childrenLeagueTypes = item?.nestedLeague;
        const isActive = item?.isActive;
        return (
          <AccordionChild
            key={index}
            headComponentLHS={
              <SectionHeadingWithCount
                heading={getTranslations(`league_sidebar_${name}`, name)}
                count={count}
              />
            }
            accordionConfig={{}}
            bodyComponent={
              <>
                <ul>
                  {childrenLeagueTypes?.map((childrenType, index) => {
                    const isItemActive =
                      currentActiveItem?.parentName === item?.name &&
                      childrenType?.name === activeItem?.name;

                    const childCount = childrenType?.count;
                    const isHidden = !childCount;

                    if (isHidden) {
                      return null;
                    }

                    return (
                      <li
                        key={index}
                        className={`${isItemActive ? "si-active" : ""}`}
                        onClick={() => {
                          updateActiveLeagueType({
                            name: childrenType?.name,
                            type: childrenType?.type,
                          });
                          dataLayerLeagueMenuClicked(name, childrenType?.name);
                        }}
                      >
                        <SectionHeadingWithCount
                          key={index}
                          heading={getTranslations(
                            `league_sidebar_${name}_${childrenType?.name}`,
                            childrenType?.name
                          )}
                          count={childCount}
                        />
                        {isItemActive ? <i className="f1i-ar-right"></i> : ""}
                      </li>
                    );
                  })}
                </ul>
              </>
            }
            index={index}
            isActive={isActive}
            updateActiveIndex={updateActiveIndex}
          />
        );
      })}
    />
  );
};

export { LeagueTypeAccordion };
