import React from "react";
import { getTranslations } from "../../../../Common/utils";
import { LIVE_POINTS_CLASS } from "../../../../Common/constants";

const TotalPoints = ({
  totalPts,
  isNoNegative,
  raceLockedPointsAndLivePoints = false,
  onlyPointsLiveClass = false,
}) => {
  return (
    <>
      <div
        className={`si-totalPts__box ${
          raceLockedPointsAndLivePoints &&
          !onlyPointsLiveClass &&
          parseInt(totalPts) >= 0
            ? LIVE_POINTS_CLASS
            : ""
        }`}
      >
        <div className="si-totalPts__title">
          <span>{getTranslations("driver_stats_modal_total", "Total")}:</span>
        </div>
        <div
          className={`si-totalPts__counts ${
            parseInt(totalPts) < 0 && "si-negative"
          } ${isNoNegative && parseInt(totalPts) < 0 ? "si-strikeOut" : ""} ${
            raceLockedPointsAndLivePoints && onlyPointsLiveClass
              ? LIVE_POINTS_CLASS
              : ""
          }`}
        >
          <span>
            <em>{!isNaN(totalPts) ? totalPts : 0}</em> pts
          </span>
        </div>
      </div>
    </>
  );
};

export default TotalPoints;
