import React, { useMemo, useState, useEffect, useContext } from "react";
import { CtaButton } from "../Buttons";
import { getTranslations, isMobile } from "../../Common/utils";
import {
  LIVE_POINTS_CLASS,
  MAX_LEAGUE_NAME_CHARACTERS,
  MIN_LEAGUE_NAME_CHARACTERS,
  PORTRAIT,
} from "../../Common/constants";
import useIPadOrientation from "../../Common/hooks/useIPadOrientation";
import { SectionHeadingWithCount } from "../SectionHeadingWithCount";
import LeagueSearchSortFilter from "../LeagueSearchSortFilter";
import InputSelectBox from "../Forms/inputSelectBox";
import { useDispatch, useSelector } from "react-redux";
import { league } from "../../redux/actions";
import { currentFixture, getMatchStatus } from "../../Common/utils/fixture";
import { ProvisionalPtsTooltip } from "../ProvisionalPtsTooltip";
import MatchStatus from "../MatchStatus";
import { ToastNotification } from "../ToastNotification";
import { useSearchParams } from "react-router-dom";
import { LeagueContext } from "../../store/League/LeagueContext";
import useLivePoints from "../../Common/hooks/useLivePoints";
import Notes from "../Notes";
import useDataLayerLeagueMenuClick from "../../Common/hooks/useDataLayerLeagueMenuClick";

const LeagueFilterBar = ({
  leagueType,
  leagueCount,
  leagueTypesWithCountData = null,
  updateActiveLeagueType = () => {},
  isJoinLeaguePage = false,
  currentActiveItem,
}) => {
  const { teamLivePointsCondition } = useLivePoints();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const dataLayerLeagueMenuClicked = useDataLayerLeagueMenuClick();
  const fixtureState = useSelector((state) => state.fixture);
  const [leagueSearch, setLeagueSearch] = useState("");
  const [showSearchErrorToast, setShowSearchErrorToast] = useState(false);
  const forMobileNoSpan = isMobile();
  const { isIPad, screenDimensionOrientation } = useIPadOrientation();
  const isIPadPortrait = isIPad && screenDimensionOrientation === PORTRAIT;
  const { updateTriggerClassicPublicLeagueRefetch } = useContext(LeagueContext);

  const [searchSortFilterMobile, setSearchSortFilterMobile] = useState(false);

  const leagueTypeKey = searchParams.get("type");

  const leaguesTypeCountSelectData = useMemo(() => {
    const selectBoxData = leagueTypesWithCountData?.map((leagueType) => {
      const name = leagueType?.name;
      const type = leagueType?.type;
      const count = leagueType?.count;
      const value = JSON.stringify({ name, type });
      const optionText = `${getTranslations(
        `league_sidebar_${name}`,
        name
      )} (${count})`;

      let nestedLeague = leagueType?.nestedLeague;
      if (leagueType?.nestedLeague) {
        nestedLeague = leagueType?.nestedLeague?.map((nestedItem) => {
          const childName = nestedItem?.name;
          const childType = nestedItem?.type;
          const childCount = nestedItem?.count;
          const value = JSON.stringify({ name: childName, type: childType });
          const optionText = `${getTranslations(
            `league_sidebar_${name}_${childName}`,
            childName
          )} (${childCount})`;
          return {
            ...nestedItem,
            value,
            optionText,
          };
        });
      }

      return {
        ...leagueType,
        value,
        nestedLeague,
        child: nestedLeague,
        optionText,
      };
    });

    return selectBoxData;
  }, [leagueTypesWithCountData]);

  useEffect(() => {
    return () => {
      setLeagueSearch("");
      dispatch(league.resetLeagueSearch());
      dispatch(league.resetLeagueFilter());
      dispatch(league.resetLeagueSort());
    };
  }, [leagueType, dispatch]);

  const displayMobileSearchSortFilter = () =>
    setSearchSortFilterMobile((value) => !value);

  const handleLeagueSearchInput = (e) => {
    const value = e.target.value;
    setLeagueSearch(value);
    if (!value) {
      if (leagueTypeKey === "public-classic") {
        updateTriggerClassicPublicLeagueRefetch(true);
      }
      dispatch(league.resetLeagueSearch());
    }
  };

  useEffect(() => {
    let timerId = null;
    if (showSearchErrorToast) {
      timerId = setTimeout(() => {
        setShowSearchErrorToast(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [showSearchErrorToast]);

  const handleReportToast = () => {
    setShowSearchErrorToast(true);
  };

  const handleLeagueSearch = () => {
    if (leagueSearch?.trim()?.length < MIN_LEAGUE_NAME_CHARACTERS) {
      handleReportToast();
      return;
    }
    dispatch(league.applyLeagueSearch(leagueSearch));
  };

  return (
    <>
      <div className="si-leagueFilterBar__wrap">
        <div className="si-leagueFilterBar__grid">
          {!isMobile() && !isIPadPortrait && (
            <>
              {isJoinLeaguePage ? (
                <SectionHeadingWithCount
                  heading={getTranslations(
                    "league_landing_public_league_title",
                    "Public Leagues"
                  )}
                />
              ) : (
                <div className="si-leagueFilterBar__title">
                  <SectionHeadingWithCount
                    heading={leagueType}
                    count={leagueCount}
                  />
                </div>
              )}
              <LeagueSearchSortFilter
                handleLeagueSearch={handleLeagueSearch}
                leagueSearch={leagueSearch}
                handleLeagueSearchInput={handleLeagueSearchInput}
                isJoinLeaguePage={isJoinLeaguePage}
              />
            </>
          )}
          {(isMobile() || isIPadPortrait) && (
            <>
              <div className="si-leagueFilterBar__title">
                {isJoinLeaguePage ? (
                  <SectionHeadingWithCount
                    heading={getTranslations(
                      "join_league_title",
                      "Join a League"
                    )}
                  />
                ) : (
                  <InputSelectBox
                    selectBoxId={"leaguesLHSNav"}
                    isLeagueTypeSelect={true}
                    handleChange={(event) => {
                      const value = JSON.parse(event?.target?.value);

                      let activeItem = null;
                      for (const index in leaguesTypeCountSelectData) {
                        const item = leaguesTypeCountSelectData?.[index];

                        if (item?.nestedLeague) {
                          let nestedLeagueFound = null;

                          for (const nestedIndex in item?.nestedLeague) {
                            const currentNestedLeague =
                              item?.nestedLeague?.[nestedIndex];

                            if (
                              currentNestedLeague?.type === value?.type &&
                              currentNestedLeague?.name === value?.name
                            ) {
                              activeItem = {
                                ...currentNestedLeague,
                                isChild: true,
                                parentName: currentNestedLeague?.name,
                              };
                              nestedLeagueFound = true;
                              break;
                            }

                            if (nestedLeagueFound) {
                              break;
                            }
                          }
                        }

                        if (item?.name === value?.name) {
                          activeItem = item;
                          break;
                        }
                      }
                      updateActiveLeagueType({
                        name: activeItem?.name,
                        type: activeItem?.type,
                      });

                      // * League type selection datalayer push logic
                      const activeItemNameOriginal = activeItem?.type;
                      const activeItemNameDL = activeItem?.type?.toLowerCase();
                      const isPrivateOrPublicLeague =
                        activeItemNameDL === "private" ||
                        activeItemNameDL === "public";
                      const activeItemTypeDL = isPrivateOrPublicLeague
                        ? activeItem?.name
                        : activeItemNameOriginal;

                      dataLayerLeagueMenuClicked(
                        activeItemNameDL,
                        activeItemTypeDL
                      );
                    }}
                    selectedOption={currentActiveItem}
                    options={leaguesTypeCountSelectData}
                  />
                )}
              </div>
              <CtaButton // * This should be the Settings CTA button to display on mobile
                btnCls={`si-btn si-btn__secondary si-btn__secondary--outline ${
                  isMobile() || isIPadPortrait ? "si-btn__icon" : ""
                }`}
                iconCls={searchSortFilterMobile ? "f1i-close" : "f1i-customise"}
                btnText={getTranslations("sort_cta_text", "SORT")}
                forMobileNoSpan={forMobileNoSpan || isIPadPortrait}
                ariaLabel="Settings"
                onClickProp={displayMobileSearchSortFilter}
              />
            </>
          )}
        </div>
        {(isMobile() || isIPadPortrait) && searchSortFilterMobile && (
          <div className="si-leagueFilterBar__mobile">
            <LeagueSearchSortFilter
              handleLeagueSearch={handleLeagueSearch}
              leagueSearch={leagueSearch}
              handleLeagueSearchInput={handleLeagueSearchInput}
              isJoinLeaguePage={isJoinLeaguePage}
            />
          </div>
        )}
      </div>

      {getMatchStatus(currentFixture(fixtureState)?.MatchStatus) ===
        "PROVISIONAL POINTS" && (
        <div className="si-leagueLanding__provisional">
          <MatchStatus
            matchStatusText={getTranslations(
              "match_status_provisional_points",
              "Provisional Points"
            )}
            matchStatusIcon={"f1i-provipts"}
            matchStatusCls={"si-provipts"}
          />
        </div>
      )}
      {teamLivePointsCondition && (
        <Notes
          text={getTranslations(
            "live_points_text_league_points_update_after_session_points",
            "*Leagues will update after the session when all data is collected."
          )}
          customCls={teamLivePointsCondition ? LIVE_POINTS_CLASS : ""}
        />
      )}
      {showSearchErrorToast && (
        <ToastNotification
          type="generic"
          toastType="success"
          alertText={getTranslations(
            "league_search_minimum_characters_required",
            "Minimum 3 characters are required for search"
          )}
        />
      )}
    </>
  );
};

export { LeagueFilterBar };
