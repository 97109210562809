const paths = {
  home: { path: "" },
  prizes: { path: "prizes" },
  howToPlay: { path: "how-to-play" },
  gameRules: { path: "game-rules" },
  faqs: { path: "faqs" },
  terms: { path: "terms" },
  achievements: { path: "achievements" },
  statistics: { path: "statistics" },
  statisticsDetails: {
    path: "details",
    parent: "statistics",
  },
  createTeam: { path: "create-team" },
  myTeam: { path: "my-team", isAuth: true },
  oppTeam: { path: "opp-team", isAuth: true },
  manageTeam: {
    path: ":teamId",
    parent: "my-team",
  },
  completedRacesDetailed: {
    path: "completed-races/:gameDayId",
    parent: "my-team",
  },
  raceWeekTeam: {
    path: ":gameDayId/:phaseId/:teamNo",
    parent: "my-team",
  },
  userRaceWeekTeam: {
    path: ":userId/:gameDayId/:phaseId/:teamNo",
    parent: "my-team",
  },
  detailedMyTeam: {
    path: "detail/:gameDayId/:phaseId/:teamNo",
    parent: "my-team",
  },
  detailedOppTeam: {
    path: "detail-opp/:gameDayId/:phaseId/:teamNo",
    parent: "opp-team",
  },
  leagues: { path: "leagues", isAuth: true },
  leagueSelectTeam: {
    isAuth: true,
    path: "select-team",
    parent: "leagues",
  },
  joinLeague: {
    isAuth: true,
    path: "join/",
    parent: "leagues",
  },
  selectedJoinLeague: {
    path: "join/:code",
    parent: "leagues",
  },
  leagueMembers: { path: "leaderboard/:type/:leagueId", parent: "leagues" },
  publicLeagues: { path: "public", parent: "leagues", isAuth: true },
  privateLeagues: { path: "private", parent: "leagues", isAuth: true },
  miniLeagues: { path: "mini", parent: "leagues", isAuth: true },
  leaguesManage: {
    path: "manage/:type/:leagueId",
    parent: "leagues",
  },
  webview: { path: "webview" },
  performanceChart: { path: "performance-chart" },
  miniLeagueRedirect: { path: "mini-league-redirect" },
  whatsNew: { path: "whats-new" },
};
export default paths;
