import { feeds } from "../../apis/services";
import {
  FETCH_CONSTRUCTOR_STATISTICS,
  FETCH_DRIVER_STATISTICS,
  FETCH_RAW_STATISTICS,
  FETCH_STATISTICS,
} from "../../constants/statistics";

export const getStatistics = (playerId, livePointsBuster = false) => ({
  type: FETCH_STATISTICS,
  payload: feeds.getPlayerStatistics(playerId, livePointsBuster),
});

export const getRawStatistics = (playerId) => ({
  type: FETCH_RAW_STATISTICS,
  payload: feeds.getPlayerStatistics(playerId),
});

export const getDriverStatistics = ({ list: drivers, url, tourId }) => ({
  type: FETCH_DRIVER_STATISTICS,
  payload: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await feeds.getDriverStatistics({ url, tourId });
        resolve({ ...data, drivers });
      } catch (error) {
        reject(error);
      }
    });
  },
});

export const getConstructorStatistics = ({
  list: constructors,
  url,
  tourId,
}) => ({
  type: FETCH_CONSTRUCTOR_STATISTICS,
  payload: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await feeds.getConstructorStatistics({ url, tourId });
        resolve({ ...data, constructors });
      } catch (error) {
        reject(error);
      }
    });
  },
});
