export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const FETCH_DRIVERS_PENDING = "FETCH_DRIVERS_PENDING";
export const FETCH_DRIVERS_REJECTED = "FETCH_DRIVERS_REJECTED";
export const FETCH_DRIVERS_FULFILLED = "FETCH_DRIVERS_FULFILLED";

export const FETCH_RACE_WEEK_DRIVERS = "FETCH_RACE_WEEK_DRIVERS";
export const FETCH_RACE_WEEK_DRIVERS_PENDING =
  "FETCH_RACE_WEEK_DRIVERS_PENDING";
export const FETCH_RACE_WEEK_DRIVERS_REJECTED =
  "FETCH_RACE_WEEK_DRIVERS_REJECTED";
export const FETCH_RACE_WEEK_DRIVERS_FULFILLED =
  "FETCH_RACE_WEEK_DRIVERS_FULFILLED";
