import React, { useState, useEffect } from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { getTranslations } from "../../Common/utils";
import { useSelector } from "react-redux";

const LANDSCAPE = "Landscape";
const PORTRAIT = "Portrait";

export const isIOSSafari = () => {
  const ua = window?.navigator?.userAgent;
  const iOS = ua?.match(/iPad/i) || ua?.match(/iPhone/i);
  const webkit = ua?.match(/WebKit/i);
  const iOSSafari = iOS && webkit && !ua?.match(/CriOS/i);

  return iOSSafari;
};
const isMobile = () => {
  return /iphone|ipod|coreappios|android|nokia|blackberry|BB10|bada|tizen|mini|windows\sce|palm/i.test(
    navigator.userAgent.toLowerCase()
  );
};
function isMobileUser() {
  return isMobile() && !/iPad/i.test(navigator?.userAgent?.toLowerCase());
}

const useMobileOrientation = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [orientation, setOrientation] = useState("");
  const [screenDimensionOrientation, setScreenDimensionOrientation] =
    useState("");

  useEffect(() => {
    const updateDevice = () => {
      if (isMobileUser()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    const updateOrientation = () => {
      const windowScreenOrientation = window?.screen?.orientation?.type;

      if (windowScreenOrientation?.includes(LANDSCAPE.toLowerCase())) {
        setOrientation(LANDSCAPE);
      } else {
        setOrientation(PORTRAIT);
      }

      setScreenDimensionOrientation(
        window?.innerWidth > window?.innerHeight ? LANDSCAPE : PORTRAIT
      );
    };

    updateDevice();
    updateOrientation();
    window?.addEventListener("orientationchange", updateOrientation);
    window?.addEventListener("resize", updateDevice);

    const mediaQuery = window?.matchMedia("(orientation: portrait)");
    mediaQuery?.addEventListener("change", updateOrientation);

    return () => {
      window?.removeEventListener("orientationchange", updateOrientation);
      window?.removeEventListener("resize", updateDevice);
      mediaQuery?.removeEventListener("change", updateOrientation);
    };
  }, []);

  return { isMobile, orientation, screenDimensionOrientation };
};

const RotateToPortrait = () => {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const { isMobile, orientation, screenDimensionOrientation } =
    useMobileOrientation();

  const rotateMarkup = (
    <div className="si-rotateToPortrait__wrap">
      <div className="si-rotateToPortrait__box">
        <div className="si-rotateToPortrait__thumb">
          <img
            src={`${
              config.IMG_BASE + webConfigData?.imagePaths?.commonAssets
            }portrait-thumb.svg?v=${IMAGE_VERSION}`}
            alt="portrait-thumb"
            loading="lazy"
          />
        </div>
        <div className="si-rotateToPortrait__text">
          {getTranslations(
            "rotate_to_portrait_text",
            " Please rotate your device to portrait mode for better experience."
          )}
        </div>
      </div>
    </div>
  );

  if (
    isMobile &&
    !isIOSSafari() &&
    (orientation === LANDSCAPE || screenDimensionOrientation === LANDSCAPE)
  ) {
    return rotateMarkup;
  }

  if (isMobile && isIOSSafari() && screenDimensionOrientation === LANDSCAPE) {
    return rotateMarkup;
  }

  return null;
};

export { RotateToPortrait };
