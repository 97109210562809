export const FETCH_INFO_POPUP_CONFIG = "FETCH_INFO_POPUP_CONFIG";
export const FETCH_INFO_POPUP_CONFIG_PENDING =
  "FETCH_INFO_POPUP_CONFIG_PENDING";
export const FETCH_INFO_POPUP_CONFIG_REJECTED =
  "FETCH_INFO_POPUP_CONFIG_REJECTED";
export const FETCH_INFO_POPUP_CONFIG_FULFILLED =
  "FETCH_INFO_POPUP_CONFIG_FULFILLED";

export const FETCH_ANNOUNCEMENT_POPUP_CONFIG =
  "FETCH_ANNOUNCEMENT_POPUP_CONFIG";
export const FETCH_ANNOUNCEMENT_POPUP_CONFIG_PENDING =
  "FETCH_ANNOUNCEMENT_POPUP_CONFIG_PENDING";
export const FETCH_ANNOUNCEMENT_POPUP_CONFIG_REJECTED =
  "FETCH_ANNOUNCEMENT_POPUP_CONFIG_REJECTED";
export const FETCH_ANNOUNCEMENT_POPUP_CONFIG_FULFILLED =
  "FETCH_ANNOUNCEMENT_POPUP_CONFIG_FULFILLED";
