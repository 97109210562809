import React from "react";
import config from "../../../Common/config";
import { IMAGE_VERSION } from "../../../Common/constants";
import { useSelector } from "react-redux";
import { getYearForImagePath } from "../../../Common/utils";
function PlayerTeamLogo({
  playerTeamLogo,
  playerTeamName,
  player,
  currentSeasonYear = null,
}) {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const playerTeamNameSpanTag = playerTeamName ? (
    <span>{playerTeamName}</span>
  ) : (
    ""
  );
  return (
    <>
      <div className="si-player__teamLogoName">
        <img
          src={`${
            config.IMG_BASE
          }${webConfigData?.imagePaths?.teamLogos?.replace(
            "{seasonYear}",
            getYearForImagePath(webConfigData, currentSeasonYear)
          )}${playerTeamLogo}.png?v=${IMAGE_VERSION}`}
          alt={player}
        />
        {playerTeamNameSpanTag}
      </div>
    </>
  );
}

export { PlayerTeamLogo };
