import { useCallback } from "react";

let hasConsentData = null;

const fetchHasConsentData = () => {
  if (hasConsentData !== null) {
    // Return cached value if it's already set
    return hasConsentData;
  }

  const localStorageUserConsentString = window.localStorage.getItem(
    process.env.REACT_APP_USER_CONSENT_LOCAL_STORAGE
  );

  if (!!localStorageUserConsentString) {
    const userConsentLocalStorage = JSON.parse(localStorageUserConsentString);
    hasConsentData =
      userConsentLocalStorage?.gdpr?.consentStatus?.hasConsentData;

    return hasConsentData;
  } else {
    return false;
  }
};

const useDataLayer = () => {
  const pushToDataLayer = useCallback((event, data = {}) => {
    const hasConsentData = fetchHasConsentData();

    if (!hasConsentData) {
      return; // * return if any consent is not provided by the user.
    }

    const pageName = data?.pageName;
    const componentType = data?.componentType;
    window.dataLayer = window?.dataLayer || [];

    if (!event) {
      console.error("Error pushing data to data layer: event is falsy");
      return;
    }

    const datalayerObject = {
      event: event ? event : null,
      ...data,
      pageName: `Fantasy | ${pageName}`,
    };

    if (!!componentType) {
      datalayerObject.componentType = `Fantasy ${componentType}`;
    }

    try {
      window?.dataLayer?.push(datalayerObject);
    } catch (error) {
      console.error("Error pushing data to data layer:", error);
    }
  }, []);

  return pushToDataLayer;
};

export default useDataLayer;
