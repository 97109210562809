import axios from "../../axios";
import { getURLString } from "../../index";
const URLString = (url) => getURLString(BASE_ROUTE, url);

const BASE_ROUTE = `feeds/translations/web`;
const TRANSLATION = (languageCode = "en") => `${languageCode}.json`;

export function getTranslations(params) {
  const { languageCode } = params;
  return axios.get(URLString(TRANSLATION(languageCode)), {
    feedBuster: true,
  });
}
