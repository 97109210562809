import React from "react";
import { useSelector } from "react-redux";
import { getTranslations } from "../../Common/utils";
import { AddPlayer } from "../Formations/AddPlayer";
import { RemovePlayer } from "../Formations/RemovePlayer";

const Formations = ({
  selectedPlayers,
  playerCardConfig,
  turboDriver,
  extraTurboDriver = null,
  hideCloseButton = false,
  showReplacedPlayer = false,
  showPoints = false,
  isLateOnBoard = false,
  replacedPlayer = null,
  isFinalFix = false,
  team = {},
  currentSeasonYear = null,
}) => {
  const players = selectedPlayers?.map((x, index) => ({
    ...x,
    formationIndex: index,
  }));

  const firstRow = players?.slice(0, 2);
  const secondRow = players?.slice(2, 5);
  const thirdRow =
    players?.length > 7 ? players?.slice(6, 8) : players?.slice(5, 7);

  const getPlayerType = (player, type = 1) => {
    return player?.Skill || type;
  };

  if (hideCloseButton) {
    playerCardConfig = { ...playerCardConfig, ctaBtn: false };
  }

  const gameplayState = useSelector((state) => state.gameplay);

  return (
    <>
      <section className="si-formation__wrap">
        <div className="si-formation__container">
          <div className="si-formation__drivers">
            <div className="si-formation__row">
              <ul>
                {firstRow?.map((row, index) => (
                  <li key={index}>
                    {row?.PlayerId ? (
                      <div className="si-player__card si-remove">
                        <RemovePlayer
                          player={row}
                          playerCardConfig={{
                            ...playerCardConfig,
                            isPlayerPlaying: row?.IsActive === "1",
                          }}
                          isTurboDriver={
                            turboDriver?.PlayerId === row?.PlayerId
                          }
                          isExtraTurboDriver={
                            extraTurboDriver?.PlayerId === row?.PlayerId
                          }
                          showPoints={showPoints}
                          isLateOnBoard={isLateOnBoard}
                          isFinalFix={isFinalFix}
                          team={team}
                          currentSeasonYear={currentSeasonYear}
                        />
                      </div>
                    ) : (
                      // ? UX guy wants click here instead of the button
                      <div className="si-player__card si-add">
                        <AddPlayer player={row} type={getPlayerType(row, 1)} />
                      </div>
                    )}
                  </li>
                ))}

                {showReplacedPlayer && replacedPlayer && (
                  <li>
                    <div className="si-player__card si-remove">
                      <RemovePlayer
                        player={replacedPlayer}
                        playerCardConfig={{
                          ...playerCardConfig,
                          addingExtraClass: "si-replaced",
                          isPlayerPlaying: replacedPlayer.IsActive === "1",
                        }}
                        dataText={getTranslations(
                          "final_fix_replaced_text",
                          "Replaced"
                        )}
                        showPoints={showPoints}
                        isLateOnBoard={isLateOnBoard}
                        isFinalFix={isFinalFix}
                        team={team}
                        currentSeasonYear={currentSeasonYear}
                      />
                    </div>
                  </li>
                )}
              </ul>
            </div>

            <div className="si-formation__row">
              <ul>
                {secondRow?.map((row, index) => (
                  <li key={index}>
                    {row?.PlayerId ? (
                      <div className="si-player__card si-remove">
                        <RemovePlayer
                          player={row}
                          playerCardConfig={{
                            ...playerCardConfig,
                            isPlayerPlaying: row?.IsActive === "1",
                          }}
                          isTurboDriver={
                            turboDriver?.PlayerId === row?.PlayerId
                          }
                          isExtraTurboDriver={
                            extraTurboDriver?.PlayerId === row?.PlayerId
                          }
                          showPoints={showPoints}
                          isLateOnBoard={isLateOnBoard}
                          isFinalFix={isFinalFix}
                          team={team}
                          currentSeasonYear={currentSeasonYear}
                        />
                      </div>
                    ) : (
                      <div className="si-player__card si-add">
                        <AddPlayer player={row} type={getPlayerType(row, 1)} />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="si-formation__constructors">
            <div className="si-formation__row">
              <ul>
                {thirdRow?.map((row, index) => (
                  <li key={index}>
                    {row?.PlayerId ? (
                      <div className="si-player__card si-remove">
                        <RemovePlayer
                          player={row}
                          playerCardConfig={{
                            ...playerCardConfig,
                            ctaBtn: isFinalFix
                              ? false
                              : hideCloseButton
                              ? false
                              : true,
                          }}
                          showPoints={showPoints}
                          isLateOnBoard={isLateOnBoard}
                          team={team}
                          currentSeasonYear={currentSeasonYear}
                        />
                      </div>
                    ) : (
                      <div className="si-player__card si-add">
                        <AddPlayer player={row} type={getPlayerType(row, 2)} />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Formations };
