import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { CtaButton } from "../Buttons";
import { getTranslations } from "../../Common/utils";
import { useSelector } from "react-redux";

const WhatsNew = ({
  wnSectionHeading,
  wnSectionTitle,
  wnSectionThumb,
  wnSectionCaption,
  wnSectionCta,
  wnSectionThumbAlt,
  wnSectionCTAHandler = () => {},
}) => {
  const translationState = useSelector((state) => state.translation);

  return (
    <>
      <div className="si-whatNew__wrap">
        <div className="si-whatNew__grid">
          <h2 className="si-section__title">
            <span>{wnSectionHeading}</span>
          </h2>
        </div>
        <div className="si-whatNew__grid">
          <div className="si-whatNew__card">
            <div className="si-whatNew__card-top">
              {wnSectionThumb && (
                <div className="si-whatNew__card-thumb">
                  <img
                    src={`${config.IMG_BASE}${wnSectionThumb}?v=${IMAGE_VERSION}`}
                    alt={wnSectionThumbAlt}
                    loading="lazy"
                  />
                </div>
              )}
              {wnSectionTitle && (
                <div className="si-whatNew__card-title">{wnSectionTitle}</div>
              )}
            </div>
            <div className="si-whatNew__card-bot">
              {wnSectionCaption && (
                <div
                  className="si-whatNew__card-caption"
                  dangerouslySetInnerHTML={{
                    __html: wnSectionCaption.replace(
                      /{{LANGUAGE}}/g,
                      translationState?.language
                    ),
                  }}
                ></div>
              )}
              {wnSectionCta && (
                <div className="si-whatNew__card-cta">
                  <CtaButton
                    btnCls="si-btn si-btn__white"
                    iconCls="f1i-chevron-right"
                    btnText={wnSectionCta}
                    ariaLabel={wnSectionCta}
                    btnState=""
                    onClickProp={wnSectionCTAHandler}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { WhatsNew };
