import React from "react";
import config from "../../../Common/config";
import { IMAGE_VERSION } from "../../../Common/constants";

const PageLoader = ({ isNoBG = false }) => {
  return (
    <>
      <div
        className={`si-pageloader__wrap ${
          isNoBG ? "si-pageloader__wrap--noBg" : ""
        }`}
      >
        <div className="si-pageloader__icon">
          <img
            src={`${config.IMG_BASE}loader.svg?v=${IMAGE_VERSION}`}
            alt="loader"
            loading="lazy"
          />
        </div>
      </div>
    </>
  );
};

export default PageLoader;
