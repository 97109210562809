import { MAX_CONSTRUCTORS, MAX_DRIVERS } from "../constants";
import { getTranslations, isMobile } from "../utils";

export const togglePlayer = (
  player,
  index = null,
  dispatch,
  gameplay,
  selectedPlayers,
  budget,
  gameplayState
) => {
  if (
    isPlayerAdded(player, selectedPlayers) ||
    canAddPlayer(player, selectedPlayers, budget, gameplayState)
  ) {
    dispatch(gameplay.togglePlayer({ player, index }));
  }
};
export const canAddPlayer = (
  player,
  selectedPlayers,
  budget,
  gameplayState
) => {
  const max = player.Skill == 1 ? MAX_DRIVERS : MAX_CONSTRUCTORS;
  const filteredPlayers = selectedPlayers?.filter(
    (p) => p.Skill === player.Skill
  );
  return isLimitless(gameplayState, gameplayState?.selectedTeam)
    ? !selectedPlayers?.length || filteredPlayers?.length < max
    : isPlayerWithinBudget(player, budget) &&
        (!selectedPlayers?.length || filteredPlayers?.length < max);
};
export const isPlayerAdded = (player, selectedPlayers) => {
  return selectedPlayers?.length
    ? selectedPlayers?.findIndex((p) => p.PlayerId === player.PlayerId) > -1
    : false;
};
export const isPlayerWithinBudget = (player, budget) => {
  return player.Value <= budget;
};

export const setSelectedTab = (val, dispatch, gameplay) => {
  dispatch(gameplay.setSelectedTab(val));
};

export const togglePlayerList = (dispatch, gameplay) => {
  if (!isMobile()) return;
  dispatch(gameplay.togglePlayerList());
};

export const isInfinite = (gameplayState, teamNo) => {
  return (
    (Array.isArray(gameplayState?.selectedBooster) &&
      gameplayState?.selectedBooster[teamNo]?.id === 2) ||
    isLimitless(gameplayState, teamNo)
  );
};

export const isLimitless = (gameplayState, teamNo) => {
  return (
    Array.isArray(gameplayState?.selectedBooster) &&
    gameplayState?.selectedBooster[teamNo]?.id === 1
  );
};

export const isWildCard = (gameplayState, teamNo) => {
  return (
    Array.isArray(gameplayState?.selectedBooster) &&
    gameplayState?.selectedBooster[teamNo]?.id === 2
  );
};

export const infiniteIcon = () => {
  return `<i class="f1i-infinite"><i/>`;
};

export const isFinalFix = (gameplayState) => {
  return (
    Array.isArray(gameplayState?.selectedBooster) &&
    gameplayState?.selectedBooster[gameplayState?.selectedTeam]?.id === 3
  );
};

export const isFinalFixConfirmed = (gameplayState) => {
  return (
    Array.isArray(gameplayState?.selectedBooster) &&
    gameplayState?.teams[gameplayState?.selectedTeam]?.boosterid === 3
  );
};

export const isExtraDRS = (gameplayState) => {
  return (
    Array.isArray(gameplayState?.selectedBooster) &&
    gameplayState?.selectedBooster[gameplayState?.selectedTeam]?.id === 6
  );
};

export const isExtraDRSConfirmed = (gameplayState) => {
  return (
    Array.isArray(gameplayState?.selectedBooster) &&
    gameplayState?.teams[gameplayState?.selectedTeam]?.boosterid === 6
  );
};

export const isLimitlessConfirmed = (gameplayState) => {
  return (
    Array.isArray(gameplayState?.selectedBooster) &&
    gameplayState?.teams[gameplayState?.selectedTeam]?.boosterid === 1
  );
};

export const isWildcardConfirmed = (gameplayState) => {
  return (
    Array.isArray(gameplayState?.selectedBooster) &&
    gameplayState?.teams[gameplayState?.selectedTeam]?.boosterid === 2
  );
};

export const isReplacedPlayer = (player, replacedPlayer) => {
  return player?.PlayerId === replacedPlayer?.PlayerId;
};

export const isUnlimitedTransfers = (availableTransfers, maxTransfers) => {
  return availableTransfers === 0 && maxTransfers === 0;
};

export const isTurboDriverChanged = (gameplayState) => {
  if (Array.isArray(gameplayState?.teams) && isNaN(gameplayState?.selectedTeam))
    return false;

  return (
    gameplayState?.selectedTurboDriver &&
    gameplayState?.teams &&
    gameplayState?.selectedTurboDriver?.PlayerId !==
      gameplayState?.teams[gameplayState?.selectedTeam]?.selectedCaptain
        ?.PlayerId
  );
};

export const isExtraTurboDriverChanged = (gameplayState) => {
  if (Array.isArray(gameplayState?.teams) && isNaN(gameplayState?.selectedTeam))
    return false;

  return (
    gameplayState?.selectedExtraTurboDriver &&
    gameplayState?.teams &&
    gameplayState?.selectedExtraTurboDriver?.PlayerId !==
      gameplayState?.teams[gameplayState?.selectedTeam]?.selectedExtraDRS
        ?.PlayerId
  );
};

export const getPlayerStatsNotFoundMessage = ({
  seasonDataPlayerInActive = false,
  tabLengthIsEmpty = false,
  weekendDataPlayerInActive = false,
  showEliminatedBeforeQ3 = false,
  showNotInGrid = false,
}) => {
  if (seasonDataPlayerInActive) {
    return getTranslations(
      "driver_constructor_card_player_inactive",
      "Player is not active"
    );
  }

  if (tabLengthIsEmpty) {
    return getTranslations(
      "driver_constructor_card_modal_points_will_be_displayed_after_first_race",
      "Points will be displayed after the first race."
    );
  }

  if (weekendDataPlayerInActive) {
    return getTranslations(
      "driver_constructor_card_player_inactive",
      "Player is not active"
    );
  }

  if (showEliminatedBeforeQ3) {
    return getTranslations(
      "driver_constructor_card_eliminated_before_q3",
      "Eliminated before Q3"
    );
  }

  if (showNotInGrid) {
    return getTranslations(
      "driver_constructor_card_modal_did_not_start",
      "Not In Grid."
    );
  }

  return getTranslations(
    "driver_constructor_card_modal_points_will_be_displayed_after_race_is_completed",
    "Points will be displayed after the race is completed."
  );
};
