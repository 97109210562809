import React from "react";
import config from "../../Common/config";
import { IMAGE_VERSION } from "../../Common/constants";
import { useSelector } from "react-redux";

const SectionHeadingWithFiller = ({ customCls, heading, dark }) => {
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  return (
    <>
      <h2
        className={`si-section__heading ${dark ? "si-light" : ""} ${
          customCls ? customCls : ""
        }`}
      >
        <span>{heading}</span>
        <img
          src={`${
            config.IMG_BASE + webConfigData?.imagePaths?.patternsAsset
          }${"tracker-arrow-filler.svg"}?v=${IMAGE_VERSION}`}
          alt={heading}
          loading="lazy"
        />
      </h2>
    </>
  );
};

export { SectionHeadingWithFiller };
