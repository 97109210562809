import React from "react";
import { useSelector } from "react-redux";
import {
  getRank,
  getSponsoredLeagueImageSrc,
  h2hCurrentUser,
  h2hOpponentUser,
} from "../../Common/utils/league";
import {
  PvtPubCardInfo,
  CtaButton,
  SponsorBanner,
  LeaguePoint,
  MiniLeagueMatchTimer,
  HeadToHeadPoints,
} from "../../ReusableComponents";
import {
  getTranslations,
  getWebPurifyStatus,
  getYearForImagePath,
} from "../../Common/utils";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";

const LeagueCard = ({
  data,
  hideRanks = false,
  isJoinALeaguePage = false,
  showJoinCta = false,
  showMiniInfo = false,
  disableJoinCta = false,
  handleMiniLeagueInfoClick = () => {},
  handleMiniLeagueJoin = () => {},
  showLeagueBanner = true,
  isTypeLeagueBanner = false,
  showMaxTeams = false,
  showRightIcon = true,
  showPin = true,
  handleCardClick = () => {},
  handlePublicSponsoredLeagueJoin = () => {},
  handleJoinPublicLeague = () => {},
  isHomepageSponsoredPublicLeague = false,
  showMobileBannerOnDesktop = false,
}) => {
  const {
    countryCode = "",
    leagueName,
    memberCount,
    teamInfo,
    leagueType,
    leagueId,
    leagueAdmin,
    isAdmin,
    legaueVipFlag,
    leagueReportFlg,
    teamCount = null,
    leagueBadgeId = null,
    teamNo = [],
    maxMemberCount = null,
    isSponsor = null,
    isJoined = null,
    isReportFlag = null,
  } = data;

  const { isEos } = useTournamentScanario();
  const driverState = useSelector((state) => state.driver);
  const mixApiState = useSelector((state) => state.mixapi);
  const isProfanityEnabled = mixApiState?.data?.conf?.ipe;
  const isWebPurifyCalled = data?.isWebPurifyCalled;
  const isSystemNameUpd = data?.isSystemNameUpd;
  const webPurifyResponse = data?.webPurifyresponse;

  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  const webPurifyStatus = getWebPurifyStatus(
    isWebPurifyCalled,
    isSystemNameUpd,
    webPurifyResponse
  );

  const imageName = () => {
    if (isSponsor) {
      const imagePath = getSponsoredLeagueImageSrc(leagueType, "logo");
      return `${imagePath}/${leagueId}.png`;
    }

    switch (leagueType) {
      case "Global":
        return "chequered-flag.svg";
      case "Country":
        return `${webConfigData?.imagePaths?.countries}${countryCode}.svg`;
      case "Team":
        return `${webConfigData?.imagePaths?.teamLogos?.replace(
          "{seasonYear}",
          getYearForImagePath(webConfigData)
        )}${leagueId}.png`;
      case "Driver":
        return `${webConfigData?.imagePaths?.driversFront?.replace(
          "{seasonYear}",
          getYearForImagePath(webConfigData)
        )}${leagueId}.png`;
      case "Mini":
        return `mini-leagues/logo/${leagueId}.png`;
      default:
        return null;
    }
  };

  const isMiniLeague = leagueType === "Mini";
  const isPrivateSponsored =
    (leagueType === "C.Private" ||
      leagueType === "classic" ||
      leagueType === "Classic Private") &&
    isSponsor;
  const isPublicSponsored =
    (leagueType === "Public" ||
      leagueType === "PUBLIC" ||
      leagueType === "Classic Public" ||
      leagueType === "PUBLIC CLASSIC") &&
    isSponsor;
  const isVipLeague =
    (leagueType === "C.Private" && legaueVipFlag) ||
    leagueType === "Vip Private";
  const isH2HLeague =
    leagueType === "Private H-T-H" || leagueType === "HTH Private";

  const defaultImageName = () => {
    if (isSponsor) {
      const imagePath = getSponsoredLeagueImageSrc(leagueType, "logo");
      return `${imagePath}/0.png`;
    }
    switch (leagueType) {
      case "Global":
        return "chequered-flag.svg";
      case "Country":
        return `${webConfigData?.imagePaths?.countries}0.svg`;
      case "Team":
        return `${webConfigData?.imagePaths?.teamLogos?.replace(
          "{seasonYear}",
          getYearForImagePath(webConfigData)
        )}0.png`;
      case "Driver":
        return `${webConfigData?.imagePaths?.driversFront?.replace(
          "{seasonYear}",
          getYearForImagePath(webConfigData)
        )}0.png`;
      case "Mini":
        return `mini-leagues/logo/0.png`;
      default:
        return null;
    }
  };

  const teamID = () => {
    switch (leagueType) {
      case "Team":
        return leagueId;
      case "Driver":
        return driverState?.list?.find((x) => x.PlayerId == leagueId)?.TeamId;
      default:
        return undefined;
    }
  };

  const conditionClsName = () => {
    switch (leagueType) {
      case "Driver":
        return "si-driver";
      default:
        return undefined;
    }
  };

  const type = () => {
    switch (leagueType) {
      case "C.Private":
      case "Classic Private":
      case "classic":
        return legaueVipFlag ? 3 : 0;
      case "Vip Private":
        return 3;
      case "Private H-T-H":
      case "HTH Private":
        return 1;
      case "Public":
      case "PUBLIC":
      case "Classic Public":
      case "Global":
      case "Country":
      case "Team":
      case "Driver":
      case "PUBLIC CLASSIC":
        return 2;
      case "Mini":
        return 4;
      default:
        return undefined;
    }
  };

  const leagueTypeBannerName = () => {
    if (isSponsor) {
      return null;
    }
    switch (leagueType) {
      case "C.Private":
      case "Classic Private":
      case "classic":
        return legaueVipFlag
          ? null
          : getTranslations("league_type_banner_name_classic", "Classic");
      case "Private H-T-H":
      case "HTH Private":
        return getTranslations("league_type_banner_name_hth", "H2H");
      case "Public":
      case "PUBLIC":
      case "Classic Public":
      case "PUBLIC CLASSIC":
      case "Global":
      case "Country":
      case "Team":
      case "Driver":
        return getTranslations("league_type_banner_name_public", "Public");
      case "Mini":
        return null;
      default:
        return null;
    }
  };

  const leagueTypeWrapperClass = () => {
    const initialCls = "si-league__card--";
    let leagueTypeCls = "";
    if (!isSponsor) {
      switch (leagueType) {
        case "C.Private":
        case "Classic Private":
        case "classic":
          leagueTypeCls = legaueVipFlag ? "vip" : "classic";
          break;
        case "Vip Private":
          leagueTypeCls = "vip";
          break;
        case "Private H-T-H":
        case "HTH Private":
          leagueTypeCls = "h2h";
          break;
        case "Public":
        case "PUBLIC":
        case "Classic Public":
        case "PUBLIC CLASSIC":
        case "Global":
        case "Country":
        case "Team":
        case "Driver":
          leagueTypeCls = "public";
          break;
        // case "Global":
        //   leagueTypeCls = "global";
        //   break;
        // case "Country":
        //   leagueTypeCls = "country";
        //   break;
        // case "Team":
        //   leagueTypeCls = "team";
        //   break;
        // case "Driver":
        //   leagueTypeCls = "driver";
        //   break;
        case "Mini":
          leagueTypeCls = "mini";
          break;
        default:
          leagueTypeCls = "";
      }
    }

    if (leagueTypeCls) {
      return `${initialCls}${leagueTypeCls}`;
    } else return "";
  };

  const isGlobal = () => {
    return (
      leagueType === "Global" ||
      leagueType === "Country" ||
      leagueType === "Team" ||
      leagueType === "Driver"
    );
  };

  const showLeagueTypeBanner = () => {
    const typeNo = type();
    if (isSponsor) {
      return false;
    }
    if (typeNo === 0 || typeNo === 1 || typeNo === 2 || isGlobal()) {
      return true;
    } else {
      return false;
    }
  };
  const isUnjoinedPublicLeague = type() === 2 && !isGlobal() && !data?.isJoined;

  const cardClickProp =
    (isMiniLeague || isPublicSponsored || isPrivateSponsored) &&
    (!teamInfo?.length || !data?.isJoined)
      ? {}
      : isJoinALeaguePage ||
        isUnjoinedPublicLeague ||
        isHomepageSponsoredPublicLeague
      ? {}
      : {
          onClick: handleCardClick,
        };

  const joinALeagueCardClickProp =
    isJoinALeaguePage && data?.teamNo?.length
      ? {
          onClick: handleCardClick,
        }
      : {};

  const ButtonOrDivElement =
    !!Object.keys(cardClickProp)?.length ||
    !!Object.keys(joinALeagueCardClickProp)?.length
      ? "button"
      : "div";

  const showJoinButton = () => {
    return new Date(data.locktime).getTime() > new Date().getTime();
  };

  const sponsorBannerBaseUrl = isVipLeague
    ? "vip-leagues/banner"
    : isMiniLeague
    ? "mini-leagues/banner"
    : isPublicSponsored
    ? "sponsored-public/banner"
    : isPrivateSponsored
    ? "sponsored-private/banner"
    : null;

  const sponsorBannerMobileBaseUrl = isVipLeague
    ? "vip-leagues/mobile-banner"
    : isMiniLeague
    ? "mini-leagues/mobile-banner"
    : isPublicSponsored
    ? "sponsored-public/mobile-banner"
    : isPrivateSponsored
    ? "sponsored-private/mobile-banner"
    : null;

  return (
    <div className={`si-league__card-container ${leagueTypeWrapperClass()}`}>
      {showLeagueBanner && (
        <SponsorBanner
          baseURL={
            showMobileBannerOnDesktop
              ? sponsorBannerMobileBaseUrl
              : sponsorBannerBaseUrl
          }
          mobileBaseURL={sponsorBannerMobileBaseUrl}
          VipSponsorBannerImage={`${leagueId}.png`}
          isVipSponsorMobileBannerImage={`${leagueId}.png`}
          showLeagueTypeBanner={showLeagueTypeBanner()}
          leagueTypeBannerName={leagueTypeBannerName()}
          showPin={showPin}
          data={data}
          type={type}
          bannerInternalUrl={data.bannerInternalUrl}
          bannerUrl={data.bannerUrl}
        />
      )}
      <ButtonOrDivElement
        className="si-league__list-top"
        {...cardClickProp}
        {...joinALeagueCardClickProp}
      >
        <PvtPubCardInfo
          PvtPubCardConfig={{
            conditionClsName: conditionClsName(),
            showThumb: imageName() ? true : false,
            imageSrc: "",
            imageName: imageName(),
            defaultImageName: defaultImageName(),
            leagueName: leagueName,
            leagueCount: memberCount,
            maxLeagueCount: maxMemberCount,
            teamID: teamID(),
            showPvtPubCardChips: !isGlobal(),
            type: type(),
            adminIcon: isSponsor ? false : !!leagueAdmin || !!isAdmin,
            errorIcon: !!leagueReportFlg || !!isReportFlag,
            showProfanityIcon:
              (!!leagueAdmin || !!isAdmin) &&
              isProfanityEnabled &&
              webPurifyStatus,
            webPurifyStatus,
            showMaxTeams: showMaxTeams,
            maxTeams: teamCount,
            userJoinedTeams: teamNo?.length,
            leagueBadgeId,
            leagueType,
          }}
        />
        {hideRanks ? (
          isJoinALeaguePage && !isJoined ? (
            <>
              <div className="si-minileague__grid-cta">
                <div className="si-featuredMiniLeagues__cta">
                  <CtaButton
                    btnCls={"si-btn si-btn__primary"}
                    btnText={getTranslations("league_card_join_cta", "Join")}
                    onClickProp={handleCardClick}
                  />
                </div>
              </div>
            </>
          ) : !isJoinALeaguePage && isPublicSponsored ? (
            <>
              {!teamInfo?.length || !data?.isJoined ? (
                <div className="si-minileague__grid-cta">
                  <div className="si-featuredMiniLeagues__cta">
                    <CtaButton
                      btnCls={"si-btn si-btn__primary"}
                      btnText={getTranslations("league_card_join_cta", "Join")}
                      onClickProp={handlePublicSponsoredLeagueJoin}
                    />
                  </div>
                </div>
              ) : (
                <div className="si-minileague__grid-cta">
                  <div className="si-featuredMiniLeagues__cta">
                    <CtaButton
                      btnCls={"si-btn si-btn__primary"}
                      btnText={getTranslations("league_card_view_cta", "View")}
                      onClickProp={handleCardClick}
                    />
                  </div>
                </div>
              )}
            </>
          ) : (
            ""
          )
        ) : isUnjoinedPublicLeague ? (
          isPublicSponsored &&
          (!teamInfo?.length || !data?.isJoined) &&
          isEos ? (
            <div className="si-minileague__grid-cta">
              <div className="si-featuredMiniLeagues__cta">
                <CtaButton
                  btnCls={"si-btn si-btn__primary"}
                  btnText={getTranslations("league_card_view_cta", "View")}
                  onClickProp={handleCardClick}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="si-minileague__grid-cta">
                <div className="si-featuredMiniLeagues__cta">
                  <CtaButton
                    btnCls={"si-btn si-btn__primary"}
                    btnText={getTranslations("league_card_join_cta", "Join")}
                    onClickProp={handleJoinPublicLeague}
                  />
                </div>
              </div>
            </>
          )
        ) : isPublicSponsored && (!teamInfo?.length || !data?.isJoined) ? (
          <div className="si-minileague__grid-cta">
            <div className="si-featuredMiniLeagues__cta">
              <CtaButton
                btnCls={"si-btn si-btn__primary"}
                btnText={getTranslations("league_card_join_cta", "Join")}
                onClickProp={handlePublicSponsoredLeagueJoin}
              />
            </div>
          </div>
        ) : isPrivateSponsored && !teamInfo?.length ? (
          <div className="si-minileague__grid-cta">
            <div className="si-featuredMiniLeagues__cta">
              <MiniLeagueMatchTimer
                locktime={null}
                label={getTranslations(
                  "league_card_private_sponsored_join_using_code",
                  "League can be joined only using the league code"
                )}
                iconClass="f1i-info"
                showTimer={false}
              />
              <CtaButton
                btnCls={"si-btn si-btn__primary si-btn__primary--outline"}
                btnText={getTranslations("league_card_view_cta", "View")}
                onClickProp={handleCardClick}
              />
            </div>
          </div>
        ) : isMiniLeague && !teamInfo?.length ? (
          <div className="si-minileague__grid-cta">
            <MiniLeagueMatchTimer
              locktime={data?.locktime}
              label={getTranslations("mini_league_last_date", "Last Date:")}
            />
            <div className="si-featuredMiniLeagues__cta">
              {data?.disableonList === 1 || data?.disableonList === 2 ? (
                <CtaButton
                  btnCls={"si-btn si-btn__primary"}
                  btnText={getTranslations("league_card_view_cta", "View")}
                  onClickProp={handleCardClick}
                />
              ) : (
                <CtaButton
                  btnCls={"si-btn si-btn__primary"}
                  btnText={getTranslations(
                    "featured_mini_league_join_cta",
                    "Join"
                  )}
                  onClickProp={handleMiniLeagueJoin}
                />
              )}
            </div>
          </div>
        ) : isH2HLeague ? (
          <HeadToHeadPoints
            user={h2hCurrentUser(data?.users)}
            opponent={h2hOpponentUser(data?.users)}
          />
        ) : (
          <div className="si-league__point-grid">
            {[1, 2, 3]?.map((num, index) => {
              const currentTeam = teamInfo?.find(
                ({ teamNo }) => teamNo === num
              );

              if (!currentTeam) {
                return (
                  <LeaguePoint
                    key={`${leagueId}-${num}-${index}`}
                    LeaguePointLbl={
                      <>
                        <span className="si-myTeamHeader__serial-number">
                          T{num}
                        </span>
                        <span className="si-myTeamHeader__serial-lbl">
                          {getTranslations(
                            "league_card_team_rank_label",
                            "Rank"
                          )}
                        </span>
                      </>
                    }
                    LeaguePointNum={"-"}
                  />
                );
              }

              return (
                <LeaguePoint
                  key={`${leagueId}-${currentTeam?.teamNo}-${index}`}
                  LeaguePointLbl={
                    <>
                      <span
                        className={`si-myTeamHeader__serial-number ${
                          leagueType === "Global" && num === 1
                            ? "si-negative"
                            : ""
                        }`}
                      >
                        T{num}
                      </span>
                      <span className="si-myTeamHeader__serial-lbl">
                        {getTranslations("league_card_team_rank_label", "Rank")}
                      </span>
                    </>
                  }
                  LeaguePointNum={getRank(currentTeam?.userRank)}
                />
              );
            })}
          </div>
        )}

        {hideRanks && !showJoinCta && !showMiniInfo && showRightIcon && (
          <div className="si-league__chevronRight">
            <i className="f1i-chevron-right"></i>
          </div>
        )}
        {showJoinCta && (
          <div className="si-league__mini-cta">
            <CtaButton
              btnCls="si-btn si-btn__primary"
              btnText={getTranslations(
                "league_landing_mini_league_join_cta",
                "Join"
              )}
              ariaLabel={getTranslations(
                "league_landing_mini_league_join_cta",
                "Join"
              )}
              btnState={disableJoinCta}
              onClickProp={handleMiniLeagueJoin}
            />
          </div>
        )}
        {showMiniInfo && (
          <div className="si-league__mini-info">
            <CtaButton
              btnCls="si-btn si-btn__icon"
              iconCls="f1i-info"
              ariaLabel={getTranslations(
                "league_landing_mini_league_join_cta",
                "Join"
              )}
              onClickProp={handleMiniLeagueInfoClick}
            />
          </div>
        )}
      </ButtonOrDivElement>
      {/* <div className="si-league__list-bot">
        <div className="si-league__ranks-row">
          <div className="si-league__ranks-title">
            <span>Ranks:</span>
          </div>
          <div className="si-league__ranks-box">
            <ul>
              {teamInfo?.map((team) => (
                <li key={`${leagueId}-${team.teamNo}`}>
                  <div className="si-league__ranks-lbl">
                    <span>T{team.teamNo}</span>
                  </div>
                  <div className="si-league__ranks-nums">
                    <span>{team.userRank ? team.userRank : "-"}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="si-league__list-cta">
            <CtaButton
              btnCls="si-btn si-btn__link  si-btn__link--outline"
              iconCls="f1i-chevron-right"
              btnText=""
              ariaLabel="view all"
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export { LeagueCard };
