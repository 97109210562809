import { useSelector } from "react-redux";

const useLivePoints = () => {
  const mixAPIState = useSelector((state) => state.mixapi);

  const mixAPIData = mixAPIState?.data;

  // * Mix api web killswitch for teams and leagues (platform specific killswitch)
  const mixApiTeamWebKillswitch = mixAPIData?.conf?.teamkillswitch?.web;
  const mixApiLeagueWebKillswitch = mixAPIData?.conf?.leaguekillswitch?.web;

  // *  displayLivePoints is condition to display the live points scenario
  // * (During session lock and first provisional points calculation)
  const displayLivePoints = mixAPIData?.isLive;

  // * Display live points condition when both killswitch and displayLivePoints are true
  const teamLivePointsCondition =
    !!mixApiTeamWebKillswitch && !!displayLivePoints;
  const leagueLivePointsCondition =
    !!mixApiLeagueWebKillswitch && !!displayLivePoints;

  return { teamLivePointsCondition, leagueLivePointsCondition };
};

export default useLivePoints;
