import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { LANDSCAPE, PORTRAIT } from "../constants";

const useIPadOrientation = () => {
  const [isIPad, setIsIPad] = useState(false);
  const [orientation, setOrientation] = useState("");
  const [screenDimensionOrientation, setScreenDimensionOrientation] =
    useState("");
  const [searchParams] = useSearchParams();
  const isIpadAppWebview = searchParams.get("isIpadAppWebview");

  useEffect(() => {
    const updateDevice = () => {
      const isAnIpad =
        !!navigator?.userAgent?.match(/iPad/i) ||
        !!/iPad/.test(navigator?.platform) ||
        window?.isIpadAppWebview ||
        isIpadAppWebview;

      if (isAnIpad) {
        setIsIPad(true);
      } else {
        setIsIPad(false);
      }
    };

    const updateOrientation = () => {
      if (
        window?.screen?.orientation?.type?.includes(LANDSCAPE.toLowerCase())
      ) {
        setOrientation(LANDSCAPE);
      } else {
        setOrientation(PORTRAIT);
      }

      setScreenDimensionOrientation(
        window?.innerWidth > window?.innerHeight ? LANDSCAPE : PORTRAIT
      );
    };

    updateDevice();
    updateOrientation();
    window?.addEventListener("orientationchange", updateOrientation);
    window?.addEventListener("resize", updateDevice);

    const mediaQuery = window?.matchMedia("(orientation: portrait)");
    mediaQuery?.addEventListener("change", updateOrientation);

    return () => {
      window?.removeEventListener("orientationchange", updateOrientation);
      window?.removeEventListener("resize", updateDevice);
      mediaQuery?.removeEventListener("change", updateOrientation);
    };
  }, [isIpadAppWebview]);

  return { isIPad, orientation, screenDimensionOrientation };
};

export default useIPadOrientation;
