import { LEAGUE_BUSTER } from "../../../../Common/busters";
import axios from "../../axios";
import { getURLString } from "../../index";
const URLString = (url) => getURLString(BASE_ROUTE, url);
const MiniLeagueURLString = (url) => getURLString(MINI_LEAGUE_BASE_ROUTE, url);

const BASE_ROUTE = `services/user/leaderboard`;
const MINI_LEAGUE_BASE_ROUTE = `services/user/minileague`;

const GET_USER_PRIVATE_LEADER_BOARD = (
  guid,
  optType,
  leagueId,
  gameDayId,
  phaseId,
  pageNo,
  topNo
) =>
  `${guid}/pvtleagueuserrankget/${optType}/${leagueId}/${gameDayId}/${phaseId}/${pageNo}/${topNo}/`;

const GET_H2H_LEADER_BOARD = (
  guid,
  optType,
  leagueId,
  gameDayId,
  phaseId,
  pageNo,
  topNo
) =>
  `${guid}/h2htoprankget/${optType}/${leagueId}/${gameDayId}/${phaseId}/${pageNo}/${topNo}/`;

const GET_USER_PUBLIC_LEADER_BOARD = (
  guid,
  optType,
  leagueId,
  tourGameDayId,
  phaseId,
  pageNo,
  topNo
) =>
  `${guid}/publicuserrankget/${optType}/${leagueId}/${tourGameDayId}/${phaseId}/${pageNo}/${topNo}`;

const GET_GLOBAL_LEADER_BOARD = (
  optType,
  tourGameDayId,
  phaseId,
  pageNo,
  topNo
) => `userrankget/${optType}/${tourGameDayId}/${phaseId}/${pageNo}/${topNo}/`;

const GET_USER_GLOBAL_LEADER_BOARD = (
  guid,
  optType,
  tourGameDayId,
  phaseId,
  pageNo,
  topNo
) =>
  `${guid}/userrankget/${optType}/${tourGameDayId}/${phaseId}/${pageNo}/${topNo}/`;

const GET_FAVORITE_TEAM_LEADER_BOARD = (
  optType,
  teamId,
  tourGameDayId,
  phaseId,
  userGuid
) =>
  `favteamuserrankget/${optType}/${teamId}/${tourGameDayId}/${phaseId}/${userGuid}`;

const GET_USER_FAVORITE_TEAM_LEADER_BOARD = (
  guid,
  optType,
  teamId,
  tourGameDayId,
  phaseId
) =>
  `${guid}/favteamuserrankget/${optType}/${teamId}/${tourGameDayId}/${phaseId}`;

const GET_COUNTRY_TEAM_LEADER_BOARD = (
  optType,
  userGuid,
  countryId,
  tourGamedayId,
  phaseId
) =>
  `countryuserrankget/${optType}/${userGuid}/${countryId}/${tourGamedayId}/${phaseId}`;

const GET_USER_COUNTRY_TEAM_LEADER_BOARD = (
  guid,
  optType,
  countryId,
  tourGamedayId,
  phaseId
) =>
  `${guid}/countryuserrankget/${optType}/${countryId}/${tourGamedayId}/${phaseId}`;

const GET_DRIVER_TEAM_LEADER_BOARD = (
  optType,
  socialId,
  driverId,
  tourGamedayId,
  phaseId
) =>
  `driveruserrankget/${optType}/${socialId}/${tourGamedayId}/${driverId}/${phaseId}`;

const GET_USER_DRIVER_TEAM_LEADER_BOARD = (
  guid,
  optType,
  socialId,
  driverId,
  tourGamedayId,
  phaseId
) =>
  `${guid}/driveruserrankget/${optType}/${socialId}/${tourGamedayId}/${driverId}/${phaseId}`;

// export function getPrivateLeaderBoard(params) {
//   const { optType, leagueId, gameDayId, phaseId, pageNo, topNo } = params;
//   return axios.get(
//     URLString(
//       GET_PRIVATE_LEADER_BOARD(
//         optType,
//         leagueId,
//         gameDayId,
//         phaseId,
//         pageNo,
//         topNo
//       )
//     )
//   );
// }

const GET_USER_MINI_LEAGUE_LEADER_BOARD = (
  guid,
  optType,
  leagueId,
  tourGamedayId,
  phaseId,
  pageNo,
  topNo
) =>
  `${guid}/miniuserrankget/${optType}/${leagueId}/${tourGamedayId}/${phaseId}/${pageNo}/${topNo}`;

export function getUserPrivateLeaderBoard(params) {
  const { guid, optType, leagueId, gameDayId, phaseId, pageNo, topNo } = params;
  return axios.get(
    URLString(
      GET_USER_PRIVATE_LEADER_BOARD(
        guid,
        optType,
        leagueId,
        gameDayId,
        phaseId,
        pageNo,
        topNo
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getH2HLeaderBoard(params) {
  const { guid, optType, leagueId, gameDayId, phaseId, pageNo, topNo } = params;
  return axios.get(
    URLString(
      GET_H2H_LEADER_BOARD(
        guid,
        optType,
        leagueId,
        gameDayId,
        phaseId,
        pageNo,
        topNo
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

// export function getPublicLeaderBoard(params) {
//   return axios.post(URLString(GET_PUBLIC_LEADER_BOARD), params);
// }

export function getUserPublicLeaderBoard(params) {
  const { guid, optType, leagueId, tourGameDayId, phaseId, pageNo, topNo } =
    params;
  return axios.get(
    URLString(
      GET_USER_PUBLIC_LEADER_BOARD(
        guid,
        optType,
        leagueId,
        tourGameDayId,
        phaseId,
        pageNo,
        topNo
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getGlobalLeaderBoard(params) {
  const { optType, tourGameDayId, phaseId, pageNo, topNo } = params;
  return axios.get(
    URLString(
      GET_GLOBAL_LEADER_BOARD(optType, tourGameDayId, phaseId, pageNo, topNo)
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getUserGlobalLeaderBoard(params) {
  const { guid, optType, tourGameDayId, phaseId, pageNo, topNo } = params;
  return axios.get(
    URLString(
      GET_USER_GLOBAL_LEADER_BOARD(
        guid,
        optType,
        tourGameDayId,
        phaseId,
        pageNo,
        topNo
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getFavoriteTeamLeaderBoard(params) {
  const { optType, teamId, tourGameDayId, phaseId, userGuid } = params;
  return axios.get(
    URLString(
      GET_FAVORITE_TEAM_LEADER_BOARD(
        optType,
        teamId,
        tourGameDayId,
        phaseId,
        userGuid
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getUserFavoriteTeamLeaderBoard(params) {
  const { guid, optType, teamId, tourGameDayId, phaseId, userGuid } = params;
  return axios.get(
    URLString(
      GET_USER_FAVORITE_TEAM_LEADER_BOARD(
        guid,
        optType,
        teamId,
        tourGameDayId,
        phaseId
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getCountryLeaderBoard(params) {
  const { optType, userGuid, countryId, tourGamedayId, phaseId } = params;
  return axios.get(
    URLString(
      GET_COUNTRY_TEAM_LEADER_BOARD(
        optType,
        userGuid,
        countryId,
        tourGamedayId,
        phaseId
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getUserCountryLeaderBoard(params) {
  const { guid, optType, userGuid, countryId, tourGamedayId, phaseId } = params;
  return axios.get(
    URLString(
      GET_USER_COUNTRY_TEAM_LEADER_BOARD(
        guid,
        optType,
        countryId,
        tourGamedayId,
        phaseId
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getDriverLeaderBoard(params) {
  const { optType, socialId, driverId, tourGamedayId, phaseId } = params;
  return axios.get(
    URLString(
      GET_DRIVER_TEAM_LEADER_BOARD(
        optType,
        socialId,
        driverId,
        tourGamedayId,
        phaseId
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getUserDriverLeaderBoard(params) {
  const { guid, optType, socialId, driverId, tourGamedayId, phaseId } = params;
  return axios.get(
    URLString(
      GET_USER_DRIVER_TEAM_LEADER_BOARD(
        guid,
        optType,
        socialId,
        driverId,
        tourGamedayId,
        phaseId
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}

export function getUserMiniLeagueLeaderBoard(params) {
  const { guid, optType, leagueId, tourGamedayId, phaseId, pageNo, topNo } =
    params;
  return axios.get(
    MiniLeagueURLString(
      GET_USER_MINI_LEAGUE_LEADER_BOARD(
        guid,
        optType,
        leagueId,
        tourGamedayId,
        phaseId,
        pageNo,
        topNo
      )
    ),
    { addBusterIfAvailable: true, busterCookieName: LEAGUE_BUSTER }
  );
}
