import React from "react";
import { useSelector } from "react-redux";
import core from "../../../Common/core";
import { getTranslations } from "../../../Common/utils";

function PlayerStats({ player }) {
  const gameplayState = useSelector((state) => state.gameplay);
  const { selectedView } = gameplayState;

  const showTitle = () => {
    return core.isMobile() && selectedView === "grid";
  };

  return (
    <>
      <div className="si-player__stats">
        <ul>
          <li>
            {showTitle() && (
              <div className="si-player__stats-lbl">
                <span>
                  {getTranslations("create_team_sort_head_1", "% Picked")}
                </span>
              </div>
            )}
            <div className="si-player__stats-nums">
              <span>
                {player?.SelectedPercentage !== undefined &&
                player?.SelectedPercentage !== ""
                  ? Number(player?.SelectedPercentage).toFixed(2)
                  : 0}
                %
              </span>
            </div>
          </li>
          <li>
            {showTitle() && (
              <div className="si-player__stats-lbl">
                <span>
                  {getTranslations("create_team_sort_head_2", "Points")}
                </span>
              </div>
            )}
            <div className="si-player__stats-nums">
              <span>
                {`
                ${
                  player?.OverallPpints !== undefined &&
                  player?.OverallPpints !== ""
                    ? isNaN(player?.OverallPpints)
                      ? 0
                      : Number(player?.OverallPpints)
                    : 0
                } ${getTranslations("driver_stats_modal_points_short", "Pts")}`}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export { PlayerStats };
