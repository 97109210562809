import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useTournamentScanario = () => {
  const constraintsState = useSelector((state) => state.constraints);
  const ActiveTour = constraintsState?.data?.ActiveTour;

  const [isEos, setIsEos] = useState(() => {
    if (ActiveTour === 3) {
      return true;
    } else {
      return false;
    }
  });
  const [lastGamedayRaceLocked, setLastGamedayRaceLocked] = useState(() => {
    if (ActiveTour === 2) {
      return true;
    } else {
      return false;
    }
  });
  const [isActiveTournament, setIsActiveTournament] = useState(() => {
    if (ActiveTour === 1) {
      return true;
    } else {
      return false;
    }
  });
  const [isPreTournament, setIsPreTournament] = useState(() => {
    if (ActiveTour === 0) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (ActiveTour === 0) {
      setIsPreTournament(true);
    }
    if (ActiveTour === 1) {
      setIsActiveTournament(true);
    }
    if (ActiveTour === 2) {
      setLastGamedayRaceLocked(true);
    }
    if (ActiveTour === 3) {
      setIsEos(true);
    }
  }, [ActiveTour]);

  return { isEos, lastGamedayRaceLocked, isActiveTournament, isPreTournament };
};

export default useTournamentScanario;
